import type { Placement } from "@popperjs/core";
import React from "react";
import { Tooltip } from "reactstrap";

interface ToolTipProps {
  children?: React.ReactNode;
  target: string | HTMLElement | React.RefObject<HTMLElement>;
  placement?: Placement;
  isOpen?: boolean;
  className?: string;
  toggle?: () => void;
  trigger?: string;
  autohide?: boolean;
}

const ToolTipComponent: React.FC<ToolTipProps> = ({
  children,
  target,
  ...rest
}: ToolTipProps) => {
  return (
    <Tooltip target={target} {...rest}>
      {children}
    </Tooltip>
  );
};

export default ToolTipComponent;
