import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import {
  Badges,
  DefaultButton,
  H4,
  MUIIcons,
  P,
  SecondaryButton,
} from "../../../AbstractElements";
import { WBT } from "../../../Api/constants";
import CourseActivity from "../../../CommonElements/CourseActivity";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import LearningPlanCourseCard from "../../../container/LearningPlanCourseCard";
import PlayCourseMaterial from "../../../container/PlayCourseMaterial";
import ProjectDescription from "../../../container/ProjectDescription";
import ScheduleListContainer from "../../../container/ScheduleListContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  downloadCertificate,
  getScheduleCourse,
  getScheduleDetails,
  mailCourse,
  mapScheduleCourse,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { getLocationList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Completed,
  CourseID,
  CourseType,
  DesignedToEvaluateYourFoundational,
  Download,
  EnrollMe,
  FailedToDownloadFileFrom,
  KnowledgeAssessment,
  LearnerDocument,
  LearningOutcomes,
  MailMe,
  MailMeAddToILP,
  Material,
  OJTAssessment,
  PreCourseWorkHeader,
  RateNow,
  Resume,
  Retake,
  Start,
  ThisCourseIncludes,
  TrainerDocument,
  WelcomeToPrerequisite,
  WhatYouWillLearnInThisCourse,
} from "../../../utils/Constant";
import "./style.scss";
import {
  assessmentStatus,
  convertMinutesToHours,
  getEllipsedFileName,
  isDateToday,
  scheduledTabs,
} from "../../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  getFromLocalStorage,
} from "../../../utils/helper/localStorageutils";

type ValidMUIIcons =
  | "CalendarTodayOutlined"
  | "HelpOutline"
  | "SignalCellularAltOutlined"
  | "PlaceOutlined"
  | "PeopleAltOutlined"
  | "PersonOutlineOutlined"
  | "FileCopyOutlined"
  | "TextSnippetOutlined"
  | "EmojiEventsOutlined"
  | "AccessTimeOutlined";

const CourseDetails = () => {
  const [courseTab, setCourseTab] = useState("details");
  const [isModal, setIsModal] = useState(false);
  const [locationNameMap, setLocationNameMap] = useState({});
  const [userNameMap, setUserNameMap] = useState({});

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const courseIdParam = params.get("course-id");

  const { scheduledDetails } = useAppSelector(state => state.course);

  const { course_id } = location.state;
  const { scheduleCourse } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);

  const loginData = getFromLocalStorage(LOGGED_IN_USER);

  const { course_detail, course_materials, learning_outcome } =
    scheduledDetails;

  const { locationList } = useAppSelector(state => state.master);

  useEffect(() => {
    dispatch(getLocationList());
  }, []);

  useEffect(() => {
    if (!courseIdParam) {
      dispatch(
        getScheduleDetails({
          courseId: course_id,
        })
      );
    }
  }, [courseIdParam]);

  useEffect(() => {
    const userMapObj: { [key: string]: { user_name: string } } = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseIdParam) {
      dispatch(
        getScheduleDetails({
          courseId: Number(courseIdParam),
        })
      );
    }
  }, [courseIdParam]);

  useEffect(() => {
    const locationMapObj: { [key: string]: { name: string } } = {};
    locationList?.location?.forEach(location => {
      locationMapObj[location.id] = location;
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  const getScheduleList = tabStatus => {
    dispatch(
      getScheduleCourse({
        status: tabStatus,
        course_id: course_id,
      })
    );
  };

  const getTrainer = trainers => {
    if (!trainers) {
      return {};
    }
    const firstTrainerId = userNameMap[trainers[0].user_id];
    const remainingTrainers = trainers.slice(1);
    const remainingNames = remainingTrainers.map(
      trainer => userNameMap[trainer.user_id]?.user_name
    );
    return {
      userName: firstTrainerId?.user_name,
      userData: remainingNames,
    };
  };

  const courseData: { icon: ValidMUIIcons | ""; value: string }[] = [
    {
      icon: "AccessTimeOutlined",
      value:
        course_detail?.schedule_details?.start_date &&
        course_detail?.schedule_details?.end_date
          ? `${course_detail?.schedule_details?.start_date} - ${course_detail?.schedule_details?.end_date}`
          : "",
    },
    {
      icon: "CalendarTodayOutlined",
      value:
        course_detail?.schedule_details?.nomination_start_date &&
        course_detail?.schedule_details?.nomination_end_date
          ? `Nomination starts: ${course_detail?.schedule_details?.nomination_start_date} - ${course_detail?.schedule_details?.nomination_end_date}`
          : "",
    },
    {
      icon: "SignalCellularAltOutlined",
      value: course_detail?.curriculum?.length
        ? course_detail?.curriculum
            .map(item => item.expertise_level)
            .filter((level, index, self) => self.indexOf(level) === index)
            .join(", ")
        : "",
    },
    {
      icon: "PlaceOutlined",
      value:
        locationNameMap[course_detail?.schedule_details?.location_id]?.name ||
        "",
    },
    {
      icon: "PeopleAltOutlined",
      value:
        course_detail?.schedule_details?.batch_minimum_size &&
        course_detail?.schedule_details?.batch_maximum_size
          ? `${course_detail?.schedule_details?.batch_minimum_size} - ${course_detail?.schedule_details?.batch_maximum_size}`
          : "",
    },
  ];

  if (getTrainer(null).userName) {
    courseData.push({
      icon: "PersonOutlineOutlined",
      value: `${getTrainer(null).userName} +${getTrainer(null)?.userData?.length}`,
    });
  }

  const filteredCourseData = courseData.filter(item => item.value);

  const courseInlcudes = () => {
    const courseIncludes: { icon: ValidMUIIcons | ""; value: string }[] = [];
    const overview = course_detail?.overview;

    overview?.duration &&
      courseIncludes.push({
        icon: "AccessTimeOutlined",
        value: `${convertMinutesToHours(overview.duration)} h`,
      });

    overview?.knowledge_assessment_count &&
      courseIncludes.push({
        icon: "FileCopyOutlined",
        value: `${overview.knowledge_assessment_count} ${KnowledgeAssessment}`,
      });

    overview?.ojt_assessment_count &&
      courseIncludes.push({
        icon: "FileCopyOutlined",
        value: `${overview.ojt_assessment_count} ${OJTAssessment}`,
      });

    overview?.learner_document_count &&
      courseIncludes.push({
        icon: "PlaceOutlined",
        value: `${overview.learner_document_count} ${LearnerDocument}`,
      });

    overview?.trainer_document_count &&
      courseIncludes.push({
        icon: "TextSnippetOutlined",
        value: `${overview.trainer_document_count} ${TrainerDocument}`,
      });

    overview?.learning_outcome_count &&
      courseIncludes.push({
        icon: "TextSnippetOutlined",
        value: `${overview.learning_outcome_count} ${LearningOutcomes}`,
      });

    return courseIncludes;
  };

  const getIconName = (icon: ValidMUIIcons | ""): ValidMUIIcons => {
    return icon || "HelpOutline";
  };

  const downloadActivity = () => {
    const dowloadURLs = course_materials.map(item => item.file_url);
    handleDownload(dowloadURLs);
  };

  const getActivityStatus = (item, handleClick) => {
    if (item.status === assessmentStatus.inProgress) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Resume}
        </button>
      );
    } else if (item.status === assessmentStatus.yetToStart) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Start}
        </button>
      );
    } else if (item.status === assessmentStatus.retake) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Retake}
        </button>
      );
    } else if (
      item.status === assessmentStatus.passed ||
      item.status === assessmentStatus.failed
    ) {
      return (
        <div className="badge-approved-bg px-2 rounded-pill d-flex align-items-center gap-1">
          <MUIIcons className="icon" iconName="CheckOutlined" size={12} />
          {Completed}
        </div>
      );
    } else if (item.status === "activity") {
      return (
        <button
          onClick={downloadActivity}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Download}
        </button>
      );
    } else {
      return (
        <button
          onClick={handleClick}
          disabled={
            item.status === "courseRate" &&
            isDateToday(course_detail.schedule_details?.end_date)
          }
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {RateNow}
        </button>
      );
    }
  };

  const handlePrequiesitesClick = item => {
    return navigate(`/course-catalog/course-details?course-id=${item.id}`, {
      state: {
        ...location.state,
      },
    });
  };

  const getPrerequisiteStatus = item => {
    if (item.status === "completed") {
      return <P className="primary-text-color">{item.status}</P>;
    }

    return (
      <button
        onClick={() => handlePrequiesitesClick(item)}
        className="border-0 bg-transparent pointer primary-text-color"
      >
        {item.status}
      </button>
    );
  };

  const transformedData = course_detail?.pre_course_work?.map(item => ({
    courseId: item.id.toString(),
    status: item.status,
    courseName: item.name,
    domainName: [],
    completedOn: null,
    scheduledOn: null,
    assignedBy: null,
    courseType: null,
  }));

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`${FailedToDownloadFileFrom} ${url}: `, error);
        }
      }
    }
  };

  const handleTabClick = (tabId: any) => {
    setCourseTab(tabId);
    if (tabId !== "details") {
      getScheduleList(tabId);
    }
  };

  const handleModal = () => {
    setIsModal(prevState => !prevState);
  };

  const isAllCompleted = () => {
    return scheduledDetails?.course_detail?.pre_course_work.every(
      item => item.status === "completed"
    );
  };

  const handleEnroll = () => {
    const payload = {
      type: "course",
      user_id: loginData.id,
      id: [course_id],
    };
    dispatch(
      mapScheduleCourse({
        courseContent: payload,
      })
    );
  };

  const handleMailAndEnroll = async (enroll = false) => {
    const notCompletedIds = scheduledDetails?.course_detail?.pre_course_work
      .filter(item => item.status !== "completed")
      .map(item => item.id);

    const payload = {
      type: "interested_course",
      user_id: loginData.id,
      id: notCompletedIds,
    };
    const mailResponse = await dispatch(
      mailCourse({
        courseContent: payload,
      })
    );

    if (mailResponse && enroll) {
      const enrollPayload = {
        type: "interested_course",
        user_id: loginData.id,
        id: notCompletedIds,
      };
      dispatch(
        mapScheduleCourse({
          courseContent: enrollPayload,
        })
      );
    }
  };

  const handleDownloadCertificate = () => {
    const payload = {
      type: course_detail?.course_type,
      courseName: course_detail?.course_name,
    };
    if (course_detail.course_type === WBT) {
      payload["courseId"] = course_detail.id;
    } else {
      payload["courseScheduleId"] =
        course_detail.schedule_details.course_schedule_id;
    }
    dispatch(downloadCertificate({ ...payload }));
  };

  return (
    <div className="page-body schedule-details">
      <Row>
        <Col md="8" sm="4">
          <ProjectDescription
            title={course_detail?.course_name}
            description={course_detail?.description}
            courseValues={[
              {
                label: CourseType,
                value: course_detail?.course_type,
                id: CourseType,
              },
              {
                label: CourseID,
                value: course_detail?.course_id,
                id: CourseID,
              },
            ]}
            scheduledOn={course_detail?.schedule_details?.start_date}
            isDownloadCertificate={{
              name:
                course_detail?.completion_status === "completed" &&
                course_detail.id,
              handleDownload: handleDownloadCertificate || course_detail.id,
            }}
          />

          {course_detail?.course_type === WBT && (
            <Row className="mt-5">
              {course_materials.map((item, index) => (
                <Col md="6" sm="6" key={item.file_name + index}>
                  <PlayCourseMaterial
                    material={item}
                    courseId={course_detail?.id}
                  />
                </Col>
              ))}
            </Row>
          )}

          {course_detail?.course_type !== WBT && (
            <Card className="mt-3">
              <CardBody>
                <JustifyTabs
                  tabs={[{ id: "details", label: "Details" }, ...scheduledTabs]}
                  activeTab={courseTab}
                  onTabClick={handleTabClick}
                />
              </CardBody>
            </Card>
          )}

          {courseTab === "details" && learning_outcome.length > 0 && (
            <Card>
              <CardHeader>
                <H4>{WhatYouWillLearnInThisCourse}</H4>
              </CardHeader>
              <CardBody className="">
                {learning_outcome.map(item => (
                  <div
                    className="d-flex gap-2 mb-3 align-items-center"
                    key={item.id}
                  >
                    <MUIIcons
                      className="primary-icon-color"
                      iconName="CheckCircleOutlined"
                      size={27}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.outcome}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          {courseTab !== "details" && (
            <Card>
              <CardBody className="">
                <ScheduleListContainer
                  data={scheduleCourse?.scheduled_courses}
                />
              </CardBody>
            </Card>
          )}
        </Col>

        <Col md="4">
          {filteredCourseData.length > 0 && (
            <Card>
              <CardBody className="schedule-details__course-data">
                {course_detail?.schedule_details &&
                  filteredCourseData?.map(item => (
                    <div className="d-flex gap-2 mb-3" key={item.icon}>
                      <MUIIcons
                        className="course-description__calender"
                        size={18}
                        iconName={getIconName(item.icon)}
                      />
                      <P className="course-description__schedule-on m-0">
                        {item.value}
                      </P>
                    </div>
                  ))}
                {course_detail?.course_type === WBT && (
                  <div className="border-top">
                    <DefaultButton
                      className="w-100 mt-3"
                      color="primary"
                      onClick={handleModal}
                    >
                      I’m Interested !
                    </DefaultButton>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {scheduledDetails?.course_detail?.course_prerequisite?.length > 0 && (
            <Card>
              <CardHeader>
                <H4>Prerequisite</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {scheduledDetails?.course_detail?.course_prerequisite?.map(
                  item => (
                    <div className="" key={item.id}>
                      <CourseActivity
                        name={item.name}
                        getStatus={() => getPrerequisiteStatus(item)}
                      />
                    </div>
                  )
                )}
              </CardBody>
            </Card>
          )}

          <Modal
            isOpen={isModal}
            toggle={handleModal}
            className="modal-body"
            centered={true}
          >
            <ModalHeader toggle={handleModal}>
              <H4>Prerequisite Course</H4>
            </ModalHeader>
            <ModalBody>
              <P className="mb-3">
                {WelcomeToPrerequisite} {course_detail?.course_name}
                {DesignedToEvaluateYourFoundational}
              </P>

              {scheduledDetails?.course_detail?.pre_course_work?.map(item => (
                <div className="" key={item.id}>
                  <CourseActivity
                    name={item.name}
                    getStatus={() =>
                      getActivityStatus(item, () => {
                        return;
                      })
                    }
                  />
                </div>
              ))}
            </ModalBody>
            <ModalFooter>
              {isAllCompleted() ? (
                <DefaultButton
                  className="w-100"
                  color="primary"
                  onClick={handleEnroll}
                >
                  {EnrollMe}
                </DefaultButton>
              ) : (
                <div className="d-flex w-100">
                  <SecondaryButton
                    onClick={() => handleMailAndEnroll()}
                    className="secondary-button w-50"
                  >
                    {MailMe}
                  </SecondaryButton>
                  <DefaultButton
                    className="w-50"
                    color="primary"
                    onClick={() => handleMailAndEnroll(true)}
                  >
                    {MailMeAddToILP}
                  </DefaultButton>
                </div>
              )}
            </ModalFooter>
          </Modal>

          {course_detail?.pre_course_work?.length > 0 && (
            <Card>
              <CardHeader>
                <H4>{PreCourseWorkHeader}</H4>
              </CardHeader>
              <CardBody className="mt-2">
                <LearningPlanCourseCard data={transformedData} />
              </CardBody>
            </Card>
          )}

          {courseIdParam && (
            <Card>
              <CardHeader>
                <H4>{Material}</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {course_materials.map((item, index) => (
                  <div className="mb-3" key={item.file_name + index}>
                    <Badges className="w-100" color="light text-dark">
                      <div className="d-flex align-items-center">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span className="text-start m-1 w-100 gap-1 p-1 mb-2">
                          {getEllipsedFileName(item.file_name)}
                        </span>
                        <MUIIcons
                          className="pointer"
                          onClick={() => handleDownload([item.file_url])}
                          size={24}
                          iconName="FileDownloadOutlined"
                        />
                      </div>
                    </Badges>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          {courseInlcudes()?.length > 0 && (
            <Card>
              <CardHeader>
                <H4>{ThisCourseIncludes}:</H4>
              </CardHeader>
              <CardBody className="schedule-details__course-includes">
                {courseInlcudes().map(item => (
                  <div className="d-flex gap-2 mb-3" key={item.icon}>
                    <MUIIcons
                      className="course-description__calender"
                      size={18}
                      iconName={getIconName(item.icon)}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.value}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseDetails;
