import { Navigate, Outlet } from "react-router-dom";

import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../utils/helper/localStorageutils";

const PrivateRoute = () => {
  const login = getFromLocalStorage(LOGGED_IN_USER);
  return login ? (
    <Outlet />
  ) : (
    <Navigate to={`${process.env.PUBLIC_URL}/login`} />
  );
};

export default PrivateRoute;
