import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H2, H1 } from "../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  courseTypeMap,
  sourceTypeMap,
  statusMap,
} from "../../../Api/constants";
import CourseSummary from "../../../CommonElements/CourseSummaryCard";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import MultiProgressBar from "../../../CommonElements/MultiProgressBar";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import LearningPlanCourseCard, {
  LearningPlanCardData,
} from "../../../container/LearningPlanCourseCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getILPCourse,
  getOverallStatistics,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  CourseType,
  ILT,
  Select,
  SelfEnrolledStatistics,
  VILT,
  WBT,
  Webinar,
} from "../../../utils/Constant";
import {
  courseFilterDropdownList,
  calculateValuePercentage,
  multiProgressData,
  ilpCourseSummaryData,
} from "../../../utils/helper/helper";

const ILPSelfEnrollment = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { ilpCourseList } = useAppSelector(state => state.learningPlan);
  const { overallStatistics } = useAppSelector(state => state.learningPlan);

  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<LearningPlanCardData[]>(
    []
  );
  const [noDataFound, setNoDataFound] = useState(false);
  const [fyYear, setFyYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    fetchCourses();
  }, [dispatch, fyYear, selectedItems]);

  const fetchCourses = async () => {
    const courseType =
      selectedItems.length > 0 ? selectedItems : ALL_COURSE_FILTER_STATUS;
    dispatch(getOverallStatistics({ selfEnrollment: true, userId: userId }));
    await dispatch(
      getILPCourse({
        courseType: courseType,
        selfEnrollment: true,
        fyYear: fyYear,
        userId: userId,
      })
    );
  };

  useEffect(() => {
    const mappedCourseData = ilpCourseList?.mapped_courses?.map(course => {
      const domainNames =
        ilpCourseList?.domain[course?.course_id]?.map(
          domainItem => domainItem.name
        ) || [];

      return {
        id: course.id,
        courseId: course.course_id.toString(),
        courseName: course.course_name,
        domainName: domainNames,
        status: statusMap[course.status],
        badgeTextColor: true,
        scheduledOn: course.created_at,
        completedOn: null,
        assignedBy: sourceTypeMap[course.source_type],
        courseType: courseTypeMap[course.course_type],
      };
    });

    setSearchResults(mappedCourseData);
    setNoDataFound(mappedCourseData?.length === 0);
  }, [ilpCourseList]);

  const handleSearch = (text: string) => {
    const filteredCourses = searchResults.filter(course =>
      course.courseName.toLowerCase().includes(text.toLowerCase())
    );
    setSearchResults(filteredCourses);
    setNoDataFound(filteredCourses.length === 0);
  };

  const handleDone = (values: { [key: string]: string[] }) => {
    if (values["course_type"]) {
      const newSelectedItems = values["course_type"];
      setSelectedItems(newSelectedItems);
      fetchCourses();
    }
  };

  const handleClear = () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
    fetchCourses();
  };

  const handleYearChange = (year: string) => {
    setFyYear(year);
  };

  const renderCourses = (data: LearningPlanCardData[]) => {
    return data && data.length > 0 ? (
      <LearningPlanCourseCard data={data} />
    ) : (
      <NoDataFound />
    );
  };

  return (
    <>
      <Card className="p-2 mb-3">
        <CardBody>
          <Row className="justify-content-center gap-5 gap-lg-0">
            <Col className="mt-3" lg={4} xs={12}>
              <H2 className="text-black">{SelfEnrolledStatistics}</H2>
              <H1 className="mt-1 custom-overall-percentage">
                {overallStatistics &&
                  `${calculateValuePercentage(
                    overallStatistics.completed_courses,
                    overallStatistics.total_courses
                  )}%`}
              </H1>
              <div className="mt-2">
                {overallStatistics?.total_courses > 0 && (
                  <MultiProgressBar
                    progressBars={multiProgressData(overallStatistics)}
                  />
                )}
              </div>
            </Col>
            <Col lg={8} xs={12}>
              {overallStatistics && (
                <CourseSummary data={ilpCourseSummaryData(overallStatistics)} />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {ILT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.ilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {VILT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.vilt}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Webinar} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.webinar}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {WBT} :
                <strong className="text-black">
                  {ilpCourseList?.course_type_count?.wbt}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end"
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <div className="mt-2">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: CourseType,
                        key: "course_type",
                        tooltipText: `${Select} ${CourseType}`,
                        options: courseFilterDropdownList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {noDataFound ? <NoDataFound /> : renderCourses(searchResults)}
    </>
  );
};

ILPSelfEnrollment.propTypes = {
  userId: PropTypes.number,
};
export default ILPSelfEnrollment;
