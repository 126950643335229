import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";

import { Btn, H4 } from "../../../../../AbstractElements";
import { ALL_USERS_STATUS, STATUS_200 } from "../../../../../Api/constants";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import CommonModal from "../../../../../CommonElements/Modal";
import StepperHorizontal from "../../../../../CommonElements/StepperHorizontal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  deleteScheduled,
  editScheduledAssessmentCatlog,
  getScheduledDetailsByScheduleId,
  handleBackButton,
  handleNextButton,
  scheduleAssessmentCatlog,
} from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../../Service";
import {
  Assessment,
  Back,
  Close,
  Confirm,
  ConfirmSchedule,
  ConfirmScheduleBody,
  ConfirmScheduleHeader,
  END_DATE_REQUIRED,
  Next,
  PleaseAddSomeUsersToSchedule,
  PleaseProvideRequiredInformation,
  Save,
  START_DATE_GREATER_ERROR,
  START_DATE_REQUIRED,
} from "../../../../../utils/Constant";
import {
  formatDate,
  scheduleAssessmentStepperData,
  scrollToTop,
  showToast,
} from "../../../../../utils/helper/helper";

import ParticipantForm from "./forms/ParticipantsForm";
import ScheduleDetailsForm from "./forms/ScheduleDetailsForm";

const ScheduleAssessmentsCatlog = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { assessmentId, scheduleId, type, page } = location.state || "";
  const { numberLevel, showFinish, scheduledDetails } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [userCardDetailIds, setUserCardDetailIds] = useState([]);
  const [scheduleDates, setScheduleDates] = useState({
    start_date: new Date(),
    end_date: new Date(),
  });
  const { usersList } = useAppSelector(state => state.user);
  const [errors, setErrors] = useState({});
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [enrolledUsersIds, setEnrolledUsersIds] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (scheduleId && assessmentId) {
      setUserCardDetailIds([]);
      setEnrolledUsers([]);
      setEnrolledUsersIds([]);
      setDeletedIds([]);
      getAllData();
    }
  }, [scheduleId, assessmentId]);

  useEffect(() => {
    if (scheduleId && page && page === 2) {
      dispatch(handleNextButton());
    }
  }, [page]);

  const getAllData = async () => {
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getScheduledDetailsByScheduleId({
        id: scheduleId,
        assessment_id: assessmentId,
      })
    );
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (scheduledDetails) {
      const dates = {
        start_date: scheduledDetails?.start_date
          ? new Date(scheduledDetails?.start_date)
          : new Date(),
        end_date: scheduledDetails?.end_date
          ? new Date(scheduledDetails?.end_date)
          : new Date(),
      };
      setScheduleDates(dates);

      const users = [];
      const ids = [];
      scheduledDetails?.participants?.forEach(item => {
        ids.push(item?.user_id);
        users.push({
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap ? userMap[item?.user_id]?.user_name : null,
          detail: userMap ? userMap[item?.user_id]?.designation : null,
          id: item?.user_id,
          delete_id: item?.id,
        });
      });
      setEnrolledUsersIds(ids);
      setEnrolledUsers(users);
    }
  }, [scheduledDetails]);

  const handleDateChange = (name: string, date: Date) => {
    setScheduleDates(prevState => ({
      ...prevState,
      [name]: date || null,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleNextClick = async () => {
    const newErrors: any = {};

    if (scheduleDates?.start_date === null) {
      newErrors.start_date = START_DATE_REQUIRED;
    }

    if (scheduleDates?.end_date === null) {
      newErrors.end_date = END_DATE_REQUIRED;
    }

    if (scheduleDates?.start_date && scheduleDates?.end_date) {
      if (scheduleDates?.end_date < scheduleDates?.start_date) {
        newErrors.start_date = START_DATE_GREATER_ERROR;
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showToast(PleaseProvideRequiredInformation, "error");
      return;
    }

    if (!showFinish && !scheduleId) {
      dispatch(handleNextButton());
    }

    if (page === 1 && scheduleId) {
      await scheduleUpdate();
    }

    if (showFinish) {
      if (userCardDetailIds?.length === 0 && !scheduleId) {
        showToast(PleaseAddSomeUsersToSchedule, "error");
        return;
      }
      toggleModal();
    }
    scrollToTop();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const renderButtonText = () => {
    if (showFinish) {
      return scheduleId ? Save : ConfirmSchedule;
    } else {
      return scheduleId ? Save : Next;
    }
  };

  const scheduleUpdate = async () => {
    const users = [];
    userCardDetailIds?.forEach(item => {
      users.push({
        user_id: item,
      });
    });

    const payload: any = {
      assessment_id: assessmentId,
      start_date: formatDate(scheduleDates?.start_date),
      end_date: formatDate(scheduleDates?.end_date),
      participants: users,
    };
    let response = null;
    if (scheduleId) {
      payload.id = scheduleId;
      response = await dispatch(
        editScheduledAssessmentCatlog({ data: payload })
      );
    } else {
      response = await dispatch(scheduleAssessmentCatlog({ data: payload }));
    }
    if (
      response?.payload?.data?.status_code === STATUS_200 ||
      response?.payload?.data?.schedule_id
    )
      return navigate(
        `${process.env.PUBLIC_URL}/assessments/assessment-details?assessmentId=${assessmentId}`
      );
  };

  return (
    <div className="page-body pb-5">
      <CommonModal
        sizeTitle={ConfirmScheduleHeader}
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          if (deletedIds?.length > 0) {
            const participants = [];
            deletedIds?.forEach(item => {
              participants.push({ participant_id: item });
            });
            const payload = {
              id: scheduleId,
              assessment_id: assessmentId,
              participants: participants,
            };
            await dispatch(deleteScheduled({ data: payload }));
          }

          await scheduleUpdate();
        }}
        primaryBtnText={Confirm}
      >
        {ConfirmScheduleBody}
      </CommonModal>
      <Card className="mb-5 pb-5">
        <CardHeader>
          <H4 className="mt-2">{Assessment}</H4>
        </CardHeader>
        <CardBody>
          <div className="basic-wizard important-validation">
            {!scheduleId && (
              <StepperHorizontal
                data={scheduleAssessmentStepperData}
                level={numberLevel}
              />
            )}
            <div>
              {numberLevel === 1 && (
                <ScheduleDetailsForm
                  start_date={scheduleDates?.start_date}
                  end_date={scheduleDates?.end_date}
                  errors={errors}
                  onDateChange={handleDateChange}
                  type={type}
                />
              )}
              {numberLevel === 2 && (
                <ParticipantForm
                  enrolledUsersIds={enrolledUsersIds}
                  enrolledUsers={enrolledUsers}
                  setEnrolledUsers={setEnrolledUsers}
                  userCardDetailIds={userCardDetailIds}
                  setUserCardDetailIds={setUserCardDetailIds}
                  setEnrolledUsersIds={setEnrolledUsersIds}
                  deletedIds={deletedIds}
                  setDeletedIds={setDeletedIds}
                  type={type}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <FixedFooter>
        <div className="wizard-footer d-flex gap-3 justify-content-end">
          <Btn
            outline
            className="alert-light-primary"
            color="primary"
            onClick={() => {
              scrollToTop();
              return navigate(
                `${process.env.PUBLIC_URL}/assessments/assessment-details?assessmentId=${assessmentId}`
              );
            }}
          >
            {Close}
          </Btn>
          {numberLevel > 1 && !scheduleId && (
            <Btn
              outline
              className="alert-light-primary"
              color="primary"
              onClick={() => {
                scrollToTop();
                dispatch(handleBackButton());
              }}
            >
              {Back}
            </Btn>
          )}
          <Btn color="primary" onClick={handleNextClick}>
            {renderButtonText()}
          </Btn>
        </div>
      </FixedFooter>
    </div>
  );
};

export default ScheduleAssessmentsCatlog;
