import { createContext, useContext, useState, ReactNode } from "react";

interface AppContextType {
  activeMenu: string[];
  setActiveMenu: (menuItems: string[]) => void;
  updateActiveMenu: (menuItems: string[]) => void;
  showSwitchRoleModal: boolean;
  setShowSwitchRoleModal: (isShow: boolean) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [activeMenu, setActiveMenu] = useState<string[]>([]);
  const [showSwitchRoleModal, setShowSwitchRoleModal] = useState(false);

  const updateActiveMenu = (menu: string[]) => {
    setActiveMenu(menu);
  };

  return (
    <AppContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        updateActiveMenu,
        showSwitchRoleModal,
        setShowSwitchRoleModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
