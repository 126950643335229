import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";

import { Role, Select, SwitchRoleInformation } from "../../utils/Constant";
import { roleMapping } from "../../utils/helper/helper";
import {
  getFromLocalStorage,
  LOGGED_IN_USER,
} from "../../utils/helper/localStorageutils";
import LabelTooltip from "../LabelTooltip";

interface SwitchModalContentProps {
  selectedRole: string;
  setSelectedRole: (value: string) => void;
  errors: {
    selectedRole?: string;
  };
}

const transformArray = (inputArray: string[]) => {
  return inputArray.map(label => ({
    id: label,
    label: roleMapping[label].value,
    value: roleMapping[label].key,
  }));
};

const SwitchModalContent: React.FC<SwitchModalContentProps> = ({
  selectedRole,
  setSelectedRole,
  errors,
}) => {
  const [roleOptions, setRoleOptions] = useState<
    { id: string; label: string; value: string }[]
  >([]);

  useEffect(() => {
    const loginData = getFromLocalStorage(LOGGED_IN_USER);
    if (loginData?.mappedRoles) {
      const availableRolesToSwitch = loginData.mappedRoles.filter(
        item => item !== loginData?.currentRole
      );
      setRoleOptions(transformArray(availableRolesToSwitch));
    }
  }, []);

  return (
    <>
      <FormGroup>
        <LabelTooltip
          label={`${Select} ${Role}`}
          tooltipText={`${Select} ${Role}`}
          important
          placement="bottom"
        />
        <Input
          type="select"
          id="dropdown"
          value={selectedRole}
          onChange={e => {
            const value = e.target.value;
            setSelectedRole(value);
          }}
        >
          <option value="">{`${Select} ${Role}`}</option>
          {roleOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </Input>
        {errors?.selectedRole && (
          <div className="text-danger">{errors.selectedRole}</div>
        )}
      </FormGroup>
      <div dangerouslySetInnerHTML={{ __html: SwitchRoleInformation }} />
    </>
  );
};

SwitchModalContent.propTypes = {
  selectedRole: PropTypes.string.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    selectedRole: PropTypes.string,
  }),
};

export default SwitchModalContent;
