import PropTypes from "prop-types";

import "./style.scss";

const ModuleCard = ({ title, score }) => {
  return (
    <div className="module-card">
      <div className="module-card__content d-flex justify-content-between align-items-center">
        <div className="module-card__module-name">{title}</div>
        <div className="module-card__score-wrapper d-flex align-items-center gap-2">
          <div className="module-card__scrore-header">Score:</div>
          <div className="module-card__module-score">{score}</div>
        </div>
      </div>
    </div>
  );
};

ModuleCard.propTypes = {
  title: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
};

export default ModuleCard;
