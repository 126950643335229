import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";

import { Btn, MUIIcons, SVG } from "../../AbstractElements";
import "./style.scss";

interface NoDataProps {
  svg: string;
  title: string;
  description?: string;
  buttonText?: string;
  onclick?: () => void;
  showIcon?: boolean;
  showCard?: boolean;
}

const NoData: React.FC<NoDataProps> = ({
  svg,
  title,
  description,
  buttonText,
  onclick,
  showIcon = true,
  showCard = true,
}) => {
  const content = (
    <>
      <SVG iconId={svg} className="empty-folder-icon" />
      <CardTitle tag="h2" className="mb-3 primary-text-color">
        {title}
      </CardTitle>
      <CardText className="mb-4 text-center custom-no-data-description">
        {description}
      </CardText>
      {buttonText && (
        <Btn
          icon={
            showIcon && (
              <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
            )
          }
          color="primary"
          onClick={onclick}
        >
          {buttonText}
        </Btn>
      )}
    </>
  );

  return showCard ? (
    <Card className="d-flex align-items-center shadow border-0 custom-no-data-card">
      <CardBody className="d-flex flex-column align-items-center p-5 mt-5">
        {content}
      </CardBody>
    </Card>
  ) : (
    <div className="d-flex flex-column align-items-center mt-5">{content}</div>
  );
};

export default NoData;
