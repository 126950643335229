import React from "react";
import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";

import Badges from "../Badge";

interface TabData {
  title: string;
  icon?: string;
  tabId: string;
}

interface BorderTabsProps {
  tabs: TabData[];
  activeTab: string;
  handleTabClick: (tabId: string) => void;
  showCard?: boolean;
}

const BorderTabs: React.FC<BorderTabsProps> = ({
  tabs,
  activeTab,
  handleTabClick,
  showCard = true,
}) => {
  const onTabClick = (tabId: string) => {
    handleTabClick(tabId);
  };

  const navContent = (
    <Nav tabs className="primary mb-0" id="bottom-tab">
      {tabs.map((tab: any) => (
        <NavItem key={tab.tabId}>
          <NavLink
            className={`nav-border pointer tab-primary primary ${
              activeTab === tab.tabId ? "active" : ""
            }`}
            onClick={() => onTabClick(tab.tabId)}
          >
            {tab.icon && <i className={`icofont ${tab.icon}`}></i>}
            {tab.title}
            {tab.count && (
              <Badges className="notification-badge bg-primary text-white">
                {tab.count}
              </Badges>
            )}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );

  return showCard ? (
    <Card>
      <CardBody className="m-1">{navContent}</CardBody>
    </Card>
  ) : (
    navContent
  );
};

export default BorderTabs;
