import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import "./style.scss";

interface RatioInputProps {
  label: string;
  maxValue: number;
  value: number;
  onChange: (value: number) => void;
}

export const RatioInput: React.FC<RatioInputProps> = ({
  maxValue,
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>(
    String(value).padStart(2, "0")
  );

  useEffect(() => {
    setInputValue(String(value).padStart(2, "0"));
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.match(/^\d{0,2}$/)) {
      setInputValue(newValue);
    }
  };

  const handleInputBlur = () => {
    let numericValue = parseInt(inputValue, 10);
    if (isNaN(numericValue) || numericValue < 0 || numericValue > maxValue) {
      numericValue = value;
    }
    setInputValue(String(numericValue).padStart(2, "0"));
    onChange(numericValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  return (
    <div className="time-input">
      <input
        className="time-value"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

RatioInput.propTypes = {
  label: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};
