import PropTypes from "prop-types";
import React from "react";

import { MUIIcons } from "../../AbstractElements";

interface ReportModalProps {
  data: {
    name: string;
    is_passed: boolean;
  }[];
}

const ReportModal: React.FC<ReportModalProps> = ({ data }) => {
  return (
    <>
      {data?.map((item, index) => {
        const isChecked = item.is_passed;

        return (
          <div key={index} className="d-flex align-items-center gap-2 my-2">
            <MUIIcons
              iconName={isChecked ? "CheckCircleOutline" : "CancelOutlined"}
              className={isChecked ? "custom-check-icon" : "custom-cancel-icon"}
              size={22}
            />
            <span className="text-black">{item.name}</span>
          </div>
        );
      })}
    </>
  );
};

ReportModal.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      is_passed: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default ReportModal;
