import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H4 } from "../../../../AbstractElements";
import Divider from "../../../../CommonElements/Divider";
import UserProfileContainer from "../../../../container/UserProfileContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMenteesUnderMentorList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getDomainList,
  getSbuList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { Menteess } from "../../../../utils/Constant";
import { formatCustomDate } from "../../../../utils/helper/helper";

const MentorMenteeList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getAllMenteesUnderMentor } = useAppSelector(state => state.learner);
  const { domainList, sbuList } = useAppSelector(state => state.master);
  const { userDetailsList } = useAppSelector(state => state.user);

  const [combinedMenteeData, setCombinedMenteeData] = useState([]);
  const [sbuNames, setSbuNames] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getSbuList());
    dispatch(getAllMenteesUnderMentorList({ status: null }));
  }, [dispatch]);

  useEffect(() => {
    const menteeIds =
      getAllMenteesUnderMentor?.mentees?.map(item => item.mentee_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, getAllMenteesUnderMentor]);

  useEffect(() => {
    const data = (getAllMenteesUnderMentor?.mentees || []).map(mentee => {
      const userDetails = userDetailsList?.users?.find(
        user => user.id === mentee.mentee_id
      );
      return { ...mentee, ...userDetails };
    });
    setCombinedMenteeData(data);
  }, [userDetailsList]);

  useEffect(() => {
    const sbuMapping = sbuList?.sbu?.reduce(
      (sbu, sbuItem) => {
        sbu[sbuItem.id] = sbuItem.name;
        return sbu;
      },
      {} as { [key: number]: string }
    );
    setSbuNames(sbuMapping || {});
  }, [sbuList]);

  const getIcons = (
    domain,
    role,
    sbuId,
    countOfSessions,
    latestSessiondate
  ) => [
    { id: 1, iconName: "DomainOutlined", value: domain },
    { id: 2, iconName: "EngineeringOutlined", value: role },
    { id: 3, iconName: "BadgeOutlined", value: sbuId },
    { id: 4, iconName: "CastForEducationOutlined", value: countOfSessions },
    { id: 5, iconName: "CalendarTodayOutlined", value: latestSessiondate },
  ];

  const onRowClick = (
    mentorMenteeId: number,
    menteeId: number,
    countOfSessions: number,
    latestSessiondate: string
  ) => {
    navigate(`${process.env.PUBLIC_URL}/mentoring/mentee-details`, {
      state: { mentorMenteeId, menteeId, countOfSessions, latestSessiondate },
    });
  };

  const getDomainName = (domainId: number) => {
    const domain = domainList?.domain?.find(item => item.id === domainId);
    return domain ? domain.domain_name : "";
  };

  return (
    <Card>
      <CardBody>
        <H4 className="fw-bold mt-3">{Menteess}</H4>
        <Divider />
        {combinedMenteeData?.map(mentee => (
          <>
            <UserProfileContainer
              key={mentee.mentor_mentee_id}
              userDetails={{
                name: mentee?.user_name,
                empId: mentee?.employee_id || "-",
                mobile: mentee?.phone,
                id: mentee?.mentee_id,
              }}
              iconItems={getIcons(
                getDomainName(mentee?.domain_id),
                mentee?.job_role_name,
                sbuNames[mentee?.sbu],
                mentee?.count_of_sessions,
                formatCustomDate(mentee?.latest_session_date)
              )}
              onRowClick={() =>
                onRowClick(
                  mentee?.mentor_mentee_id,
                  mentee?.mentee_id,
                  mentee?.count_of_sessions,
                  mentee?.latest_session_date
                )
              }
            />
            <Divider />
          </>
        ))}
      </CardBody>
    </Card>
  );
};

export default MentorMenteeList;
