import PropTypes from "prop-types";
import { Input, Table } from "reactstrap";

import "./style.scss";
import { Replacement } from "../../utils/Constant";
import Badges from "../Badge";

const ParticipantAttendanceTable = ({
  userMap,
  participants,
  dates,
  title,
  onHandleChange,
  isTrainer,
  mark,
  replacedTrainerId,
}) => {
  return (
    <div className="participant-attendance-table">
      <div className="table-responsive">
        <Table bordered className="scrollable-table">
          <thead>
            <tr>
              <th>{title}</th>
              {dates.map(date => (
                <th key={date}>{date}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(participants)?.map(participant => {
              const participantData = participants[participant];

              return (
                <tr key={userMap[participant]?.email}>
                  <td>
                    <div>
                      {userMap[participant]?.user_name}
                      {replacedTrainerId?.includes(parseInt(participant)) &&
                        isTrainer && (
                          <Badges className="ms-2" status="draft">
                            {Replacement}
                          </Badges>
                        )}
                    </div>
                    <div>{userMap[participant]?.email}</div>
                  </td>

                  {dates.map(date => (
                    <td key={date}>
                      <div className="d-flex gap-1">
                        {[1, 2].map(sessionNumber => (
                          <Input
                            disabled={!mark}
                            key={sessionNumber}
                            value={
                              participantData.sessions[date]?.[sessionNumber]?.[
                                "is_present"
                              ]
                                ? "P"
                                : "A"
                            }
                            name="role"
                            type="select"
                            className={
                              participantData.sessions[date]?.[sessionNumber]?.[
                                "is_present"
                              ]
                                ? "dropdown-present"
                                : "dropdown-absent"
                            }
                            onChange={e => {
                              const isPresent = e.target.value === "P";
                              const sessionId =
                                participantData.sessions[date]?.[
                                  sessionNumber
                                ]?.["id"];
                              const payload = {
                                id: sessionId,
                                date: date,
                                session_number: sessionNumber,
                                is_present: isPresent,
                              };
                              onHandleChange(
                                sessionId,
                                participantData.sessions[date]?.[
                                  sessionNumber
                                ]?.["attendee_id"],
                                payload,
                                isTrainer
                              );
                            }}
                          >
                            <option value="P">P</option>
                            <option value="A">A</option>
                          </Input>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

ParticipantAttendanceTable.propTypes = {
  userMap: PropTypes.object.isRequired,
  participants: PropTypes.object.isRequired,
  dates: PropTypes.array.isRequired,
  onHandleChange: PropTypes.any,
  title: PropTypes.string.isRequired,
  isTrainer: PropTypes.any,
  mark: PropTypes.bool,
  replacedTrainerId: PropTypes.array,
};

export default ParticipantAttendanceTable;
