import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { CardHeaderDropDownProps } from "../../Types/CommonElements/CommonElementsTypes";

const CardHeaderDropDown = ({
  firstItem,
  secondItem,
  thirdItem,
  mainTitle,
  menuTitle,
  onClickItem,
}: CardHeaderDropDownProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const handleItemClick = (key: string) => {
    if (onClickItem) {
      onClickItem(key);
    }
  };

  return (
    <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret color="">
        {mainTitle ? <i className="icon-more-alt" /> : menuTitle}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem
          onClick={() => {
            handleItemClick("item1");
          }}
        >
          {firstItem}
        </DropdownItem>
        {secondItem && (
          <DropdownItem onClick={() => handleItemClick("item2")}>
            {secondItem}
          </DropdownItem>
        )}
        {thirdItem && (
          <DropdownItem onClick={() => handleItemClick("item3")}>
            {thirdItem}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CardHeaderDropDown;
