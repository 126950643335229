import PropTypes from "prop-types";
import { Badge } from "reactstrap";

import "./style.scss";
import H5 from "../Headings/H5Element";

export interface AnswerStatusIndicatorProps {
  color?: string;
  text?: string;
}

const AnswerStatusIndicator: React.FC<AnswerStatusIndicatorProps> = ({
  color,
  text,
}) => {
  const badgeClass =
    color === "white"
      ? "answer-indicator-badge-custom white-outline"
      : `answer-indicator-badge-custom ${color}`;

  return (
    <div className="d-flex gap-2">
      <Badge
        color={color}
        className={"answer-indicator-badge-custom " + badgeClass}
      >
        .
      </Badge>
      <H5>{text}</H5>
    </div>
  );
};

AnswerStatusIndicator.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
};

export default AnswerStatusIndicator;
