import PropTypes from "prop-types";

import { H3 } from "../../../../AbstractElements";
import "./style.scss";

interface ProfileNameDataProps {
  name: string;
  designation: string;
}

const ProfileName: React.FC<ProfileNameDataProps> = ({ name, designation }) => {
  return (
    <div className="user-profile-designation">
      <H3>{name}</H3>
      <div className="desc">{designation}</div>
    </div>
  );
};

ProfileName.propTypes = {
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
};

export default ProfileName;
