import { useEffect, useState, useCallback } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertDomainPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createDomain,
  deleteDomain,
  getManageMasterDomainList,
  getManageMasterDomainListById,
  setDomainListByIdToInitialValue,
  updateDomain,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  AddNew,
  Competency,
  DeleteDomainHeader,
  DeleteDomainMessage,
  Domain,
  Domains,
  Enter,
  Confirm,
  NoDataText,
} from "../../../utils/Constant";

const DomainPage = () => {
  const dispatch = useAppDispatch();
  const { domainList, domainListById } = useAppSelector(
    state => state.manageMasterData
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [domainToDelete, setDomainToDelete] = useState(null);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getManageMasterDomainList());
  }, [dispatch]);

  useEffect(() => {
    if (domainListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: domainListById.domain_name,
        description: domainListById.description,
      }));
    }
  }, [domainListById]);

  const AccordionData = domainList?.domain?.map(domain => ({
    id: domain.id.toString(),
    name: domain.domain_name,
    description: domain.description,
    createdOn: domain.created_at,
    modifiedOn: domain.updated_at,
    depedentBadges: [
      { id: 1, badge: `${Competency}: ${domain.competency_count}` },
    ],
  }));

  const filteredAccordionData = AccordionData?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSave = useCallback(
    (title: string, description: string) => {
      const payload: UpsertDomainPayload = { name: title, description };

      if (domainListById) {
        payload.id = domainListById.id;
        dispatch(updateDomain({ updateDomainPayload: payload })).then(() => {
          dispatch(getManageMasterDomainList());
          toggleModal();
        });
      } else {
        dispatch(createDomain({ createDomainPayload: payload })).then(() => {
          dispatch(getManageMasterDomainList());
          toggleModal();
        });
      }
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getManageMasterDomainListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setDomainToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (domainToDelete) {
      dispatch(deleteDomain({ id: domainToDelete })).then(() => {
        dispatch(getManageMasterDomainList());
        toggleDeleteConfirmModal();
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteDomainHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteDomainMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Domains}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  dispatch(setDomainListByIdToInitialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>

      {filteredAccordionData?.length > 0 ? (
        filteredAccordionData.map(item => (
          <MasterDataAccordion
            key={item.id}
            accordionItems={item}
            onEditClick={() => handleEdit(item.id)}
            onDeleteClick={() => handleDelete(item.id)}
          />
        ))
      ) : (
        <NoData svg={"empty-folder-icon"} title={NoDataText} />
      )}

      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Domain}
        placeholderText={`${Enter} ${Domain}`}
        toolTipText={`${Enter} ${Domain}`}
        initialValues={modalFormValues}
      />
    </div>
  );
};

export default DomainPage;
