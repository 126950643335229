import React, { useState } from "react";
import { Card, CardBody, Tooltip } from "reactstrap";

import { MUIIcons, H4, H5, P } from "../../AbstractElements";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../../CommonElements/Divider";
import { RM } from "../../utils/Constant";
import { sanitizeString } from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

interface CourseCardProps {
  title: string;
  courseRequestId: number;
  header: string;
  rm: string;
  requestedOn: string;
  status: string;
}

const RequestCard: React.FC<CourseCardProps> = ({
  title,
  courseRequestId,
  header,
  rm,
  requestedOn,
  status,
}) => {
  const isMobile = useIsMobile();
  const truncatedHeader =
    header?.length > 25 ? `${header?.substring(0, 15)}...` : header;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Card
      className={
        isMobile
          ? "request-course-card-responsive-height"
          : "request-course-card-height"
      }
    >
      <CommonCardHeader
        status={status}
        badge="text"
        headClass="card-no-border pb-0"
        title={[title]}
      />
      <CardBody>
        <div>
          <div className="d-flex align-items-center gap-2">
            <div>
              <div>
                <span
                  id={sanitizeString(`tooltip-course-name-${courseRequestId}`)}
                >
                  <H4 className="header-text" data-fulltext={header}>
                    {truncatedHeader}
                  </H4>
                </span>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target={sanitizeString(
                    `tooltip-course-name-${courseRequestId}`
                  )}
                  toggle={toggleTooltip}
                >
                  {header}
                </Tooltip>
              </div>
              <span className="mt-2"></span>
              <H5>
                {RM} {rm}
              </H5>
            </div>
          </div>
          <Divider />
          <div className="d-flex gap-2">
            <MUIIcons
              className="text-gray mt-1"
              size={18}
              iconName="CalendarTodayOutlined"
            />
            <P className="text-muted mt-1">{requestedOn}</P>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default RequestCard;
