import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CourseManagementData {
  basicInputFormValue: {
    course_name: string;
    course_type: string;
    assign_trainer: any[];
    assign_sme: string;
    objective: string;
  };
  loading: boolean;
  error: string | null;
}

const initialState: CourseManagementData = {
  basicInputFormValue: {
    course_name: "",
    course_type: "",
    assign_trainer: [],
    assign_sme: "",
    objective: "",
  },
  loading: false,
  error: null,
};

const CourseWizardSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCourseBasicInputFormValueToInitials: state => {
      state.basicInputFormValue = initialState.basicInputFormValue;
    },
    setBasicInputFormValue(
      state,
      action: PayloadAction<CourseManagementData["basicInputFormValue"]>
    ) {
      state.basicInputFormValue = action.payload;
    },
  },
});

export const {
  setBasicInputFormValue,
  setCourseBasicInputFormValueToInitials,
} = CourseWizardSlice.actions;
export default CourseWizardSlice.reducer;
