import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, MUIIcons, H4, P, ToolTip } from "../../AbstractElements";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../../CommonElements/Divider";
import { ILPYear } from "../../utils/Constant";
import { truncateText } from "../../utils/helper/helper";
import useIsMobile from "../../utils/helper/responsive";

export interface FloatingCourserCardData {
  courseId: string;
  domainName: string[];
  ilpYear?: string;
  courseName: string;
  courseType: string;
}

interface FloatingCourserCardDataProps {
  data: FloatingCourserCardData[];
  mainTitle?: boolean;
  firstItem?: string;
  secondItem?: string;
  onFirstItemClick?: (courseId: any) => void;
}

const FloatingCourseCard: React.FC<FloatingCourserCardDataProps> = ({
  data,
  mainTitle,
  firstItem,
  secondItem,
  onFirstItemClick,
}) => {
  const isMobile = useIsMobile();
  const [tooltipCourseNameOpen, setTooltipCourseNameOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const courseNameTooltip = (courseId: string) => {
    setTooltipCourseNameOpen(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  return (
    <Row className="mt-5">
      {data.map(item => (
        <Col key={item.courseId} sm={12} lg={4}>
          <Card
            className={`pointer ${isMobile ? "" : "learningPlan-courseCard"}`}
          >
            <CommonCardHeader
              courseId={item.courseId}
              headClass="card-no-border pb-0"
              title={item.domainName}
              mainTitle={mainTitle}
              firstItem={firstItem}
              secondItem={secondItem}
              onFirstItemClick={onFirstItemClick}
            />
            <CardBody className="card-body d-flex flex-column">
              <div className="flex-grow-1">
                <div>
                  <span id={`tooltip-course-name${item.courseId}`}>
                    <H4 className="header-text truncated-header">
                      {truncateText(item.courseName, 65)}
                    </H4>
                  </span>
                  <ToolTip
                    placement="top"
                    target={`tooltip-course-name${item.courseId}`}
                    isOpen={tooltipCourseNameOpen[item.courseId] || false}
                    toggle={() => courseNameTooltip(item.courseId)}
                  >
                    {item.courseName}
                  </ToolTip>
                </div>
              </div>
              <div className="learningPlan-courseCard-footer">
                <Divider />
                <div className="d-flex align-items-center mt-2">
                  {item.ilpYear && (
                    <div className="d-flex gap-2">
                      <MUIIcons
                        className="text-gray mt-1"
                        size={18}
                        iconName="CalendarTodayOutlined"
                      />
                      <P className="text-muted mt-1">
                        {ILPYear}: {item.ilpYear}
                      </P>
                    </div>
                  )}
                  <div className="ms-auto">
                    <Badges className="mt-1">{item.courseType}</Badges>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

FloatingCourseCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      courseId: PropTypes.string.isRequired,
      domainName: PropTypes.arrayOf(PropTypes.string).isRequired,
      ilpYear: PropTypes.string,
      courseName: PropTypes.string.isRequired,
      courseType: PropTypes.string.isRequired,
    })
  ).isRequired,
  mainTitle: PropTypes.bool,
  firstItem: PropTypes.string,
  secondItem: PropTypes.string,
  onFirstItemClick: PropTypes.func,
};

export default FloatingCourseCard;
