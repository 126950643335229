import PropTypes from "prop-types";
import React from "react";

import { H4 } from "../../AbstractElements";
import CheckList from "../../CommonElements/CheckList";
import Divider from "../../CommonElements/Divider";
import CommonModal from "../../CommonElements/Modal";
import { Done, ParticipantCheckList } from "../../utils/Constant";
import ReportModal from "../ReportModal";

interface CheckListModalProps {
  isOpen: boolean;
  toggle: () => void;
  data: CheckListModalData;
  selectedType: string;
}

interface CheckListModalData {
  name: string;
  userId: string;
  checkList: CheckListData[];
  rules: any[];
}

interface CheckListData {
  id: number;
  isChecked: boolean;
  description: string;
}

const CheckListModal: React.FC<CheckListModalProps> = ({
  isOpen,
  toggle,
  data,
  selectedType,
}) => {
  const getCheckListModalBody = () => {
    return (
      <div className="p-2">
        <H4>{data?.name}</H4>
        <div className="mt-1">{data?.userId}</div>
        <Divider />
        {selectedType === "info" && <CheckList data={data?.checkList} />}
        {selectedType === "report" && <ReportModal data={data?.rules} />}
        <Divider />
      </div>
    );
  };

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={ParticipantCheckList}
      showFooter={true}
      primaryBtnText={Done}
      onPrimaryBtnClick={toggle}
    >
      {getCheckListModalBody()}
    </CommonModal>
  );
};

CheckListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  data: PropTypes.exact({
    name: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    checkList: PropTypes.array,
    rules: PropTypes.array,
  }).isRequired,
};

export default CheckListModal;
