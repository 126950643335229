import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H4, H5, P } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import VerticalDivider from "../../CommonElements/VericalDivider";
import { DetailHeaderProps } from "../../utils/helper/propTypes";
import useIsMobile from "../../utils/helper/responsive";

interface RowProps {
  columns: DetailHeaderProps[];
}

interface DateRangeProps {
  startDate: string;
  endDate: string;
}
interface CatlogAssessmentHeading {
  heading: string;
  badgeText?: string;
  rows: RowProps[];
  dateRange?: DateRangeProps;
}

const CatlogAssessmentHeading: React.FC<CatlogAssessmentHeading> = ({
  heading,
  rows,
}) => {
  const isMobile = useIsMobile();

  const getDetail = (assign, name) => {
    return (
      <div className="flex-grow-1">
        <P className="mb-3 text-gray">{assign}</P>
        <H5 className="fw-bold fs-16">{name}</H5>
      </div>
    );
  };

  const renderColumns = (columns: DetailHeaderProps[]) => {
    if (isMobile) {
      const pairs = [];
      for (let i = 0; i < columns.length; i += 2) {
        pairs.push(columns.slice(i, i + 2));
      }
      return pairs.map((pair, index) => (
        <Row key={index} className={index > 0 ? "mt-4" : ""}>
          {pair.map((col, colIndex) => (
            <Col key={colIndex} className="d-flex col-6">
              {getDetail(col.assign, col.name)}
            </Col>
          ))}
        </Row>
      ));
    } else {
      return (
        <Row>
          {columns.map((col, colIndex) => (
            <Col key={colIndex} className="d-flex col-lg-3">
              {getDetail(col.assign, col.name)}
              {colIndex < columns.length - 1 && <VerticalDivider />}
            </Col>
          ))}
        </Row>
      );
    }
  };

  return (
    <Card>
      <CardBody className="m-2">
        <div className="d-flex justify-content-between">
          <H4>{heading}</H4>
        </div>
        <Divider />
        <div className="mt-2">
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className={rowIndex > 0 ? "mt-4" : ""}>
              {renderColumns(row.columns)}
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

CatlogAssessmentHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  badgeText: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
};

export default CatlogAssessmentHeading;
