import * as Icon from "@mui/icons-material";
import PropTypes from "prop-types";

export interface MUIIconType {
  iconName: keyof typeof Icon;
  className?: string;
  onClick?: () => void;
  size: number;
}

const MUIIcons: React.FC<MUIIconType> = ({
  iconName,
  className,
  size = 24,
  onClick,
}) => {
  const IconComp = Icon[iconName];
  return (
    <IconComp
      style={size ? { fontSize: size } : {}}
      className={className}
      onClick={onClick}
    />
  );
};

MUIIcons.propTypes = {
  iconName: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
};

export default MUIIcons;
