import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";

import { Btn, H3 } from "../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../Api/constants";
import BorderTabs from "../../../CommonElements/BorderTabs";
import DateRangeDisplay from "../../../CommonElements/DateRangeDisplay";
import Divider from "../../../CommonElements/Divider";
import CommonModal from "../../../CommonElements/Modal";
import StatusDisplay from "../../../CommonElements/StatusDisplay";
import TotalSeatCard from "../../../container/TotalSeatCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCourseContent } from "../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getScheduledCourseById,
  updateScheduledCourse,
} from "../../../ReduxToolkit/Reducers/LoadFactorSlice";
import {
  getClassRoomList,
  getLocationList,
  getSbuList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddParticipant,
  AddTrainer,
  Available,
  Booked,
  Cancel,
  CancelSchedule,
  CancelScheduleConfirmation,
  Confirm,
  EditSchedule,
  Location,
  Nomination,
  Room,
  Status,
  TotalSeat,
  WaitList,
} from "../../../utils/Constant";
import {
  courseScheduleStatusMap,
  rateLearnerStatus,
  scheduledCourseDetailsTabs,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

import AttendanceList from "./AttendanceList";
import AttendeesList from "./Attendees";
import FeedbackListing from "./Feedback";
import NomineesList from "./NomineesList";
import RatingListing from "./Rating";
import ReplacementList from "./ReplacementList";
import ScheduledTrainersList from "./TrainerList";

const ScheduleDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const childRef = useRef(null);
  const [activeTab, setActiveTab] = useState<string>("trainer");
  const { scheduleCourseById } = useAppSelector(state => state.loadFactor);
  const { locationList, classRoomList, sbuList } = useAppSelector(
    state => state.master
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState("");
  const { usersList } = useAppSelector(state => state.user);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showNominationButton, setShowNominationButton] = useState(false);
  const [userMap, setUserMap] = useState({});
  const { courseId, scheduledId } = location.state || "";
  const { courseContent } = useAppSelector(state => state.course);
  const [locationListMap, setLocationListMap] = useState({});
  const [classListMap, setClassListMap] = useState({});
  const [totalSeatData, setTotalSeatData] = useState([
    { id: 1, count: 0, title: TotalSeat },
    { id: 2, count: 0, title: Available },
    { id: 3, count: 0, title: Booked },
    { id: 4, count: 0, title: WaitList },
  ]);
  const [sbuMap, setSbuMap] = useState({});

  useEffect(() => {
    dispatch(getLocationList());
    dispatch(getSbuList());
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseContent({ id: courseId }));
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    getScheduledDetails(scheduledId);
  }, [dispatch, scheduledId]);

  const getScheduledDetails = id => {
    if (id) {
      dispatch(getScheduledCourseById(id));
    }
  };

  useEffect(() => {
    const locationMap = {};
    locationList?.location?.forEach(item => {
      locationMap[item?.id] = item?.name;
    });
    setLocationListMap(locationMap);
  }, [locationList]);

  useEffect(() => {
    const classMap = {};
    classRoomList?.class_room?.forEach(item => {
      classMap[item?.id] = item?.name;
    });
    setClassListMap(classMap);
  }, [classRoomList]);

  useEffect(() => {
    const valueMap = {};
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        valueMap[item?.id] = item?.name;
      });
    }
    setSbuMap(valueMap);
  }, [sbuList]);

  useEffect(() => {
    if (scheduleCourseById) {
      if (scheduleCourseById?.location_id)
        dispatch(
          getClassRoomList({
            id: scheduleCourseById?.location_id,
          })
        );
      setTotalSeatData([
        {
          id: 1,
          count: scheduleCourseById?.batch_maximum_size,
          title: TotalSeat,
        },
        {
          id: 2,
          count: scheduleCourseById?.available_count,
          title: Available,
        },
        {
          id: 3,
          count: scheduleCourseById?.booked_count,
          title: Booked,
        },
        {
          id: 4,
          count: scheduleCourseById?.waitlist_count,
          title: WaitList,
        },
      ]);
    }
  }, [dispatch, scheduleCourseById]);

  const toggleDropdown = e => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  };

  const handleDropdownClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleTabClick = async (tabId: any) => {
    setActiveTab(tabId);
  };

  const handleClick = () => {
    if (activeTab === "trainer" && childRef.current) {
      childRef.current.addTrainers();
    }
    if (activeTab === "nomination" && childRef.current) {
      childRef.current.onHandleClick();
    }
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedModalType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  let sizeTitle = "";
  if (selectedModalType === "cancel") {
    sizeTitle = CancelSchedule;
  }

  const renderModalBody = type => {
    if (type === "cancel") {
      return CancelScheduleConfirmation;
    }
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "cancel") {
      const payload = { ...scheduleCourseById };
      payload.status = "cancelled";
      await dispatch(updateScheduledCourse(payload));
      await dispatch(getScheduledCourseById(scheduledId));
      toggleModal();
      return navigate(
        `${process.env.PUBLIC_URL}/course-management/course-details`,
        {
          state: {
            courseId: courseId,
            scheduled: true,
          },
        }
      );
    }
  };

  const isNominationPeriod = () => {
    if (
      new Date(new Date().setHours(0, 0, 0, 0)) >=
        new Date(
          new Date(scheduleCourseById?.nomination_start_date).setHours(
            0,
            0,
            0,
            0
          )
        ) &&
      new Date(new Date().setHours(0, 0, 0, 0)) <=
        new Date(
          new Date(scheduleCourseById?.nomination_end_date).setHours(0, 0, 0, 0)
        )
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="page-body pb-5">
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      <Card>
        <CardBody className="m-2">
          <H3 className="fw-bold">{courseContent?.course_name}</H3>
          <Row className="mt-3">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay
                startDate={scheduleCourseById?.start_date}
                endDate={scheduleCourseById?.end_date}
              />
            </Col>
            <Col className="d-flex justify-content-between mt-3"></Col>
            <Col lg="1" className="mt-1 primary-icon-color">
              {hasPermissionToComponent("SCHEDULE_COURSE") && (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  onClick={handleDropdownClick}
                >
                  <DropdownToggle color="" className="three-dots">
                    <div className="mb-1">&#x22EE;</div>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => {
                        setSelectedModalType("cancel");
                        toggleModal();
                      }}
                    >
                      {Cancel}
                    </DropdownItem>
                    <DropdownItem>
                      <Link
                        to={"/schedule-course"}
                        state={{
                          schedule_id: scheduledId,
                          course_id: courseContent?.id,
                          course_name: courseContent?.course_name,
                        }}
                      >
                        {EditSchedule}
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </Col>
          </Row>
          <div className="d-flex justify-content-between mt-4">
            <StatusDisplay
              label={Location}
              number={locationListMap[scheduleCourseById?.location_id]}
            />
            <StatusDisplay
              label={Room}
              number={classListMap[scheduleCourseById?.classroom_id]}
            />
            <StatusDisplay
              label={Nomination}
              number={
                scheduleCourseById?.nomination_start_date +
                " - " +
                scheduleCourseById?.nomination_end_date
              }
            />
            <StatusDisplay
              label={Status}
              number={courseScheduleStatusMap[scheduleCourseById?.status]}
            />
          </div>
          <div className="mt-4">
            <TotalSeatCard data={totalSeatData} />
          </div>
          <div className="mb-2">
            <Divider />
          </div>
          <div className="d-flex justify-content-between">
            <BorderTabs
              showCard={false}
              tabs={scheduledCourseDetailsTabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
            {hasPermissionToComponent("ASSIGN_TRAINERS") &&
              activeTab === "trainer" && (
                <Btn className="mt-1" color="primary" onClick={handleClick}>
                  {AddTrainer}
                </Btn>
              )}
            {hasPermissionToComponent("ADD_NOMINEES") &&
              isNominationPeriod() &&
              scheduleCourseById?.status !== "cancelled" &&
              showNominationButton &&
              activeTab === "nomination" && (
                <Btn className="mt-1" color="primary" onClick={handleClick}>
                  {AddParticipant}
                </Btn>
              )}
          </div>
          {activeTab === "trainer" && (
            <ScheduledTrainersList
              scheduledId={scheduledId}
              userMap={userMap}
              ref={childRef}
              getScheduledDetails={getScheduledDetails}
            />
          )}
          {activeTab === "nomination" && (
            <NomineesList
              scheduledId={scheduledId}
              courseId={courseId}
              courseName={courseContent?.course_name}
              userMap={userMap}
              ref={childRef}
              setShowNominationButton={setShowNominationButton}
              sbuMap={sbuMap}
              startDate={scheduleCourseById?.nomination_start_date}
              endDate={scheduleCourseById?.nomination_end_date}
              primaryTrainerId={scheduleCourseById?.primary_trainer_id}
              replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
              status={scheduleCourseById?.status}
              getScheduledDetails={getScheduledDetails}
              available={scheduleCourseById?.available_count}
              booked={scheduleCourseById?.booked_count}
            />
          )}
          {activeTab === "replacement" && (
            <ReplacementList
              scheduledId={scheduledId}
              userMap={userMap}
              sbuMap={sbuMap}
              getScheduledDetails={getScheduledDetails}
            />
          )}
          {activeTab === "attendees" && (
            <AttendeesList
              scheduledId={scheduledId}
              courseId={courseId}
              courseName={courseContent?.course_name}
              userMap={userMap}
              sbuMap={sbuMap}
              startDate={scheduleCourseById?.start_date}
              primaryTrainerId={scheduleCourseById?.primary_trainer_id}
              replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
              getScheduledDetails={getScheduledDetails}
            />
          )}
          {activeTab === "attendance" && (
            <AttendanceList
              scheduledId={scheduledId}
              primaryTrainerId={scheduleCourseById?.primary_trainer_id}
              replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
            />
          )}
          {activeTab === "feedback" && (
            <FeedbackListing
              scheduledId={scheduledId}
              courseName={courseContent?.course_name}
              startDate={scheduleCourseById?.start_date}
              endDate={scheduleCourseById?.end_date}
              locationName={locationListMap[scheduleCourseById?.location_id]}
              classRoomName={classListMap[scheduleCourseById?.classroom_id]}
              instructorId={scheduleCourseById?.primary_trainer_id}
            />
          )}
          {activeTab === "rating" &&
            rateLearnerStatus.includes(scheduleCourseById?.status) && (
              <RatingListing
                scheduledId={scheduledId}
                courseName={courseContent?.course_name}
                startDate={scheduleCourseById?.start_date}
                endDate={scheduleCourseById?.end_date}
                locationName={locationListMap[scheduleCourseById?.location_id]}
                classRoomName={classListMap[scheduleCourseById?.classroom_id]}
                instructorId={scheduleCourseById?.primary_trainer_id}
                primaryTrainerId={scheduleCourseById?.primary_trainer_id}
                replacedTrainerId={scheduleCourseById?.replaced_trainer_id}
              />
            )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ScheduleDetails;
