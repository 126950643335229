import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SessionList from "../../../../container/SessionList";
import UserProfileCard from "../../../../container/UserProfileCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getMentorSessionList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../Service";
import {
  Contact,
  CWSAndCatRecId,
  Location,
  Mail,
  RMContactNumber,
  RMEmail,
  RMName,
  SUBNo,
} from "../../../../utils/Constant";
import { UserProfileInformationDetails } from "../../../../utils/helper/propTypes";

const MentorDetailsPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const { mentorMenteeId, mentorId } = state || {};
  const navigate = useNavigate();
  const { getMentorSessionLists } = useAppSelector(state => state.learner);
  const { userDetailsList } = useAppSelector(state => state.user);
  const [userProfileData, setUserProfileData] = useState(null);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getMentorSessionList({ mentorMenteeId }));
    dispatch(getUserDetailsList({ id: [mentorId] }));
  }, [dispatch]);

  useEffect(() => {
    if (getMentorSessionLists?.session_list) {
      const mappedData = getMentorSessionLists?.session_list?.map(session => {
        return {
          sessionId: session.session_id,
          topic: session.topic,
          duration: String(session.duration),
          sessionDate: session.start_date,
          status: session.status,
        };
      });

      setFilteredData(mappedData);
    }
  }, [getMentorSessionLists]);

  useEffect(() => {
    const mentorDetails = userDetailsList?.users?.find(
      user => user.id === mentorId
    );
    if (mentorDetails) {
      const mapUserToProfileDetails = (
        user: any
      ): UserProfileInformationDetails => ({
        userProfileUrl: dynamicImage("dashboard-4/user.png"),
        name: user.user_name,
        designation: user.designation,
        userDetails: [
          {
            icon: "LocationOnOutlined",
            title: Location,
            detail:
              [user?.office_city || "", user?.office_state || ""]
                .filter(Boolean)
                .join(", ") || "-",
          },
          {
            icon: "BadgeOutlined",
            title: CWSAndCatRecId,
            detail: user?.cws_cat_rec,
          },
          {
            icon: "EmailOutlined",
            title: Mail,
            detail: user.email,
          },
          {
            icon: "PhoneOutlined",
            title: Contact,
            detail: user?.phone,
          },
          {
            icon: "PinOutlined",
            title: SUBNo,
            detail: user?.sbu,
          },
          {
            icon: "PersonOutline",
            title: RMName,
            detail: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
          },
          {
            icon: "EmailOutlined",
            title: RMEmail,
            detail:
              user.mapped_rm?.length > 0 && user.mapped_rm[0].email
                ? user.mapped_rm[0].email
                : "-",
          },
          {
            icon: "PhoneOutlined",
            title: RMContactNumber,
            detail: user?.mapped_rm?.[0]?.rm_phone || "-",
          },
        ],
      });
      setUserProfileData(mapUserToProfileDetails(mentorDetails));
    }
  }, [userDetailsList]);

  const handleSearch = searchTerm => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = getMentorSessionLists?.session_list?.filter(session =>
      session.topic.toLowerCase().includes(lowercasedSearchTerm)
    );
    setFilteredData(filtered);
  };

  const onRowClick = row => {
    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId },
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <UserProfileCard userData={userProfileData || {}} />
      <div>
        <SessionList
          data={filteredData}
          handleSearch={handleSearch}
          showActions
          onRowClick={onRowClick}
        />
      </div>
    </div>
  );
};

export default MentorDetailsPage;
