import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  basicInputFormValue: {
    course_name: "",
    course_type: "",
    assign_trainer: [],
    assign_sme: "",
    objective: "",
  },
  loading: false,
  error: null,
};

const AdditionalInfoWizardSlice = createSlice({
  name: "infoWizard",
  initialState,
  reducers: {
    setBasicInputFormValue(state, action) {
      state.basicInputFormValue = action.payload;
    },
  },
});

export const { setBasicInputFormValue } = AdditionalInfoWizardSlice.actions;
export default AdditionalInfoWizardSlice.reducer;
