import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Input, Row, Col, Form } from "reactstrap";

import { PUBLISHED } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  getAssessmentByStatus,
  mapAssessment,
} from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Assessment,
  AssessmentRequired,
  AssessmentType,
  AssessmentTypeRequired,
  Dates,
  EndDate,
  EndDateRequired,
  ILPAssessmentToolTipMessage,
  ILPAssessmentTypeToolTipMessage,
  ILPEndDateToolTipMessage,
  ILPStartDateToolTipMessage,
  MapAssessment,
  Select,
  START_DATE_GREATER_ERROR,
  StartDate,
  StartDateRequired,
  Submit,
} from "../../utils/Constant";
import {
  assessmentTypes,
  assessmentTypesMap,
  formatDate,
  mapListToOptions,
} from "../../utils/helper/helper";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";
import "./style.scss";

interface MapAssessmentModalProps {
  isOpen: boolean;
  toggle: (isMapped?: boolean) => void;
  userId?: number;
}

const MapAssessmentModal: React.FC<MapAssessmentModalProps> = ({
  isOpen,
  toggle,
  userId,
}) => {
  const [assessmentOptions, setAssessmentOptions] = useState([]);
  const filteredAssessmentTypes = assessmentTypes.filter(
    type => type.value !== "course"
  );
  const [formValues, setFormValues] = useState({
    assessment_type: "",
    assessment: "",
    start_date: new Date(),
    end_date: new Date(),
  });
  const [errors, setErrors] = useState({
    assessment_type: "",
    assessment: "",
    start_date: "",
    end_date: "",
  });
  const dispatch = useAppDispatch();
  const { assessmentList } = useAppSelector(state => state.learningPlan);

  useEffect(() => {
    if (formValues.assessment_type) {
      dispatch(
        getAssessmentByStatus({
          status: PUBLISHED,
          requestType: formValues.assessment_type,
          userId: userId,
        })
      );
      setFormValues(prevState => ({
        ...prevState,
        assessment: "",
        start_date: new Date(),
        end_date: new Date(),
      }));
    }
  }, [formValues.assessment_type]);

  useEffect(() => {
    const options = mapListToOptions(assessmentList?.assessments, "name", "id");
    setAssessmentOptions(options);
  }, [assessmentList]);

  const handleDateChange = (name: string, date: Date) => {
    setFormValues(prevState => ({
      ...prevState,
      [name]: date || null,
    }));
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const isSelftAssessment =
      assessmentTypesMap.competency === formValues.assessment_type;
    const newErrors = {
      assessment_type: "",
      assessment: "",
      start_date: "",
      end_date: "",
    };

    let isError = false;
    if (!formValues.assessment_type) {
      newErrors.assessment_type = AssessmentTypeRequired;
      isError = true;
    }

    if (!formValues.assessment) {
      newErrors.assessment = AssessmentRequired;
      isError = true;
    }

    if (!isSelftAssessment && !formValues.start_date) {
      newErrors.start_date = StartDateRequired;
      isError = true;
    }

    if (!isSelftAssessment && !formValues.end_date) {
      newErrors.end_date = EndDateRequired;
      isError = true;
    }

    if (
      !isSelftAssessment &&
      formValues.start_date &&
      formValues.end_date &&
      formValues.end_date < formValues.start_date
    ) {
      newErrors.start_date = START_DATE_GREATER_ERROR;
      isError = true;
    }

    setErrors(newErrors);
    return isError;
  };

  const getMapAssessmentModalBody = () => {
    return (
      <Form className="custom-input" noValidate>
        <div className="mb-3">
          <LabelTooltip
            label={AssessmentType}
            tooltipText={ILPAssessmentTypeToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <Input
            type="select"
            value={formValues?.assessment_type}
            onChange={handleChange}
            name="assessment_type"
            invalid={!!errors?.assessment_type}
          >
            <option value="" disabled>
              {Select} {AssessmentType}
            </option>
            {filteredAssessmentTypes.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          {errors?.assessment_type && (
            <div className="invalid-feedback">{errors?.assessment_type}</div>
          )}
        </div>

        <div className="mb-3">
          <LabelTooltip
            label={Assessment}
            tooltipText={ILPAssessmentToolTipMessage}
            important={true}
            placement={"bottom"}
          />
          <Input
            type="select"
            value={formValues?.assessment}
            onChange={handleChange}
            name="assessment"
            disabled={!formValues?.assessment_type}
            invalid={!!errors?.assessment}
          >
            {assessmentOptions.map(assessment => (
              <option key={assessment.value} value={assessment.value}>
                {assessment.label}
              </option>
            ))}
          </Input>
          {errors?.assessment && (
            <div className="invalid-feedback">{errors?.assessment}</div>
          )}
        </div>

        {assessmentTypesMap.competency !== formValues.assessment_type && (
          <div className="mb-3">
            <Row>
              <Col md={6}>
                <LabelTooltip
                  label={StartDate}
                  tooltipText={ILPStartDateToolTipMessage}
                  important={true}
                  placement={"bottom"}
                />
                <div className={"w-100"}>
                  <DatePicker
                    selected={formValues?.start_date}
                    onChange={(date: Date | null) =>
                      handleDateChange("start_date", date)
                    }
                    minDate={new Date()}
                    placeholderText={`${Select} ${Dates}`}
                    className={`form-control ${errors?.start_date ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation={true}
                    onKeyDown={e => e.preventDefault()}
                  />

                  {errors?.start_date && (
                    <div className="invalid-date-picker-feedback">
                      {errors?.start_date}
                    </div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <LabelTooltip
                  label={EndDate}
                  tooltipText={ILPEndDateToolTipMessage}
                  important={true}
                  placement={"bottom"}
                />
                <div className={"w-100"}>
                  <DatePicker
                    selected={formValues?.end_date}
                    onChange={(date: Date | null) =>
                      handleDateChange("end_date", date)
                    }
                    minDate={formValues?.start_date || new Date()}
                    placeholderText={`${Select} ${Dates}`}
                    className={`w-100 form-control ${errors?.end_date ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation={true}
                    onKeyDown={e => e.preventDefault()}
                  />

                  {errors?.end_date && (
                    <div className="invalid-date-picker-feedback">
                      {errors?.end_date}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    );
  };

  const handleSubmit = () => {
    if (validateForm()) {
      return;
    }
    const isSelftAssessment =
      assessmentTypesMap.competency === formValues.assessment_type;
    dispatch(
      mapAssessment({
        assessmentPayload: {
          type: "assessment",
          user_id: userId,
          id: Number(formValues.assessment),
          start_date: !isSelftAssessment
            ? formatDate(formValues.start_date)
            : null,
          end_date: !isSelftAssessment ? formatDate(formValues.end_date) : null,
        },
      })
    ).then(res => {
      if (res.payload) {
        toggle(true);
      }
    });
  };
  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={MapAssessment}
      showFooter={true}
      primaryBtnText={Submit}
      onPrimaryBtnClick={handleSubmit}
    >
      {getMapAssessmentModalBody()}
    </CommonModal>
  );
};

MapAssessmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

export default MapAssessmentModal;
