import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import {
  Badges,
  Btn,
  DefaultButton,
  H4,
  MUIIcons,
  P,
  Progressbar,
  SecondaryButton,
} from "../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  ASSESSMENT_POST_PHASE,
  ASSESSMENT_PRE_PHASE,
  DRAFT_STATUS,
  WBT,
} from "../../../Api/constants";
import SimpleAccordion from "../../../CommonElements/Accordion";
import BorderTabs from "../../../CommonElements/BorderTabs";
import CourseActivity from "../../../CommonElements/CourseActivity";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../CommonElements/MultiFileUpload/MultiFileUpload";
import LearningPlanCourseCard from "../../../container/LearningPlanCourseCard";
import PlayCourseMaterial from "../../../container/PlayCourseMaterial";
import ProjectDescription from "../../../container/ProjectDescription";
import ScheduleListContainer from "../../../container/ScheduleListContainer";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  downloadCertificate,
  getCourseActivity,
  getScheduleCourse,
  getScheduleDetails,
  getSkillAssessment,
  mailCourse,
  mapScheduleCourse,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { getCourseModuleFeedabck } from "../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import {
  getLOForAssessment,
  uploadSkillDocs,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import { getLocationList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  getUsersList,
  uploadFileApi,
} from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  ActivityWorkbook,
  Completed,
  CourseFeedbackForm,
  CourseID,
  CourseType,
  DesignedToEvaluateYourFoundational,
  Download,
  EnableLastDayOfCourse,
  EnrollMe,
  FailedToDownloadFileFrom,
  FormativeAssessment,
  KnowledgeAssessment,
  LearnerDocument,
  LearningOutcomes,
  MailMe,
  MailMeAddToILP,
  Material,
  ModuleImprovementForm,
  OJTAssessment,
  PleaseTakeLast,
  PleaseTakePreCourse,
  PostTest,
  PreCourseWorkHeader,
  PreTest,
  RateNow,
  Resume,
  Retake,
  ReviewHasBeenSubmitted,
  SkillAssessment,
  Start,
  ThisCourseIncludes,
  TrainerDocument,
  UnderReview,
  UploadFile,
  WelcomeToPrerequisite,
  WhatYouWillLearnInThisCourse,
  YourFileFileSizeNote,
} from "../../../utils/Constant";
import "./style.scss";
import {
  assessmentStatus,
  convertMinutesToHours,
  getEllipsedFileName,
  scheduledTabs,
  showToast,
  skillAssessmentTabs,
} from "../../../utils/helper/helper";
import {
  LOGGED_IN_USER,
  getFromLocalStorage,
} from "../../../utils/helper/localStorageutils";

type ValidMUIIcons =
  | "CalendarTodayOutlined"
  | "HelpOutline"
  | "SignalCellularAltOutlined"
  | "PlaceOutlined"
  | "PeopleAltOutlined"
  | "PersonOutlineOutlined"
  | "FileCopyOutlined"
  | "TextSnippetOutlined"
  | "EmojiEventsOutlined"
  | "AccessTimeOutlined";

const LearnerScheduleDetails = () => {
  const [activeTab, setActiveTab] = useState("material");
  const [courseTab, setCourseTab] = useState("details");
  const [mediaFile, setMediaFile] = useState<any>("");
  const [flushData, setFlushData] = useState(false);
  const [skillAssessments, setSkillAssessments] = useState({
    percentage: 0,
  });
  const [isModal, setIsModal] = useState(false);
  const [activityData, setActivityData] = useState<any>([]);
  const [locationNameMap, setLocationNameMap] = useState({});
  const [userNameMap, setUserNameMap] = useState({});

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const courseIdParam = params.get("course-id");

  const { scheduledDetails, courseActivity, skillDocuments } = useAppSelector(
    state => state.course
  );
  const currentDate = new Date().toISOString().slice(0, 10);
  const { loForAssessment } = useAppSelector(state => state.learningPlan);
  const { course_schedule_id, course_id, status } = location.state;
  const { scheduleCourse } = useAppSelector(state => state.course);

  const loginData = getFromLocalStorage(LOGGED_IN_USER);

  const { course_detail, course_materials, learning_outcome } =
    scheduledDetails;

  const { locationList } = useAppSelector(state => state.master);
  const { usersList } = useAppSelector(state => state.user);

  useEffect(() => {
    if (!courseIdParam) {
      dispatch(
        getScheduleDetails({
          courseScheduleId: course_schedule_id,
          courseId: course_id,
        })
      );
      dispatch(getCourseActivity({ courseScheduleId: course_schedule_id }));
      dispatch(getSkillAssessment({ courseScheduleId: course_schedule_id }));
      dispatch(getLocationList());
      dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    }
  }, [courseIdParam]);

  useEffect(() => {
    const userMapObj: { [key: string]: { user_name: string } } = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseIdParam) {
      dispatch(
        getScheduleDetails({
          courseId: Number(courseIdParam),
        })
      );
      dispatch(getCourseActivity({ courseScheduleId: course_schedule_id }));
      dispatch(getSkillAssessment({ courseScheduleId: course_schedule_id }));
    }
  }, [courseIdParam]);

  useEffect(() => {
    const locationMapObj: { [key: string]: { name: string } } = {};
    locationList?.location?.forEach(location => {
      locationMapObj[location.id] = location;
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  useEffect(() => {
    if (courseActivity.assessments.length > 0) {
      const updatedActivity = courseActivity.assessments?.map(item => {
        return {
          ...item,
        };
      });
      updatedActivity.push({
        name: ModuleImprovementForm,
        assessment_phase: "rateModule",
      });

      updatedActivity.push({
        name: CourseFeedbackForm,
        assessment_phase: "rateCourse",
      });

      if (course_materials?.length > 0) {
        updatedActivity.push({
          name: ActivityWorkbook,
          assessment_phase: "activity",
          status: "activity",
        });
      }

      setActivityData(updatedActivity);
    }
  }, [courseActivity, course_materials]);

  const getScheduleList = tabStatus => {
    dispatch(
      getScheduleCourse({
        status: tabStatus,
        course_id: course_id,
      })
    );
  };

  const extractDocumentsByCourseAssessment = skillDocument => {
    const result = {
      courseAssessmentIds: [],
      percentage: 0,
    };

    let approvedCount = 0;

    skillDocument.forEach(doc => {
      const courseAssessmentId = doc.course_assessment_id;

      if (!result[courseAssessmentId]) {
        result[courseAssessmentId] = {
          documents: [],
          uploaded_learner_document: [],
          id: doc.id,
        };
      }

      result.courseAssessmentIds.push(courseAssessmentId);

      if (doc.skill_document?.ojt_skill) {
        result[courseAssessmentId].documents.push(
          ...doc.skill_document.ojt_skill
        );
        result[courseAssessmentId].title = "OJT assessment";
      }

      if (doc.skill_document.field_skill?.learner_url) {
        result[courseAssessmentId].documents.push(
          ...doc.skill_document.field_skill.learner_url
        );
        result[courseAssessmentId].title = "Field assessment";
      }

      if (doc.uploaded_learner_document) {
        doc.uploaded_learner_document.forEach(url => {
          result[courseAssessmentId].uploaded_learner_document.push({
            file_name: url.split("/").pop(),
            file_type: "pdf",
            file_url: url,
            file_category: "uploaded_learner_document",
          });
        });
      }

      if (doc.status === "approved") {
        approvedCount++;
      }
    });

    const totalDocuments = skillDocument.length;
    result.percentage =
      totalDocuments > 0 ? (approvedCount / totalDocuments) * 100 : 0;

    return result;
  };

  useEffect(() => {
    if (skillDocuments?.skill_document.length > 0) {
      const result = extractDocumentsByCourseAssessment(
        skillDocuments.skill_document
      );
      setSkillAssessments(result);
      dispatch(
        getLOForAssessment({ ids: result.courseAssessmentIds.toString() })
      );
    }
  }, [skillDocuments]);

  const getTrainer = trainers => {
    if (!trainers) {
      return {};
    }
    const firstTrainerId = userNameMap[trainers[0].user_id];
    const remainingTrainers = trainers.slice(1);
    const remainingNames = remainingTrainers.map(
      trainer => userNameMap[trainer.user_id]?.user_name
    );
    return {
      userName: firstTrainerId?.user_name,
      userData: remainingNames,
    };
  };

  const courseData: { icon: ValidMUIIcons | ""; value: string }[] = [
    {
      icon: "AccessTimeOutlined",
      value: `${course_detail?.schedule_details?.start_date} -
        ${course_detail?.schedule_details?.end_date}`,
    },
    {
      icon: "CalendarTodayOutlined",
      value: `Nomination starts: ${course_detail?.schedule_details?.nomination_start_date} -
        ${course_detail?.schedule_details?.nomination_end_date}`,
    },
    {
      icon: "SignalCellularAltOutlined",
      value: course_detail?.curriculum
        .map(item => item.expertise_level)
        .filter((level, index, self) => self.indexOf(level) === index)
        .join(", "),
    },
    {
      icon: "PlaceOutlined",
      value:
        locationNameMap[course_detail?.schedule_details?.location_id]?.name ||
        "-",
    },
    {
      icon: "PeopleAltOutlined",
      value: `${course_detail?.schedule_details?.batch_minimum_size} -
        ${course_detail?.schedule_details?.batch_maximum_size}`,
    },
  ];

  if (getTrainer(null).userName) {
    courseData.push({
      icon: "PersonOutlineOutlined",
      value: `${getTrainer(null).userName} +${getTrainer(null)?.userData?.length}`,
    });
  }

  const courseInlcudes = () => {
    const courseIncludes: { icon: ValidMUIIcons | ""; value: string }[] = [];
    const overview = course_detail?.overview;

    overview?.duration &&
      courseIncludes.push({
        icon: "AccessTimeOutlined",
        value: `${convertMinutesToHours(overview.duration)} h`,
      });

    overview?.knowledge_assessment_count &&
      courseIncludes.push({
        icon: "FileCopyOutlined",
        value: `${overview.knowledge_assessment_count} ${KnowledgeAssessment}`,
      });

    overview?.ojt_assessment_count &&
      courseIncludes.push({
        icon: "FileCopyOutlined",
        value: `${overview.ojt_assessment_count} ${OJTAssessment}`,
      });

    overview?.learner_document_count &&
      courseIncludes.push({
        icon: "PlaceOutlined",
        value: `${overview.learner_document_count} ${LearnerDocument}`,
      });

    overview?.trainer_document_count &&
      courseIncludes.push({
        icon: "TextSnippetOutlined",
        value: `${overview.trainer_document_count} ${TrainerDocument}`,
      });

    overview?.learning_outcome_count &&
      courseIncludes.push({
        icon: "TextSnippetOutlined",
        value: `${overview.learning_outcome_count} ${LearningOutcomes}`,
      });

    return courseIncludes;
  };

  const getIconName = (icon: ValidMUIIcons | ""): ValidMUIIcons => {
    return icon || "HelpOutline";
  };

  const downloadActivity = () => {
    const dowloadURLs = course_materials.map(item => item.file_url);
    handleDownload(dowloadURLs);
  };

  const handleRateNow = async item => {
    if (item.assessment_phase === "rateCourse") {
      if (currentDate < course_detail?.schedule_details?.end_date) {
        return showToast(EnableLastDayOfCourse, "error");
      }

      const response = await dispatch(
        getCourseModuleFeedabck({
          type: "course",
          courseScheduleId: course_schedule_id,
        })
      );

      if (response?.payload?.questions?.length > 0) {
        return navigate("/schedule-list/course-details/course-feedback", {
          state: {
            ...location.state,
            tranierName:
              userNameMap[location.state.primary_trainer_id]?.user_name,
          },
        });
      } else {
        return showToast(ReviewHasBeenSubmitted, "error");
      }
    }

    return navigate("/schedule-list/course-details/module-feedback", {
      state: {
        ...location.state,
        tranierName: userNameMap[location.state.primary_trainer_id]?.user_name,
      },
    });
  };

  const getActivityStatus = (item, handleClick) => {
    if (item.status === assessmentStatus.inProgress) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Resume}
        </button>
      );
    } else if (item.status === assessmentStatus.yetToStart) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Start}
        </button>
      );
    } else if (item.status === assessmentStatus.retake) {
      return (
        <button
          onClick={handleClick}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Retake}
        </button>
      );
    } else if (
      item.status === assessmentStatus.underReview ||
      item.status === DRAFT_STATUS
    ) {
      return <div className="primary-text-color">{UnderReview}</div>;
    } else if (
      item.status === assessmentStatus.passed ||
      item.status === assessmentStatus.failed
    ) {
      return (
        <div className="badge-approved-bg px-2 rounded-pill d-flex align-items-center gap-1">
          <MUIIcons className="icon" iconName="CheckOutlined" size={12} />
          {Completed}
        </div>
      );
    } else if (item.status === "activity") {
      return (
        <button
          onClick={downloadActivity}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {Download}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => handleRateNow(item)}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {RateNow}
        </button>
      );
    }
  };

  const handlePrequiesitesClick = item => {
    if (item.course_type === WBT) {
      return navigate("/course-catalog/course-details", {
        state: {
          course_id: item.id,
        },
      });
    }

    return navigate(`/schedule-list/course-details?course-id=${item.id}`, {
      state: {
        ...location.state,
      },
    });
  };

  const getPrerequisiteStatus = item => {
    if (item.status === "completed") {
      return <P className="primary-text-color">{item.status}</P>;
    }

    return (
      <button
        onClick={() => handlePrequiesitesClick(item)}
        className="border-0 bg-transparent pointer primary-text-color"
      >
        {item.status}
      </button>
    );
  };

  const transformedData = course_detail?.pre_course_work?.map(item => ({
    courseId: item.id.toString(),
    status: item.status,
    courseName: item.name,
    domainName: [],
    completedOn: null,
    scheduledOn: null,
    assignedBy: null,
    courseType: null,
    id: item.id,
  }));

  const handleFileChange = (key, file, status) => {
    if (status === "removed") {
      setMediaFile(prevDocuments => ({
        ...prevDocuments,
        [key]: prevDocuments[key]?.filter(item => item[0].name !== file?.name),
      }));
    } else if (status === "done" && file) {
      setMediaFile(prevDocuments => ({
        ...prevDocuments,
        [key]: [
          ...(Array.isArray(prevDocuments[key]) ? prevDocuments[key] : []),
          [file],
        ],
      }));
    }
    setFlushData(false);
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file[0]);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handleUpload = async key => {
    await uploadFile(mediaFile[key]).then(async uploaded => {
      const payload = {
        action: "upload_document",
        skill_document: [
          {
            id: skillAssessments[key].id,
            uploaded_learner_document: uploaded,
          },
        ],
      };
      const res = await dispatch(uploadSkillDocs({ docs: payload }));
      if (res) {
        setMediaFile(prevDocuments => ({
          ...prevDocuments,
          [key]: [],
        }));
        setFlushData(true);
        dispatch(getSkillAssessment({ courseScheduleId: course_schedule_id }));
      }
    });
  };

  const getSkillAssessmentContent = () => {
    const data =
      loForAssessment &&
      Object.keys(loForAssessment?.data)?.map(key => {
        const course = loForAssessment.data[key];
        const firstOutcome = course.outcomes[0];
        return {
          id: key,
          icon: true,
          accordionHeading: (
            <div>
              <P>{firstOutcome.outcome}</P>
            </div>
          ),
          spanClass: "custom-skillUpdate-heading",
          bodyText: (
            <div>
              <P>{skillAssessments[key]?.title}</P>
              <BorderTabs
                tabs={skillAssessmentTabs}
                activeTab={activeTab}
                handleTabClick={tabId => setActiveTab(tabId)}
              />

              <CardBody>
                {activeTab === "material" &&
                  skillAssessments[key]?.documents?.map((item, index) => (
                    <Badges
                      color="light text-dark my-2"
                      key={item.file_name + index}
                    >
                      <div className="d-flex align-items-center">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span className="m-1">
                          {getEllipsedFileName(item.file_name)}
                        </span>
                        <MUIIcons
                          className="pointer"
                          onClick={() => handleDownload([item.file_url])}
                          size={24}
                          iconName="FileDownloadOutlined"
                        />
                      </div>
                    </Badges>
                  ))}

                {activeTab === "your_file" && (
                  <>
                    {skillAssessments[key]?.uploaded_learner_document?.map(
                      (item, index) => (
                        <Badges
                          color="light text-dark my-2"
                          key={item.file_name + index}
                        >
                          <div className="d-flex align-items-center">
                            <MUIIcons
                              size={24}
                              iconName="InsertDriveFileOutlined"
                            />
                            <span className="m-1">
                              {getEllipsedFileName(item.file_name)}
                            </span>
                            <MUIIcons
                              className="pointer"
                              onClick={() => handleDownload([item.file_url])}
                              size={24}
                              iconName="FileDownloadOutlined"
                            />
                          </div>
                        </Badges>
                      )
                    )}
                    <LabelTooltip
                      label={UploadFile}
                      tooltipText={UploadFile}
                      important={false}
                    />
                    <div className="text-danger">{YourFileFileSizeNote}</div>
                    <MultiFileUpload
                      flushData={flushData}
                      accept=".pdf,.docx,.doc"
                      multiple={true}
                      onFileChange={(file, status) =>
                        handleFileChange(key, file, status)
                      }
                    />
                    <div className="mt-4 d-flex justify-content-end">
                      <Btn color="primary" onClick={() => handleUpload(key)}>
                        {UploadFile}
                      </Btn>
                    </div>
                  </>
                )}
              </CardBody>
            </div>
          ),
        };
      });

    return data;
  };

  const handleDownload = async (urls: string[]) => {
    for (const url of urls) {
      if (url) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const link = document.createElement("a");
          const objectURL = URL.createObjectURL(blob);
          link.href = objectURL;
          link.setAttribute("download", url.split("/").pop() || "file");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error(`${FailedToDownloadFileFrom} ${url}: `, error);
        }
      }
    }
  };

  const handleTabClick = (tabId: any) => {
    setCourseTab(tabId);
    if (tabId !== "details") {
      getScheduleList(tabId);
    }
  };

  const handleModal = () => {
    setIsModal(prevState => !prevState);
  };

  const isAllCompleted = () => {
    return scheduledDetails?.course_detail?.pre_course_work.every(
      item => item.status === "completed"
    );
  };

  const handleEnroll = () => {
    const payload = {
      type: "interested_course",
      user_id: loginData.id,
      id: [course_id],
    };
    dispatch(
      mapScheduleCourse({
        courseContent: payload,
      })
    );
    handleModal();
  };

  const handleMailAndEnroll = async (enroll = false) => {
    const notCompletedIds = scheduledDetails?.course_detail?.pre_course_work
      .filter(item => item.status !== "completed")
      .map(item => item.id);

    const payload = {
      type: "interested_course",
      user_id: loginData.id,
      id: notCompletedIds,
    };
    const mailResponse = await dispatch(
      mailCourse({
        courseContent: payload,
      })
    );

    if (mailResponse && enroll) {
      const enrollPayload = {
        type: "course",
        user_id: loginData.id,
        id: notCompletedIds,
      };
      dispatch(
        mapScheduleCourse({
          courseContent: enrollPayload,
        })
      );
      handleModal();
    }
  };

  const isOngoingOrNominationClosed = () => {
    return status === "ongoing" || status === "nomination_closed";
  };

  const handlePreClick = item => {
    if (item.status === assessmentStatus.inProgress) {
      return navigate("/learning-assessment", {
        state: {
          participantId: item?.participant_id,
          name: item?.name,
        },
      });
    }

    return navigate("/assessment-instruction", {
      state: {
        participantId: item?.participant_id,
        name: item?.name,
        termsAndCondition: item?.term_and_condition,
      },
    });
  };

  const checkPreAssessmentPassedOrFailed = (): boolean => {
    return courseActivity?.assessments?.some(
      assessment =>
        assessment.assessment_phase === "pre" &&
        (assessment.status === "passed" || assessment.status === "failed")
    );
  };

  const handlePostClick = item => {
    if (checkPreAssessmentPassedOrFailed()) {
      if (currentDate !== course_detail.schedule_details?.end_date) {
        return showToast(PleaseTakeLast, "error");
      } else {
        if (item.status === assessmentStatus.inProgress) {
          return navigate("/learning-assessment", {
            state: {
              participantId: item?.participant_id,
              name: item?.name,
            },
          });
        }

        return navigate("/assessment-instruction", {
          state: {
            participantId: item?.participant_id,
            name: item?.name,
            termsAndCondition: item?.term_and_condition,
          },
        });
      }
    } else {
      return showToast(PleaseTakePreCourse, "error");
    }
  };

  const handleReview = item => {
    return item;
  };

  const getAssessmentDetails = item => {
    switch (item.assessment_phase) {
      case ASSESSMENT_PRE_PHASE:
        return {
          name: `${PreTest}-${item.name}`,
          onClick: () => handlePreClick(item),
        };

      case ASSESSMENT_POST_PHASE:
        return {
          name: `${PostTest}-${item.name}`,
          onClick: () => handlePostClick(item),
        };

      case "":
      case null:
        return {
          name: `${FormativeAssessment}-${item.name}`,
          onClick: () => handlePreClick(item),
        };
      case "activity":
        return {
          name: item.name,
          onClick: downloadActivity,
        };
      case "rateModule":
      case "rateCourse":
        return {
          name: item.name,
          onClick: () => handleReview(item),
        };

      default:
        return {};
    }
  };

  const sortActivity = () => {
    const sorted = [...activityData].sort((a, b) => {
      const order = [
        ASSESSMENT_PRE_PHASE,
        "",
        null,
        "activity",
        "rateModule",
        "rateCourse",
        ASSESSMENT_POST_PHASE,
      ];
      const phaseA = a.assessment_phase || "";
      const phaseB = b.assessment_phase || "";

      return order.indexOf(phaseA) - order.indexOf(phaseB);
    });

    return sorted;
  };

  const handleDownloadCertificate = () => {
    const payload = {
      type: course_detail?.course_type,
      courseName: course_detail?.course_name,
    };
    if (course_detail.course_type === WBT) {
      payload["courseId"] = course_detail.id;
    } else {
      payload["courseScheduleId"] = course_schedule_id;
    }
    dispatch(downloadCertificate({ ...payload }));
  };

  return (
    <div className="page-body schedule-details">
      <Row>
        <Col md="8" sm="4">
          <div className="mb-3">
            <ProjectDescription
              title={course_detail?.course_name}
              description={course_detail?.description}
              courseValues={[
                {
                  label: CourseType,
                  value: course_detail?.course_type,
                  id: CourseType,
                },
                {
                  label: CourseID,
                  value: course_detail?.course_id,
                  id: CourseID,
                },
              ]}
              scheduledOn={course_detail?.schedule_details?.start_date}
              isDownloadCertificate={{
                name: course_detail?.completion_status === "completed",
                handleDownload: handleDownloadCertificate,
              }}
            />
          </div>

          {courseIdParam && course_detail?.course_type === WBT && (
            <Row className="mt-5">
              {course_materials.map((item, index) => (
                <Col md="6" sm="6" key={item.file_name + index}>
                  <PlayCourseMaterial
                    material={item}
                    courseId={course_detail?.id}
                  />
                </Col>
              ))}
            </Row>
          )}

          {activityData.length > 0 && isOngoingOrNominationClosed() && (
            <Card className="course-activity mt-4">
              <CardHeader>
                <H4>Course Activity</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {sortActivity()?.map(item => {
                  const assessmentDetails = getAssessmentDetails(item);
                  return (
                    <div className="" key={item.participant_id}>
                      <CourseActivity
                        name={assessmentDetails.name}
                        getStatus={() =>
                          getActivityStatus(item, assessmentDetails.onClick)
                        }
                      />
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          )}

          {isOngoingOrNominationClosed() &&
            loForAssessment?.data &&
            Object.keys(loForAssessment?.data)?.length > 0 && (
              <Card className="course-activity mt-4">
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H4>{SkillAssessment}</H4>
                  <div className="d-flex flex-row align-items-center gap-3">
                    <Progressbar
                      barClassName="custom-sga-progressBar"
                      value={skillAssessments.percentage}
                      className="custom-sgaProgressBar"
                    />
                    {`${skillAssessments.percentage}%`}
                  </div>
                </CardHeader>
                <div className="mt-3 mx-3">
                  <SimpleAccordion
                    accordionList={getSkillAssessmentContent()}
                  />
                </div>
              </Card>
            )}

          {((!isOngoingOrNominationClosed() && !courseIdParam) ||
            (courseIdParam && course_detail?.course_type !== "wbt")) && (
            <Card>
              <CardBody>
                <JustifyTabs
                  tabs={[{ id: "details", label: "Details" }, ...scheduledTabs]}
                  activeTab={courseTab}
                  onTabClick={handleTabClick}
                />
              </CardBody>
            </Card>
          )}

          {((courseTab === "details" && !courseIdParam) ||
            (courseTab === "details" &&
              courseIdParam &&
              course_detail?.course_type !== WBT)) && (
            <Card>
              <CardHeader>
                <H4>{WhatYouWillLearnInThisCourse}</H4>
              </CardHeader>
              <CardBody className="">
                {learning_outcome.map(item => (
                  <div
                    className="d-flex gap-2 mb-3 align-items-center"
                    key={item.id}
                  >
                    <MUIIcons
                      className="primary-icon-color"
                      iconName="CheckCircleOutlined"
                      size={27}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.outcome}
                    </P>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          {courseTab !== "details" && (
            <Card>
              <CardBody className="">
                <ScheduleListContainer
                  data={scheduleCourse?.scheduled_courses}
                />
              </CardBody>
            </Card>
          )}
        </Col>

        <Col md="4">
          <Card>
            <CardBody className="schedule-details__course-data">
              {course_detail?.schedule_details &&
                courseData.map(item => (
                  <div className="d-flex gap-2 mb-3" key={item.icon}>
                    <MUIIcons
                      className="course-description__calender"
                      size={18}
                      iconName={getIconName(item.icon)}
                    />
                    <P className="course-description__schedule-on m-0">
                      {item.value}
                    </P>
                  </div>
                ))}
              {course_detail?.schedule_details &&
                !isOngoingOrNominationClosed() &&
                course_detail?.schedule_details?.self_enrollment && (
                  <div className="border-top">
                    <DefaultButton
                      className="w-100 mt-3"
                      color="primary"
                      onClick={handleModal}
                    >
                      I’m Interested !
                    </DefaultButton>
                  </div>
                )}
            </CardBody>
          </Card>
          {!isOngoingOrNominationClosed() && (
            <Card>
              <CardHeader>
                <H4>Prerequisite</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {scheduledDetails?.course_detail?.course_prerequisite?.length >
                  0 &&
                  scheduledDetails?.course_detail?.course_prerequisite?.map(
                    item => (
                      <div className="" key={item.id}>
                        <CourseActivity
                          name={item.name}
                          getStatus={() => getPrerequisiteStatus(item)}
                        />
                      </div>
                    )
                  )}
              </CardBody>
            </Card>
          )}

          <Modal
            isOpen={isModal}
            toggle={handleModal}
            className="modal-body"
            centered={true}
          >
            <ModalHeader toggle={handleModal}>
              <H4>Prerequisite Course</H4>
            </ModalHeader>
            <ModalBody>
              <P className="mb-3">
                {WelcomeToPrerequisite} {course_detail?.course_name} $
                {DesignedToEvaluateYourFoundational}
              </P>

              {scheduledDetails?.course_detail?.course_prerequisite?.map(
                item => (
                  <div className="" key={item.id}>
                    <CourseActivity
                      name={item.name}
                      getStatus={() => getPrerequisiteStatus(item)}
                    />
                  </div>
                )
              )}
            </ModalBody>
            <ModalFooter>
              {isAllCompleted() ? (
                <DefaultButton
                  className="w-100"
                  color="primary"
                  onClick={handleEnroll}
                >
                  {EnrollMe}
                </DefaultButton>
              ) : (
                <div className="d-flex w-100">
                  <SecondaryButton
                    onClick={() => handleMailAndEnroll()}
                    className="secondary-button w-50"
                  >
                    {MailMe}
                  </SecondaryButton>
                  <DefaultButton
                    className="w-50"
                    color="primary"
                    onClick={() => handleMailAndEnroll(true)}
                  >
                    {MailMeAddToILP}
                  </DefaultButton>
                </div>
              )}
            </ModalFooter>
          </Modal>

          {isOngoingOrNominationClosed() &&
            course_detail?.pre_course_work.length > 0 && (
              <Card>
                <CardHeader>
                  <H4>{PreCourseWorkHeader}</H4>
                </CardHeader>
                <CardBody className="mt-2">
                  <LearningPlanCourseCard
                    data={transformedData}
                    handleClick={handlePrequiesitesClick}
                  />
                </CardBody>
              </Card>
            )}

          {isOngoingOrNominationClosed() && (
            <Card>
              <CardHeader>
                <H4>{Material}</H4>
              </CardHeader>
              <CardBody className="mt-2">
                {course_materials.map((item, index) => (
                  <div className="mb-3" key={item.file_name + index}>
                    <Badges className="w-100" color="light text-dark">
                      <div className="d-flex align-items-center">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span className="text-start m-1 w-100 gap-1 p-1 mb-2">
                          {getEllipsedFileName(item.file_name)}
                        </span>
                        <MUIIcons
                          className="pointer"
                          onClick={() => handleDownload([item.file_url])}
                          size={24}
                          iconName="FileDownloadOutlined"
                        />
                      </div>
                    </Badges>
                  </div>
                ))}
              </CardBody>
            </Card>
          )}

          <Card>
            <CardHeader>
              <H4>{ThisCourseIncludes}:</H4>
            </CardHeader>
            <CardBody className="schedule-details__course-includes">
              {courseInlcudes().map(item => (
                <div className="d-flex gap-2 mb-3" key={item.icon}>
                  <MUIIcons
                    className="course-description__calender"
                    size={18}
                    iconName={getIconName(item.icon)}
                  />
                  <P className="course-description__schedule-on m-0">
                    {item.value}
                  </P>
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LearnerScheduleDetails;
