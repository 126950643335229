import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

import { Badges, H5 } from "../../AbstractElements";
import { formatCustomDate } from "../../utils/helper/helper";

const CommentsCard = ({ comment, userMap }) => {
  return (
    <Card className="p-3 comments-card">
      <CardBody className="p-2">
        <H5 className="comment-text">{comment?.feedback}</H5>
        <div className="d-flex gap-2 align-items-center mt-2">
          <H5 className="text-muted mb-2">
            {userMap[comment?.created_by]?.user_name}
          </H5>
          <H5 className="text-muted mb-2">
            {formatCustomDate(comment?.created_at)}
          </H5>
          {comment?.status === "rejected" && (
            <H5 className="mb-2">
              <Badges status={"rejected"} />
            </H5>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

CommentsCard.propTypes = {
  comment: PropTypes.shape({
    feedback: PropTypes.string,
    created_by: PropTypes.string,
    status: PropTypes.string,
    created_at: PropTypes.string,
  }),
  userMap: PropTypes.objectOf(
    PropTypes.shape({
      user_name: PropTypes.string,
    })
  ),
};

export default CommentsCard;
