import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import GenericCard from "../../../../container/GenericCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAssinedAssessmentToLearner } from "../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";

const LearnerAssessmets = () => {
  const dispatch = useAppDispatch();
  const { learnerAssignedAssessments } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [assessments, setAssessments] = useState([]);

  useEffect(() => {
    dispatch(getAssinedAssessmentToLearner());
  }, [dispatch]);

  useEffect(() => {
    const assessments = [];
    if (learnerAssignedAssessments) {
      learnerAssignedAssessments?.assessments?.forEach(item => {
        assessments.push(item);
      });
    }
    setAssessments(assessments);
  }, [learnerAssignedAssessments]);

  return (
    <div className="page-body">
      <Row className="mt-5">
        {assessments?.map((item, index) => {
          return (
            <Col key={index} sm={12} lg={4}>
              <Link
                to={"/assessments/take-assessments"}
                state={{ participantId: item?.id }}
              >
                <GenericCard
                  id={index?.toString()}
                  header={item?.name}
                  status={"in_progress"}
                  isHideMoreOption
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default LearnerAssessmets;
