import React from "react";

import { Course, Courses } from "../../utils/Constant";
import Divider from "../Divider";
import H5 from "../Headings/H5Element";
import P from "../Paragraph";

export interface CourseSummaryModalData {
  courseName: string;
  totalCount: number;
}

interface CourseSummaryModalProps {
  cardData: CourseSummaryModalData[];
}

const CourseSummaryModal: React.FC<CourseSummaryModalProps> = ({
  cardData,
}) => {
  return (
    <div className="p-2">
      {cardData.map((item, index) => (
        <div key={index}>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <H5>{item.courseName}</H5>
            <P>
              {`${item.totalCount}
               ${
                 item.totalCount === 1 || item.totalCount === 0
                   ? Course
                   : Courses
               }`}
            </P>
          </div>
          {index < cardData.length - 1 && (
            <div className="mb-3">
              <Divider />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CourseSummaryModal;
