import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertJobRolePayload } from "../../../Api/entities/ManageMasterDataEntity";
import { MultiselectOption } from "../../../Api/entities/SgaEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createJobRole,
  deleteJobRole,
  getJobRoleList,
  getJobRoleListById,
  setJobRoleListByIdToIntialValue,
  updateJobRole,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  getGradeList,
  getIndustryList,
} from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  Confirm,
  DeleteJobRoleHeader,
  DeleteJobRoleMessage,
  Enter,
  Grade,
  Grades,
  Industry,
  JobRole,
  JobRoles,
  NoDataText,
  Select,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";

const JobRolePage = () => {
  const dispatch = useAppDispatch();
  const { jobRoleList, jobRoleListById } = useAppSelector(
    state => state.manageMasterData
  );
  const { gradeList } = useAppSelector(state => state.master);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [jobRoleToDelete, setJobRoleToDelete] = useState(null);
  const { industryList } = useAppSelector(state => state.master);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndustryId, setSelectedIndustryId] = useState("");
  const [industryListData, setIndustryListData] = useState([]);
  const [gradeOptions, setGradeOptions] = useState<MultiselectOption[]>([]);
  const [preSelectedGrades, setPreSelectedGrades] = useState<
    MultiselectOption[]
  >([]);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getJobRoleList());
    dispatch(getGradeList());
    if (!modalOpen) {
      dispatch(getIndustryList());
    }
  }, [dispatch]);

  useEffect(() => {
    const data = gradeList?.grades?.map(grade => ({
      label: grade.name,
      value: grade.name,
      name: grade?.id.toString(),
    }));
    setGradeOptions(data);
  }, [gradeList]);

  useEffect(() => {
    const industries = mapListToOptions(industryList?.industry, "name", "id");
    setIndustryListData(industries);
  }, [industryList]);

  useEffect(() => {
    if (jobRoleListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: jobRoleListById.name,
        description: jobRoleListById.description,
        selectedOption: jobRoleListById.industry_id?.toString(),
      }));
      if (jobRoleListById.grades) {
        setPreSelectedGrades(
          jobRoleListById.grades.map(item => ({
            name: item.id.toString(),
            value: item.name,
            label: item.name,
          }))
        );
      }
    }
  }, [jobRoleListById]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setJobRoleToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (jobRoleToDelete) {
      dispatch(deleteJobRole({ id: jobRoleToDelete })).then(() => {
        dispatch(getJobRoleList());
        toggleDeleteConfirmModal();
      });
    }
  };

  const handleSave = useCallback(
    (name: string, description: string, industryId: string, grades: any[]) => {
      const gradeIds = grades.map(item => item.name);
      const payload: UpsertJobRolePayload = {
        industry_id: industryId ? parseInt(industryId) : 0,
        name: name,
        description: description,
        grade: gradeIds || [],
      };

      if (jobRoleListById) {
        payload.id = jobRoleListById.id;
        dispatch(updateJobRole({ updateJobRolePayload: payload })).then(() => {
          dispatch(getJobRoleList());
          toggleModal();
        });
      } else {
        dispatch(createJobRole({ createJobRolePayload: payload })).then(() => {
          dispatch(getJobRoleList());
          toggleModal();
        });
      }
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getJobRoleListById({ id: selectedId }));
    toggleModal();
  };

  const AccordionData = jobRoleList?.job_role?.map(item => ({
    id: item.id.toString(),
    name: item.name,
    description: item.description,
    createdOn: item.created_at,
    modifiedOn: item.updated_at,
    depedentBadges: [
      { id: 1, badge: `${Grade}: ${item.grade_count}` },
      { id: 2, badge: `${Industry}: ${item.industry_id ? "1" : "0"}` },
    ],
  }));

  const filteredAccordionData = AccordionData?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteJobRoleHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteJobRoleMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{JobRoles}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  setSelectedIndustryId("");
                  dispatch(setJobRoleListByIdToIntialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>
      {filteredAccordionData?.length > 0 ? (
        filteredAccordionData.map(item => (
          <MasterDataAccordion
            key={item.id}
            accordionItems={item}
            onEditClick={() => handleEdit(item.id)}
            onDeleteClick={() => handleDelete(item.id)}
          />
        ))
      ) : (
        <NoData svg={"empty-folder-icon"} title={NoDataText} />
      )}

      {filteredAccordionData?.map(item => (
        <MasterDataAccordion
          key={item.id}
          accordionItems={item}
          onEditClick={() => handleEdit(item.id)}
          onDeleteClick={() => handleDelete(item.id)}
        />
      ))}
      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={JobRole}
        placeholderText={`${Enter} ${JobRole}`}
        toolTipText={`${Enter} ${JobRole}`}
        dropdownLabel={Industry}
        dropdownLabelTooltip={`${Select} ${Industry}`}
        selectedDropdownValue={selectedIndustryId}
        onDropdownChange={value => setSelectedIndustryId(value)}
        dropdownOptions={industryListData}
        initialValues={modalFormValues}
        multiSelectDropdownLabel={Grades}
        multiSelectDropdownLabelTooltip={`${Select} ${Grades}`}
        multiSelectOptions={gradeOptions}
        selectedMultiSelectValues={preSelectedGrades}
        multiSelectPlaceholder={`${Select} ${Grade}`}
      />
    </div>
  );
};

export default JobRolePage;
