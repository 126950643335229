import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { P } from "../../AbstractElements";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../CommonElements/JustifyTabs";
import CommonModal from "../../CommonElements/Modal";
import SearchBar from "../../CommonElements/SearchBar";
import NominationListTable from "../../container/NominationListTable";
import RmReplacementCard from "../../container/RmReplacementCard";
import TotalCountCard from "../../container/TotalCountCard";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  getNominationList,
  reviewTrainerOrNominee,
} from "../../ReduxToolkit/Reducers/CourseSlice";
import { getClassRoomList } from "../../ReduxToolkit/Reducers/MasterSlice";
import {
  Approve,
  ApproveConfirmation,
  ApprovedHeader,
  ClassRoom,
  Confirm,
  Confirmation,
  Decline,
  Declined,
  EndDate,
  Expired,
  Locations,
  MoreActions,
  RejectConfirmation,
  Replace,
  Select,
  StartDate,
  TotalRequest,
} from "../../utils/Constant";
import { nominationListTabs } from "../../utils/helper/helper";

import "./style.scss";

const createTotalCountData = nominationList => [
  {
    count: nominationList?.total_request || 0,
    title: TotalRequest,
    icon: "total-request",
  },
  {
    count: nominationList?.total_approved || 0,
    title: ApprovedHeader,
    icon: "approved",
  },
  {
    count: nominationList?.total_declined || 0,
    title: Declined,
    icon: "declined",
  },
  {
    count: nominationList?.total_expired || 0,
    title: Expired,
    icon: "declined",
  },
];

const NominationRequest = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [userMap, setUserMap] = useState(null);
  const [replacementListData, setReplacementListData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedModalType, setSelectedModalType] = useState("");
  const [locationFilterValues, setLocationFilterValues] = useState([]);
  const [classroomFilterValues, setClassroomFilterValues] = useState([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { nominationList } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const { locationList, classRoomList } = useAppSelector(state => state.master);

  const [totalCountData, setTotalCountData] = useState(
    createTotalCountData({})
  );

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (selectedFilterValues["location"]?.length > 0)
      dispatch(
        getClassRoomList({
          id: selectedFilterValues["location"],
        })
      );
  }, [selectedFilterValues["location"]]);

  useEffect(() => {
    if (locationList?.location?.length > 0) {
      const location = locationList.location.map(item => ({
        name: item?.id,
        label: item?.name,
        value: item?.name,
      }));
      setLocationFilterValues(location);
    }
  }, [locationList]);

  useEffect(() => {
    if (classRoomList?.class_room?.length > 0) {
      const classRooms = classRoomList.class_room.map(item => ({
        name: item?.id,
        label: item?.name,
        value: item?.name,
      }));
      setClassroomFilterValues(classRooms);
    }
  }, [classRoomList]);

  useEffect(() => {
    getNominationData();
  }, [activeTab]);

  const getNominationData = (filterData = null) => {
    dispatch(getNominationList({ type: activeTab, filterData }));
  };

  useEffect(() => {
    if (nominationList?.nominees?.length > 0) {
      const modifiedData = nominationList?.nominees.map(item => ({
        id: item.id,
        oldName: userMap ? userMap[item?.participant_id]?.user_name : "-",
        newName: userMap ? userMap[item?.replacement_user_id]?.user_name : "-",
      }));
      setReplacementListData(modifiedData);
    } else {
      setReplacementListData([]);
    }
  }, [nominationList, userMap]);

  const isReplacementTab = () => {
    return activeTab === "replacement";
  };

  useEffect(() => {
    setTotalCountData(createTotalCountData(nominationList));
    setSearchResults(nominationList.nominees);
  }, [nominationList]);

  const handleSearch = (query: string) => {
    const filteredAssessment = nominationList?.nominees?.filter(nomini =>
      nomini.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredAssessment || []);
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    getNominationData(values);
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    getNominationData();
  };

  const handleCourseClick = row => {
    return navigate("/nomination-request/course-details", {
      state: {
        ...row,
      },
    });
  };

  const handleCancel = row => {
    setSelectedId(row);
    setSelectedModalType("reject");
    toggleModal();
  };

  const handleApprove = row => {
    setSelectedId(row);
    setSelectedModalType("approve");
    toggleModal();
  };

  const handleReplace = row => {
    return navigate("/add-participant", {
      state: {
        replace_id: row.nomination_id,
        participant_id: row.participant_id,
        replace_name: userMap
          ? userMap[row?.primary_trainer_id]?.user_name
          : "-",
        scheduledId: row.schedule_id,
        courseId: row.course_id,
        courseName: row.course_name,
        isFromNomination: true,
      },
    });
  };

  const actions = [
    {
      type: "icon",
      icon: "CancelOutlined",
      label: Decline,
      onClick: handleCancel,
      className: "primary-icon-color pointer",
    },
    {
      type: "icon",
      icon: "CheckCircleOutlined",
      label: Approve,
      onClick: handleApprove,
      className: "text-success pointer",
    },
    {
      type: "dropdown",
      label: MoreActions,
      dropdownOptions: [
        {
          label: Replace,
          onClick: handleReplace,
        },
      ],
    },
  ];

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedModalType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handlePrimaryBtnClick = async () => {
    let payload;
    if (selectedModalType === "approve") {
      payload = {
        action: "approved",
        type: "nominee",
        id: selectedId?.nomination_id?.toString(),
        reason: "",
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "reject") {
      payload = {
        action: "rejected",
        type: "nominee",
        id: selectedId?.nomination_id?.toString(),
        reason: "",
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    }

    await dispatch(getNominationList({ type: activeTab }));

    toggleModal();
  };

  let sizeTitle = "";
  if (selectedModalType === "approve") {
    sizeTitle = ApproveConfirmation;
  } else if (selectedModalType === "reject") {
    sizeTitle = RejectConfirmation;
  }

  const renderModalBody = type => {
    return <>{type !== "remove" && <P>{sizeTitle}</P>}</>;
  };

  return (
    <div className="page-body nomination-list">
      <div>
        <TotalCountCard data={totalCountData} />
        <Card className="p-1">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
                <JustifyTabs
                  tabs={nominationListTabs}
                  activeTab={activeTab}
                  onTabClick={handleTabClick}
                />
              </div>
              {!isReplacementTab() && (
                <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
                  <SearchBar onSearch={handleSearch} />
                  <div className="mt-2">
                    <FilterSliderModal
                      dropdowns={[
                        {
                          label: StartDate,
                          key: "start_date",
                          tooltipText: StartDate,
                          isDate: true,
                          isMultiSelect: false,
                        },
                        {
                          label: EndDate,
                          key: "end_date",
                          tooltipText: EndDate,
                          isDate: true,
                          isMultiSelect: false,
                        },
                        {
                          label: Locations,
                          key: "location",
                          tooltipText: `${Select} ${Locations}`,
                          options: locationFilterValues,
                          isMultiSelect: true,
                        },
                        {
                          label: ClassRoom,
                          key: "classroom",
                          tooltipText: `${Select} ${ClassRoom}`,
                          options: classroomFilterValues,
                          isMultiSelect: true,
                        },
                      ]}
                      selectedFilterValues={selectedFilterValues}
                      defaultFilterValues={defaultFilterValues}
                      setSelectedFilterValues={setSelectedFilterValues}
                      setDefaultFilterValues={setDefaultFilterValues}
                      onDone={handleDone}
                      onClear={handleClear}
                    />
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
        {!isReplacementTab() && (
          <NominationListTable
            data={searchResults}
            handleCourseClick={handleCourseClick}
            actions={actions}
          />
        )}
        {isReplacementTab() && <RmReplacementCard data={replacementListData} />}

        <CommonModal
          sizeTitle={Confirmation}
          modalBodyClassName=""
          isOpen={isModalOpen}
          toggle={toggleModal}
          backdrop="static"
          size="lg"
          showFooter
          onPrimaryBtnClick={handlePrimaryBtnClick}
          primaryBtnText={Confirm}
        >
          {renderModalBody(selectedModalType)}
        </CommonModal>
      </div>
    </div>
  );
};

export default NominationRequest;
