import React, { useState } from "react";
import { Range } from "react-range";
import { Container, Row, Col } from "reactstrap";

import "./style.scss";
import H3 from "../Headings/H3Element";

interface CustomRangeSliderProps {
  min: number;
  max: number;
  onValueChange: (value: number) => void;
}

const RangeSlider: React.FC<CustomRangeSliderProps> = ({
  min,
  max,
  onValueChange,
}) => {
  const [values, setValues] = useState([min]);

  const handleChange = (newValues: number[]) => {
    setValues(newValues);
    onValueChange(newValues[0]);
  };

  return (
    <Container className="custom-range-slider-container">
      <Row className="justify-content-center align-items-center">
        <Col xs="auto">{min}</Col>
        <Col xs={8}>
          <Range
            step={1}
            min={min}
            max={max}
            values={values}
            onChange={handleChange}
            renderTrack={({ props, children }) => (
              <div {...props} className="custom-slider-track">
                <div
                  className="custom-slider-track-inner"
                  style={{
                    width: `${((values[0] - min) / (max - min)) * 100}%`,
                  }}
                />
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div {...props} className="custom-slider-thumb" />
            )}
          />
        </Col>
        <Col xs="auto">{max}</Col>
      </Row>
      <Row>
        <Col>
          <H3>{values[0]}</H3>
        </Col>
      </Row>
    </Container>
  );
};

export default RangeSlider;
