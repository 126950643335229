import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import Btn from "../../../CommonElements/Button";
import MapAssessmentModal from "../../../CommonElements/MapAssessmentModal";
import MUIIcons from "../../../CommonElements/MUIIcon/MUIIcons";
import SearchBar from "../../../CommonElements/SearchBar";
import YearNavigator from "../../../CommonElements/YearNavigator";
import GenericCard from "../../../container/GenericCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getMappedAssessment } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import {
  Add,
  Completed,
  CompletedOn,
  End,
  FinalScore,
  Modules,
  Pending,
  Questions,
  ScheduledOn,
  Start,
  SubmittedOn,
  Time,
  Total,
} from "../../../utils/Constant";
import {
  assessmentStatus,
  assessmentTypesMap,
  formatCustomDate,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

import "./style.scss";

const ILPAssessment = ({ userId }) => {
  const dispatch = useAppDispatch();
  const { mappedAssessment } = useAppSelector(state => state.learningPlan);
  const [isMapAssessmentModalOpen, setIsMapAssessmentModalOpen] =
    useState(false);
  const [searchResults, setSearchResults] = useState<string>("");
  const [fyYear, setFyYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    getAssessmentList();
  }, [dispatch, fyYear]);

  const getAssessmentList = () => {
    if (userId) {
      dispatch(
        getMappedAssessment({
          fyYear: fyYear,
          userId: userId,
        })
      );
    } else {
      dispatch(
        getMappedAssessment({
          fyYear: fyYear,
          userId: null,
        })
      );
    }
  };

  const mapAssessmentModalToggle = isMapped => {
    setIsMapAssessmentModalOpen(!isMapAssessmentModalOpen);
    if (isMapped === true) {
      getAssessmentList();
    }
  };

  const handleSearch = (query: string) => {
    setSearchResults(query.toLowerCase());
  };

  const filteredAssessments = mappedAssessment?.data?.assessments?.filter(
    assessment => assessment?.name?.toLowerCase().includes(searchResults)
  );

  const calculateAssessmentCounts = (
    mappedAssessment: any,
    searchResults: string
  ) => {
    const assessments = mappedAssessment?.data?.assessments || [];

    const counts = assessments.reduce(
      (acc, assessment) => {
        if (
          !assessment?.name?.toLowerCase().includes(searchResults.toLowerCase())
        ) {
          return acc;
        }

        acc.total++;

        switch (assessment.status) {
          case "yts":
          case "in_progress":
            acc.pending++;
            break;
          case "failed":
          case "passed":
          case "under_review":
          case "certification":
          case "retake":
          case "draft":
          case "completed":
            acc.completed++;
            break;
          default:
            break;
        }

        return acc;
      },
      { pending: 0, total: 0, completed: 0 }
    );

    return counts;
  };

  const renderAssessmentCard = assessment => {
    const {
      participant_id,
      start_date,
      end_date,
      name,
      estimated_time,
      overall_question_count,
      module_count,
      status,
      end_time,
      score,
      assessment_type,
    } = assessment;
    let message = "";
    switch (status) {
      case "yts":
      case "in_progress":
        message = ScheduledOn;
        break;
      case "failed":
      case "passed":
      case "certification":
      case "retake":
      case "completed":
        message = CompletedOn;
        break;
      case "under_review":
      case "draft":
        message = SubmittedOn;
        break;
      default:
        break;
    }

    let footerDate = "";

    if (start_date && assessment_type === assessmentTypesMap.sga) {
      footerDate = `${ScheduledOn} ${formatCustomDate(start_date)}`;
    } else if (end_time) {
      footerDate = `${message} ${formatCustomDate(end_time)}`;
    }

    return (
      <Col key={participant_id} sm={12} lg={4} className="ilp-assessment-card">
        <GenericCard
          id={participant_id}
          header={name}
          moduleDetails={[
            {
              label: Start,
              value: start_date ? formatCustomDate(start_date) : "-",
              key: `${participant_id}_${Start}`,
            },
            {
              label: End,
              value: end_date ? formatCustomDate(end_date) : "-",
              key: `${participant_id}_${End}`,
            },
            {
              label: Time,
              value: estimated_time || "-",
              key: `${participant_id}_${Time}`,
            },
            {
              label: Questions,
              value: overall_question_count || "-",
              key: `${participant_id}_${Questions}`,
            },
            {
              label: Modules,
              value: module_count || "-",
              key: `${participant_id}_${Modules}`,
            },
          ]}
          isHideMoreOption={true}
          footerBadge={
            status === assessmentStatus.failed ||
            status === assessmentStatus.passed
              ? `${FinalScore}: ${score}%`
              : null
          }
          footerDate={footerDate}
          footerBadgeStatus={
            status === assessmentStatus.passed ? "draft" : "rejected"
          }
        />
      </Col>
    );
  };

  const filteredCountData = calculateAssessmentCounts(
    mappedAssessment,
    searchResults
  );

  const handleYearChange = (year: string) => {
    setFyYear(year);
  };

  return (
    <div>
      <Card className="p-1 mb-3">
        <CardBody>
          <Row className="align-items-center gap-lg-0 gap-3">
            <Col xs="12" lg="7" className="d-flex justify-content-start gap-3">
              <span className="text-gray d-flex gap-1">
                {Total} :
                <strong className="text-black">
                  {filteredCountData.total}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Pending}:
                <strong className="text-black">
                  {filteredCountData.pending}
                </strong>
              </span>
              <span className="text-gray d-flex gap-1">
                {Completed}:
                <strong className="text-black">
                  {filteredCountData.completed}
                </strong>
              </span>
            </Col>
            <Col
              xs="12"
              lg="5"
              className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end "
            >
              <div className="d-flex flex-row gap-3 align-items-center">
                <SearchBar onSearch={handleSearch} />
                <YearNavigator
                  pastYears={20}
                  futureYears={20}
                  onYearChange={handleYearChange}
                />
                {userId &&
                  hasPermissionToComponent(
                    "USER_MANAGEMENT.MAP_ASSESSMENT"
                  ) && (
                    <Btn
                      icon={
                        <MUIIcons
                          size={16}
                          iconName="AddCircleOutlineOutlined"
                        />
                      }
                      color="primary"
                      onClick={mapAssessmentModalToggle}
                    >
                      {Add}
                    </Btn>
                  )}
                {isMapAssessmentModalOpen && (
                  <MapAssessmentModal
                    isOpen={isMapAssessmentModalOpen}
                    toggle={mapAssessmentModalToggle}
                    userId={userId}
                  />
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row className="mt-5">
        {filteredAssessments?.length > 0 ? (
          filteredAssessments?.map(assessment =>
            renderAssessmentCard(assessment)
          )
        ) : (
          <NoDataFound />
        )}
      </Row>
    </div>
  );
};
ILPAssessment.propTypes = {
  userId: PropTypes.number,
};
export default ILPAssessment;
