import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H4 } from "../../AbstractElements";
import "./style.scss";

interface TotalSeatData {
  id: number;
  count: number | string;
  title: string;
}

interface TotalSeatCardProps {
  data: TotalSeatData[];
}

const TotalSeatCard: React.FC<TotalSeatCardProps> = ({ data }) => {
  return (
    <Row>
      {data?.map(item => (
        <Col key={item.id}>
          <Card className="custom-total-seat">
            <CardBody className="d-flex flex-column gap-3">
              <H4 className="custom-total-card-font">{item.title}</H4>
              {item.count ? <H4 className="fw-bold">{item.count}</H4> : "-"}
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

TotalSeatCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TotalSeatCard;
