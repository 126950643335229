import { Divider } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";

import "./style.scss";
import { Btn, H4 } from "../../../../AbstractElements";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import { Continue, Instruction } from "../../../../utils/Constant";
import { InstructionData } from "../../../../utils/helper/helper";
import useIsMobile from "../../../../utils/helper/responsive";

const InstructionPage: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { sections } = InstructionData;

  const handleButtonClick = () => {
    return navigate(
      `${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`
    );
  };

  return (
    <div className="page-body page-body-margin custom-instruction-page">
      <Card>
        <CardBody>
          <H4 className="p-2 mb-2">{Instruction}</H4>
          <Divider />
          {sections.map(section => (
            <div key={section.id} className="mt-3 p-1">
              <h5 className="fw-bold">{section.heading}</h5>
              <p>{section.content}</p>
            </div>
          ))}
        </CardBody>
        <FixedFooter>
          <Row>
            <Col xs="auto" className={isMobile ? "mb-5" : ""}>
              <Btn onClick={handleButtonClick} color="primary">
                {Continue}
              </Btn>
            </Col>
          </Row>
        </FixedFooter>
      </Card>
    </div>
  );
};

export default InstructionPage;
