import { useEffect, useState, useCallback } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertCompetencyPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createCompetency,
  deleteCompetency,
  getCompetencyList,
  getManageMasterCompetencyListById,
  setCompetencyListByIdToInitialValue,
  updateCompetency,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import { getDomainList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  Competency,
  DeleteCompetencyHeader,
  DeleteCompetencyMessage,
  Competencies,
  Enter,
  Confirm,
  LO,
  Level,
  Domain,
  Select,
  NoDataText,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";

const CompetencyPage = () => {
  const dispatch = useAppDispatch();
  const { competencyList, competencyListById } = useAppSelector(
    state => state.manageMasterData
  );
  const { domainList } = useAppSelector(state => state.master);
  const [modalOpen, setModalOpen] = useState(false);
  const [domainListData, setDomainListData] = useState([]);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [competencyToDelete, setCompetencyToDelete] = useState(null);
  const [selectedDomainId, setSelectedDomainId] = useState("");
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getCompetencyList());
    if (!modalOpen) {
      dispatch(getDomainList());
    }
  }, [dispatch]);

  useEffect(() => {
    const domains = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    if (competencyListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: competencyListById.competency_name,
        description: competencyListById.description,
        selectedOption: competencyListById.domain_id.toString(),
      }));
    }
  }, [competencyListById]);

  const AccordionData = competencyList?.competency?.map(comp => ({
    id: comp.id.toString(),
    name: comp.competency_name,
    description: comp.description,
    createdOn: comp.created_at,
    modifiedOn: comp.updated_at,
    depedentBadges: [
      { id: 1, badge: `${Level}: ${comp.level_count}` },
      { id: 2, badge: `${LO}: ${comp.lo_count}` },
    ],
  }));

  const filteredAccordionData = AccordionData?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSave = useCallback(
    (name: string, description: string, domainId: string) => {
      const payload: UpsertCompetencyPayload = {
        name,
        description,
        domain_id: parseInt(domainId),
      };

      if (competencyListById) {
        payload.id = competencyListById.id;
        dispatch(updateCompetency({ updateCompetencyPayload: payload })).then(
          () => {
            dispatch(getCompetencyList());
            toggleModal();
          }
        );
      } else {
        dispatch(createCompetency({ createCompetencyPayload: payload })).then(
          () => {
            dispatch(getCompetencyList());
            toggleModal();
          }
        );
      }
    },
    [dispatch, toggleModal]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getManageMasterCompetencyListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setCompetencyToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (competencyToDelete) {
      dispatch(deleteCompetency({ id: competencyToDelete })).then(() => {
        dispatch(getCompetencyList());
        toggleDeleteConfirmModal();
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteCompetencyHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteCompetencyMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Competencies}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  setSelectedDomainId("");
                  dispatch(setCompetencyListByIdToInitialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>

      {filteredAccordionData?.length > 0 ? (
        filteredAccordionData.map(item => (
          <MasterDataAccordion
            key={item.id}
            accordionItems={item}
            onEditClick={() => handleEdit(item.id)}
            onDeleteClick={() => handleDelete(item.id)}
          />
        ))
      ) : (
        <NoData svg={"empty-folder-icon"} title={NoDataText} />
      )}

      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Competency}
        placeholderText={`${Enter} ${Competency}`}
        toolTipText={`${Enter} ${Competency}`}
        initialValues={modalFormValues}
        dropdownLabel={Domain}
        dropdownLabelTooltip={`${Select} ${Domain}`}
        dropdownOptions={domainListData}
        selectedDropdownValue={selectedDomainId}
        onDropdownChange={value => setSelectedDomainId(value)}
      />
    </div>
  );
};

export default CompetencyPage;
