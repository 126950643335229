import { Button } from "reactstrap";
import "./style.scss";

interface SecondaryButtonPropsTypes {
  children?: React.ReactNode;
  onClick?: (key: any) => void;
  className?: string;
}

const SecondaryButton = (props: SecondaryButtonPropsTypes) => {
  const { children } = props;
  return (
    <Button className="secondary-button" {...props}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
