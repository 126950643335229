import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Row, Col, Badge } from "reactstrap";

import { Badges, H1, H4, H5, P } from "../../AbstractElements";
import { YourScore } from "../../utils/Constant";

import "./style.scss";

interface FailedScoreCardProps {
  score: number;
  message: string;
  reattempDays?: string;
  testName?: string;
  attemptOn?: string;
}

const FailedScoreCard: React.FC<FailedScoreCardProps> = ({
  score,
  message,
  reattempDays,
  testName,
  attemptOn,
}) => {
  return (
    <Card className="failed-score-card my-4">
      <CardBody className="m-3">
        <Row>
          <Col
            xs="12"
            md="6"
            className="d-block d-md-flex align-items-center gap-4 mt-3 text-center text-md-left"
          >
            <div>
              <H5>{YourScore}</H5>
              <H1 className="text-danger failed-score-card__score">{score}%</H1>
            </div>
            <div>
              <H4>{message}</H4>
              <P className="mt-3">{reattempDays}</P>
            </div>
          </Col>
          <Col xs="12" md="6" className="text-center">
            <div>
              <Badges className="p-2">
                <P>{attemptOn}</P>
              </Badges>
            </div>
            {testName && (
              <div className="mt-2">
                <Badge color="warning" className="p-2 text-wrap">
                  <P>{testName}</P>
                </Badge>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

FailedScoreCard.propTypes = {
  score: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  reattempDays: PropTypes.string.isRequired,
  testName: PropTypes.string.isRequired,
};

export default FailedScoreCard;
