import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";

import type { RootState, AppDispatch } from "./Store";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

const useCountdown = (initialMinutes: number, callback?: () => void) => {
  const [remainingSeconds, setRemainingSeconds] = useState(initialMinutes);

  useEffect(() => {
    setRemainingSeconds(initialMinutes);
  }, [initialMinutes]);

  const time: Time = {
    hours: Math.floor(remainingSeconds / 3600),
    minutes: Math.floor((remainingSeconds % 3600) / 60),
    seconds: remainingSeconds % 60,
  };

  useEffect(() => {
    if (remainingSeconds <= 0) return;

    const countdown = setInterval(() => {
      setRemainingSeconds(prevSeconds => {
        if (prevSeconds <= 1) {
          clearInterval(countdown);
          if (callback) callback();
          return 0;
        }

        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [remainingSeconds, callback]);

  return time;
};

export default useCountdown;
