import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import {
  DefaultButton,
  MUIIcons,
  H4,
  P,
} from "../../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  REJECTED_STATUS,
} from "../../../../../Api/constants";
import BorderTabs from "../../../../../CommonElements/BorderTabs";
import DateRangeDisplay from "../../../../../CommonElements/DateRangeDisplay";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import SearchBar from "../../../../../CommonElements/SearchBar";
import StatusDisplay from "../../../../../CommonElements/StatusDisplay";
import ParticipantsDetails from "../../../../../container/ParticipantDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  deleteScheduled,
  getScheduledDetailsByScheduleId,
  reviewModificationRequest,
  setAssessmentCatlogPayloadToInitials,
} from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import { updateReAttemptDays } from "../../../../../ReduxToolkit/Reducers/AssessmentSlice";
import { getUsersList } from "../../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../../Service";
import {
  SubmittedOn,
  AddUsers,
  NotStarted,
  ReviewPending,
  Score,
  Status,
  UserDetails,
  UsersEnrolled,
  Review,
  ExportPDF,
  ComingSoon,
  YourReason,
  Days,
  LearnerReason,
  Enter,
  AddReasonForDecline,
  DeclineReason,
  ApproveReason,
  Submit,
  Reason,
  AddReasonForApprove,
  NumberOfDays,
  FillAllFields,
  ReAttempt,
  ScheduledOn,
  CompletedOn,
  ExtensionRequestLimit,
  EnteredMoreThanRequested,
  Declined,
  Delete,
  DeleteUserHeader,
  DeleteUserMessage,
} from "../../../../../utils/Constant";
import {
  assessmentParticipantStatus,
  capitalizeFirstLetter,
  displayStatus,
  formatDate,
  handleKeyDownOnNumericWithoutDecimal,
  pendingStatus,
  scheduledDate,
  scheduledDetailsTabs,
  showToast,
} from "../../../../../utils/helper/helper";

const ScheduledUserDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const { usersList } = useAppSelector(state => state.user);
  const { scheduleId, assessmentId, type } = location.state || "";
  const [activeTab, setActiveTab] = useState<string>("assigned");
  const { scheduledDetails } = useAppSelector(state => state.assessmentCatlog);
  const [userMap, setUserMap] = useState(null);
  const [pendingCount, setPendingCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noOfDays, setNoOfDays] = useState(null);
  const [updatedScheduledDetailsTabs, setUpdatedScheduledDetailsTabs] =
    useState(scheduledDetailsTabs);
  const [participantDetails, setParticipantDetails] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [comments, setComments] = useState("");
  const [query, setQuery] = useState("");
  const [selectedModal, setSelectedModal] = useState("");
  const [requestedDates, setRequestedDates] = useState(null);

  useEffect(() => {
    if (scheduleId && assessmentId) {
      getAllData();
    }
  }, [scheduleId, assessmentId]);

  const getAllData = async () => {
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(
      getScheduledDetailsByScheduleId({
        id: scheduleId,
        assessment_id: assessmentId,
      })
    );
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const users = [];
    let pending = 0;
    if (scheduledDetails && scheduledDetails?.participants) {
      scheduledDetails?.participants?.forEach((item: any) => {
        let dropValues = [Review, ReAttempt, ExportPDF];
        let message = "";
        if (item?.status === "yts") {
          if (type === "upcoming") {
            dropValues = [Delete];
            message = ScheduledOn;
          } else {
            dropValues = [];
            message = ScheduledOn;
          }
        }
        if (item?.status === "in_progress") {
          dropValues = [];
          message = ScheduledOn;
        }
        if (item?.status === "failed" || item?.status === "retake") {
          dropValues = [ReAttempt, ExportPDF];
          message = CompletedOn;
        }
        if (item?.status === "passed") {
          dropValues = [ExportPDF];
          message = CompletedOn;
        }
        if (item?.status === "under_review" || item?.status === "draft") {
          dropValues = [Review];
          message = SubmittedOn;
        }

        if (pendingStatus.includes(assessmentParticipantStatus[item?.status])) {
          pending = pending + 1;
        }
        users.push({
          status: {
            label: Status,
            numbers: [assessmentParticipantStatus[item?.status] || "-"],
          },
          extraStatus: {
            label: Score,
            numbers: [item?.score || "-"],
          },
          actions:
            dropValues?.length > 0
              ? [
                  {
                    type: "dropdown",
                    items: dropValues,
                    id: item?.id,
                    status: item?.status,
                  },
                ]
              : [],
          imagePath: dynamicImage("dashboard-2/user/2.png"),
          name: userMap ? userMap[item?.user_id]?.user_name : null,
          detail: `${message} : ` + formatDate(item?.updated_at),
          display_status: item?.status,
        });
      });
    }
    const tabs: any = [...scheduledDetailsTabs];
    if (pending > 0) {
      tabs[1].count = pending;
    } else {
      delete tabs[1].count;
    }
    if (scheduledDetails?.extension_request?.length > 0) {
      const extensionRequests = scheduledDetails?.extension_request.filter(
        (req: { status: string }) => req.status === "pending"
      ).length;
      if (extensionRequests > 0) tabs[2].count = extensionRequests;
      else {
        tabs[2].count = 0;
        delete tabs[2].count;
      }
    }
    setUpdatedScheduledDetailsTabs(tabs);
    setPendingCount(pending);
    setUsers(users);

    const extensionData = scheduledDetails?.extension_request?.map(
      extension => {
        const status = extension.status;
        return {
          userDetails: {
            imagePath: dynamicImage("dashboard-2/user/2.png"),
            name: userMap ? userMap[extension.user_id]?.user_name : null,
            detail: `Extenstion requested on : ${formatDate(extension.created_at)}`,
          },
          childStatus: [
            {
              isLarge: 4,
              label: LearnerReason,
              numbers: [extension.requester_comments],
              childLabel: YourReason,
              childNumbers: [extension.reviewer_comments],
            },
            {
              label: Days,
              numbers: [extension.requested_days],
              childLabel: status === REJECTED_STATUS ? "" : Days,
              childNumbers: [extension.provided_days],
            },
            {
              label: Status,
              numbers:
                status && status !== "pending"
                  ? [
                      status === "rejected"
                        ? Declined
                        : capitalizeFirstLetter(status),
                    ]
                  : [],
            },
          ],
          actions:
            status === "pending"
              ? [
                  {
                    type: "approve",
                    id: extension.id,
                  },
                  {
                    type: "reject",
                    id: extension.id,
                  },
                ]
              : [],
        };
      }
    );

    setParticipantDetails(extensionData);
  }, [scheduledDetails, userMap]);

  const handleTabClick = async (tabId: any) => {
    setActiveTab(tabId);
  };

  const onDropdownClick = (id, item, status) => {
    if (status === "yts") {
      setSelectedUserId(id);
      setSelectedModal("delete");
      toggleModal();
    }
    if (status === "in_progress") {
      setSelectedUserId(id);
      setSelectedModal("reattempt");
      toggleModal();
    }
    if (status === "failed" || status === "retake") {
      if (item === "item1") {
        setSelectedUserId(id);
        setSelectedModal("reattempt");
        toggleModal();
      }
      if (item === "item2") {
        //Export as PDF for the particular user
        showToast(ComingSoon, "warning");
        return;
      }
    }
    if (status === "passed") {
      //Export as PDF for the particular user
      showToast(ComingSoon, "warning");
      return;
    }
    if (status === "under_review" || status === "draft") {
      if (item === "item1") {
        return navigate(
          `${process.env.PUBLIC_URL}/assessments/review-standalone-assessment`,
          {
            state: { participantId: id },
          }
        );
      }
      if (item === "item2") {
        setSelectedModal("reattempt");
        toggleModal();
      }
    }
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setComments("");
      setNoOfDays(null);
      setSelectedModal("");
      setSelectedUserId("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const approveExtensionRequest = async (id: any) => {
    const findElement = scheduledDetails?.extension_request?.find(
      item => item.id === id
    );

    if (findElement?.requested_days) {
      setNoOfDays(findElement.requested_days);
      setRequestedDates(findElement.requested_days);
    } else {
      setNoOfDays(null);
    }

    setSelectedUserId(id);
    setComments("");
    setSelectedModal("approve");
    toggleModal();
  };

  const rejectExtensionRequest = (id: any) => {
    setSelectedUserId(id);
    setComments("");
    setNoOfDays(null);
    setSelectedModal("reject");
    toggleModal();
  };

  const getModalHeader = () => {
    if (selectedModal === "reject") {
      return DeclineReason;
    }
    if (selectedModal === "approve") {
      return ApproveReason;
    }
    if (selectedModal === "reattempt") {
      return ReAttempt;
    }
    if (selectedModal === "delete") {
      return DeleteUserHeader;
    }
  };

  const handleSearch = (query: string) => {
    setQuery(query);
  };

  const handleCheckDay = e => {
    if (e.target.value > requestedDates) {
      showToast(EnteredMoreThanRequested, "info");
    }
  };

  return (
    <div className="page-body">
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={getModalHeader()}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={async () => {
          if (selectedModal === "delete") {
            const participants = [];
            participants.push({ participant_id: selectedUserId });
            const payload = {
              id: scheduleId,
              assessment_id: assessmentId,
              participants: participants,
            };
            await dispatch(deleteScheduled({ data: payload }));
            await dispatch(
              getScheduledDetailsByScheduleId({
                id: scheduleId,
                assessment_id: assessmentId,
              })
            );
            setSelectedUserId("");
            setComments("");
            setNoOfDays(null);
            toggleModal();
            return;
          }
          if (selectedModal !== "reattempt") {
            const payload = {
              id: selectedUserId,
              reviewer_comments: comments,
              ...(selectedModal === "approve" && {
                status: "approved",
                provided_days: noOfDays,
              }),
              ...(selectedModal === "reject" && { status: "rejected" }),
            };
            const isApprove = selectedModal === "approve";
            const isReject = selectedModal === "reject";
            let isValid;
            if (isApprove) {
              isValid =
                comments && comments.length > 1 && noOfDays && noOfDays > 0;
            } else if (isReject) {
              isValid = comments && comments.length > 0;
            } else {
              isValid = false;
            }
            if (isValid) {
              await dispatch(reviewModificationRequest({ data: payload }));
              await dispatch(
                getScheduledDetailsByScheduleId({
                  id: scheduleId,
                  assessment_id: assessmentId,
                })
              );
              setSelectedUserId("");
              setComments("");
              setNoOfDays(null);
              toggleModal();
            } else {
              showToast(FillAllFields, "error");
            }
          } else {
            if (noOfDays === "") {
              showToast(FillAllFields, "error");
              return;
            }
            const request: UpdateReAttemptDaysRequest = {
              participant_id: parseInt(selectedUserId),
              re_attempt_count: noOfDays,
            };
            await dispatch(updateReAttemptDays({ request: request }));
            await dispatch(
              getScheduledDetailsByScheduleId({
                id: scheduleId,
                assessment_id: assessmentId,
              })
            );
            toggleModal();
          }
        }}
      >
        {selectedModal === "reattempt" && (
          <FormGroup>
            <LabelTooltip
              label={ReAttempt}
              tooltipText={`${Enter} ${ReAttempt}`}
              important
            />

            <Input
              name="re_attempt"
              type="number"
              inputMode="numeric"
              value={noOfDays}
              onKeyDown={handleKeyDownOnNumericWithoutDecimal}
              placeholder={`${Enter} ${ReAttempt}`}
              onChange={e => {
                const value = e.target.value;
                if (parseInt(value) < 100 || value === "") setNoOfDays(value);
              }}
            />
          </FormGroup>
        )}
        {selectedModal === "approve" && (
          <FormGroup>
            <LabelTooltip
              label={NumberOfDays}
              tooltipText={`${Enter} ${NumberOfDays}`}
              important
            />
            <Input
              onChange={e => {
                if (Number(e.target.value) && Number(e.target.value) > 30) {
                  setNoOfDays("");
                  showToast(ExtensionRequestLimit, "error");
                } else {
                  setNoOfDays(Number(e.target.value));
                }
              }}
              inputMode="numeric"
              value={noOfDays}
              name="noOfDays"
              type="number"
              onKeyDown={handleKeyDownOnNumericWithoutDecimal}
              placeholder={`${Enter} ${NumberOfDays}`}
              onBlur={handleCheckDay}
            />
          </FormGroup>
        )}

        {selectedModal === "delete" && <P>{DeleteUserMessage}</P>}
        {selectedModal !== "reattempt" && selectedModal !== "delete" && (
          <FormGroup>
            <LabelTooltip
              label={
                selectedModal === "approve"
                  ? AddReasonForApprove
                  : AddReasonForDecline
              }
              tooltipText={`${Enter} ${Reason}`}
              important
            />
            <Input
              onChange={e => {
                if (
                  (e.target.value && e.target.value?.length <= 255) ||
                  e.target.value === ""
                )
                  setComments(e.target.value);
              }}
              maxLength={255}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Reason}`}
            />
          </FormGroup>
        )}
      </CommonModal>
      <Card>
        <CardBody className="mb-3 m-2">
          <H4 className="mt-3">{UserDetails}</H4>
          <Row className="mt-3 d-flex justify-content-between align-items-center">
            <Col lg="4" className="d-flex gap-3">
              <DateRangeDisplay
                startDate={scheduledDate(scheduledDetails?.start_date)}
                endDate={scheduledDate(scheduledDetails?.end_date)}
              />
              {type === "upcoming" && (
                <Link
                  to={"/assessments/schedule-assessment"}
                  state={{
                    assessmentId: assessmentId,
                    scheduleId: scheduleId,
                    type: type,
                    page: 1,
                  }}
                  onClick={() => {
                    dispatch(setAssessmentCatlogPayloadToInitials());
                  }}
                >
                  <MUIIcons
                    className="primary-icon-color pointer mt-2"
                    size={18}
                    iconName="EditOutlined"
                  />
                </Link>
              )}
            </Col>
            <Col
              lg={type === "upcoming" ? "3" : "1"}
              className="d-flex gap-2 mt-2 align-items-center"
            >
              <SearchBar onSearch={handleSearch} />
              <MUIIcons
                size={24}
                className="primary-icon-color pointer"
                iconName="FileDownloadOutlined"
                onClick={() => {
                  showToast(ComingSoon, "warning");
                }}
              />
              {type === "upcoming" && (
                <Link
                  to={"/assessments/schedule-assessment"}
                  state={{
                    assessmentId: assessmentId,
                    scheduleId: scheduleId,
                    type: type,
                    page: 2,
                  }}
                  onClick={() => {
                    dispatch(setAssessmentCatlogPayloadToInitials());
                  }}
                >
                  <DefaultButton color="primary">{AddUsers}</DefaultButton>
                </Link>
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <StatusDisplay
                label={UsersEnrolled}
                number={scheduledDetails?.participants_count}
              />
            </Col>
            <Col>
              <StatusDisplay
                label={NotStarted}
                number={scheduledDetails?.not_started}
              />
            </Col>
            <Col>
              <StatusDisplay
                label={ReAttempt}
                number={scheduledDetails?.re_attempt}
              />
            </Col>
            <Col>
              <StatusDisplay
                label={ReviewPending}
                number={pendingCount?.toString()}
              />
            </Col>
          </Row>
          <div className="mt-4">
            <BorderTabs
              showCard={false}
              tabs={updatedScheduledDetailsTabs}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
          </div>

          {activeTab === "assigned" && (
            <div>
              {scheduledDetails && (
                <ParticipantsDetails
                  sections={users?.filter(item => {
                    const nameMatches = item?.name
                      ?.toLowerCase()
                      ?.includes(query.toLowerCase());
                    const statusMatches = item?.status?.numbers[0]
                      ?.toLowerCase()
                      ?.includes(query.toLowerCase());

                    return nameMatches || statusMatches;
                  })}
                  title={UserDetails}
                  onDropdownClick={onDropdownClick}
                  showCard={false}
                />
              )}
            </div>
          )}
          {activeTab === "pending_review" && (
            <div>
              {scheduledDetails && (
                <ParticipantsDetails
                  sections={users?.filter(item => {
                    return (
                      item?.name?.toLowerCase().includes(query.toLowerCase()) &&
                      displayStatus.includes(item?.display_status)
                    );
                  })}
                  title={UserDetails}
                  onDropdownClick={onDropdownClick}
                  showCard={false}
                />
              )}
            </div>
          )}

          {activeTab === "extension_request" && (
            <div>
              {participantDetails?.length > 0 && (
                <ParticipantsDetails
                  sections={participantDetails}
                  title=""
                  onApproveRequest={approveExtensionRequest}
                  onRejectRequest={rejectExtensionRequest}
                />
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ScheduledUserDetails;
