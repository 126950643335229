import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H4 } from "../../../../AbstractElements";
import { useAppContext } from "../../../../AppContext";
import IconWithValue from "../../../../CommonElements/IconWithValue";
import SessionList from "../../../../container/SessionList";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMentorSessionListUrl } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getProficiencyList,
  getSbuList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  MenteeDetails,
  SideBarTitleIAmAMentor,
  UpcomingSessionErrorToastMessage,
} from "../../../../utils/Constant";
import { formatCustomDate, showToast } from "../../../../utils/helper/helper";

const MenteeDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { activeMenu } = useAppContext();
  const navigate = useNavigate();
  const { menteeId, mentorMenteeId, countOfSessions, latestSessiondate } =
    state || {};
  const { getAllMentorSessionList } = useAppSelector(state => state.learner);
  const { proficiencyList } = useAppSelector(state => state.master);
  const { userDetailsList } = useAppSelector(state => state.user);
  const { sbuList } = useAppSelector(state => state.master);
  const [filteredSessionData, setFilteredSessionData] = useState<any[]>([]);
  const [sbuNames, setSbuNames] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    dispatch(getSbuList());
  }, [dispatch]);

  useEffect(() => {
    const sbuMapping = sbuList?.sbu?.reduce(
      (sbu, sbuItem) => {
        sbu[sbuItem.id] = sbuItem.name;
        return sbu;
      },
      {} as { [key: number]: string }
    );
    setSbuNames(sbuMapping || {});
  }, [sbuList]);

  useEffect(() => {
    dispatch(getUserDetailsList({ id: menteeId }));
    dispatch(getProficiencyList());
  }, [dispatch]);

  useEffect(() => {
    if (mentorMenteeId) {
      dispatch(
        getAllMentorSessionListUrl({
          mentorMenteeId,
        })
      );
    }
  }, [dispatch, mentorMenteeId]);

  useEffect(() => {
    const mappedData =
      getAllMentorSessionList?.session_list?.map(session => {
        return {
          topic: session.topic,
          duration: session.duration,
          sessionDate: session.start_date,
          status: session.status,
          sessionId: session.session_id,
        };
      }) || [];
    setFilteredSessionData(mappedData);
  }, [getAllMentorSessionList]);

  const getProficiencyName = (levelId: number) => {
    const proficiency = proficiencyList.proficiency.find(
      item => item.id === levelId
    );
    return proficiency ? proficiency.level_name : "";
  };

  const getIcons = (role, sbuId, countOfSessions, latestSessiondate) => [
    { id: 1, iconName: "EngineeringOutlined", value: role },
    { id: 2, iconName: "BadgeOutlined", value: sbuId },
    { id: 3, iconName: "CastForEducationOutlined", value: countOfSessions },
    { id: 4, iconName: "CalendarTodayOutlined", value: latestSessiondate },
  ];

  const onSessionRowClick = row => {
    const result = [SideBarTitleIAmAMentor].some(role =>
      activeMenu.includes(role)
    );

    if (row.status === "completed") {
      navigate(`${process.env.PUBLIC_URL}/mentoring/session-details`, {
        state: { sessionId: row.sessionId },
      });
    } else if (row.status === "upcoming") {
      if (!result) {
        showToast(UpcomingSessionErrorToastMessage, "error");
        return;
      }
      navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`, {
        state: { sessionId: row.sessionId },
      });
    }
  };

  const handleSearch = (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.trim().toLowerCase();
    const mappedSessions =
      getAllMentorSessionList?.session_list?.map(session => ({
        topic: session.topic,
        duration: session.duration,
        sessionDate: session.start_date,
        status: session.status,
        session: session.session_id,
      })) || [];

    const filteredData =
      trimmedSearchTerm === ""
        ? mappedSessions
        : mappedSessions.filter(session =>
            session.topic?.toLowerCase().includes(trimmedSearchTerm)
          );

    setFilteredSessionData(filteredData);
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="mb-3">
        <CardBody className="mt-2 p-3">
          <H4>{MenteeDetails}</H4>
          {userDetailsList?.users.map(item => (
            <Row key={item.id} className="mt-4 align-items-center">
              <Col md="3">
                <H4 className="mb-1">{item.user_name}</H4>
                <span>
                  {item.user_name} | {getProficiencyName(item.level_id)}
                </span>
              </Col>
              <Col md="5">
                <IconWithValue
                  items={getIcons(
                    item?.job_role_name,
                    sbuNames[item.sbu],
                    countOfSessions || 0,
                    formatCustomDate(latestSessiondate) || "-"
                  )}
                />
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>

      <SessionList
        data={filteredSessionData}
        onRowClick={onSessionRowClick}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default MenteeDetailsPage;
