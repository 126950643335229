import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, DefaultButton, H3, P } from "../../../../AbstractElements";
import { emailTemplateUrl } from "../../../../Api";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import BorderTabs from "../../../../CommonElements/BorderTabs";
import DateRangeDisplay from "../../../../CommonElements/DateRangeDisplay";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import MultiFileUpload from "../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import MultiSelectDropdown from "../../../../CommonElements/MultiSelectDropdown";
import EligibleListing from "../../../../container/EligibleList";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { bulkUploadEligibleUsers } from "../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  getEligibleList,
  getGlobalSettings,
  nominatePrticipants,
  putEligibleList,
  replaceTrainerOrNominee,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  getFilterRolesList,
  getProficiencyList,
  getSbuList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddComment,
  CompleteNomination,
  ConsiderTrainingGap,
  Description,
  Download,
  EmptyCommentsAlert,
  Enter,
  FindParticipantBy,
  IsActivelyEnrolledInSGA,
  IsAttendedEarlier,
  IsFirstTimer,
  IsMappedToILP,
  IsNominated,
  isPendingForCertification,
  IsReappear,
  Level,
  NoDataText,
  ParticipantListValidationRequest,
  PleaseAddParticipants,
  Proceed,
  Role,
  SBU,
  Search,
  Select,
  Upload,
  UploadFile,
  UseThisTemplate,
  ValidateAndSchedule,
} from "../../../../utils/Constant";
import {
  eligibleListSearchBy,
  eligibleListTabs,
  globalSettingsStatusMap,
  participantValidationStatusMap,
  showToast,
} from "../../../../utils/helper/helper";
import { ParticipantTotalCount } from "../../../Dashboard/SamplePage";

const AddNominees = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [comments, setComments] = useState("");
  const [userMap, setUserMap] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const {
    courseName,
    scheduledId,
    courseId,
    replace_id,
    replace_name,
    participant_id,
    isFromNomination,
    available,
    booked,
  } = location.state || "";
  const { roleOnFilter, sbuList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { eligibleList } = useAppSelector(state => state.course);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [activeTab, setActiveTab] = useState<string>("add_users");
  const [roleFilterList, setRoleFilterList] = useState([]);
  const [selectedType, setSelectedType] = useState("employee_id");
  const [sbuFilterList, setSbuFilterList] = useState([]);
  const [sbuMap, setSbuMap] = useState({});
  const [levelFilterList, setLevelFilterList] = useState([]);
  const [eligible, setEligible] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [flushData, setFlushData] = useState(false);
  const [uploadFile, setUploadFile] = useState([]);
  const [globalSettings, setGlobalSettings] = useState([]);

  const handleTabClick = async (tabId: any) => {
    setActiveTab(tabId);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setComments("");
    }
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getFilterRolesList());
    dispatch(getSbuList());
    dispatch(getProficiencyList());
    getSettings();
  }, [dispatch]);

  useEffect(() => {
    getEligibility(eligibleList);
  }, [eligibleList]);

  const getEligibility = eligibleList => {
    const eligibleUser = [];
    if (eligibleList?.eligible_users) {
      eligibleList?.eligible_users?.forEach(item => {
        if (userMap && Object.keys(userMap)?.length > 0)
          eligibleUser.push({
            id: userMap[item?.user_id]?.id,
            name: item?.user_id,
            user_name: userMap[item?.user_id]?.user_name,
            label:
              userMap[item?.user_id]?.employee_code +
              " - " +
              userMap[item?.user_id]?.user_name +
              " - " +
              userMap[item?.user_id]?.email,
            value: userMap[item?.user_id]?.user_name,
            email: userMap[item?.user_id]?.email,
            empId: userMap[item?.user_id]?.employee_code,
            rmName: userMap[item?.user_id]?.mapped_rm[0]?.name,
            rmMobileNo: userMap[item?.user_id]?.mapped_rm[0]?.rm_phone,
            mobile: userMap[item?.user_id]?.phone,
            info: item?.user_detail,
            is_info: false,
            sbu: sbuMap[userMap[item?.user_id]?.sbu] || null,
          });
      });
    }
    setEligible(eligibleUser);
  };

  const getSettings = async (disable = false) => {
    const settingsMap = {};
    const res = await dispatch(getGlobalSettings());
    const filters = res?.payload?.filters;
    const rules = res?.payload?.rules;
    let serializedFilters = JSON.stringify(filters);
    if (disable) {
      const updatedFilters = filters.map(filter => ({
        ...filter,
        enabled: false,
      }));
      serializedFilters = JSON.stringify(updatedFilters);
    }

    filters?.forEach(item => {
      settingsMap[item?.name] = item;
    });

    const defaultSettings = {
      nominationGap: settingsMap["nomination_gap"]["enabled"],
      after: parseInt(settingsMap["nomination_gap"]["rules"][1]["value"]) || 0,
      before: parseInt(settingsMap["nomination_gap"]["rules"][0]["value"]) || 0,
      isSelfEnrolled: settingsMap["self"]["enabled"],
      isActivelyEnrolled:
        settingsMap["not_active_in_sga_enrollment"]["enabled"],
      isPendingCertification:
        settingsMap["pending_for_certification"]["enabled"],
      isMappedILP: settingsMap["mapped_to_ipl"]["enabled"],
      isFirstTimer: settingsMap["first_timer"]["enabled"],
      isReappear: settingsMap["reappear"]["enabled"],
      considerTrainingGap: settingsMap["training_gap"]["enabled"],
      trainingGapCount:
        parseInt(settingsMap["training_gap"]["rules"][0]["value"]) || 0,
      peopleOnCount: settingsMap["exclude_people_on_count"]["enabled"],
      peopleOnCountValue:
        parseInt(settingsMap["exclude_people_on_count"]["rules"][0]["value"]) ||
        0,
    };
    setGlobalSettings(rules || []);
    setSelectedFilterValues(defaultSettings);
    dispatch(getEligibleList({ scheduledId, filters: serializedFilters }));
  };

  useEffect(() => {
    const value = [];
    if (roleOnFilter) {
      roleOnFilter?.job_role?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setRoleFilterList(value);
  }, [roleOnFilter]);

  useEffect(() => {
    const value = [];
    const valueMap = {};
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        valueMap[item?.id] = item?.name;
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setSbuMap(valueMap);
    setSbuFilterList(value);
  }, [sbuList]);

  useEffect(() => {
    const value = [];
    if (proficiencyList) {
      proficiencyList?.proficiency?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.level_name,
          value: item?.level_name,
        });
      });
    }
    setLevelFilterList(value);
  }, [proficiencyList]);

  const handleDone = async (values: { [key: string]: string[] | any }) => {
    const transformedSettings = [
      { name: "reappear", enabled: values.isReappear ? 1 : 0 },
      { name: "first_timer", enabled: values.isFirstTimer ? 1 : 0 },
      { name: "self", enabled: values.isSelfEnrolled ? 1 : 0 },
      {
        name: "not_active_in_sga_enrollment",
        enabled: values.isActivelyEnrolled ? 1 : 0,
      },
      {
        name: "pending_for_certification",
        enabled: values.isPendingCertification ? 1 : 0,
      },
      { name: "mapped_to_ipl", enabled: values.isMappedILP ? 1 : 0 },
      {
        name: "nomination_gap",
        enabled: values.nominationGap ? 1 : 0,
        rules: [
          { name: "before_weeks", value: parseInt(values.before) || 0 },
          { name: "after_weeks", value: parseInt(values.after) || 0 },
        ],
      },
      {
        name: "exclude_people_on_count",
        enabled: values.peopleOnCount ? 1 : 0,
        rules: [
          { name: "count", value: parseInt(values.peopleOnCountValue) || 0 },
        ],
      },
      {
        name: "training_gap",
        enabled: values.considerTrainingGap ? 1 : 0,
        rules: [
          { name: "count", value: parseInt(values.trainingGapCount) || 0 },
        ],
      },
    ];
    const serializedFilters = JSON.stringify(transformedSettings);
    await dispatch(
      getEligibleList({
        scheduledId,
        filters: serializedFilters,
        roleId: values?.role?.toString(),
        levelId: values?.level?.toString(),
        sbuId: values?.sbu?.toString(),
      })
    );
  };

  const handleClear = async () => {
    await getSettings(true);
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const filteredEligible = eligible.filter(option => {
    const isInSelectedEmp = selectedEmp.some(emp => emp.id === option.id);
    const isReplaceConditionMet = replace_id && option.id === participant_id;

    return !isInSelectedEmp && !isReplaceConditionMet;
  });

  const handleGoBack = () => {
    if (isFromNomination) {
      return navigate(-1);
    } else {
      return navigate("/schedule-details", {
        state: {
          courseId: courseId,
          scheduledId: scheduledId,
        },
      });
    }
  };

  const onUploadFile = async () => {
    const postData = new FormData();
    uploadFile.forEach(file => {
      postData.append("file", file[0]);
    });
    const eligible = await dispatch(
      bulkUploadEligibleUsers({ id: scheduledId, file: postData })
    );
    if (eligible?.payload?.eligible_users) {
      setFlushData(true);
      const users = [];
      eligible?.payload?.eligible_users?.forEach(item => {
        users.push({
          email: userMap[item?.user_id]?.email,
          empId: userMap[item?.user_id]?.employee_code,
          id: userMap[item?.user_id]?.id,
          info: item?.user_detail,
          label: userMap[item?.user_id]?.user_name,
          mobile: userMap[item?.user_id]?.phone,
          name: userMap[item?.user_id]?.id,
          rmMobileNo: userMap[item?.user_id]?.mapped_rm?.rm_phone,
          rmName: userMap[item?.user_id]?.mapped_rm?.name,
          user_name: userMap[item?.user_id].user_name,
          value: userMap[item?.user_id].user_name,
          is_info: false,
          rules: [],
          sbu: sbuMap[userMap[item?.user_id]?.sbu] || null,
        });
      });
      setSelectedEmp(users);
      setActiveTab("add_users");
    }
    setUploadFile([]);
  };

  const participantRulesDisplay = rules => {
    return (
      <>
        <Divider />
        <Card>
          <CardBody>
            <Row>
              {rules?.length === 0 ? (
                <Col xs={12} className="text-center">
                  <strong>{NoDataText}</strong>
                </Col>
              ) : (
                rules?.map(rule => {
                  if (rule?.enabled)
                    return (
                      <Col xs={12} key={rule?.id} className="mt-3">
                        <Row className="d-flex justify-content-between align-items-center">
                          <Col xs={rule?.rules?.length > 1 ? 12 : 8}>
                            <strong>
                              {participantValidationStatusMap[rule?.name]}:
                            </strong>
                          </Col>
                          <Col
                            xs={rule?.rules?.length > 1 ? 12 : 4}
                            className="text-right"
                          >
                            {rule?.rules?.length > 1 ? (
                              rule?.rules?.map(nestedRule => (
                                <Row
                                  key={nestedRule.name || nestedRule.value}
                                  className="d-flex justify-content-between align-items-center"
                                >
                                  <Col xs={8}>
                                    {nestedRule?.name ? (
                                      <strong>
                                        {
                                          participantValidationStatusMap[
                                            nestedRule.name
                                          ]
                                        }
                                        :
                                      </strong>
                                    ) : null}
                                  </Col>
                                  <Col xs={4} className="text-right">
                                    <span>{nestedRule?.value} %</span>
                                  </Col>
                                </Row>
                              ))
                            ) : (
                              <span>{rule?.rules[0]?.value} %</span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    );
                })
              )}
            </Row>
          </CardBody>
        </Card>
        <Divider />
      </>
    );
  };

  return (
    <div className="page-body pb-5">
      <CommonModal
        sizeTitle={ParticipantListValidationRequest}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          setSelectedUserId(null);
          if (employees?.length === 0) {
            showToast(PleaseAddParticipants, "error");
            return;
          }

          if (!replace_id) {
            const participants = {
              course_schedule_id: scheduledId,
              nominees: employees.map(userId => ({
                user_id: userId,
              })),
            };

            await dispatch(nominatePrticipants({ participants: participants }));
            handleGoBack();
          }
          if (replace_id) {
            if (!comments || comments.trim() === "") {
              showToast(EmptyCommentsAlert, "error");
              return;
            }

            const payload = {
              type: "nominee",
              id: replace_id,
              reason: comments,
              replacement_user_id: employees[0],
            };

            await dispatch(replaceTrainerOrNominee({ replace: payload }));
            handleGoBack();
          }
        }}
        primaryBtnText={CompleteNomination}
        onSecondaryBtnClick={async () => {
          setSelectedUserId(null);
          if (employees?.length === 0) {
            showToast(PleaseAddParticipants, "error");
            return;
          }

          const eligibleUsers = {
            eligible_users: employees.map(userId => ({
              user_id: userId,
              user_detail:
                selectedEmp?.find(item => item?.id === userId)?.info || {},
            })),
          };
          const res = await dispatch(
            putEligibleList({
              id: scheduledId,
              review: eligibleUsers,
            })
          );
          if (res?.payload?.data?.course_schedule) {
            setSelectedEmp([]);
            setEmployees([]);
            const users = [];
            res?.payload?.data?.course_schedule?.forEach(item => {
              users.push({
                email: userMap[item?.user_id]?.email,
                empId: userMap[item?.user_id]?.employee_code,
                id: userMap[item?.user_id]?.id,
                info: item?.user_detail,
                label: userMap[item?.user_id]?.user_name,
                mobile: userMap[item?.user_id]?.phone,
                name: userMap[item?.user_id]?.id,
                rmMobileNo: userMap[item?.user_id]?.mapped_rm[0]?.rm_phone,
                rmName: userMap[item?.user_id]?.mapped_rm[0]?.name,
                user_name: userMap[item?.user_id].user_name,
                value: userMap[item?.user_id].user_name,
                is_info: !item?.is_eligible,
                rules: item?.rules,
                sbu: sbuMap[userMap[item?.user_id]?.sbu] || null,
              });
            });
            setSelectedEmp(users);
          }
          setSelectAll(false);
          toggleModal();
        }}
        secondaryBtnText={ValidateAndSchedule}
      >
        {replace_id && (
          <FormGroup>
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Description}`}
              important
            />
            <Input
              onChange={e => {
                if (
                  (e.target.value && e.target.value.length <= 255) ||
                  e.target.value === ""
                ) {
                  setComments(e.target.value);
                }
              }}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Description}`}
            />
          </FormGroup>
        )}
        <div>{participantRulesDisplay(globalSettings)}</div>
      </CommonModal>
      <Card className="p-2">
        <CardBody>
          <div>
            <H3 className="mt-2">{courseName}</H3>
            <Divider />
            {replace_name && (
              <div className="mt-2 w-25">
                <DateRangeDisplay
                  startDate={replace_name}
                  endDate="?"
                  type="text"
                />
              </div>
            )}
            <div className="mt-2">
              <BorderTabs
                showCard={false}
                tabs={eligibleListTabs}
                activeTab={activeTab}
                handleTabClick={handleTabClick}
              />
            </div>
            {activeTab === "add_users" && (
              <Row className="mt-4">
                <Col sm="12" lg="3">
                  <FormGroup>
                    <LabelTooltip
                      label={FindParticipantBy}
                      tooltipText={FindParticipantBy}
                      important
                    />
                    <Input
                      onChange={e => {
                        setSelectedType(e.target.value);
                      }}
                      value={selectedType}
                      name="type"
                      type="select"
                    >
                      {eligibleListSearchBy?.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm="12" lg="6">
                  <LabelTooltip label={Search} tooltipText={Search} important />

                  <MultiSelectDropdown
                    onChange={() => {
                      return;
                    }}
                    options={filteredEligible}
                    placeholder={Search}
                    defaultSelected={[]}
                    onBlurDropdown={values => {
                      setSelectAll(false);
                      setSelectedEmp([...selectedEmp, ...values]);
                    }}
                  />
                </Col>
                <Col lg="1">
                  <div className="filter-margin">
                    <FilterSliderModal
                      dropdowns={[
                        {
                          label: Role,
                          key: "role",
                          tooltipText: `${Select} ${Role}`,
                          options: roleFilterList,
                          isMultiSelect: true,
                        },
                        {
                          label: SBU,
                          key: "sbu",
                          tooltipText: `${Select} ${SBU}`,
                          options: sbuFilterList,
                          isMultiSelect: true,
                        },
                        {
                          label: Level,
                          key: "level",
                          tooltipText: `${Select} ${Level}`,
                          options: levelFilterList,
                          isMultiSelect: true,
                        },
                        {
                          label: globalSettingsStatusMap["nomination_gap"],
                          key: "nominationGap",
                          tooltipText: `${Select} ${IsNominated}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                          additionalFields: [
                            {
                              label:
                                globalSettingsStatusMap[
                                  "nomination_gap_before_weeks"
                                ],
                              key: "before",
                              type: "number",
                            },
                            {
                              label:
                                globalSettingsStatusMap[
                                  "nomination_gap_after_weeks"
                                ],
                              key: "after",
                              type: "number",
                            },
                          ],
                        },
                        {
                          label: globalSettingsStatusMap["self"],
                          key: "isSelfEnrolled",
                          tooltipText: `${Select} ${IsFirstTimer}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                        },
                        {
                          label:
                            globalSettingsStatusMap[
                              "not_active_in_sga_enrollment"
                            ],
                          key: "isActivelyEnrolled",
                          tooltipText: `${Select} ${IsActivelyEnrolledInSGA}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                        },
                        {
                          label:
                            globalSettingsStatusMap[
                              "pending_for_certification"
                            ],
                          key: "isPendingCertification",
                          tooltipText: `${Select} ${isPendingForCertification}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                        },
                        {
                          label: globalSettingsStatusMap["mapped_to_ipl"],
                          key: "isMappedILP",
                          tooltipText: `${Select} ${IsMappedToILP}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                        },
                        {
                          label: globalSettingsStatusMap["first_timer"],
                          key: "isFirstTimer",
                          tooltipText: `${Select} ${IsFirstTimer}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                        },

                        {
                          label: globalSettingsStatusMap["reappear"],
                          key: "isReappear",
                          tooltipText: `${Select} ${IsReappear}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                        },
                        {
                          label: globalSettingsStatusMap["training_gap"],
                          key: "considerTrainingGap",
                          tooltipText: `${Select} ${ConsiderTrainingGap}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                          additionalFields: [
                            {
                              label:
                                globalSettingsStatusMap["training_gap_count"],
                              key: "trainingGapCount",
                              type: "number",
                            },
                          ],
                        },
                        {
                          label:
                            globalSettingsStatusMap["exclude_people_on_count"],
                          key: "peopleOnCount",
                          tooltipText: `${Select} ${IsAttendedEarlier}`,
                          isCheckbox: true,
                          isMultiSelect: false,
                          additionalFields: [
                            {
                              label:
                                globalSettingsStatusMap[
                                  "exclude_people_on_count_count"
                                ],
                              key: "peopleOnCountValue",
                              type: "number",
                            },
                          ],
                        },
                      ]}
                      selectedFilterValues={selectedFilterValues}
                      defaultFilterValues={defaultFilterValues}
                      setSelectedFilterValues={setSelectedFilterValues}
                      setDefaultFilterValues={setDefaultFilterValues}
                      onDone={handleDone}
                      onClear={handleClear}
                      globalSettings={true}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
          {activeTab === "add_users" && <Divider />}
          <div className="mt-4">
            {activeTab === "add_users" && (
              <EligibleListing
                eligibleData={selectedEmp}
                participantTotalCount={ParticipantTotalCount}
                employees={employees}
                setEmployees={setEmployees}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                replace_id={replace_id}
                setSelectedUserId={setSelectedUserId}
                selectedUserId={selectedUserId}
                available={available}
                booked={booked}
              />
            )}

            {activeTab !== "add_users" && (
              <>
                <LabelTooltip
                  label={UploadFile}
                  tooltipText={UploadFile}
                  important
                />
                <div className="d-flex gap-4">
                  <P>{UseThisTemplate}</P>
                  <a href={emailTemplateUrl} download>
                    <P className="primary-text-color">{Download}</P>
                  </a>
                </div>
                <div className="mb-2">
                  <MultiFileUpload
                    flushData={flushData}
                    accept=".csv"
                    multiple={false}
                    onFileChange={(file, status) => {
                      if (flushData) {
                        setFlushData(false);
                      }
                      if (status === "removed") {
                        if (uploadFile)
                          setUploadFile(prevDocuments =>
                            prevDocuments.filter(
                              item => item[0].name !== file.name
                            )
                          );
                      } else if (status === "done" && file) {
                        setUploadFile(prevDocuments => [
                          ...(Array.isArray(prevDocuments)
                            ? prevDocuments
                            : []),
                          [file],
                        ]);
                      }
                    }}
                  />
                </div>
                {uploadFile?.length > 0 && (
                  <Btn className="mt-2" color="primary" onClick={onUploadFile}>
                    {Upload}
                  </Btn>
                )}
              </>
            )}
          </div>
        </CardBody>
      </Card>
      <FixedFooter>
        <DefaultButton color="primary" onClick={toggleModal}>
          {Proceed}
        </DefaultButton>
      </FixedFooter>
    </div>
  );
};

export default AddNominees;
