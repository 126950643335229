import { useState } from "react";

import BorderTabs from "../../../CommonElements/BorderTabs";
import { remedialActionTabs } from "../../../utils/helper/helper";
import CourseFeedback from "../../LearnerSchedule/LearnerScheduleDetails/CourseFeedback";
import RatingSummary from "../../ScheduledCourse/ScheduleDetails/Rating/RatingSummary";

const ParticipantDetails = () => {
  const [activeTab, setActiveTab] = useState("feedback");

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  return (
    <div className="page-body">
      <BorderTabs
        tabs={remedialActionTabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
      />

      {activeTab === "feedback" && <CourseFeedback />}
      {activeTab === "instructor_rating" && <RatingSummary />}
    </div>
  );
};

export default ParticipantDetails;
