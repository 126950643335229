import React from "react";

type StepperHorizontalProps = {
  level: number;
  data: string[];
};

const StepperHorizontal: React.FC<StepperHorizontalProps> = ({
  level,
  data,
}) => {
  return (
    <div className="stepper-horizontal theme-scrollbar">
      {data?.map((data, index) => (
        <div
          key={index}
          className={`stepper-one stepper step ${level > index + 1 ? "done active" : ""}`}
        >
          <div className="step-circle">
            <span>{index + 1}</span>
          </div>
          <div className="step-title">{data}</div>
          <div className="step-bar-left" />
          <div className="step-bar-right" />
        </div>
      ))}
    </div>
  );
};

export default StepperHorizontal;
