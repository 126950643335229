import { ListGroupItem } from "reactstrap";
import { CSSModule } from "reactstrap/types/lib/utils";

interface ListItemPropsTypes {
  children?: React.ReactNode;
  val?: string;
  className?: string;
  disabled?: boolean;
  onClick?: (key: any) => void;
  tag?: React.ElementType;
  href?: string;
  style?:
    | {
        listStyle?: string;
        display?: string;
        paddingBottom?: number;
        alignItems?: string;
        borderLeft?: string;
        borderRight?: string;
        padding?: string;
      }
    | CSSModule;
  title?: string;
}

const LI = (props: ListItemPropsTypes) => {
  const { children, val } = props;
  return (
    <ListGroupItem {...props}>
      {val ? <div dangerouslySetInnerHTML={{ __html: val }} /> : ""} {children}
    </ListGroupItem>
  );
};

export default LI;
