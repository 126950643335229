import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { ChangeEvent } from "react";
import { Form, FormGroup } from "reactstrap";

import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import { Notes, NotesToolTipMessage } from "../../../../../utils/Constant";
import { richTextSupportedItems } from "../../../../../utils/helper/helper";

interface BasicInputFormValueInterface {
  notes: string;
}

interface BasicInputFormValuePropsType {
  getRichTextUserData: (name: string, value: string) => void;
  getUserData: (event: ChangeEvent<HTMLInputElement>) => void;
  basicInputFormValue: BasicInputFormValueInterface;
  errors: {
    notes: string;
  };
}

const CourseFormFour: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getRichTextUserData,
  errors,
}) => {
  const { notes } = basicInputFormValue;

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <FormGroup className="mt-4">
        <LabelTooltip
          label={Notes}
          tooltipText={NotesToolTipMessage}
          important={true}
        />
        <div className={errors.notes ? "border border-danger" : ""}>
          <CKEditor
            onChange={(_event, editor) => {
              const data = editor.getData();
              getRichTextUserData("notes", data);
            }}
            config={{
              toolbar: {
                items: richTextSupportedItems,
              },
            }}
            editor={ClassicEditor}
            data={notes}
          />
        </div>
        <div className="mt-1 text-sm text-danger">{errors.notes}</div>
      </FormGroup>
    </Form>
  );
};

export default CourseFormFour;
