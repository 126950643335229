import React, { ChangeEvent } from "react";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";

import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import {
  EmailId,
  Enter,
  Extension,
  PhoneNumber,
  PrimaryContact,
  PrimaryContactNameToolTipMessage,
  PrimaryContactNumberToolTipMessage,
  PrimaryEmailIdToolTipMessage,
  PrimaryExtensionToolTipMessage,
  SecondaryContact,
  SecondaryContactNameToolTipMessage,
  SecondaryContactNumberToolTipMessage,
  SecondaryEmailId,
  SecondaryEmailIdToolTipMessage,
  SecondaryExtension,
  SecondaryExtensionToolTipMessage,
  SecondaryPhoneNumber,
} from "../../../../../utils/Constant";

interface BasicInputFormValueInterface {
  primary_contact: string;
  phone_number: number | string;
  email_id: string;
  extension: string | number;
  secondary_contact: string;
  secondary_phone_number: number | string;
  secondary_email_id: string;
  secondary_extension: string | number;
}

interface BasicInputFormValuePropsType {
  getUserData: (event: ChangeEvent<HTMLInputElement>) => void;
  basicInputFormValue: BasicInputFormValueInterface;
  errors: {
    primary_contact: string;
    phone_number: number;
    email_id: string;
    secondary_contact: string;
    secondary_phone_number: number;
    secondary_email_id: string;
    extension: string;
    secondary_extension: string;
  };
}

const CourseFormThree: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getUserData,
  errors,
}) => {
  const {
    primary_contact,
    phone_number,
    email_id,
    extension,
    secondary_contact,
    secondary_phone_number,
    secondary_email_id,
    secondary_extension,
  } = basicInputFormValue;

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value = event.target.value;

    switch (name) {
      case "primary_contact":
      case "secondary_contact":
        if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 100) {
          getUserData(event);
        }
        break;
      case "phone_number":
      case "secondary_phone_number":
        if (/^\d{0,10}$/.test(value)) {
          getUserData(event);
        }
        break;
      case "extension":
      case "secondary_extension":
        if (value === "") {
          value = "0";
        }
        if (/^[0-9+]{1,4}$/.test(value)) {
          getUserData(event);
        }
        break;
      case "email_id":
      case "secondary_email_id":
        if (value.length <= 50) {
          getUserData(event);
        }
        break;
      default:
        getUserData(event);
        break;
    }
  };

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <Row className="mt-4">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={PrimaryContact}
              tooltipText={PrimaryContactNameToolTipMessage}
              important
            />
            <Input
              onChange={handleInputChange}
              value={primary_contact}
              name="primary_contact"
              type="text"
              placeholder={`${Enter} ${PrimaryContact}`}
              maxLength={100}
              required
              invalid={!!errors.primary_contact}
            />
            <div className="invalid-feedback">{errors.primary_contact}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={PhoneNumber}
              tooltipText={PrimaryContactNumberToolTipMessage}
              important
            />
            <Input
              name="phone_number"
              type="text"
              value={phone_number}
              onChange={handleInputChange}
              maxLength={10}
              pattern="\d*"
              inputMode="numeric"
              placeholder={`${Enter} ${PhoneNumber}`}
              required
              invalid={!!errors.phone_number}
            />
            <div className="invalid-feedback">{errors.phone_number}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={EmailId}
              tooltipText={PrimaryEmailIdToolTipMessage}
              important
            />
            <Input
              onChange={handleInputChange}
              value={email_id}
              name="email_id"
              type="email"
              placeholder={`${Enter} ${EmailId}`}
              required
              invalid={!!errors.email_id}
            />
            <div className="invalid-feedback">{errors.email_id}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={Extension}
              tooltipText={PrimaryExtensionToolTipMessage}
              important={false}
            />
            <Input
              onChange={handleInputChange}
              value={extension}
              name="extension"
              type="text"
              placeholder={`${Enter} ${Extension}`}
              pattern="[0-9]*"
              required
              invalid={!!errors.extension}
            />
            <div className="invalid-feedback">{errors.extension}</div>
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={SecondaryContact}
              tooltipText={SecondaryContactNameToolTipMessage}
              important={false}
            />
            <Input
              onChange={handleInputChange}
              value={secondary_contact}
              name="secondary_contact"
              type="text"
              placeholder={`${Enter} ${SecondaryContact}`}
              maxLength={100}
              required
              invalid={!!errors.secondary_contact}
            />
            <div className="invalid-feedback">{errors.secondary_contact}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={SecondaryPhoneNumber}
              tooltipText={SecondaryContactNumberToolTipMessage}
              important={false}
            />
            <Input
              name="secondary_phone_number"
              type="text"
              value={secondary_phone_number}
              onChange={handleInputChange}
              maxLength={10}
              pattern="\d*"
              inputMode="numeric"
              placeholder={`${Enter} ${SecondaryPhoneNumber}`}
              required
              invalid={!!errors.secondary_phone_number}
            />
            <div className="invalid-feedback">
              {errors.secondary_phone_number}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={SecondaryEmailId}
              tooltipText={SecondaryEmailIdToolTipMessage}
              important={false}
            />
            <Input
              onChange={handleInputChange}
              value={secondary_email_id}
              name="secondary_email_id"
              type="email"
              placeholder={`${Enter} ${SecondaryEmailId}`}
              required
              invalid={!!errors.secondary_email_id}
            />
            <div className="invalid-feedback">{errors.secondary_email_id}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={SecondaryExtension}
              tooltipText={SecondaryExtensionToolTipMessage}
              important={false}
            />
            <Input
              onChange={handleInputChange}
              value={secondary_extension}
              name="secondary_extension"
              type="text"
              placeholder={`${Enter} ${SecondaryExtension}`}
              pattern="[0-9]*"
              required
              invalid={!!errors.secondary_extension}
            />
            <div className="invalid-feedback">{errors.secondary_extension}</div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default CourseFormThree;
