import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Card, CardBody } from "reactstrap";

import BorderTabs from "../../../../CommonElements/BorderTabs";
import Divider from "../../../../CommonElements/Divider";
import UserProfileContainer from "../../../../container/UserProfileContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllMentorUnderMenteeList } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getSbuList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUserDetailsList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { Mentor } from "../../../../utils/Constant";
import {
  formatCustomDate,
  MentorsListTabs,
} from "../../../../utils/helper/helper";

const MentorsList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState({
    id: "1",
    title: MentorsListTabs[0].title.toLowerCase(),
  });

  const { mentorUnderMenteeList } = useAppSelector(state => state.learner);
  const { userDetailsList } = useAppSelector(state => state.user);
  const { sbuList } = useAppSelector(state => state.master);
  const [sbuNames, setSbuNames] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    dispatch(getAllMentorUnderMenteeList({ status: activeTab.title }));
    dispatch(getSbuList());
  }, [dispatch, activeTab.title]);

  useEffect(() => {
    const menteeIds =
      mentorUnderMenteeList?.mentors?.map(item => item.mentor_id) || [];
    if (menteeIds.length > 0) {
      dispatch(getUserDetailsList({ id: menteeIds }));
    }
  }, [dispatch, mentorUnderMenteeList]);

  useEffect(() => {
    if (sbuList?.sbu) {
      const sbuMapping = sbuList.sbu.reduce(
        (map, sbuItem) => {
          map[sbuItem.id] = sbuItem.name;
          return map;
        },
        {} as { [key: number]: string }
      );
      setSbuNames(sbuMapping);
    }
  }, [sbuList]);

  const getIcons = (role, sbuId, countOfSessions, latestSessiondate) => [
    { id: 1, iconName: "EngineeringOutlined", value: role },
    { id: 2, iconName: "BadgeOutlined", value: sbuNames[sbuId] },
    { id: 3, iconName: "CastForEducationOutlined", value: countOfSessions },
    { id: 4, iconName: "CalendarTodayOutlined", value: latestSessiondate },
  ];

  const handleTabClick = (tabId, title) => {
    setActiveTab({ id: tabId, title: title.toLowerCase() });
  };

  const onRowClick = (mentorMenteeId, mentorId) => {
    navigate(
      `${process.env.PUBLIC_URL}/mentoring/i-am-mentee/mentor-details-page`,
      {
        state: { mentorMenteeId, mentorId },
      }
    );
  };

  const combinedMentorData = (mentorUnderMenteeList?.mentors || []).map(
    mentee => {
      const userDetails = userDetailsList?.users?.find(
        user => user.id === mentee.mentor_id
      );
      return { ...mentee, ...userDetails };
    }
  );

  const renderMentorProfile = mentor => (
    <React.Fragment key={mentor?.mentor_mentee_id}>
      <UserProfileContainer
        userDetails={{
          name: mentor?.user_name,
          empId: mentor?.employee_id || "-",
          mobile: mentor?.phone,
          id: mentor?.mentor_id,
        }}
        iconItems={getIcons(
          mentor?.job_role_name,
          mentor?.sbu,
          mentor?.count_of_sessions,
          formatCustomDate(mentor?.latest_session_date) || "-"
        )}
        onRowClick={() =>
          onRowClick(mentor?.mentor_mentee_id, mentor?.mentor_id)
        }
      />
      <Divider />
    </React.Fragment>
  );

  return (
    <Card>
      <CardBody>
        <div className="mt-4">
          <BorderTabs
            tabs={MentorsListTabs}
            activeTab={activeTab.id}
            showCard={false}
            handleTabClick={tabId => {
              const selectedTab = MentorsListTabs.find(
                tab => tab.tabId === tabId
              );
              handleTabClick(tabId, selectedTab?.title || "");
            }}
          />
          <Badge className="text-black mt-3 custom-Mentees-badge">
            {mentorUnderMenteeList?.count_of_mentors} {Mentor}
          </Badge>

          {combinedMentorData.map(
            mentor => mentor && renderMentorProfile(mentor)
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default MentorsList;
