import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Col, Input } from "reactstrap";

import "./style.scss";
import { H5, MUIIcons } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import { Available, NotAvailable } from "../../utils/Constant";

interface Trainer {
  id: string;
  name: string;
  available: boolean;
  always_available?: boolean;
}

interface TrainerDropdownProps {
  primaryTrainers: Trainer[];
  secondaryTrainers: Trainer[];
  otherTrainers: Trainer[];
  isMultiSelect: boolean;
  onBlur?: (selectedTrainers: Trainer[], type: string) => void;
  knowTrainerAvailability?: (selectedTrainers: [string]) => void;
  setSelectedTrainers?;
  selectedTrainers?;
  disabled?;
  otherTrainerChecked?;
  setOtherTrainerChecked?;
}

const TrainerDropdown: React.FC<TrainerDropdownProps> = ({
  primaryTrainers,
  secondaryTrainers,
  otherTrainers,
  isMultiSelect,
  knowTrainerAvailability,
  onBlur,
  setSelectedTrainers,
  selectedTrainers,
  disabled = false,
  otherTrainerChecked,
  setOtherTrainerChecked,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedTrainerType, setSelectedTrainerType] = useState("");

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleSelection = (trainer: Trainer) => {
    if (isMultiSelect) {
      setSelectedTrainers(prevSelected => {
        const isSelected = prevSelected.some(t => t.id === trainer.id);
        if (isSelected) {
          return prevSelected.filter(t => t.id !== trainer.id);
        } else {
          return [...prevSelected, trainer];
        }
      });
    } else {
      knowTrainerAvailability([trainer?.id]);
      setSelectedTrainers([trainer]);
    }

    if (primaryTrainers.some(t => t.id === trainer.id)) {
      setSelectedTrainerType("primary");
    } else if (secondaryTrainers.some(t => t.id === trainer.id)) {
      setSelectedTrainerType("primary");
    } else if (otherTrainers.some(t => t.id === trainer.id)) {
      setSelectedTrainerType("other");
    }

    if (otherTrainers.some(t => t.id === trainer.id)) {
      setOtherTrainerChecked(prevState =>
        prevState.includes(trainer.id) ? prevState : [...prevState, trainer.id]
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
        onBlur(selectedTrainers, selectedTrainerType);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen, selectedTrainers, onBlur]);

  const renderTrainerList = (
    title: string,
    trainers: Trainer[],
    isPrimaryOrSecondary: boolean,
    showDivider: boolean
  ) => (
    <>
      <div>{title}</div>
      <div className="trainer-list">
        {trainers.map(trainer => (
          <div className="trainer-item mt-2" key={trainer.id}>
            <Col lg={4}>
              <H5 className="fw-bold">{trainer.name}</H5>
            </Col>

            <Col lg={4} className="d-flex align-items-center">
              {(isPrimaryOrSecondary ||
                otherTrainerChecked.includes(trainer.id)) && (
                <span
                  className={`status ${trainer.available ? "available" : "not-available"}`}
                >
                  {trainer.available ? (
                    <div className="d-flex align-items-center">
                      <div className="custom-available-indicator bg-success"></div>
                      {Available}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="custom-available-indicator bg-gray"></div>
                      {NotAvailable}
                    </div>
                  )}
                </span>
              )}
            </Col>

            <Col lg={1} className="d-flex align-items-center">
              <input
                type="checkbox"
                checked={selectedTrainers.some(t => t.id === trainer.id)}
                onChange={() => handleSelection(trainer)}
              />
            </Col>
          </div>
        ))}
        {showDivider && <Divider />}
      </div>
    </>
  );

  const selectedTrainersNames =
    selectedTrainers.length > 0
      ? selectedTrainers.map(t => t.name).join(", ")
      : "";

  return (
    <div ref={wrapperRef}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="trainer-dropdown h-50"
      >
        <DropdownToggle tag="div" onClick={toggleDropdown}>
          <Input
            disabled={disabled}
            type="text"
            value={selectedTrainersNames}
            placeholder="Select Trainers"
            readOnly
            className="trainer-input"
            onClick={toggleDropdown}
          />

          <MUIIcons
            iconName={
              dropdownOpen
                ? "KeyboardArrowUpOutlined"
                : "KeyboardArrowDownOutlined"
            }
            size={20}
            className="trainer-dropdown-icon"
          />
        </DropdownToggle>
        <DropdownMenu className="custom-trainer-dropdownMenu">
          {renderTrainerList("Primary Trainers", primaryTrainers, true, true)}
          {renderTrainerList(
            "Secondary Trainers",
            secondaryTrainers,
            true,
            true
          )}
          {renderTrainerList("Other Trainers", otherTrainers, false, false)}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default TrainerDropdown;
