import PropTypes from "prop-types";
import React from "react";
import "./style.scss";
import { Col, Row } from "reactstrap";

import {
  eligibleListStatus,
  preCourseWorkStatus,
} from "../../utils/helper/helper";

interface CheckListProps {
  data: any;
}

const CheckList: React.FC<CheckListProps> = ({ data }) => {
  return (
    <>
      {Object.keys(data)?.map(item => {
        const itemData: any = data[item];
        let displayValue = data[item];

        if (item === "status_of_pre_course_work") {
          displayValue = preCourseWorkStatus[data[item]] || "-";
        } else if (item === "type") {
          displayValue = data[item] === "first_timer" ? "Yes" : "No";
        } else if (
          data[item] === null ||
          data[item] === undefined ||
          data[item] === ""
        ) {
          displayValue = "-";
        }

        return (
          <div key={itemData?.id || item}>
            <Row className="mt-2">
              <Col className="fw-bold">{eligibleListStatus[item]}</Col>
              <Col>{displayValue}</Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

CheckList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      isChecked: PropTypes.bool.isRequired,
      description: PropTypes.string.isRequired,
      last_attempt_course: PropTypes.any,
      score_on_last_attempt: PropTypes.any,
      no_of_times_attended: PropTypes.number,
      status_of_pre_course_work: PropTypes.string,
    })
  ).isRequired,
};

export default CheckList;
