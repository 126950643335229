import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "reactstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import "./style.scss";

export interface MultiSelectDropdownDataItem {
  name: string;
  value: string;
  label: string;
}

interface MultiSelectDropdownProps {
  options: MultiSelectDropdownDataItem[];
  placeholder: string;
  onChange: (selectedIds: string[]) => void;
  defaultSelected?: MultiSelectDropdownDataItem[];
  disabled?: boolean;
  onBlurDropdown?: (value: any) => void;
  closeButton?: boolean;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  placeholder,
  onChange,
  defaultSelected = [],
  disabled = false,
  onBlurDropdown,
  closeButton = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] =
    useState<MultiSelectDropdownDataItem[]>(defaultSelected);

  useEffect(() => {
    setSelectedItems(defaultSelected);
  }, [defaultSelected]);

  const handleToggleDropdown = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const handleCloseDropdown = () => {
    if (onBlurDropdown) onBlurDropdown(selectedItems);
    setIsOpen(false);
  };

  const handleChange = (selected: MultiSelectDropdownDataItem[]) => {
    const uniqueSelected = selected.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          t =>
            t.name === item.name &&
            t.value === item.value &&
            t.label === item.label
        )
    );
    setSelectedItems(uniqueSelected);
    const selectedIds = uniqueSelected.map(item => item.name);
    onChange(selectedIds);
  };

  return (
    <div id="multiple-datasets">
      <Form className="theme-form">
        <div>
          <Typeahead
            disabled={disabled}
            options={options}
            multiple
            clearButton={closeButton}
            placeholder={placeholder}
            labelKey="label"
            selected={selectedItems}
            id="1"
            open={isOpen}
            onFocus={handleToggleDropdown}
            onBlur={handleCloseDropdown}
            onChange={handleChange}
            defaultSelected={defaultSelected}
          />
        </div>
      </Form>
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired as PropTypes.Validator<MultiSelectDropdownDataItem[]>,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultSelected: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ) as PropTypes.Validator<MultiSelectDropdownDataItem[]>,
};

export default MultiSelectDropdown;
