import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { DefaultButton, H3 } from "../../AbstractElements";
import FixedFooter from "../../CommonElements/FixedFooter";
import VerticalTabs from "../../CommonElements/VerticalTabs";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import {
  getGlobalSettings,
  updateGlobalSettings,
} from "../../ReduxToolkit/Reducers/CourseSlice";
import { GlobalSetting, Update } from "../../utils/Constant";
import { globalSettingsTab } from "../../utils/helper/helper";

import EligibleListing from "./EligibleList";
import ParticipantValidation from "./ParticipantValidation";

const GlobalSettings = () => {
  const dispatch = useAppDispatch();
  const [basicTab, setBasicTab] = useState("eligible_list");
  const [settings, setSettings] = useState({ filters: [] });

  useEffect(() => {
    getSettings();
  }, [dispatch]);

  const getSettings = async () => {
    const res = await dispatch(getGlobalSettings());
    const filters = res?.payload || { filters: [] };
    setSettings(filters);
  };

  const onUpdate = async () => {
    const payload = transformSettingsToPayload(settings);
    await dispatch(updateGlobalSettings({ payload: payload }));
    await getSettings();
  };

  const transformSettingsToPayload = settings => {
    const filtersPayload = settings?.filters?.map(filter => ({
      name: filter.name,
      enabled: filter.enabled ? 1 : 0,
      rules:
        Array.isArray(filter.rules) && filter.rules.length > 0
          ? filter.rules.map(rule => ({
              name: rule.name,
              value: parseInt(rule.value) || 0,
            }))
          : undefined,
    }));

    const rulesPayload = settings?.rules?.map(rule => ({
      name: rule?.name,
      enabled: rule?.enabled ? 1 : 0,
      rules: Array.isArray(rule.rules)
        ? rule.rules.map(r => ({
            value: parseInt(r.value) || 0,
            ...(r.name ? { name: r.name } : {}),
          }))
        : undefined,
    }));

    return {
      filters: filtersPayload,
      rules: rulesPayload,
    };
  };

  return (
    <div className="page-body pb-5">
      <Row>
        <Col lg="3">
          <Card className="p-2">
            <CardBody>
              <H3 className="mb-3 fw-bold">{GlobalSetting}</H3>
              <VerticalTabs
                tabItems={globalSettingsTab}
                activeTab={basicTab}
                setActiveTab={setBasicTab}
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          {basicTab === "eligible_list" ? (
            <EligibleListing settings={settings} setSettings={setSettings} />
          ) : (
            <ParticipantValidation
              settings={settings}
              setSettings={setSettings}
            />
          )}
        </Col>
      </Row>
      <FixedFooter>
        <DefaultButton
          onClick={() => {
            onUpdate();
          }}
          color="primary float-end"
        >
          {Update}
        </DefaultButton>
      </FixedFooter>
    </div>
  );
};

export default GlobalSettings;
