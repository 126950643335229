import JSZip from "jszip";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";

import {
  MUIIcons,
  H4,
  H2,
  DefaultButton,
  SecondaryButton,
} from "../../../../../../AbstractElements";
import { ALL_USERS_STATUS, WBT } from "../../../../../../Api/constants";
import Divider from "../../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import Comments from "../../../../../../container/Comments";
import MaterialDetails from "../../../../../../container/MaterialDetails";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import { createCourseMaterials } from "../../../../../../ReduxToolkit/Reducers/CourseStagesSlice";
import {
  getScormData,
  setScormToInitialValues,
} from "../../../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getUsersList,
  uploadFileApi,
} from "../../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddDuration,
  AddedMaterials,
  AddMaterial,
  Comment,
  Duration,
  Enter,
  EnterComments,
  FileSizeNote,
  LearnerDocument,
  LearnerDocumentToolTipMessage,
  PleaseDeleteTheUploadedScorm,
  Save,
  SaveAsDraft,
  Scorm,
  ScormToUpload,
  TrainerDocument,
  TrainerDocumentToolTipMessage,
  Upload,
  UPLOAD_SCORM_FILE,
  UploadScormFileToolTipMessage,
  zipFileSizeNote,
} from "../../../../../../utils/Constant";
import {
  handleKeyDownOnNumericWithoutDecimal,
  showToast,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";
import {
  OPENED_SCORM_DETAILS,
  saveToLocalStorage,
} from "../../../../../../utils/helper/localStorageutils";
import useIsMobile from "../../../../../../utils/helper/responsive";

const CreateMaterials = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseContent } = useAppSelector(state => state.course);
  const { courseId } = location.state || "";
  const [sliderModal, setSliderModal] = useState(false);
  const [learnerDocument, setLearnerDocument] = useState([]);
  const [scromDocument, setScromDocument] = useState([]);
  const [trainerDocument, setTrainerDocument] = useState([]);
  const [duration, setDuration] = useState("");
  const [iconComments, setIconComments] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const [commentList, setCommentList] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const [materialData, setMaterialData] = useState([
    { documents: [], greeting: "Learner documents", paths: [] },
    { documents: [], greeting: "Trainer Documents", paths: [] },
  ]);
  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseContent({ id: courseId }));
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.course_material) {
      comment = sortCommentsByDate(courseContent.comment.course_material);
    }
    setCommentList(comment);

    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "course_material"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
    const contents = [
      { documents: [], greeting: "Learner documents", paths: [] },
      { documents: [], greeting: "Trainer Documents", paths: [] },
    ];
    if (courseContent) {
      courseContent?.course_materials?.forEach(element => {
        if (element?.file_category === "learner_document") {
          contents[0].documents.push(element.file_name);
          contents[0].paths.push(element.file_url);
        } else {
          contents[1].documents.push(element.file_name);
          contents[1].paths.push(element.file_url);
        }
      });
    }
    if (courseContent?.course_type === WBT) {
      const scromFile: any = contents[0];
      scromFile.greeting = Scorm;
      if (scromFile?.documents?.length > 0)
        setDuration(courseContent?.duration?.toString() || "");
      setMaterialData([scromFile]);
    } else {
      setMaterialData(contents);
    }
  }, [courseContent]);

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const checkDocuments = (documents: any[]) => {
    if (!documents || documents?.length === 0) {
      return true;
    }
    return false;
  };

  const handleComplete = async (type, materialData, fileDelete = false) => {
    if (courseContent?.course_type === WBT) {
      if (
        !fileDelete &&
        checkDocuments(scromDocument) &&
        checkDocuments(materialData?.[0]?.documents)
      ) {
        showToast(ScormToUpload, "error");
        return;
      }

      if (!duration || duration === "") {
        showToast(AddDuration, "error");
        return;
      }
    }

    const materials = [];
    let allfiles = [];
    if (learnerDocument?.length > 0) {
      allfiles = [...allfiles, ...learnerDocument];
    }
    if (trainerDocument?.length > 0) {
      allfiles = [...allfiles, ...trainerDocument];
    }
    if (scromDocument?.length > 0) {
      allfiles = [...allfiles, ...scromDocument];
    }
    let files = [];

    if (allfiles.length > 0) files = await uploadFile(allfiles);

    if (courseContent?.course_type !== WBT) {
      if (files?.length > 0) {
        files.forEach((fileUrl, index) => {
          if (index < learnerDocument.length) {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
            const fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            materials.push({
              file_name: fileName,
              file_type: fileExtension,
              file_url: fileUrl,
              file_category: "learner_document",
            });
          }
          if (index >= learnerDocument.length && index < files.length) {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
            const fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            materials.push({
              file_name: fileName,
              file_type: fileExtension,
              file_url: fileUrl,
              file_category: "trainer_document",
            });
          }
        });
      }
      if (materialData) {
        materialData[0]?.documents?.forEach((_item, index) => {
          const fileName = materialData[0]?.paths[index].substring(
            materialData[0]?.paths[index].lastIndexOf("/") + 1
          );
          const fileExtension = fileName.substring(
            fileName.lastIndexOf(".") + 1
          );
          materials.push({
            file_name: fileName,
            file_type: fileExtension,
            file_url: materialData[0]?.paths[index],
            file_category: "learner_document",
          });
        });
        materialData[1]?.documents?.forEach((_item, index) => {
          const fileName = materialData[1]?.paths[index].substring(
            materialData[1]?.paths[index].lastIndexOf("/") + 1
          );
          const fileExtension = fileName.substring(
            fileName.lastIndexOf(".") + 1
          );
          materials.push({
            file_name: fileName,
            file_type: fileExtension,
            file_url: materialData[1]?.paths[index],
            file_category: "trainer_document",
          });
        });
      }
    } else if (courseContent?.course_type === WBT) {
      if (files?.length > 0) {
        files.forEach(fileUrl => {
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          const fileExtension = fileName.substring(
            fileName.lastIndexOf(".") + 1
          );
          materials.push({
            file_name: fileName,
            file_type: fileExtension,
            file_url: fileUrl,
            file_category: "learner_document",
          });
        });
      }
      if (materialData) {
        materialData[0]?.documents?.forEach((_item, index) => {
          const fileName = materialData[0]?.paths[index].substring(
            materialData[0]?.paths[index].lastIndexOf("/") + 1
          );
          const fileExtension = fileName.substring(
            fileName.lastIndexOf(".") + 1
          );
          materials.push({
            file_name: fileName,
            file_type: fileExtension,
            file_url: materialData[0]?.paths[index],
            file_category: "learner_document",
          });
        });
      }
    }

    const materialsObject = {
      course_id: courseId,
      materials: materials,
      duration: duration === "" || !duration ? null : parseInt(duration),
      status: type,
    };

    await dispatch(createCourseMaterials({ materialsData: materialsObject }));

    await dispatch(getCourseContent({ id: courseId }));
    setLearnerDocument(null);
    setTrainerDocument(null);
    setScromDocument(null);
    if (!fileDelete)
      return navigate(
        `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
      );
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "course_material",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "course_material",
        comment: iconComments,
      },
    };

    await dispatch(addCommentToStages({ comment: payload }));
    await dispatch(getCourseContent({ id: courseId }));
    setIconComments("");
  };

  const onDeleteFile = (fileType: number, fileIndex: number) => {
    const fileData = [...materialData];
    fileData[fileType].documents.splice(fileIndex, 1);
    fileData[fileType].paths.splice(fileIndex, 1);
    setMaterialData(fileData);
    handleComplete("draft", fileData, true);
  };

  const onPlayFile = async (fileType: number, fileIndex: number) => {
    try {
      const scormResponse = await dispatch(
        getScormData({ courseId: courseContent?.id.toString() })
      );
      const fileData = [...materialData];
      saveToLocalStorage(OPENED_SCORM_DETAILS, {});
      const scormPackagePath = fileData[fileType].paths[fileIndex];
      if (scormPackagePath.includes(".zip")) {
        const response = await fetch(scormPackagePath);
        const blob = await response.blob();
        const zip = await JSZip.loadAsync(blob);
        const files = [];
        zip.forEach((relativePath, file) => {
          files.push({ path: relativePath, file });
        });

        const indexFile = files.find(
          f =>
            f.path.endsWith("index.html") || f.path.endsWith("launchpage.html")
        );
        if (indexFile) {
          const content = await indexFile.file.async("string");
          const blob = new Blob([content], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          const width = 1400;
          const height = 700;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;
          if (scormResponse?.payload?.data?.scorm_data) {
            saveToLocalStorage(
              OPENED_SCORM_DETAILS,
              scormResponse.payload.data
            );
          } else {
            saveToLocalStorage(OPENED_SCORM_DETAILS, {
              course_id: courseContent?.id.toString(),
              scorm_data: {},
            });
          }
          window.open(
            url,
            "_blank",
            `width=${width},height=${height},top=${top},left=${left}`
          );
        }
      }
    } catch (e) {
      dispatch(setScormToInitialValues());
      saveToLocalStorage(OPENED_SCORM_DETAILS, {});
    }
  };

  return (
    <div className="page-body pb-5">
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      {materialData &&
        (materialData[0]?.documents?.length > 0 ||
          materialData[1]?.documents?.length > 0) && (
          <div className="mt-4">
            <MaterialDetails
              title={AddedMaterials}
              data={materialData}
              showDelete
              duration={duration}
              setDuration={setDuration}
              showPlay={courseContent?.course_type === WBT}
              onDeleteFile={onDeleteFile}
              onPlayFile={onPlayFile}
            />
          </div>
        )}
      <Card className={isMobile && "custom-mobile-card"}>
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{AddMaterial}</H4>
          <div className="comment-icon-container">
            <MUIIcons
              size={24}
              onClick={sliderToggle}
              className="primary-icon-color mt-2 pointer"
              iconName="ChatBubbleOutlineOutlined"
            />
            {isComentRead != null && isComentRead && (
              <span className="red-dot"></span>
            )}
          </div>
        </CardHeader>
        <CardBody className="pb-5">
          {courseContent?.course_type !== WBT && (
            <>
              <div className="mb-3">
                <LabelTooltip
                  label={`${Upload} ${LearnerDocument}`}
                  tooltipText={LearnerDocumentToolTipMessage}
                  important
                />
                <MultiFileUpload
                  materials
                  note={FileSizeNote}
                  onFileChange={(file: any, status) => {
                    if (status === "removed") {
                      setLearnerDocument(prevDocuments =>
                        prevDocuments.filter(item => item.name !== file.name)
                      );
                    } else if (status === "done" && file) {
                      setLearnerDocument(prevDocuments => [
                        ...(Array.isArray(prevDocuments) ? prevDocuments : []),
                        file,
                      ]);
                    }
                  }}
                />
              </div>
              <Divider />
              <div className="mt-4 mb-2">
                <LabelTooltip
                  label={`${Upload} ${TrainerDocument}`}
                  tooltipText={TrainerDocumentToolTipMessage}
                  important
                />
                <MultiFileUpload
                  materials
                  note={FileSizeNote}
                  onFileChange={(file: any, status) => {
                    if (status === "removed") {
                      setTrainerDocument(prevDocuments =>
                        prevDocuments.filter(item => item.name !== file.name)
                      );
                    } else if (status === "done" && file) {
                      setTrainerDocument(prevDocuments => [
                        ...(Array.isArray(prevDocuments) ? prevDocuments : []),
                        file,
                      ]);
                    }
                  }}
                />
              </div>
            </>
          )}
          {courseContent?.course_type === WBT &&
            (materialData[0]?.documents?.length == 0 ? (
              <>
                <div className="col-lg-6">
                  <LabelTooltip
                    important
                    label={Duration}
                    tooltipText={Duration}
                  />
                  <Input
                    type="number"
                    name="duration"
                    inputMode="numeric"
                    value={duration}
                    onChange={e => {
                      setDuration(e.target.value);
                    }}
                    placeholder={`${Enter} ${Duration}`}
                    onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                    min={0}
                    max={1000}
                  />
                </div>
                <div className="mt-3 mb-3">
                  <LabelTooltip
                    label={UPLOAD_SCORM_FILE}
                    tooltipText={UploadScormFileToolTipMessage}
                    important
                  />
                  <MultiFileUpload
                    materials
                    note={zipFileSizeNote}
                    onFileChange={(file: any, status) => {
                      if (status === "removed") {
                        setDuration("");
                        setScromDocument(prevDocuments =>
                          prevDocuments.filter(item => item.name !== file.name)
                        );
                      } else if (status === "done" && file) {
                        setScromDocument(prevDocuments => [
                          ...(Array.isArray(prevDocuments)
                            ? prevDocuments
                            : []),
                          file,
                        ]);
                      }
                    }}
                    multiple={false}
                    accept=".zip"
                  />
                </div>
              </>
            ) : (
              <>
                {courseContent?.course_type === WBT && (
                  <div className="m-3">
                    <H4 className="primary-text-color">
                      {PleaseDeleteTheUploadedScorm}
                    </H4>
                  </div>
                )}
              </>
            ))}
        </CardBody>
      </Card>
      <FixedFooter>
        <SecondaryButton onClick={() => handleComplete("draft", materialData)}>
          {SaveAsDraft}
        </SecondaryButton>

        <Row>
          <Col xs="auto">
            <DefaultButton
              onClick={() => handleComplete("completed", materialData)}
              color="primary"
            >
              {Save}
            </DefaultButton>
          </Col>
        </Row>
      </FixedFooter>
    </div>
  );
};

export default CreateMaterials;
