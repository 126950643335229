import { ListGroup } from "reactstrap";
export interface UnorderedListPropsTypes {
  children?: React.ReactNode;
  className?: string;
  role?: string;
  style?: Object;
  id?: string;
}

const UL = (props: UnorderedListPropsTypes) => {
  return <ListGroup {...props}>{props.children}</ListGroup>;
};

export default UL;
