import React, { useState } from "react";

import "./style.scss";
import {
  generateFiscalYearSeries,
  getCurrentFiscalYear,
} from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";

interface YearNavigatorProps {
  pastYears: number;
  futureYears: number;
  onYearChange?: (year: string) => void;
}

const YearNavigator: React.FC<YearNavigatorProps> = ({
  pastYears,
  futureYears,
  onYearChange,
}) => {
  const [fiscalYearIndex, setFiscalYearIndex] = useState(() =>
    getCurrentFiscalYear(pastYears, futureYears)
  );

  const fiscalYearSeries = generateFiscalYearSeries(pastYears, futureYears);

  const handlePreviousYear = () => {
    const newIndex = Math.max(fiscalYearIndex - 1, 0);
    setFiscalYearIndex(newIndex);
    const extractedYear = parseInt(
      fiscalYearSeries[newIndex].split(" ")[1].split("-")[0]
    );
    const startYear =
      extractedYear < 50 ? 2000 + extractedYear : 1900 + extractedYear;

    onYearChange?.(startYear.toString());
  };

  const handleNextYear = () => {
    const newIndex = Math.min(fiscalYearIndex + 1, fiscalYearSeries.length - 1);
    setFiscalYearIndex(newIndex);
    const extractedYear = parseInt(
      fiscalYearSeries[newIndex].split(" ")[1].split("-")[0]
    );
    const startYear =
      extractedYear < 50 ? 2000 + extractedYear : 1900 + extractedYear;

    onYearChange?.(startYear.toString());
  };

  const isPreviousDisabled = fiscalYearIndex === 0;
  const isNextDisabled = fiscalYearIndex === fiscalYearSeries.length - 1;

  return (
    <div className="d-flex flex-row align-items-center year-navigator">
      <div
        className={`navigator-icon navigator-icon-circle ${
          isPreviousDisabled ? "custom-icon-disabled" : ""
        }`}
        onClick={handlePreviousYear}
      >
        <MUIIcons iconName={"ChevronLeftOutlined"} size={24} />
      </div>
      <h4 className="custom-year">{fiscalYearSeries[fiscalYearIndex]}</h4>
      <div
        className={`navigator-icon navigator-icon-circle ${
          isNextDisabled ? "custom-icon-disabled" : ""
        }`}
        onClick={handleNextYear}
      >
        <MUIIcons iconName={"ChevronRightOutlined"} size={24} />
      </div>
    </div>
  );
};

export default YearNavigator;
