import React from "react";
import { Row, Col } from "reactstrap";

import { MUIIcons } from "../../AbstractElements";
import IconWithValue from "../../CommonElements/IconWithValue";
import UserDetailsCard, { UserDetails } from "../UserDetailsCard";

interface IconWithTextData {
  id: number;
  iconName: any;
  value: string;
}

interface UserProfileContainerProps {
  userDetails: UserDetails;
  iconItems: IconWithTextData[];
  onUnlinkClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  unlink?: boolean;
  onRowClick: () => void;
}

const UserProfileContainer: React.FC<UserProfileContainerProps> = ({
  userDetails,
  iconItems,
  onUnlinkClick,
  unlink,
  onRowClick,
}) => {
  return (
    <Row className="align-items-center p-1 mt-3 pointer" onClick={onRowClick}>
      <Col md="3">
        <UserDetailsCard userDetails={userDetails} />
      </Col>

      <Col md="6">
        <IconWithValue items={iconItems} />
      </Col>

      {unlink && (
        <Col md="3" className="d-flex justify-content-end">
          <button
            className="border-0 bg-transparent pointer"
            onClick={e => onUnlinkClick(e)}
          >
            <MUIIcons
              className="pointer primary-icon-color"
              iconName="LinkOffOutlined"
              size={20}
            />
          </button>
        </Col>
      )}
    </Row>
  );
};

export default UserProfileContainer;
