import React, { useState } from "react";
import { Tooltip, Label } from "reactstrap";

import { sanitizeString } from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";
import "./style.scss";

interface TooltipLabelProps {
  label: string;
  tooltipText: string;
  important: boolean;
  placement?: any;
}

const LabelTooltip: React.FC<TooltipLabelProps> = ({
  label,
  tooltipText,
  placement = "top",
  important = false,
}) => {
  const toolTipUniqueId = sanitizeString(tooltipText);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <div className="d-flex align-items-center gap-2">
      <div>
        <Label className="col-form-label">{label}</Label>
        {important && <span className="txt-danger">*</span>}
      </div>
      <span id={`TooltipIcon${toolTipUniqueId}`} className="ml-2 pointer">
        <MUIIcons className="mt-1" size={16} iconName="InfoOutlined" />
      </span>
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={`TooltipIcon${toolTipUniqueId}`}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    </div>
  );
};

export default LabelTooltip;
