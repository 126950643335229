import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { Btn, H3, MUIIcons } from "../../../AbstractElements";
import { UpsertClassRoomPayload } from "../../../Api/entities/ManageMasterDataEntity";
import MasterDataAccordion from "../../../CommonElements/MasterDataAccordion";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createClassRoom,
  deleteClassRoom,
  getClassRoomList,
  getClassRoomListById,
  setClassRoomListByIdToInitialValue,
  updateClassRoom,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import { getLocationList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import {
  AddNew,
  Classroom,
  Classrooms,
  Confirm,
  DeleteClassRoomHeader,
  DeleteClassRoomMessage,
  Enter,
  Location,
  NoDataText,
  Select,
} from "../../../utils/Constant";
import { mapListToOptions } from "../../../utils/helper/helper";

const ClassRoomPage = () => {
  const dispatch = useAppDispatch();
  const { classRoomList, classRoomListById } = useAppSelector(
    state => state.manageMasterData
  );
  const { locationList } = useAppSelector(state => state.master);
  const [modalOpen, setModalOpen] = useState(false);
  const [locationListData, setLocationListData] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [classRoomToDelete, setClassRoomToDelete] = useState(null);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getClassRoomList());
    if (!modalOpen) {
      dispatch(getLocationList());
    }
  }, [dispatch]);

  useEffect(() => {
    const domains = mapListToOptions(locationList?.location, "name", "id");
    setLocationListData(domains);
  }, [locationList]);

  useEffect(() => {
    if (classRoomListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: classRoomListById.name,
        description: classRoomListById.description,
        selectedOption: classRoomListById.location_id.toString(),
      }));
    }
  }, [classRoomListById]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  const AccordionData = classRoomList?.class_room?.map(item => ({
    id: item.id.toString(),
    name: item.name,
    description: item.description,
    createdOn: item.created_at,
    modifiedOn: item.updated_at,
    depedentBadges: [
      {
        id: 1,
        badge: `${Location}: ${item.location}`,
      },
    ],
  }));

  const filteredAccordionData = AccordionData?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSave = useCallback(
    (name: string, description: string, locationId: string) => {
      const selectedLocation = locationListData.find(
        location => location.id === locationId
      );

      const payload: UpsertClassRoomPayload = {
        name,
        description,
        location: selectedLocation.label,
        location_id: parseInt(locationId),
      };

      if (classRoomListById) {
        payload.id = classRoomListById.id;
        dispatch(updateClassRoom({ updateClassRoomPayload: payload })).then(
          () => {
            dispatch(getClassRoomList());
            toggleModal();
          }
        );
      } else {
        dispatch(createClassRoom({ createClassRoomPayload: payload })).then(
          () => {
            dispatch(getClassRoomList());
            toggleModal();
          }
        );
      }
    },
    [dispatch, toggleModal, locationListData]
  );

  const handleEdit = (id: string) => {
    const selectedId = parseInt(id);
    dispatch(getClassRoomListById({ id: selectedId }));
    toggleModal();
  };

  const handleDelete = (id: string) => {
    const selectedId = parseInt(id);
    setClassRoomToDelete(selectedId);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (classRoomToDelete) {
      dispatch(deleteClassRoom({ id: classRoomToDelete })).then(() => {
        dispatch(getClassRoomList());
        toggleDeleteConfirmModal();
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteClassRoomHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteClassRoomMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Classrooms}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  setSelectedLocationId("");
                  dispatch(setClassRoomListByIdToInitialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>
      {filteredAccordionData?.length > 0 ? (
        filteredAccordionData.map(item => (
          <MasterDataAccordion
            key={item.id}
            accordionItems={item}
            onEditClick={() => handleEdit(item.id)}
            onDeleteClick={() => handleDelete(item.id)}
          />
        ))
      ) : (
        <NoData svg={"empty-folder-icon"} title={NoDataText} />
      )}

      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Classroom}
        placeholderText={`${Enter} ${Classroom}`}
        toolTipText={`${Enter} ${Classroom}`}
        dropdownLabel={Location}
        dropdownLabelTooltip={`${Select} ${Location}`}
        dropdownOptions={locationListData}
        selectedDropdownValue={selectedLocationId}
        onDropdownChange={value => setSelectedLocationId(value)}
        initialValues={modalFormValues}
      />
    </div>
  );
};

export default ClassRoomPage;
