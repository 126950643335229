import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET,
  GET_SGA_LIST_API,
  GET_SGA_LIST,
  GET_ALL_SELF_ASSESSMENT_API,
  GET_ALL_SELF_ASSESSMENT,
  GET_CURRICULUM_BY_ROLE_ID,
  GET_CURRICULUM_BY_ROLE,
  DELETE_SGA_COURSE,
  DELETE,
  GET_SGA_BY_ID,
  GET_SGA_BY_ID_API,
  MAP_TO_SGA,
  MAP_TO_SGA_API,
  PUT,
  POST,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  SgaResponse,
  SelfAssessmentResponse,
  DeleteSgaCoursePayload,
  SgaDetailsResponse,
  MapSgaPayload,
  CourseByLevelIdResponse,
} from "../../Api/entities/SgaEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface SgaInterface {
  sgaList: SgaResponse;
  selfAssessmentList: SelfAssessmentResponse;
  loading: boolean;
  error: string | null;
  courseList: CourseByLevelIdResponse;
  sgaDetails: SgaDetailsResponse;
}

const initialState: SgaInterface = {
  sgaList: { sga_list: [] },
  selfAssessmentList: {
    assessments: [],
  },
  loading: false,
  courseList: { courses: [] },
  error: null,
  sgaDetails: {
    id: null,
    role_id: null,
    level_id: null,
    assessment_id: null,
    assessment_name: "",
    courses: [],
    grades: [],
    duration_count: null,
    effective_from: "",
    policy_id: null,
    policy_name: "",
    duration_type: "",
    audit_logs: [],
    version: [],
  },
};

export const getSgaList = createAsyncThunk(GET_SGA_LIST, async () => {
  const response = await apiRequest(GET, GET_SGA_LIST_API());
  return response;
});

export const getAllSelfAssessment = createAsyncThunk(
  GET_ALL_SELF_ASSESSMENT,
  async () => {
    const response = await apiRequest(GET, GET_ALL_SELF_ASSESSMENT_API());
    return response.data;
  }
);

export const getCurriculumByRoleId = createAsyncThunk(
  GET_CURRICULUM_BY_ROLE,
  async ({
    role_id,
    level_id,
    course_type,
  }: {
    role_id: number;
    level_id?: number;
    course_type?: string[];
  }) => {
    const response = await apiRequest(
      GET,
      GET_CURRICULUM_BY_ROLE_ID(role_id, level_id, course_type)
    );
    return response.data;
  }
);

export const deleteSgaCourse = createAsyncThunk(
  DELETE_SGA_COURSE,
  async ({ courseContent }: { courseContent: DeleteSgaCoursePayload }) => {
    const response = await apiRequest(
      DELETE,
      GET_SGA_LIST_API(),
      courseContent
    );
    return response;
  }
);

export const getSgaById = createAsyncThunk(
  GET_SGA_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_SGA_BY_ID_API(id));
    return response.data;
  }
);

export const mapToSga = createAsyncThunk(
  MAP_TO_SGA,
  async ({ sgaContentPayload }: { sgaContentPayload: MapSgaPayload }) => {
    const response = await apiRequest(
      sgaContentPayload?.id ? PUT : POST,
      MAP_TO_SGA_API(),
      sgaContentPayload
    );
    return response;
  }
);

const SgaSlice = createSlice({
  name: "sga",
  reducers: {
    setSgaDetailsToInitials: state => {
      state.sgaDetails = initialState.sgaDetails;
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getSgaList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSgaList.fulfilled,
        (state, action: PayloadAction<SgaResponse>) => {
          state.loading = false;
          state.sgaList = action.payload;
        }
      )
      .addCase(getSgaList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(getAllSelfAssessment.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllSelfAssessment.fulfilled,
        (state, action: PayloadAction<SelfAssessmentResponse>) => {
          state.loading = false;
          state.selfAssessmentList = action.payload;
        }
      )
      .addCase(getAllSelfAssessment.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCurriculumByRoleId.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCurriculumByRoleId.fulfilled,
        (state, action: PayloadAction<CourseByLevelIdResponse>) => {
          state.loading = false;
          state.courseList = action.payload;
        }
      )
      .addCase(getCurriculumByRoleId.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(deleteSgaCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSgaCourse.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteSgaCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSgaById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getSgaById.fulfilled,
        (state, action: PayloadAction<SgaDetailsResponse>) => {
          state.loading = false;
          state.sgaDetails = action.payload;
        }
      )
      .addCase(getSgaById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(mapToSga.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(mapToSga.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(mapToSga.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setSgaDetailsToInitials } = SgaSlice.actions;

export default SgaSlice.reducer;
