import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Form, FormGroup, Input } from "reactstrap";

import { Btn, H3 } from "../../../../AbstractElements";
import DateRangeDisplay from "../../../../CommonElements/DateRangeDisplay";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { replaceMentor } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getFilterRolesList,
  getProficiencyList,
  getSbuList,
  getUserMasterList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Level,
  Mentor,
  Replace,
  Role,
  SBU,
  Select,
  Update,
} from "../../../../utils/Constant";

const ReplaceMentor = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { mentorId, mentorName, mentor_domain_id } = state || {};

  const { roleOnFilter, sbuList, proficiencyList, userMasterList } =
    useAppSelector(state => state.master);

  const [selectedMentor, setSelectedMentor] = useState<number | null>(null);
  const [roleFilterList, setRoleFilterList] = useState([]);
  const [sbuFilterList, setSbuFilterList] = useState([]);
  const [levelFilterList, setLevelFilterList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [mentorOptions, setMentorOptions] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});

  useEffect(() => {
    dispatch(
      getUserMasterList({
        roleId: null,
        levelId: null,
        sbuId: null,
      })
    );
    dispatch(getFilterRolesList());
    dispatch(getSbuList());
    dispatch(getProficiencyList());
  }, [dispatch]);

  useEffect(() => {
    const value = [];
    if (userMasterList) {
      userMasterList?.users?.forEach((item: any) => {
        value.push({
          name: item?.id,
          label: item?.user_name,
          value: item?.user_name,
        });
      });
    }
    setUsersList(value);
  }, [userMasterList]);

  useEffect(() => {
    const value = [];
    if (roleOnFilter) {
      roleOnFilter?.job_role?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setRoleFilterList(value);
  }, [roleOnFilter]);

  useEffect(() => {
    const value = [];
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setSbuFilterList(value);
  }, [sbuList]);

  useEffect(() => {
    const value = [];
    if (proficiencyList) {
      proficiencyList?.proficiency?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.level_name,
          value: item?.level_name,
        });
      });
    }
    setLevelFilterList(value);
  }, [proficiencyList]);

  useEffect(() => {
    setMentorOptions(usersList.filter(user => user.name !== mentorId));
  }, [usersList, mentorId]);

  const handleMentorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mentorId = Number(e.target.value);
    setSelectedMentor(mentorId);
  };

  const handleUpdateClick = () => {
    dispatch(
      replaceMentor({
        replaceMentorPayload: {
          mentor_domain_id: mentor_domain_id,
          replaced_by: selectedMentor,
        },
      })
    );
    navigate(`${process.env.PUBLIC_URL}/mentoring/manage-mentoring`);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["role"] || values["sbu"] || values["level"]) {
      await dispatch(
        getUserMasterList({
          roleId: values["role"]?.toString(),
          levelId: values["level"]?.toString(),
          sbuId: values["sbu"]?.toString(),
        })
      );
    }
  };

  const handleClear = async () => {
    setUsersList([]);
    await dispatch(
      getUserMasterList({
        roleId: "",
        levelId: "",
        sbuId: "",
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody className="custom-replace-mentor">
          <H3 className="fw-bold mt-3">
            {Replace} {Mentor}
          </H3>
          <Divider />

          <Col md={2} key={mentorId} className="mt-3">
            <DateRangeDisplay
              startDate={mentorName}
              endDate={"?"}
              type="text"
            />
          </Col>

          <Form className="mt-4">
            <div className="d-flex flex-row gap-3 align-items-center">
              <Col md={4} className="position-relative">
                <FormGroup>
                  <LabelTooltip
                    label={Mentor}
                    important
                    tooltipText={`${Select} ${Mentor}`}
                  />
                  <Input
                    type="select"
                    name="mentor"
                    className="text-gray"
                    onChange={handleMentorChange}
                    value={selectedMentor || ""}
                  >
                    <option>
                      {Select} {Mentor}
                    </option>
                    {mentorOptions?.map(user => (
                      <option key={user.value} value={user.name}>
                        {user.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <div className="mt-4">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: Role,
                      key: "role",
                      tooltipText: `${Select} ${Role}`,
                      options: roleFilterList,
                      isMultiSelect: true,
                    },
                    {
                      label: SBU,
                      key: "sbu",
                      tooltipText: `${Select} ${SBU}`,
                      options: sbuFilterList,
                      isMultiSelect: true,
                    },
                    {
                      label: Level,
                      key: "level",
                      tooltipText: `${Select} ${Level}`,
                      options: levelFilterList,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>

            <Divider />

            <div className="d-flex justify-content-end mt-2 mb-2">
              <Btn color="primary" onClick={handleUpdateClick}>
                {Update}
              </Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default ReplaceMentor;
