import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { LI, SVG, UL } from "../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  handlePined,
  setToggleSidebar,
} from "../../ReduxToolkit/Reducers/LayoutSlice";
import {
  MenuItem,
  MenuListType,
  SidebarItemTypes,
} from "../../Types/Layout/SidebarType";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";

const Menulist: React.FC<MenuListType> = ({
  menu,
  setActiveMenu,
  activeMenu,
  level,
  className,
}) => {
  const isMobile = useIsMobile();
  const { toggleSidebar } = useAppSelector(state => state.layout);
  const { pinedMenu } = useAppSelector(state => state.layout);
  const { sidebarIconType } = useAppSelector(state => state.themeCustomizer);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const ActiveNavLinkUrl = (path?: string, active?: boolean) => {
    if (location.pathname === path) {
      return active || true;
    }
    return "";
  };

  const shouldSetActive = ({ item }: SidebarItemTypes) => {
    let returnValue = false;
    if (item?.path === location.pathname) returnValue = true;
    if (!returnValue && item?.children) {
      item?.children.every(subItem => {
        returnValue = shouldSetActive({ item: subItem });
        return !returnValue;
      });
    }
    return returnValue;
  };
  const handleClick = (item: string) => {
    const temp = activeMenu;
    temp[level] = item !== temp[level] ? item : "";
    setActiveMenu([...temp]);
  };
  useEffect(() => {
    menu?.forEach((item: any) => {
      const gotValue = shouldSetActive({ item });
      if (gotValue) {
        const temp = [...activeMenu];
        temp[level] = item.title;
        setActiveMenu(temp);
      }
    });
  }, []);

  const getPath = (path: string) => {
    if (
      path === "/course-management" &&
      !hasPermissionToComponent("COURSE_MANAGEMENT.COURSE_REQUEST")
    ) {
      return "/course-management/course-list";
    } else if (path === "/course-management") {
      return "/course-management/course-request";
    } else if (path === "/assessments") {
      return "/assessments/assessments-list";
    } else if (
      path === "/mentoring" &&
      !hasPermissionToComponent("MENTORING.MANAGE_MENTORING")
    ) {
      return "/mentoring/i-am-mentor";
    } else if (
      path === "/mentoring" &&
      hasPermissionToComponent("MENTORING.MANAGE_MENTORING")
    ) {
      return "/mentoring/manage-mentoring";
    } else if (
      path === "/master-data" &&
      !hasPermissionToComponent("MASTER_DATA.QUESTION_BANK")
    ) {
      return "/master-data/domain";
    } else if (
      path === "/master-data" &&
      hasPermissionToComponent("MASTER_DATA.QUESTION_BANK")
    ) {
      return "/master-data/question-bank-list";
    }

    return path;
  };

  const handleItemClick = (item: MenuItem) => {
    if (isMobile && !item?.children) {
      dispatch(setToggleSidebar(!toggleSidebar));
    }
    handleClick(item.title);
  };

  return (
    <>
      {menu?.map(item => {
        const isActive =
          (item.children
            ? item.children
                .map(innerItem => ActiveNavLinkUrl(innerItem.path))
                .includes(true)
            : ActiveNavLinkUrl(item.path)) || activeMenu[level] === item.title;

        const linkClassName = `${!className && level !== 2 ? "sidebar-link sidebar-title" : ""} ${
          isActive ? "active" : ""
        }`;

        return (
          <LI
            key={item.id}
            className={`${level === 0 ? "sidebar-list" : ""} ${pinedMenu.includes(item.title || "") ? "pined" : ""} ${
              isActive ? "active" : ""
            }`}
          >
            {level === 0 && (
              <i
                className="fa fa-thumb-tack text-black thumb-icon-margin"
                onClick={() => dispatch(handlePined(item.title))}
              ></i>
            )}
            <Link
              className={linkClassName}
              to={getPath(item.path)}
              onClick={() => handleItemClick(item)}
            >
              {item.icon && (
                <SVG
                  className={`${sidebarIconType}-icon`}
                  iconId={`${sidebarIconType}-${item.icon}`}
                />
              )}
              <span className={item.lanClass ? item.lanClass : ""}>
                {item.title}
              </span>
              <div>
                {item.children && (
                  <div className="according-menu">
                    <i
                      className={
                        activeMenu[level] === item.title
                          ? "fa fa-angle-down text-dark"
                          : "fa fa-angle-right text-white"
                      }
                    />
                  </div>
                )}
              </div>
            </Link>
            {item.children && (
              <UL
                className={`simple-list ${level !== 0 ? "nav-sub-childmenu submenu-content" : "sidebar-submenu "}`}
                style={{
                  display: isActive ? "block" : "none",
                }}
              >
                <Menulist
                  menu={item.children}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                  level={level + 1}
                  className="sidebar-submenu"
                  handleMenuClick={handleItemClick}
                />
              </UL>
            )}
          </LI>
        );
      })}
    </>
  );
};

export default Menulist;
