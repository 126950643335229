import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { H2, H3, SVG } from "../../AbstractElements";
import "./style.scss";

interface TotalCountCardProps {
  data: TotalCountData[];
}

interface TotalCountData {
  count: number;
  title: string;
  icon: string;
}

const TotalCountCard: React.FC<TotalCountCardProps> = ({ data }) => {
  return (
    <Row>
      {data.map((item, index) => (
        <Col key={index}>
          <Card className="mb-3">
            <CardBody className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column gap-4">
                <H2 className="fw-bold">{item.count}</H2>
                <H3>{item.title}</H3>
              </div>
              <SVG iconId={item.icon} className="total-count-icon-circle" />
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default TotalCountCard;
