import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import {
  ALL_COURSE_FILTER_STATUS,
  EXPERTISE_LEVEL_STATUS,
} from "../../../../../../../Api/constants";
import FilterSliderModal from "../../../../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../../../../CommonElements/MultiSelectDropdown";
import NumberPicker from "../../../../../../../CommonElements/RatioPicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../ReduxToolkit/Hooks";
import { setBasicInputFormValue } from "../../../../../../../ReduxToolkit/Reducers/CompetencyWizardSlice";
import { getCourseList } from "../../../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  Enter,
  PersonalStatement,
  ClassroomEnvironment,
  EstimatedDelivery,
  Classroom,
  Practical,
  Reference,
  MediaDemonstration,
  Others,
  OthersRequired,
  Select,
  OtherReference,
  Tools,
  TrainingAids,
  CourseType,
  Domain,
  MinimumRequirementToolTipMessage,
  MediaDemonstrationToolTipMessage,
  EstimatedDaysToolTipMessage,
  ClassRoomToolTipMessage,
  PracticalToolTipMessage,
  ReferenceToolTipMessage,
  OtherTools,
  OtherTrainingAids,
  OthersToolsRequiredText,
  OthersTrainingAidsRequiredText,
} from "../../../../../../../utils/Constant";
import {
  richTextSupportedItems,
  courseFilterDropdownList,
  handleKeyDownOnNumericWithDecimal,
} from "../../../../../../../utils/helper/helper";

interface BasicInputFormValueInterface {
  personnel_requirement: string;
  media_demonstration: string;
  classroom_environment: string;
  estimated_delivery: string;
  class_room_ratio: string;
  practical_class_ratio: string;
  resources: string;
  tools: string;
  training_aids: string;
  reference: string;
  other_references: string;
  other_tools: string;
  other_training_aids: string;
}

interface BasicInputFormValuePropsType {
  preSelectedTools: any[];
  preSelectedTrainingAids: any[];
  preSelectedResource: any[];
  preSelectedReference: any[];
  showOthers: boolean;
  setShowOthers: any;
  showOtherTools: boolean;
  setShowOtherTools: any;
  showOtherTrainingAids: boolean;
  setShowOtherTrainingAids: any;
  getMultiDropdownResourceData: (val) => void;
  getMultiDropdownToolsData: (val) => void;
  getMultiDropdownTrainingAidsData: (val) => void;
  getMultiDropdownReferenceData: (val) => void;
  getUserData: (event: ChangeEvent<HTMLInputElement>) => void;
  basicInputFormValue: BasicInputFormValueInterface;
  level?: number;
  referenceListData: any;
  resourceListData: any;
  toolsListData: any;
  trainingAidsListData: any;
  getRichTextUserData: any;
  errors: {
    personnel_requirement: string;
    media_demonstration: string;
    classroom_environment: string;
    estimated_delivery: string;
    class_room_ratio: string;
    practical_class_ratio: string;
    tools: string;
    training_aids: string;
    reference: string;
    other_references: string;
    other_tools: string;
    other_training_aids: string;
  };
  domainList: any;
}

const CompetencyFormTwo: React.FC<BasicInputFormValuePropsType> = ({
  basicInputFormValue,
  getUserData,
  toolsListData,
  trainingAidsListData,
  getRichTextUserData,
  errors,
  getMultiDropdownToolsData,
  getMultiDropdownTrainingAidsData,
  getMultiDropdownReferenceData,
  preSelectedTools,
  preSelectedTrainingAids,
  preSelectedReference,
  showOthers,
  setShowOthers,
  showOtherTools,
  setShowOtherTools,
  showOtherTrainingAids,
  setShowOtherTrainingAids,
  domainList,
}) => {
  const dispatch = useAppDispatch();
  const {
    personnel_requirement,
    media_demonstration,
    classroom_environment,
    estimated_delivery,
    class_room_ratio,
    practical_class_ratio,
    other_references,
    other_tools,
    other_training_aids,
  } = basicInputFormValue;
  const { courseList } = useAppSelector(state => state.course);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [courseListData, setCourseListData] = useState([]);

  useEffect(() => {
    dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const publishedCourse = [];
    if (courseList) {
      courseList?.course_list?.forEach(item => {
        publishedCourse.push({
          name: item?.id?.toString(),
          label: item?.course_name,
          value: item?.course_name,
        });
      });
    }
    setCourseListData(publishedCourse);
  }, [courseList]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const value = event.target.value;
    if (name === "estimated_delivery") {
      const numberPattern = /^-?\d+(\.\d*)?$/;
      if (
        numberPattern.test(value) &&
        parseFloat(value) > 0 &&
        parseFloat(value) <= 99 &&
        !isNaN(parseFloat(value)) &&
        parseFloat(value) % 0.5 === 0
      ) {
        const decimalPart = value.split(".")[1];

        if (!decimalPart || decimalPart.length <= 2) {
          getUserData(event);
        }
      } else if (
        value === "" ||
        value === "." ||
        value === "0." ||
        value === "0"
      ) {
        getUserData(event);
      }
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const { course_type = ALL_COURSE_FILTER_STATUS, domain = [] } = values;
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type:
          course_type.length > 0 ? course_type : ALL_COURSE_FILTER_STATUS,
        domain: domain.length > 0 ? domain : null,
      })
    );
  };
  const handleClear = async () => {
    await dispatch(
      getCourseList({
        status: EXPERTISE_LEVEL_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  return (
    <Form className="stepper-one g-3 needs-validation custom-input" noValidate>
      <Col className="mt-3">
        <FormGroup>
          <LabelTooltip
            label={PersonalStatement}
            tooltipText={MinimumRequirementToolTipMessage}
            important
          />
          <div
            className={
              errors.personnel_requirement ? "border border-danger" : ""
            }
          >
            <CKEditor
              onChange={(_event, editor) => {
                const data = editor.getData();
                getRichTextUserData("personnel_requirement", data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor}
              data={personnel_requirement}
            />
          </div>
          <div className="mt-1 text-sm text-danger">
            {errors.personnel_requirement}
          </div>
        </FormGroup>
      </Col>
      <Col className="mt-3">
        <FormGroup>
          <LabelTooltip
            label={MediaDemonstration}
            tooltipText={MediaDemonstrationToolTipMessage}
            important
          />
          <div
            className={errors.media_demonstration ? "border border-danger" : ""}
          >
            <CKEditor
              onChange={(_event, editor) => {
                const data = editor.getData();
                getRichTextUserData("media_demonstration", data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor}
              data={media_demonstration}
            />
          </div>
          <div className="mt-1 text-sm text-danger">
            {errors.media_demonstration}
          </div>
        </FormGroup>
      </Col>
      <Col className="mt-3">
        <FormGroup>
          <div>
            <Label className="col-form-label">{ClassroomEnvironment}</Label>
            <span className="txt-danger">*</span>
          </div>
          <div
            className={
              errors.classroom_environment ? "border border-danger" : ""
            }
          >
            <CKEditor
              onChange={(_event, editor) => {
                const data = editor.getData();
                getRichTextUserData("classroom_environment", data);
              }}
              config={{
                toolbar: {
                  items: richTextSupportedItems,
                },
              }}
              editor={ClassicEditor}
              data={classroom_environment}
            />
          </div>
          <div className="mt-1 text-sm text-danger">
            {errors.classroom_environment}
          </div>
        </FormGroup>
      </Col>

      <Row className="mt-4">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={EstimatedDelivery}
              tooltipText={EstimatedDaysToolTipMessage}
              important
            />
            <Input
              onChange={handleInputChange}
              value={estimated_delivery}
              name="estimated_delivery"
              type="number"
              inputMode="numeric"
              step={0.5}
              onKeyDown={handleKeyDownOnNumericWithDecimal}
              placeholder={`${Enter} ${EstimatedDelivery}`}
              invalid={!!errors.estimated_delivery}
            />
            <div className="invalid-feedback">{errors.estimated_delivery}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <Row>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Classroom}
                  tooltipText={ClassRoomToolTipMessage}
                  important
                />
                <NumberPicker
                  limit={100}
                  hour={
                    parseInt(class_room_ratio?.toString()?.split(".")[0]) || 0
                  }
                  minute={
                    parseInt(class_room_ratio?.toString()?.split(".")[1]) || 0
                  }
                  onChange={value => {
                    const [hours, minutes] = value.split(":");
                    const formattedValue = `${hours}.${minutes}`;
                    dispatch(
                      setBasicInputFormValue({
                        ...basicInputFormValue,
                        class_room_ratio: formattedValue,
                      })
                    );
                  }}
                />
                <div className="text-danger">{errors.class_room_ratio}</div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={Practical}
                  tooltipText={PracticalToolTipMessage}
                  important
                />
                <NumberPicker
                  limit={100}
                  hour={
                    parseInt(
                      practical_class_ratio?.toString()?.split(".")[0]
                    ) || 0
                  }
                  minute={
                    parseInt(
                      practical_class_ratio?.toString()?.split(".")[1]
                    ) || 0
                  }
                  onChange={value => {
                    const [hours, minutes] = value.split(":");
                    const formattedValue = `${hours}.${minutes}`;
                    dispatch(
                      setBasicInputFormValue({
                        ...basicInputFormValue,
                        practical_class_ratio: formattedValue,
                      })
                    );
                  }}
                />
                <div className="text-danger">
                  {errors.practical_class_ratio}
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={Tools}
              tooltipText={`${Select} ${Tools}`}
              important={!showOtherTools}
            />
            <MultiSelectDropdown
              onChange={values => {
                getMultiDropdownToolsData(values);
              }}
              options={toolsListData}
              placeholder={`${Select} ${Tools}`}
              defaultSelected={preSelectedTools}
            />
            <div className="text-danger">{errors.tools}</div>
          </FormGroup>
        </Col>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={TrainingAids}
              tooltipText={`${Select} ${TrainingAids}`}
              important={!showOtherTrainingAids}
            />
            <MultiSelectDropdown
              onChange={values => {
                getMultiDropdownTrainingAidsData(values);
              }}
              options={trainingAidsListData}
              placeholder={`${Select} ${TrainingAids}`}
              defaultSelected={preSelectedTrainingAids}
            />
            <div className="text-danger">{errors.training_aids}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2">
            <div className="form-check text-dark">
              <Input
                className=".border-primary"
                id="flexCheckAddCurriculum"
                type="checkbox"
                checked={showOtherTools}
                onChange={() => {
                  setShowOtherTools(!showOtherTools);
                }}
              />
              <Label
                className="text-dark"
                htmlFor="flexCheckAddCurriculum"
                check
              >
                {OthersToolsRequiredText}
              </Label>
            </div>
          </div>
          {showOtherTools && (
            <Col sm="12" xs="12">
              <FormGroup>
                <LabelTooltip
                  label={OtherTools}
                  tooltipText={`${Enter} ${OtherTools}`}
                  important
                />
                <div
                  className={errors.other_tools ? "border border-danger" : ""}
                >
                  <CKEditor
                    onChange={(_event, editor) => {
                      const data = editor.getData();
                      getRichTextUserData("other_tools", data);
                    }}
                    config={{
                      toolbar: {
                        items: richTextSupportedItems,
                      },
                    }}
                    editor={ClassicEditor}
                    data={other_tools}
                  />
                </div>
                <div className="mt-1 text-sm text-danger">
                  {errors.other_tools}
                </div>
              </FormGroup>
            </Col>
          )}
        </Col>
        <Col>
          <div className="mt-2">
            <div className="form-check text-dark">
              <Input
                className=".border-primary"
                id="flexCheckAddCurriculum"
                type="checkbox"
                checked={showOtherTrainingAids}
                onChange={() => {
                  setShowOtherTrainingAids(!showOtherTrainingAids);
                }}
              />
              <Label
                className="text-dark"
                htmlFor="flexCheckAddCurriculum"
                check
              >
                {OthersTrainingAidsRequiredText}
              </Label>
            </div>
          </div>
          {showOtherTrainingAids && (
            <Col sm="12" xs="12">
              <FormGroup>
                <LabelTooltip
                  label={OtherTrainingAids}
                  tooltipText={`${Enter} ${OtherTrainingAids}`}
                  important
                />
                <div
                  className={
                    errors.other_training_aids ? "border border-danger" : ""
                  }
                >
                  <CKEditor
                    onChange={(_event, editor) => {
                      const data = editor.getData();
                      getRichTextUserData("other_training_aids", data);
                    }}
                    config={{
                      toolbar: {
                        items: richTextSupportedItems,
                      },
                    }}
                    editor={ClassicEditor}
                    data={other_training_aids}
                  />
                </div>
                <div className="mt-1 text-sm text-danger">
                  {errors.other_training_aids}
                </div>
              </FormGroup>
            </Col>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm="12" lg="6">
          <FormGroup>
            <LabelTooltip
              label={Reference}
              tooltipText={ReferenceToolTipMessage}
              important={!showOthers}
            />
            <MultiSelectDropdown
              onChange={values => {
                getMultiDropdownReferenceData(values);
              }}
              options={courseListData}
              placeholder={"Select Reference"}
              defaultSelected={preSelectedReference}
            />
            <div className="text-danger">{errors.reference}</div>
          </FormGroup>
        </Col>
        <Col className="filter-margin">
          <FilterSliderModal
            dropdowns={[
              {
                label: CourseType,
                key: "course_type",
                tooltipText: `${Select} ${CourseType}`,
                options: courseFilterDropdownList,
                isMultiSelect: true,
              },
              {
                label: Domain,
                key: "domain",
                tooltipText: `${Select} ${Domain}`,
                options: domainList,
                isMultiSelect: true,
              },
            ]}
            selectedFilterValues={selectedFilterValues}
            defaultFilterValues={defaultFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
            setDefaultFilterValues={setDefaultFilterValues}
            onDone={handleDone}
            onClear={handleClear}
          />
        </Col>
      </Row>
      <div className="mt-2">
        <div className="form-check text-dark">
          <Input
            className=".border-primary"
            id="flexCheckAddCurriculum"
            type="checkbox"
            checked={showOthers}
            onChange={() => {
              setShowOthers(!showOthers);
            }}
          />
          <Label className="text-dark" htmlFor="flexCheckAddCurriculum" check>
            {OthersRequired}
          </Label>
        </div>
      </div>
      {showOthers && (
        <Col sm="12" xs="12">
          <FormGroup>
            <LabelTooltip
              label={Others}
              tooltipText={`${Enter} ${OtherReference}`}
              important
            />
            <div
              className={errors.other_references ? "border border-danger" : ""}
            >
              <CKEditor
                onChange={(_event, editor) => {
                  const data = editor.getData();
                  getRichTextUserData("other_references", data);
                }}
                config={{
                  toolbar: {
                    items: richTextSupportedItems,
                  },
                }}
                editor={ClassicEditor}
                data={other_references}
              />
            </div>
            <div className="mt-1 text-sm text-danger">
              {errors.other_references}
            </div>
          </FormGroup>
        </Col>
      )}
    </Form>
  );
};

export default CompetencyFormTwo;
