import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { ALL_USERS_STATUS } from "../../../../Api/constants";
import LOAccordion from "../../../../CommonElements/LOAccordion";
import CommonModal from "../../../../CommonElements/Modal";
import NoData from "../../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getAllLearningOutcomeList,
  unArchiveLearningOutcome,
} from "../../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  getDomainList,
  getProficiencyList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  ConfirmUnArchive,
  NoDataText,
  UnArchive,
  UnArchiveConfirmMessage,
} from "../../../../utils/Constant";

const LOArchive = ({ searchQuery }) => {
  const dispatch = useAppDispatch();
  const { domainList, competencyList, proficiencyList } = useAppSelector(
    state => state.master
  );
  const { usersList } = useAppSelector(state => state.user);
  const { learningOutcomeList } = useAppSelector(
    state => state.manageMasterData
  );
  const [accordionData, setAccordionData] = useState([]);
  const [userMap, setUserMap] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, []);

  useEffect(() => {
    dispatch(getDomainList());
    dispatch(getProficiencyList());
    dispatch(
      getAllLearningOutcomeList({
        status: "archived",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const filteredArchivedLearningOutcomes =
      learningOutcomeList?.learning_outcomes?.filter(outcome =>
        outcome.outcome.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const formattedAccordionData = filteredArchivedLearningOutcomes?.map(
      outcome => ({
        id: outcome.id.toString(),
        title: outcome.outcome,
        description: outcome.function,
        depedentBadges: [
          {
            id: outcome.domain_id,
            badge: getItemNameById(
              domainList.domain,
              outcome.domain_id,
              "domain_name"
            ),
          },
          {
            id: outcome.competency_id,
            badge: getItemNameById(
              competencyList.competency,
              outcome.competency_id,
              "competency_name"
            ),
          },
          {
            id: outcome.proficiency_id,
            badge: getItemNameById(
              proficiencyList.proficiency,
              outcome.proficiency_id,
              "level_name"
            ),
          },
        ],
        createdOn: outcome.created_at,
        modifiedOn: outcome.updated_at,
        createdBy: userMap ? userMap[outcome.created_by]?.user_name : "-",
      })
    );

    setAccordionData(formattedAccordionData);
  }, [learningOutcomeList, userMap, searchQuery]);

  const getItemNameById = (list: any[], id: number, key: string) => {
    const item = list.find(item => item.id === id);
    return item ? item[key] : "";
  };

  const handleUnArchiveClick = (
    id: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    setSelectedItemId(id);
    setIsConfirmModalOpen(true);
  };

  const handleConfirm = async () => {
    const payload = {
      id: parseInt(selectedItemId),
      is_published: true,
    };
    await dispatch(
      unArchiveLearningOutcome({ unArchiveLearningOutcomePayload: payload })
    );
    setIsConfirmModalOpen(false);
    setSelectedItemId(null);
    dispatch(
      getAllLearningOutcomeList({
        status: "archived",
      })
    );
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  return (
    <>
      <CommonModal
        sizeTitle={ConfirmUnArchive}
        isOpen={isConfirmModalOpen}
        toggle={toggleConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handleConfirm}
        primaryBtnText={UnArchive}
      >
        {UnArchiveConfirmMessage}
      </CommonModal>

      {accordionData?.length > 0 ? (
        accordionData.map(item => (
          <LOAccordion
            key={item.id}
            accordionItems={item}
            unArchive
            onArchiveClick={handleUnArchiveClick}
          />
        ))
      ) : (
        <NoData svg={"empty-folder-icon"} title={NoDataText} />
      )}
    </>
  );
};

LOArchive.propTypes = {
  searchQuery: PropTypes.string.isRequired,
};

export default LOArchive;
