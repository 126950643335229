import { useState } from "react";
import {
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

import { MUIIcons, ToolTip } from "../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  PUBLISH_STATUS,
  PUBLISHED,
  SCHEDULED_FOR_PUBLISH,
} from "../../Api/constants";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import {
  getCourseList,
  reviewCourse,
} from "../../ReduxToolkit/Reducers/CourseSlice";
import { PropsTypes } from "../../Types/CommonElements/CommonElementsTypes";
import {
  Confirm,
  Confirmation,
  Inactive,
  InactiveCourseMessage,
  ReAttempt,
} from "../../utils/Constant";
import { getCurrentDate } from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import Badges from "../Badge";
import H5 from "../Headings/H5Element";
import P from "../Paragraph";

import CardHeaderDropDown from "./CardHeaderDropDown";

const CommonCardHeader = ({
  headClass,
  title,
  titleClass,
  mainTitle,
  firstItem,
  secondItem,
  thirdItem,
  subClass,
  badge,
  status,
  courseId,
  handleDelete,
  showDelete,
  badgeTextColor,
  reAttemptCount,
  onFirstItemClick,
  showDrop,
  dropVal,
  onDropClick,
}: PropsTypes) => {
  const dispatch = useAppDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const truncatedHeader =
    title?.length <= 1 ? title[0] : `${title[0]} ${title?.length - 1}+`;

  const [tooltipDomainNameOpen, setTooltipDomainNameOpen] = useState<{
    [key: string]: boolean;
  }>({});

  const domainNameTooltip = (courseId: string) => {
    setTooltipDomainNameOpen(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  const toggleDropdown = e => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen(prevState => !prevState);
  };

  const handleDropdownClick = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const handleInactiveClick = e => {
    e.preventDefault();
    e.stopPropagation();
    toggleModal();
  };

  const handleSubmit = async () => {
    const formData = {
      course_id: courseId,
      status: "",
      reason: "",
      is_review: false,
      course_content: {
        is_self_enrollment: false,
        bond_amount: 0,
        bond_year: 0,
        course_publish_date: getCurrentDate(),
        curriculum: [],
        course_fee: 0,
        course_status: "unpublished",
        is_publish_now: true,
      },
    };

    const wrapperObj = {
      courseContent: formData,
    };

    dispatch(reviewCourse(wrapperObj));
    toggleModal();
    dispatch(
      getCourseList({
        status: PUBLISH_STATUS,
        course_type: ALL_COURSE_FILTER_STATUS,
        domain: null,
      })
    );
  };

  return (
    <CardHeader className={headClass}>
      <div
        className={`header-top ${subClass} d-flex align-items-center justify-content-between`}
      >
        <div>
          <span id={`tooltip-domain-name${courseId}`}>
            <P className={`${titleClass} mb-2 header-text truncated-header`}>
              {truncatedHeader}
            </P>
          </span>
          <ToolTip
            placement="top"
            target={`tooltip-domain-name${courseId}`}
            isOpen={tooltipDomainNameOpen[courseId] || false}
            toggle={() => domainNameTooltip(courseId)}
          >
            {title.join(", ")}
          </ToolTip>
        </div>
        <div className="d-flex align-items-center">
          {mainTitle && (
            <CardHeaderDropDown
              firstItem={firstItem}
              secondItem={secondItem}
              thirdItem={thirdItem}
              mainTitle={mainTitle}
              onClickItem={() => {
                onFirstItemClick && onFirstItemClick(courseId);
              }}
            />
          )}
          {badge && (
            <span className="mb-2">
              <H5 className="d-flex flex-row">
                <Badges
                  className="d-flex align-items-center"
                  status={status}
                  badgeTextColor={badgeTextColor}
                />
                {typeof reAttemptCount === "number" && reAttemptCount > 0 && (
                  <Badges>
                    {ReAttempt} {reAttemptCount}
                  </Badges>
                )}
              </H5>
            </span>
          )}
          {showDelete && (
            <MUIIcons
              iconName="DeleteOutlineOutlined"
              className="primary-icon-color pointer"
              size={24}
              onClick={handleDelete}
            />
          )}
          {showDrop && (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              onClick={handleDropdownClick}
            >
              <DropdownToggle color="" className="three-dots">
                <div className="mb-1">&#x22EE;</div>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {dropVal?.map((item, ind) => {
                  return (
                    <DropdownItem
                      key={ind}
                      onClick={() => onDropClick(item?.courseId, item?.name)}
                    >
                      {item?.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          )}

          {(status === PUBLISHED || status === SCHEDULED_FOR_PUBLISH) &&
            hasPermissionToComponent("UN_PUBLISH_COURSE") && (
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                onClick={handleDropdownClick}
              >
                <DropdownToggle color="" className="three-dots">
                  <div className="mb-1">&#x22EE;</div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={handleInactiveClick}>
                    {Inactive}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
        </div>

        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{Confirmation}</ModalHeader>
          <ModalBody>{InactiveCourseMessage}</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleSubmit}>
              {Confirm}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </CardHeader>
  );
};

export default CommonCardHeader;
