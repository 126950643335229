import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET,
  GET_ATTENDANCE_FOR_SCHEDULE,
  GET_ATTENDANCE_FOR_SCHEDULE_URL,
  MARK_ATTENDANCE_FOR_SCHEDULE,
  MARK_ATTENDANCE_FOR_SCHEDULE_URL,
  PUT,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { AttendanceData } from "../../Api/entities/AttendanceEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface Attendance {
  attendanceList: AttendanceData;
  loading: boolean;
  error: string | null;
}

const initialState: Attendance = {
  loading: false,
  error: null,
  attendanceList: {
    trainer_attendance: [],
    participant_attendance: [],
  },
};

export const getAttendanceForSchedule = createAsyncThunk(
  GET_ATTENDANCE_FOR_SCHEDULE,
  async (schedule_id: { schedule_id: string }) => {
    const response = await apiRequest(
      GET,
      GET_ATTENDANCE_FOR_SCHEDULE_URL(schedule_id)
    );
    return response?.data;
  }
);

export const markAttendanceForSchedule = createAsyncThunk(
  MARK_ATTENDANCE_FOR_SCHEDULE,
  async (payload: any) => {
    return await apiRequest(PUT, MARK_ATTENDANCE_FOR_SCHEDULE_URL(), payload);
  }
);

const AttendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAttendanceForSchedule.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAttendanceForSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.attendanceList = action.payload;
      })
      .addCase(getAttendanceForSchedule.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(markAttendanceForSchedule.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markAttendanceForSchedule.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(markAttendanceForSchedule.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export default AttendanceSlice.reducer;
