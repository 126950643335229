import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, FormGroup, Input } from "reactstrap";

import Divider from "../../../../CommonElements/Divider";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import NoData from "../../../../container/NoData";
import UserActionCard from "../../../../container/UserActionCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getGradingList } from "../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  getNomineesOfSchedule,
  reviewTrainerOrNominee,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  AddComment,
  ApproveNomineeReason,
  Assessment,
  Back,
  Confirm,
  CourseStartedWarning,
  Description,
  EmptyCommentsAlert,
  Enter,
  NoDataText,
  NotYetAdded,
  RejectNomineeReason,
  Remove,
  RemoveParticipants,
  Review,
} from "../../../../utils/Constant";
import {
  formatDate,
  nomineeStatusMap,
  showToast,
} from "../../../../utils/helper/helper";
import {
  currentUserId,
  hasPermissionToComponent,
} from "../../../../utils/helper/permission";

const AttendeesList = ({
  scheduledId,
  userMap,
  courseId,
  courseName,
  sbuMap,
  startDate,
  primaryTrainerId,
  replacedTrainerId,
  getScheduledDetails,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedModalType, setSelectedModalType] = useState("");
  const [comments, setComments] = useState("");
  const { nominees } = useAppSelector(state => state.course);
  const { gradingList } = useAppSelector(state => state.assessmentCatlog);

  useEffect(() => {
    dispatch(
      getNomineesOfSchedule({ schedule_id: scheduledId, type: "attendees" })
    );
  }, [dispatch, scheduledId]);

  let sizeTitle = "";
  if (selectedModalType === "approve") {
    sizeTitle = ApproveNomineeReason;
  } else if (selectedModalType === "reject") {
    sizeTitle = RejectNomineeReason;
  } else if (selectedModalType === "remove") {
    sizeTitle = Remove;
  } else if (selectedModalType === "assessment") {
    sizeTitle = Assessment;
  }

  const renderModalBody = type => {
    return (
      <>
        {(selectedModalType === "approve" ||
          selectedModalType === "reject") && (
          <FormGroup>
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Description}`}
              important
            />
            <Input
              onChange={e => {
                if (
                  (e.target.value && e.target.value.length <= 255) ||
                  e.target.value === ""
                ) {
                  setComments(e.target.value);
                }
              }}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Description}`}
            />
          </FormGroup>
        )}
        {type === "remove" && RemoveParticipants}
        {type === "assessment" && (
          <div>
            <div className="text-center">
              {gradingList?.grading?.length === 0 && NoDataText}
            </div>
            {gradingList?.grading?.map(item => {
              return (
                <Link
                  key={item?.participant_id}
                  to={`${process.env.PUBLIC_URL}/assessments/review-standalone-assessment`}
                  state={{
                    participantId: item?.participant_id,
                    courseId: courseId,
                    scheduledId: scheduledId,
                  }}
                >
                  <Card className="mt-2 p-2 border border-grey">
                    <CardBody className="w-75">
                      <div className="d-flex justify-content-between">
                        <div>
                          {item?.assessment_name} - {item?.assessment_phase}
                        </div>
                        <div className="primary-text-color pointer">
                          {Review}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "approve" || selectedModalType === "reject") {
      if (!comments || comments.trim() === "") {
        showToast(EmptyCommentsAlert, "error");
        return;
      }
    }

    let payload;
    if (selectedModalType === "approve") {
      payload = {
        action: "approved",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "reject") {
      payload = {
        action: "rejected",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "remove") {
      payload = {
        action: "removed",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "assessment") {
      toggleModal();
      return;
    }
    await dispatch(
      getNomineesOfSchedule({ schedule_id: scheduledId, type: "attendees" })
    );
    await getScheduledDetails(scheduledId);
    toggleModal();
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedModalType("");
      setComments("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const onClickDropdownItem = async (value, id, participant_id, name) => {
    if (value === "item1") {
      await dispatch(
        getGradingList({ user_id: participant_id, schedule_id: scheduledId })
      );

      setSelectedId(participant_id);
      setSelectedModalType("assessment");
      toggleModal();
    }
    if (value === "item2") {
      if (
        new Date(new Date(startDate).setHours(0, 0, 0, 0)) <=
        new Date(new Date().setHours(0, 0, 0, 0))
      ) {
        showToast(CourseStartedWarning, "warning");
        return;
      }
      setSelectedId(id);
      setSelectedModalType("remove");
      toggleModal();
    }
    if (value === "item3") {
      if (
        new Date(new Date(startDate).setHours(0, 0, 0, 0)) <=
        new Date(new Date().setHours(0, 0, 0, 0))
      ) {
        showToast(CourseStartedWarning, "warning");
        return;
      }
      return navigate("/add-participant", {
        state: {
          replace_id: id,
          participant_id: participant_id,
          replace_name: name,
          scheduledId: scheduledId,
          courseId: courseId,
          courseName: courseName,
        },
      });
    }
  };

  const isEligibleToUpdate = () => {
    return (
      currentUserId() === primaryTrainerId ||
      replacedTrainerId?.includes(currentUserId()) ||
      hasPermissionToComponent("ADD_NOMINEES")
    );
  };

  return (
    <>
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={selectedModalType === "assessment" ? Back : Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      {nominees?.nominees?.length === 0 && (
        <div className="mt-2 p-5">
          <NoData
            showCard={false}
            showIcon={false}
            svg="empty-folder-icon"
            title={NotYetAdded}
          />
        </div>
      )}
      {nominees?.nominees?.length > 0 &&
        nominees?.nominees?.map((item, index) => {
          if (userMap) {
            const details = {
              unique_id: item.id,
              id: userMap[item?.participant_id]?.id,
              name: userMap[item?.participant_id]?.user_name,
              user_name: userMap[item?.participant_id]?.user_name,
              empId: userMap[item?.participant_id]?.employee_code,
              userId: userMap[item?.participant_id]?.id,
              mobile: userMap[item?.participant_id]?.phone,
              sbu: sbuMap[userMap[item?.participant_id]?.sbu],
              rmName: userMap[item?.participant_id]?.mapped_rm[0]?.name,
              rmMobileNo: userMap[item?.participant_id]?.mapped_rm[0]?.rm_phone,
              status: nomineeStatusMap[item?.nomination_status],
              participant_id: item?.id?.toString(),
              nominatedOn: item?.updated_at ? formatDate(item?.updated_at) : "",
            };
            return (
              <div key={item.id} className="mt-3">
                <UserActionCard
                  userDetails={details}
                  actions={isEligibleToUpdate()}
                  firstItem={"Grading"}
                  secondItem={"Cancel"}
                  thirdItem={"Replacement"}
                  status
                  onClickDropdownItem={onClickDropdownItem}
                />

                {index < nominees?.nominees?.length - 1 && <Divider />}
              </div>
            );
          }
        })}
    </>
  );
};

AttendeesList.propTypes = {
  scheduledId: PropTypes.number.isRequired,
  userMap: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      user_name: PropTypes.string,
      employee_code: PropTypes.string,
      phone: PropTypes.string,
      sbu: PropTypes.string,
      mapped_rm: PropTypes.shape({
        name: PropTypes.string,
        rm_phone: PropTypes.string,
      }),
    })
  ).isRequired,
  courseId: PropTypes.number.isRequired,
  courseName: PropTypes.string.isRequired,
  sbuMap: PropTypes.object,
  startDate: PropTypes.any,
  primaryTrainerId: PropTypes.any,
  replacedTrainerId: PropTypes.any,
  getScheduledDetails: PropTypes.any,
};

export default AttendeesList;
