import { ReactNode } from "react";

export interface H3ElementPropsTypes {
  children?: ReactNode;
  className?: string;
}

const H3 = (props: H3ElementPropsTypes) => {
  const { children = "" } = props;
  return <h3 {...props}>{children}</h3>;
};

export default H3;
