import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

import { Btn, H1, H2, P } from "../../AbstractElements";
import "./style.scss";
import {
  Assessment,
  Done,
  ExamResponseText,
  YourResponsesSubmitted,
} from "../../utils/Constant";

interface ExamResponseModalProps {
  examName: string;
  isOpen: boolean;
  handleModal: () => void;
}

const ExamResponseModal: React.FC<ExamResponseModalProps> = ({
  examName,
  isOpen,
  handleModal,
}) => {
  return (
    <Modal className="assessment-modal" isOpen={isOpen} centered={true}>
      <ModalBody className="text-center d-flex flex-column align-items-center px-5">
        <H1 className="py-2 assessment-modal__header">
          {YourResponsesSubmitted}
        </H1>
        <H2 className="py-2 assessment-modal__exam">
          {Assessment}:{" "}
          <span className="py-2 assessment-modal__exam-name">{examName}</span>
        </H2>
        <P className="py-2">{ExamResponseText}</P>
        <Btn className="text-center mt-4" color="primary" onClick={handleModal}>
          {Done}
        </Btn>
      </ModalBody>
    </Modal>
  );
};

ExamResponseModal.propTypes = {
  examName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
};

export default ExamResponseModal;
