import React, { useState } from "react";
import { Row, Col, Container, Tooltip } from "reactstrap";

import "./style.scss";
import { P } from "../../AbstractElements";
import { sanitizeString } from "../../utils/helper/helper";

interface Detail {
  label: string;
  value: string;
  key: string;
}

interface GenericModuleCardProps {
  details: Detail[];
}

const GenericModuleCard: React.FC<GenericModuleCardProps> = ({ details }) => {
  const [tooltipOpen, setTooltipOpen] = useState(null);

  const toggleTooltip = (id: string) => {
    setTooltipOpen(tooltipOpen === id ? null : id);
  };
  const truncatedHeader = header => {
    return header?.length > 14 ? `${header?.substring(0, 14)}...` : header;
  };

  return (
    <Container className="generic-module-card p-2">
      {details?.map(
        (detail, index) =>
          index % 2 === 0 && (
            <Row key={detail.key} className="mt-2 generic-module-card-margin">
              <>
                <Col xs="6">
                  <P>
                    {detail.label ? `${detail.label}:` : ""}
                    <span id={`tool_tip${sanitizeString(detail.key)}`}>
                      {truncatedHeader(detail.value)}
                    </span>
                    <Tooltip
                      placement="top"
                      isOpen={
                        tooltipOpen === `tool_tip${sanitizeString(detail.key)}`
                      }
                      target={`tool_tip${sanitizeString(detail.key)}`}
                      toggle={() =>
                        toggleTooltip(`tool_tip${sanitizeString(detail.key)}`)
                      }
                    >
                      {detail.value}
                    </Tooltip>
                  </P>
                </Col>
                {index + 1 < details.length && (
                  <Col xs="6">
                    <P>
                      {details[index + 1].label
                        ? `${details[index + 1].label}:`
                        : ""}

                      <span
                        id={`tool_tip${sanitizeString(details[index + 1].key)}`}
                      >
                        {truncatedHeader(details[index + 1].value)}
                      </span>
                      <Tooltip
                        placement="top"
                        isOpen={
                          tooltipOpen ===
                          `tool_tip${sanitizeString(details[index + 1].key)}`
                        }
                        target={`tool_tip${sanitizeString(details[index + 1].key)}`}
                        toggle={() =>
                          toggleTooltip(
                            `tool_tip${sanitizeString(details[index + 1].key)}`
                          )
                        }
                      >
                        {details[index + 1].value}
                      </Tooltip>
                    </P>
                  </Col>
                )}
              </>
            </Row>
          )
      )}
    </Container>
  );
};

export default GenericModuleCard;
