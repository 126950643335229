import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input } from "reactstrap";

import {
  Add,
  Description,
  DescriptionLimitationMessage,
  DescriptionValidationMessage,
  Enter,
  IsRequired,
  LimitValidationMessage,
  New,
  PleaseSelect,
} from "../../utils/Constant";
import LabelTooltip from "../LabelTooltip";
import CommonModal from "../Modal";
import MultiSelectDropdown, {
  MultiSelectDropdownDataItem,
} from "../MultiSelectDropdown";

interface MasterDataAddNewModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSave: (
    title: string,
    description: string,
    selectedOption?: string,
    selectedMultiSelectOptions?: any[]
  ) => void;
  labelText: string;
  placeholderText: string;
  toolTipText: string;
  initialValues?: {
    name: string;
    description: string;
    selectedOption?: string;
  };
  dropdownLabel?: string;
  dropdownLabelTooltip?: string;
  dropdownOptions?: { id: number; label: string; value: string }[];
  selectedDropdownValue?: string;
  onDropdownChange?: (value: string) => void;
  multiSelectOptions?: MultiSelectDropdownDataItem[];
  selectedMultiSelectValues?: MultiSelectDropdownDataItem[];
  multiSelectPlaceholder?: string;
  onMultiSelectChange?: (selectedIds: string[]) => void;
  multiSelectDropdownLabel?: string;
  multiSelectDropdownLabelTooltip?: string;
}

const MasterDataAddNewModal: React.FC<MasterDataAddNewModalProps> = ({
  isOpen,
  toggle,
  onSave,
  labelText,
  toolTipText,
  placeholderText,
  initialValues,
  dropdownLabel,
  dropdownLabelTooltip,
  dropdownOptions = [],
  selectedDropdownValue,
  onDropdownChange,
  multiSelectOptions = [],
  selectedMultiSelectValues = [],
  multiSelectPlaceholder = "",
  onMultiSelectChange,
  multiSelectDropdownLabel,
  multiSelectDropdownLabelTooltip,
}) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedMultiSelectOptions, setSelectedMultiSelectOptions] = useState<
    MultiSelectDropdownDataItem[]
  >(selectedMultiSelectValues || []);
  const [errors, setErrors] = useState<{
    title?: string;
    description?: string;
    selectedOption?: string;
    selectedMultiSelect?: string;
  }>({});

  useEffect(() => {
    if (initialValues) {
      setTitle(initialValues.name || "");
      setDescription(initialValues.description || "");
      setSelectedOption(
        initialValues.selectedOption || selectedDropdownValue || ""
      );
      setSelectedMultiSelectOptions(selectedMultiSelectValues || []);
    } else {
      setTitle("");
      setDescription("");
      setSelectedOption(selectedDropdownValue || "");
      setSelectedMultiSelectOptions([]);
    }
  }, [initialValues, isOpen]);

  const validateForm = () => {
    const validationErrors: {
      title?: string;
      description?: string;
      selectedOption?: string;
      selectedMultiSelect?: string;
    } = {};

    if (!title) {
      validationErrors.title = `${labelText} ${IsRequired}`;
    } else if (title.length > 100) {
      validationErrors.title = `${labelText} ${LimitValidationMessage}`;
    }

    if (dropdownLabel && !selectedOption) {
      validationErrors.selectedOption = `${PleaseSelect} ${dropdownLabel}`;
    }
    if (multiSelectDropdownLabel && selectedMultiSelectOptions?.length <= 0) {
      validationErrors.selectedMultiSelect = `${PleaseSelect} ${multiSelectDropdownLabel}`;
    }

    if (!description) {
      validationErrors.description = DescriptionValidationMessage;
    } else if (description.length > 255) {
      validationErrors.description = DescriptionLimitationMessage;
    }

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      onSave(title, description, selectedOption, selectedMultiSelectOptions);
      toggle();
    }
  };

  const getMasterDataAddNewModalBody = () => (
    <Form>
      {dropdownLabel && dropdownOptions.length > 0 && (
        <FormGroup>
          <LabelTooltip
            label={dropdownLabel}
            tooltipText={dropdownLabelTooltip}
            important
            placement={"bottom"}
          />
          <Input
            type="select"
            id="dropdown"
            value={selectedOption}
            onChange={e => {
              const value = e.target.value;
              setSelectedOption(value);
              onDropdownChange?.(value);
            }}
          >
            {dropdownOptions.map(option => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          {errors.selectedOption && (
            <div className="text-danger">{errors.selectedOption}</div>
          )}
        </FormGroup>
      )}

      {multiSelectDropdownLabel && multiSelectOptions.length > 0 && (
        <FormGroup>
          <LabelTooltip
            label={multiSelectDropdownLabel}
            tooltipText={multiSelectDropdownLabelTooltip}
            important
            placement="bottom"
          />
          <MultiSelectDropdown
            options={multiSelectOptions}
            placeholder={multiSelectPlaceholder}
            onChange={selectedIds => {
              onMultiSelectChange?.(selectedIds);
              const selectedItems = multiSelectOptions.filter(option =>
                selectedIds.includes(option.name)
              );
              setSelectedMultiSelectOptions(selectedItems);
            }}
            defaultSelected={selectedMultiSelectOptions}
            closeButton={true}
          />
          {errors.selectedMultiSelect && (
            <div className="text-danger">{errors.selectedMultiSelect}</div>
          )}
        </FormGroup>
      )}

      <FormGroup>
        <LabelTooltip
          label={labelText}
          tooltipText={toolTipText}
          important
          placement={"bottom"}
        />
        <Input
          type="text"
          id="title"
          placeholder={placeholderText}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        {errors.title && <div className="text-danger">{errors.title}</div>}
      </FormGroup>

      <FormGroup>
        <LabelTooltip
          label={`${Enter} ${Description}`}
          tooltipText={`${Enter} ${Description}`}
          important
          placement={"bottom"}
        />
        <Input
          type="textarea"
          id="description"
          placeholder={`${Enter} ${Description}`}
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        {errors.description && (
          <div className="text-danger">{errors.description}</div>
        )}
      </FormGroup>
    </Form>
  );

  return (
    <CommonModal
      backdrop="static"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      sizeTitle={`${Add} ${New} ${labelText}`}
      showFooter={true}
      primaryBtnText="Save"
      onPrimaryBtnClick={handleSave}
    >
      {getMasterDataAddNewModalBody()}
    </CommonModal>
  );
};

export default MasterDataAddNewModal;
