import PropTypes from "prop-types";

import { P } from "../../AbstractElements";

import "./style.scss";

const CourseActivity = ({ name, getStatus }) => {
  return (
    <div className="course-activity">
      <div className="course-activity__course d-flex align-items-center justify-content-between">
        <P className="m-0">{name}</P>
        <P className="m-0">{getStatus()}</P>
      </div>
    </div>
  );
};

CourseActivity.propTypes = {
  name: PropTypes.string,
  getStatus: PropTypes.func.isRequired,
};

export default CourseActivity;
