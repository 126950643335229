import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { Btn, MUIIcons, H4, H2, P } from "../../../../../AbstractElements";
import {
  CONTENT_APPROVED_SME,
  CONTENT_SUBMITTED,
  PUBLISHED,
  SCHEDULED_FOR_PUBLISH,
} from "../../../../../Api/constants";
import DetailHeader from "../../../../../CommonElements/DetailHeader";
import Divider from "../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../CommonElements/Modal";
import SliderModal from "../../../../../CommonElements/SliderModal";
import Comments from "../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  addCommentToStages,
  getCourseContent,
  reviewCourse,
  updateCommentStatus,
} from "../../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  AddComment,
  Approve,
  Comment,
  Competency,
  ConfirmPublish,
  Enter,
  EnterComments,
  PleaseAddComment,
  PublishConfirmation,
  PublishNow,
  Reject,
  RejectReason,
  Resources,
  Submit,
} from "../../../../../utils/Constant";
import {
  getCurrentDate,
  showToast,
  sortCommentsByDate,
} from "../../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../../utils/helper/permission";

const WebinarCompetativeStatement = () => {
  const [resource, setResource] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId } = location.state || "";
  const [comments, setComments] = useState(null);
  const [sliderModal, setSliderModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userMap, setUserMap] = useState(null);
  const [iconComments, setIconComments] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseContent } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    dispatch(getCourseContent({ id: courseId }));
  }, [dispatch, courseId]);

  useEffect(() => {
    if (courseContent?.competency_statement) {
      const resource = [];

      const statement = courseContent?.competency_statement;
      statement?.tool_aid?.tool?.forEach((item: any) => {
        resource.push(item?.name);
      });
      setResource(resource?.toString());
    }
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "competency_statement"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    let comment = [];
    if (courseContent?.comment?.competency_statement) {
      comment = sortCommentsByDate(courseContent.comment.competency_statement);
    }
    setCommentList(comment);
  }, [courseContent]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const togglePublishConfirmModal = () => {
    setIsPublishConfirmModalOpen(!isPublishConfirmModalOpen);
  };

  const handleApproveReview = async () => {
    const reasonObj = {
      course_id: courseId,
      status: "approved",
      reason: "",
      is_review: true,
      course_content: "",
    };
    const wrapperObj = {
      courseContent: reasonObj,
    };
    await dispatch(reviewCourse(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const handleSubmit = async () => {
    const formData = {
      course_id: courseId,
      status: "",
      reason: "",
      is_review: false,
      course_content: {
        is_self_enrollment: false,
        bond_amount: 0,
        bond_year: 0,
        course_publish_date: getCurrentDate(),
        curriculum: [],
        course_fee: 0,
        course_status: CONTENT_APPROVED_SME,
        is_publish_now: true,
      },
    };

    const wrapperObj = {
      courseContent: formData,
    };

    await dispatch(reviewCourse(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const handleRejectReview = async () => {
    if (comments === "" || comments === null) {
      showToast(PleaseAddComment, "error");
      return;
    }
    const reasonObj = {
      course_id: courseId,
      status: "rejected",
      reason: comments,
      is_review: true,
      course_content: "",
    };
    const wrapperObj = {
      courseContent: reasonObj,
    };
    await dispatch(reviewCourse(wrapperObj));
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const sliderToggle = async () => {
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "competency_statement",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "competency_statement",
        comment: iconComments,
      },
    };

    await dispatch(addCommentToStages({ comment: payload }));
    await dispatch(getCourseContent({ id: courseId }));
    setIconComments("");
  };

  return (
    <div className="page-body pb-5">
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isPublishConfirmModalOpen}
        toggle={togglePublishConfirmModal}
        sizeTitle={ConfirmPublish}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={() => {
          togglePublishConfirmModal();
          handleSubmit();
        }}
      >
        <P>{PublishConfirmation}</P>
      </CommonModal>
      <CommonModal
        backdrop="static"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggleModal}
        sizeTitle={RejectReason}
        showFooter={true}
        primaryBtnText={Submit}
        onPrimaryBtnClick={handleRejectReview}
      >
        <FormGroup>
          <LabelTooltip
            label={AddComment}
            tooltipText={`${Enter} ${Comment}`}
            important
          />
          <Input
            onChange={e => {
              if (
                (e.target.value && e.target.value?.length <= 255) ||
                e.target.value === ""
              )
                setComments(e.target.value);
            }}
            rows={4}
            name="objective"
            type="textarea"
            placeholder={`${Enter} ${Comment}`}
          />
        </FormGroup>
      </CommonModal>
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{Comment}</H2>
        <Divider />
        <Comments
          userMap={userMap}
          text={iconComments}
          comments={commentList}
          send
          onSendComments={onSendComments}
          onCommentsChange={onCommentsChange}
        />
      </SliderModal>
      <Card className="pb-5">
        <CardHeader className="d-flex justify-content-between">
          <H4 className="mt-2">{Competency}</H4>
          <div className="comment-icon-container">
            <MUIIcons
              size={24}
              onClick={sliderToggle}
              className="primary-icon-color mt-2 pointer"
              iconName="ChatBubbleOutlineOutlined"
            />
            {isComentRead != null && isComentRead && (
              <span className="red-dot"></span>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <DetailHeader name={resource} assign={Resources} />
        </CardBody>
      </Card>
      <FixedFooter>
        {hasPermissionToComponent("APPROVE_STAGES") &&
          courseContent?.status !== PUBLISHED &&
          courseContent?.status !== SCHEDULED_FOR_PUBLISH &&
          (courseContent?.status === CONTENT_SUBMITTED ||
            (hasPermissionToComponent("PUBLISH_CONTENT") &&
              courseContent?.status === CONTENT_APPROVED_SME)) && (
            <Col xs="auto">
              <Btn
                onClick={() => {
                  toggleModal();
                }}
                outline
                className="alert-light-primary"
                color="primary"
              >
                {Reject}
              </Btn>
            </Col>
          )}
        {hasPermissionToComponent("PUBLISH_CONTENT") &&
          courseContent?.status !== PUBLISHED &&
          courseContent?.status !== SCHEDULED_FOR_PUBLISH &&
          (courseContent?.status === CONTENT_SUBMITTED ||
            courseContent?.status === CONTENT_APPROVED_SME) && (
            <Row>
              <Col xs="auto">
                <Btn onClick={togglePublishConfirmModal} color="primary">
                  {PublishNow}
                </Btn>
              </Col>
            </Row>
          )}
        {!hasPermissionToComponent("PUBLISH_CONTENT") &&
          hasPermissionToComponent("APPROVE_STAGES") &&
          courseContent?.status !== PUBLISHED &&
          courseContent?.status !== SCHEDULED_FOR_PUBLISH &&
          courseContent?.status === CONTENT_SUBMITTED && (
            <Row>
              <Col xs="auto">
                <Btn onClick={handleApproveReview} color="primary">
                  {Approve}
                </Btn>
              </Col>
            </Row>
          )}
      </FixedFooter>
    </div>
  );
};

export default WebinarCompetativeStatement;
