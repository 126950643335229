import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

import { P, Progressbar } from "../../../AbstractElements";
import AnswerStatusIndicator from "../../../CommonElements/AnswerStatusIndicator";
import AssessmentTimerCard from "../../../container/AssessmentTimer";
import "./style.scss";
import QuestionNavigator from "../../../container/QuestionNavigator";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setQuestionIndex } from "../../../ReduxToolkit/Reducers/AssessmentSlice";
import { Answered, InProgress, Questions } from "../../../utils/Constant";

const AssessmentInfo = ({ selectedData, time }) => {
  const { questions, totalQuestions } = useAppSelector(
    state => state.assessment
  );
  const dispatch = useAppDispatch();

  const getQuestionType = response => {
    if (!response) {
      return 1;
    }

    const hasSelectedOption =
      response.selected_option_id && response.selected_option_id?.length > 0;
    const hasAnswerText =
      response.answer_text && response.answer_text?.trim() !== "";

    if (hasSelectedOption || hasAnswerText) {
      return 3;
    }
    return 1;
  };

  const compareQuestionsAndValues = (questions, responses) => {
    return questions?.map((question, index) => {
      const response = responses?.find(
        resp => resp.question_id === question.question_id
      );

      const type = getQuestionType(response);

      return {
        number: index + 1,
        type,
      };
    });
  };

  const result = compareQuestionsAndValues(questions?.questions, selectedData);
  const answeredCount = result?.filter(item => item.type === 3).length;
  const percentage = (answeredCount / totalQuestions) * 100;

  const handleClick = item => {
    dispatch(setQuestionIndex(item.number - 1));
  };

  return (
    <Card>
      <CardBody>
        <div className="timer-card">
          <AssessmentTimerCard time={time} />
          <div className="d-flex align-items-baseline gap-2">
            <P>
              {answeredCount}/{totalQuestions}
            </P>
            <Progressbar
              color="dark"
              value={percentage}
              style={{ height: "8px" }}
              className="w-100"
            />
          </div>
          <div className="d-flex gap-4">
            <AnswerStatusIndicator color="dark" text={Answered} />
            <AnswerStatusIndicator color="white" text={InProgress} />
          </div>
          <div className="mt-3">
            <QuestionNavigator
              handleClick={handleClick}
              moduleName={Questions}
              questions={result}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

AssessmentInfo.propTypes = {
  selectedData: PropTypes.arrayOf(
    PropTypes.shape({
      question_id: PropTypes.number.isRequired,
      selected_option_id: PropTypes.number,
      selected_option_ids: PropTypes.arrayOf(PropTypes.number),
      answer_text: PropTypes.string,
    })
  ).isRequired,
  time: PropTypes.object,
};

export default AssessmentInfo;
