import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

import { H4, P } from "../../../../../AbstractElements";
import SimpleAccordion from "../../../../../CommonElements/Accordion";
import Divider from "../../../../../CommonElements/Divider";
import FileHandler from "../../../../../CommonElements/FileHandler";
import StatusDisplay from "../../../../../CommonElements/StatusDisplay";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import { getLearnerAndRmResponse } from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  Competent,
  LearnerResponses,
  NotCompetent,
  PartialCompetent,
  Questions,
  RMResponses,
} from "../../../../../utils/Constant";
import { competencyAnswersMap } from "../../../../../utils/helper/helper";

const CompetencyAssessmentDetails = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { participantId, preview } = location.state || "";
  const { learnerAndRMResponse } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [accordionData, setAccordionData] = useState([]);
  const [responseCount, setResponseCount] = useState({
    competent: 0,
    not_competent: 0,
    partial_competent: 0,
  });

  useEffect(() => {
    setAccordionData([]);
    if (participantId) {
      dispatch(getLearnerAndRmResponse({ participant_id: participantId }));
    }
  }, [participantId]);

  useEffect(() => {
    if (learnerAndRMResponse) {
      const modules = {};
      const response = {
        competent: 0,
        not_competent: 0,
        partial_competent: 0,
      };
      learnerAndRMResponse?.responses?.forEach(item => {
        if (!modules[item?.module_id]) {
          modules[item?.module_id] = {
            id: item?.module_id,
            title: item?.module_name,
            questions: [item],
          };
        } else {
          modules[item?.module_id].questions.push(item);
        }
        response[item?.reviewer_response] =
          response[item?.reviewer_response] + 1;
      });

      const accordionData = Object.values(modules).map((module: any) => ({
        id: module?.id,
        accordionItemClass: "item-class",
        accordionHeaderClass: "header-class",
        spanClass: "span-class",
        accordionHeading: module.title,
        icon: true,
        bodyText: renderModule(module),
      }));
      setResponseCount(response);
      setAccordionData(accordionData);
    }
  }, [learnerAndRMResponse, participantId]);

  const renderModule = module => {
    return (
      <div className="mt-3">
        <Card className="generic-module-card">
          <CardBody className="m-2">
            <Row>
              <Col lg={!preview ? 7 : 5}>
                <P className="fw-bold">{Questions}</P>
              </Col>
              <Col>
                <P className="fw-bold">{LearnerResponses}</P>
              </Col>
              {preview && (
                <Col>
                  <P className="fw-bold">{RMResponses}</P>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
        {module?.questions?.map((question, index) => {
          return (
            <div className="m-2 mt-3" key={index}>
              <Row>
                <Col lg={!preview ? 6 : 4}>{question?.question_text}</Col>
                <Col lg={1}>
                  {question?.media_url ? (
                    <FileHandler showIcon mediaUrl={question?.media_url} />
                  ) : null}
                </Col>
                <Col className="d-flex gap-3">
                  <Label>
                    {competencyAnswersMap[question?.learner_response]}
                  </Label>
                </Col>
                <Col className="d-flex gap-3">
                  <Label>
                    {competencyAnswersMap[question?.reviewer_response]}
                  </Label>
                </Col>
              </Row>
              <Divider />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="page-body mb-5 pb-5">
      <Card>
        <CardBody className="d-flex justify-content-between m-2">
          <H4>{learnerAndRMResponse?.name}</H4>
          <div className="d-flex gap-5">
            <StatusDisplay
              label={Competent}
              number={responseCount?.competent?.toString()}
            />
            <StatusDisplay
              label={NotCompetent}
              number={responseCount?.not_competent?.toString()}
            />
            <StatusDisplay
              label={PartialCompetent}
              number={responseCount?.partial_competent?.toString()}
            />
          </div>
        </CardBody>
      </Card>
      {accordionData && <SimpleAccordion accordionList={accordionData} />}
    </div>
  );
};

export default CompetencyAssessmentDetails;
