import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

import { H4, P } from "../../../../../AbstractElements";
import { questionBankTemplteUrl } from "../../../../../Api";
import LabelTooltip from "../../../../../CommonElements/LabelTooltip";
import MultiFileUpload from "../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import {
  BulkUploadNote,
  ClickHereToAddFile,
  Download,
  QBQuestionBulkUploadFileToolTipMessage,
  UploadFile,
  UseThisTemplate,
} from "../../../../../utils/Constant";

const BulkUpload = ({ onFileChange, flushData }) => {
  return (
    <div>
      <Card>
        <CardBody className="m-2 mt-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
              <div className="choose-option">
                <H4 className="title">{UploadFile}</H4>
              </div>
            </div>
          </div>
          <div className="d-flex gap-4">
            <P>{UseThisTemplate}</P>
            <a href={questionBankTemplteUrl} download>
              <P className="primary-text-color">{Download}</P>
            </a>
          </div>
          <LabelTooltip
            label={ClickHereToAddFile}
            tooltipText={QBQuestionBulkUploadFileToolTipMessage}
            important={false}
          />
          <div
            className="primary-text-color"
            dangerouslySetInnerHTML={{ __html: BulkUploadNote }}
          />
          <MultiFileUpload
            flushData={flushData}
            accept=".csv"
            multiple={false}
            onFileChange={file => {
              onFileChange(file);
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

BulkUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  flushData: PropTypes.bool,
};

export default BulkUpload;
