import PropTypes from "prop-types";
import { useState } from "react";
import {
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

interface ItemProps {
  item: string;
  onClick: () => void;
}

interface ButtonDropdownProps {
  items: ItemProps[];
  btnText: string;
  direction: "up" | "down" | "start" | "end";
}

const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  items,
  btnText,
  direction,
}) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <ButtonGroup className="bg-primary">
      <Dropdown isOpen={open} toggle={toggle} direction={direction}>
        <DropdownToggle
          caret
          color="bg-primary"
          className="text-white bg-primary"
        >
          {btnText}
        </DropdownToggle>
        <DropdownMenu className="dropdown-block">
          {items.map((data, index) => (
            <DropdownItem onClick={data.onClick} key={index}>
              {data.item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  );
};

ButtonDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  btnText: PropTypes.string.isRequired,
  direction: PropTypes.any.isRequired,
};

export default ButtonDropdown;
