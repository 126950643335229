import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Row,
  Tooltip,
} from "reactstrap";

import {
  Badges,
  Btn,
  MUIIcons,
  H3,
  H2,
  DefaultButton,
  SecondaryButton,
} from "../../../../../../AbstractElements";
import { ALL_USERS_STATUS, PUBLISHED } from "../../../../../../Api/constants";
import Divider from "../../../../../../CommonElements/Divider";
import FixedFooter from "../../../../../../CommonElements/FixedFooter";
import LabelTooltip from "../../../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../../../CommonElements/Modal";
import MultiFileUpload from "../../../../../../CommonElements/MultiFileUpload/MultiFileUpload";
import SliderModal from "../../../../../../CommonElements/SliderModal";
import AssessmentTable from "../../../../../../container/AssessmentTable/AssessmentTable";
import Comments from "../../../../../../container/Comments";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../ReduxToolkit/Hooks";
import { getAssessmentCatlogList } from "../../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  createAssessment,
  deleteAssessment,
  getAssessment,
  updateAssessment,
} from "../../../../../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  addCommentToStages,
  getCourseContent,
  updateCommentStatus,
} from "../../../../../../ReduxToolkit/Reducers/CourseSlice";
import { updateLearningOutcome } from "../../../../../../ReduxToolkit/Reducers/CourseStagesSlice";
import {
  getPolicyList,
  getQuestionBankList,
} from "../../../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  getUsersList,
  uploadFileApi,
} from "../../../../../../ReduxToolkit/Reducers/UserSlice";
import {
  AddAnyOfAssessments,
  AddDocument,
  AddLearnerDocument,
  AddNew,
  AddTrainerDocument,
  AddValidNumberInRange250,
  AddValidNumberInRange500,
  Assessment,
  AssessmentType,
  AssessmentTypeChange,
  AssessmentTypeToolTipMessage,
  Comment,
  Confirm,
  CreateAssessments,
  DeleteAssessment,
  DeleteAssessmentHeader,
  Document,
  Done,
  EditAssessment,
  EnterComments,
  FileSizeNote,
  Knowledge,
  KnowledgeAssessmentType,
  LearnerDocument,
  LearningOutcomes,
  MaximumCharacters,
  MinimumSummativeAssessmentError,
  OnlyOneSummativeAssessment,
  PleaseAddAssessmentName,
  PleaseProvideRequiredInformation,
  PleaseSelectPolicy,
  PleaseSelectQuestionBank,
  PleaseSelectSkillType,
  Save,
  SaveAsDraft,
  Select,
  SelectAssessment,
  SelectAssessmentType,
  SelectLo,
  SelectLO,
  Skill,
  SkillType,
  SkillTypeError,
  SkillTypeHeader,
  SkillTypeToolTipMessage,
  TrainerDocument,
  Upload,
  UploadLearnerDocToolTipMessage,
  UploadTrainerDocToolTipMessage,
} from "../../../../../../utils/Constant";
import {
  assessmentTypesOptions,
  handleAllFilesDownload,
  knowledgeHeader,
  knowledgeOptions,
  sanitizeString,
  showToast,
  skillHeader,
  skillOptions,
  sortCommentsByDate,
} from "../../../../../../utils/helper/helper";
import useIsMobile from "../../../../../../utils/helper/responsive";

const CreateAssessment: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isComentRead, setIsComentRead] = useState(null);
  const { courseId } = location.state || "";
  const [sliderModal, setSliderModal] = useState(false);
  const [iconComments, setIconComments] = useState(null);
  const [modalContent, setModalContent] = useState<
    "comments" | "CreateAssessment" | "EditAssessment"
  >("comments");
  const [userMap, setUserMap] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const { moduleList } = useAppSelector(state => state.master);
  const [isSkillAssessmentAvailable, setIsSkillAssessmentAvailable] =
    useState(false);
  const [learnerDocument, setLearnerDocument] = useState([]);
  const [trainerDocument, setTrainerDocument] = useState([]);
  const [ojtDocument, setOjtDocument] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const { courseContent } = useAppSelector(state => state.course);
  const { courseAssessments } = useAppSelector(state => state.assessment);
  const [selectedLOs, setSelectedLOs] = useState([]);
  const [selectedLoIdMap, setSelectedLoIdMap] = useState({});
  const { usersList } = useAppSelector(state => state.user);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [learningOutcomesMap, setLearningOutcomesMap] = useState([]);
  const [knowledgeAssessments, setKnowledgeAssessments] = useState([]);
  const [skillAssessments, setSkillAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [summative, setSummative] = useState(0);
  const [existingIds, setExistingIds] = useState([]);
  const [existingLos, setExistingLos] = useState({
    ojt: [],
    field: [],
  });
  const [assesmentCatlog, setAssesmentCatlog] = useState([]);
  const { assessmentCatlogList } = useAppSelector(
    state => state.assessmentCatlog
  );
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);
  const [formData, setFormData] = useState<any>({
    assessment_subtype: "knowledge",
    question_bank_id: "",
    modules: [],
    name: "",
    type: "field",
    overall_question_count: "",
    assessment_id: "",
    estimated_time: "",
    policy_id: "",
    skill_document: {},
  });
  const [errors, setErrors] = useState({
    assessment_subtype: "",
    question_bank_id: "",
    modules: "",
    name: "",
    type: "",
    assessment_id: "",
    overall_question_count: "",
    estimated_time: "",
    policy_id: "",
    skill_document: "",
    lo: "",
  });

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    setSelectedAssessment(null);
    setSelectedAssessmentType(null);
    dispatch(getCourseContent({ id: courseId }));
    dispatch(getQuestionBankList());
    dispatch(getPolicyList());
  }, [dispatch, courseId]);

  useEffect(() => {
    const list = [{ label: Select + " " + Assessment, value: "" }];
    if (assessmentCatlogList?.assessments) {
      assessmentCatlogList?.assessments?.forEach(item => {
        list.push({ label: item?.name, value: item?.id?.toString() });
      });
    }
    setAssesmentCatlog(list);
  }, [assessmentCatlogList]);

  useEffect(() => {
    dispatch(
      getAssessmentCatlogList({
        status: PUBLISHED,
        assessment_type: ["course"],
        tag: "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAssessment({ id: courseId }));
  }, [dispatch, courseId]);

  const toggleTooltip = (index: string) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    let comment = [];
    const outcomes = [];
    const outcomesMap = [];
    if (courseContent?.comment?.assessment) {
      comment = sortCommentsByDate(courseContent.comment.assessment);
    }

    if (courseContent && courseContent?.learning_outcome) {
      courseContent?.learning_outcome.forEach((out: any) => {
        outcomes.push({
          name: out.id.toString(),
          value: out.outcome,
          label: out.outcome,
        });
        outcomesMap.push({
          name: out.id.toString(),
          value: out.outcome,
          label: out.outcome,
          competency_id: out?.competency_id,
          domain_id: out?.domain_id,
          function: out?.function,
          proficiency_level_id: out?.proficiency_level_id,
        });
      });
    }
    setLearningOutcomesMap(outcomesMap);
    setLearningOutcomes(outcomes);
    setCommentList(comment);
    if (courseContent?.comment?.comment_status) {
      try {
        const infoStage = courseContent?.comment?.comment_status?.find(
          (stage: { stages: string }) => stage.stages === "assessment"
        );
        setIsComentRead(infoStage ? !infoStage.is_read : false);
      } catch (error) {
        setIsComentRead(false);
      }
    }
  }, [courseContent]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const knowledgeAssessments = [];
    const skillAssessments = [];
    const loMap = {};
    const loIdMap = {};
    const loId2Map = {};

    courseContent?.assessment_learning_outcome?.forEach((items: any) => {
      const assessmentId = items.assessment_id;
      if (loMap[assessmentId]) {
        loMap[assessmentId].push(items?.outcome);
      } else {
        loMap[assessmentId] = [items?.outcome];
      }

      loIdMap[assessmentId] = items?.id?.toString();
      loId2Map[items?.assessment_id] = items?.id?.toString();
    });

    let summativeCount = 0;
    const existingId = [];
    const existingLo = {
      ojt: [],
      field: [],
    };
    if (
      courseAssessments?.assessments?.knowledge &&
      courseContent?.assessment_learning_outcome
    ) {
      courseAssessments?.assessments?.knowledge?.forEach((item: any) => {
        existingId.push(item?.assessment_id?.toString());
        if (item?.type === "summative") {
          summativeCount = summativeCount + 1;
        }
        const selectedModules = [];
        item?.modules?.forEach(module => {
          selectedModules.push(module?.module_id);
        });

        knowledgeAssessments.push({
          question_bank_id: item?.question_bank?.question_bank_id,
          question_bank_name: item?.question_bank?.name,
          course_assessment_id: item?.course_assessment_id,
          assessment_id: item?.assessment_id?.toString(),
          skillName: item?.name,
          lo: loMap[item?.course_assessment_id] || [],
          type: item?.type,
          questions: item?.overall_question_count,
          modules: item?.modules?.length,
          selected_module: selectedModules,
          assessmentDuration: item?.estimated_time,
          actions: "",
        });
      });
    }

    if (
      courseAssessments?.assessments?.skill &&
      courseContent?.assessment_learning_outcome
    ) {
      setIsSkillAssessmentAvailable(true);
      courseAssessments?.assessments?.skill?.forEach((item: any) => {
        existingLo[item?.type].push(loIdMap[item?.course_assessment_id]);

        skillAssessments.push({
          course_assessment_id: item?.course_assessment_id,
          skillName: item?.name,
          lo: loMap[item?.course_assessment_id] || [],
          type: item?.type,
          learnerDocs:
            item?.skill_document?.ojt_skill?.length > 0
              ? item?.skill_document?.ojt_skill || []
              : item?.skill_document?.field_skill?.learner_url || [],
          trainerDocs:
            item?.skill_document?.ojt_skill?.length > 0
              ? []
              : item?.skill_document?.field_skill?.trainer_url || [],
          actions: "",
        });
      });
    }
    setSelectedLoIdMap(loId2Map);
    setExistingLos(existingLo);
    setExistingIds(existingId);
    setSummative(summativeCount);
    setSkillAssessments(skillAssessments);
    setKnowledgeAssessments(knowledgeAssessments);
  }, [courseAssessments, courseContent]);

  useEffect(() => {
    if (moduleList) {
      const modules = [];
      moduleList?.modules?.forEach(element => {
        modules.push({ value: element.id, label: element.module_name });
      });
      setFormData({ ...formData, modules });
    }
  }, [moduleList]);

  const sliderToggle = async () => {
    if (sliderModal) {
      setSelectedAssessment(null);
      setSelectedAssessmentType(null);
      setDefaultData();
      setModalContent("comments");
    }
    setSliderModal(!sliderModal);
    await dispatch(
      updateCommentStatus({
        correspondingStatus: {
          component_name: "assessment",
          course_id: courseId,
        },
      })
    );
    setIsComentRead(false);
  };

  const onCommentsChange = e => {
    if (
      (e.target.value && e.target.value?.length <= 255) ||
      e.target.value === ""
    )
      setIconComments(e.target.value);
  };

  const setDefaultData = () => {
    setLearnerDocument([]);
    setTrainerDocument([]);
    setOjtDocument([]);
    setSelectedLOs([]);
    setFormData({
      assessment_subtype: "knowledge",
      question_bank_id: "",
      modules: [],
      name: "",
      type: "field",
      assessment_id: "",
      overall_question_count: "",
      estimated_time: "",
      policy_id: "",
      skill_document: {},
    });
    setErrors({
      assessment_subtype: "",
      question_bank_id: "",
      modules: "",
      name: "",
      type: "",
      overall_question_count: "",
      assessment_id: "",
      estimated_time: "",
      policy_id: "",
      skill_document: "",
      lo: "",
    });
  };

  const onSendComments = async () => {
    if (iconComments === "") {
      showToast(EnterComments, "error");
      return;
    }

    const payload = {
      course_id: courseId,
      is_comment: true,
      comment_data: {
        entity_type: "assessment",
        comment: iconComments,
      },
    };
    await dispatch(addCommentToStages({ comment: payload }));
    setIconComments("");
    await dispatch(getCourseContent({ id: courseId }));
  };

  const validateField = (name: string, value: any) => {
    let error = "";

    switch (name) {
      case "name":
        if (value === "") {
          error = PleaseAddAssessmentName;
        } else if (value.length > 100) {
          error = MaximumCharacters;
        }
        break;

      case "overall_question_count":
        if (
          !/^\d*$/.test(value) ||
          (value && (parseInt(value) < 1 || parseInt(value) > 250))
        ) {
          error = AddValidNumberInRange250;
        }
        break;

      case "estimated_time":
        if (
          !/^\d*$/.test(value) ||
          (value && (parseInt(value) < 1 || parseInt(value) > 500))
        ) {
          error = AddValidNumberInRange500;
        }
        break;

      case "question_bank_id":
        if (!value) {
          error = PleaseSelectQuestionBank;
        }
        break;

      case "policy_id":
        if (!value) {
          error = PleaseSelectPolicy;
        }
        break;

      case "skill_type":
        if (!value) {
          error = PleaseSelectSkillType;
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setErrors({
      assessment_subtype: "",
      question_bank_id: "",
      modules: "",
      name: "",
      type: "",
      overall_question_count: "",
      estimated_time: "",
      assessment_id: "",
      policy_id: "",
      skill_document: "",
      lo: "",
    });
    const { name, value } = e.target;

    const error = validateField(name, value);

    if ((name === "name" && value === "") || error === "") {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    let isError = false;
    const error = {
      assessment_subtype: "",
      question_bank_id: "",
      modules: "",
      name: "",
      type: "",
      overall_question_count: "",
      assessment_id: "",
      estimated_time: "",
      policy_id: "",
      skill_document: "",
      lo: "",
    };

    let requiredFields = [];
    if (formData?.assessment_subtype === "knowledge") {
      requiredFields = [
        {
          field: "type",
          value: formData?.type,
          message: SelectAssessmentType,
        },
        {
          field: "assessment_id",
          value: formData?.assessment_id,
          message: SelectAssessment,
        },
      ];
    } else {
      requiredFields = [
        {
          field: "type",
          value: formData?.type,
          message: SelectAssessmentType,
        },
        {
          field: "skill_document",
          value: formData?.skill_document,
          message: AddTrainerDocument,
        },
      ];
    }

    requiredFields.forEach(field => {
      if (!field.value || field.value === null) {
        isError = true;
        error[field.field] = field.message;
      }
      if (
        formData?.assessment_subtype !== "knowledge" &&
        (selectedLOs.length === 0 || selectedLOs[0]?.length === 0)
      ) {
        isError = true;
        error["lo"] = SelectLO;
      }
    });

    if (
      formData?.assessment_subtype === "knowledge" &&
      formData?.type === "field"
    ) {
      isError = true;
      error["type"] = SelectAssessmentType;
    }

    const isKnowledgeSummative = (formData: any) => {
      return (
        formData?.assessment_subtype === "knowledge" &&
        formData?.type === "summative"
      );
    };

    const hasExistingSummativeAssessment = (courseAssessments: any) => {
      return courseAssessments?.assessments?.knowledge?.some((item: any) => {
        return (
          item?.course_assessment_id?.toString() !==
            formData?.course_assessment_id?.toString() &&
          item?.type === "summative"
        );
      });
    };

    if (isKnowledgeSummative(formData)) {
      if (modalContent !== "EditAssessment" && summative > 0) {
        showToast(OnlyOneSummativeAssessment, "error");
        return;
      }

      if (
        modalContent === "EditAssessment" &&
        hasExistingSummativeAssessment(courseAssessments)
      ) {
        showToast(OnlyOneSummativeAssessment, "error");
        return;
      }
    }

    if (isError) {
      showToast(PleaseProvideRequiredInformation, "error");
      setErrors(error);
      return;
    }

    if (formData?.assessment_subtype === "skill") {
      if (formData?.type === null) {
        showToast(SkillTypeError, "error");
        return;
      }
      if (
        formData?.type !== "ojt" &&
        learnerDocument.length === 0 &&
        (!formData?.skill_document?.field_skill?.learner_url ||
          formData?.skill_document?.field_skill?.learner_url?.length === 0)
      ) {
        showToast(AddLearnerDocument, "error");
        return;
      }
      if (
        formData?.type !== "ojt" &&
        trainerDocument.length === 0 &&
        (!formData?.skill_document?.field_skill?.trainer_url ||
          formData?.skill_document?.field_skill?.trainer_url?.length === 0)
      ) {
        showToast(AddTrainerDocument, "error");
        return;
      }
      if (
        formData?.type === "ojt" &&
        ojtDocument.length === 0 &&
        (!formData?.skill_document?.ojt_skill ||
          formData?.skill_document?.ojt_skill?.length === 0)
      ) {
        showToast(AddDocument, "error");
        return;
      }
      let allfiles = [];
      if (learnerDocument?.length) {
        allfiles = [...allfiles, ...learnerDocument];
      }
      if (trainerDocument?.length) {
        allfiles = [...allfiles, ...trainerDocument];
      }
      if (ojtDocument?.length) {
        allfiles = [...allfiles, ...ojtDocument];
      }

      let files = [];
      if (allfiles?.length > 0) files = await uploadFile(allfiles);
      const trainer = [];
      const learner = [];
      const ojt = [];
      if (files?.length > 0 && formData?.type === "ojt") {
        files.forEach(fileUrl => {
          const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
          const fileExtension = fileName.substring(
            fileName.lastIndexOf(".") + 1
          );
          ojt.push({
            file_name: fileName,
            file_type: fileExtension,
            file_url: fileUrl,
            file_category: "learner_document",
          });
        });
      }
      if (files?.length > 0 && formData?.type !== "ojt") {
        files.forEach((fileUrl, index) => {
          if (index < learnerDocument.length) {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
            const fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            learner.push({
              file_name: fileName,
              file_type: fileExtension,
              file_url: fileUrl,
              file_category: "learner_document",
            });
          }
          if (index >= learnerDocument.length && index < files.length) {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
            const fileExtension = fileName.substring(
              fileName.lastIndexOf(".") + 1
            );
            trainer.push({
              file_name: fileName,
              file_type: fileExtension,
              file_url: fileUrl,
              file_category: "trainer_document",
            });
          }
        });
      }

      if (formData?.type === "ojt") {
        const defaultJsonDocument = {
          ojt_skill: ojt,
        };
        if (isSkillAssessmentAvailable && formData?.skill_document) {
          const ojtSkillFromFormData = Array.isArray(
            formData.skill_document?.ojt_skill
          )
            ? formData.skill_document.ojt_skill
            : [];

          const ojtSkillFromDefaultJson = Array.isArray(
            defaultJsonDocument?.ojt_skill
          )
            ? defaultJsonDocument.ojt_skill
            : [];

          const mergedJsonDocument = {
            ojt_skill: [...ojtSkillFromFormData, ...ojtSkillFromDefaultJson],
          };

          formData.skill_document = mergedJsonDocument;
        } else {
          formData.skill_document = defaultJsonDocument;
        }
      } else {
        const defaultJsonDocument = {
          field_skill: { learner_url: learner, trainer_url: trainer },
        };
        if (formData?.skill_document && isSkillAssessmentAvailable) {
          const mergedJsonDocument = {
            field_skill: {
              learner_url: [
                ...(formData?.skill_document?.field_skill?.learner_url || []),
                ...(defaultJsonDocument.field_skill.learner_url || []),
              ],
              trainer_url: [
                ...(formData?.skill_document?.field_skill?.trainer_url || []),
                ...(defaultJsonDocument?.field_skill.trainer_url || []),
              ],
            },
          };
          formData.skill_document = mergedJsonDocument;
        } else {
          formData.skill_document = defaultJsonDocument;
        }
      }
    }

    let existingId = null;
    if (formData?.assessment_subtype === "knowledge") {
      existingId = courseAssessments?.assessments?.knowledge?.[
        selectedAssessment
      ]
        ? courseAssessments.assessments.knowledge[selectedAssessment]
            ?.course_assessment_id
        : null;
    } else {
      existingId = courseAssessments?.assessments?.skill?.[selectedAssessment]
        ? courseAssessments.assessments.skill[selectedAssessment]
            .course_assessment_id
        : null;
    }

    let assessmentData: any = {};
    if (formData?.assessment_subtype === "knowledge") {
      assessmentData = {
        course_id: courseId,
        status: "draft",
        assessment_subtype: formData?.assessment_subtype,
        type: formData?.type,
        is_document_upload: false,
        course_assessment_id: existingId || null,
        assessment_id: formData?.assessment_id,
        skill_document: {},
      };
    }
    if (formData?.assessment_subtype === "skill") {
      assessmentData = {
        course_id: courseId,
        course_assessment_id: existingId || null,
        status: "draft",
        assessment_subtype: formData?.assessment_subtype,
        question_bank_id: null,
        modules: null,
        name: formData?.name,
        type: formData?.type,
        is_document_upload: true,
        overall_question_count: null,
        estimated_time: null,
        policy_id: null,
        skill_document: formData.skill_document,
      };
    }

    let data: any = {};
    if (selectedAssessment !== "" && selectedAssessment !== null) {
      data = await dispatch(updateAssessment({ assessmentData }));
    } else {
      data = await dispatch(createAssessment({ assessmentData }));
    }

    const selected = [];
    selectedLOs?.forEach(item => {
      selected.push(item?.name);
    });

    const loData = [];
    const added = {};
    const updatedId = data?.payload?.id || existingId;
    courseContent?.assessment_learning_outcome?.forEach((item: any) => {
      const assessmentId = item?.assessment_id;

      if (updatedId !== assessmentId) {
        if (!added[assessmentId] || added[assessmentId].length === 0) {
          added[assessmentId] = [item?.id?.toString()];
        } else {
          added[assessmentId].push(item?.id?.toString());
        }

        loData.push({
          assessment_id: assessmentId,
          outcome: item?.outcome,
          function: item?.function,
          learning_outcome_id: item?.id,
        });
      }
    });

    learningOutcomesMap?.forEach(item => {
      if (
        selected.includes(item?.name) &&
        !added[updatedId]?.includes(item?.name) &&
        updatedId
      ) {
        loData.push({
          assessment_id: updatedId,
          outcome: item?.label,
          function: item?.function,
          learning_outcome_id: item?.name,
        });
      }
    });

    const loMap = {
      course_id: courseId,
      is_assessment: true,
      facilitator_information: "",
      learning_outcome: loData,
    };
    if (formData?.assessment_subtype !== "knowledge")
      await dispatch(updateLearningOutcome({ learningOutcome: loMap }));
    sliderToggle();
    setDefaultData();
    await dispatch(getAssessment({ id: courseId }));
    await dispatch(getCourseContent({ id: courseId }));
  };

  const getKnowledgeAssessments = () => {
    return (
      <FormGroup>
        <LabelTooltip
          label={KnowledgeAssessmentType}
          tooltipText={`${Select} ${KnowledgeAssessmentType}`}
          important
        />
        <Input
          onChange={handleChange}
          name="type"
          type="select"
          value={formData?.type}
          invalid={!!errors.type}
          disabled={modalContent === "EditAssessment"}
        >
          {knowledgeOptions?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
        {errors.type && <div className="invalid-feedback">{errors.type}</div>}

        <div className="mt-3"></div>
        <LabelTooltip
          label={Assessment}
          tooltipText={`${Select} ${Assessment}`}
          important
        />
        <Input
          onChange={handleChange}
          name="assessment_id"
          type="select"
          value={formData?.assessment_id}
          invalid={!!errors.assessment_id}
        >
          {assesmentCatlog
            ?.filter(item => {
              if (modalContent !== "EditAssessment")
                return !existingIds.includes(item?.value);
              else if (modalContent === "EditAssessment") {
                return (
                  !existingIds.includes(item?.value) ||
                  item?.value?.toString() ===
                    formData?.assessment_id?.toString()
                );
              }
            })
            ?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </Input>
        {errors.assessment_id && (
          <div className="invalid-feedback">{errors.assessment_id}</div>
        )}
      </FormGroup>
    );
  };

  const changeSkillType = () => {
    const data = { ...formData };
    if (data.type === "field") {
      data.type = "ojt";
    } else if (data.type === "ojt") {
      data.type = "field";
    }
    data.skill_document = {};
    setFormData(data);
  };

  const getSkillAssessments = () => {
    return (
      <>
        <FormGroup>
          <LabelTooltip
            label={SkillType}
            tooltipText={SkillTypeToolTipMessage}
            important
          />
          <Input
            value={formData?.type}
            name="type"
            type="select"
            invalid={!!errors.type}
            onChange={() => {
              if (modalContent === "EditAssessment") {
                toggleTypeModal();
              } else {
                changeSkillType();
              }
            }}
            disabled={modalContent === "EditAssessment"}
          >
            {skillOptions?.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          {errors.type && <div className="invalid-feedback">{errors.type}</div>}
        </FormGroup>
        <Col>
          <FormGroup>
            <LabelTooltip
              label={LearningOutcomes}
              tooltipText={`${Select} ${LearningOutcomes}`}
              important
            />
            <Input
              value={selectedLOs?.length > 0 ? selectedLOs[0]?.value : ""}
              name="type"
              type="select"
              invalid={!!errors.lo}
              onChange={e => {
                const selected = learningOutcomes?.filter(item => {
                  return item?.value === e.target.value;
                });
                setSelectedLOs(selected);
              }}
            >
              <option key={"0"} value={""}>
                {SelectLo}
              </option>
              {learningOutcomes
                ?.filter(item => {
                  if (modalContent !== "EditAssessment")
                    return !existingLos[formData?.type].includes(item?.name);
                  else if (modalContent === "EditAssessment") {
                    return (
                      !existingLos[formData?.type].includes(item?.name) ||
                      item?.name?.toString() ===
                        selectedLoIdMap[
                          formData?.course_assessment_id
                        ]?.toString()
                    );
                  }
                })
                ?.map(option => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
            </Input>
            {errors.lo && <div className="invalid-feedback">{errors.lo}</div>}
          </FormGroup>
        </Col>

        {formData?.type === "ojt" && (
          <>
            <LabelTooltip
              label={`${Upload} ${Document}`}
              tooltipText={UploadLearnerDocToolTipMessage}
              important
            />
            {formData?.skill_document?.ojt_skill?.map((item, index) => {
              const uniqueIds = `${index}-${index + index}-${index + index + index}`;
              return (
                <H3 key={index} className="mt-2">
                  <Badges color="light text-dark">
                    <MUIIcons size={24} iconName="InsertDriveFileOutlined" />
                    <span
                      className="m-1"
                      id={sanitizeString(`tooltip-course-name${uniqueIds}`)}
                    >
                      {item?.file_name?.length > 36
                        ? `${item?.file_name?.substring(0, 36)}...`
                        : item?.file_name}
                    </span>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[uniqueIds] || false}
                      target={sanitizeString(`tooltip-course-name${uniqueIds}`)}
                      toggle={() => toggleTooltip(uniqueIds)}
                    >
                      {item?.file_name}
                    </Tooltip>
                    <MUIIcons
                      iconName="DeleteOutlineOutlined"
                      className="primary-icon-color pointer"
                      size={24}
                      onClick={() => {
                        const data = {
                          ...formData,
                          skill_document: {
                            ...formData.skill_document,
                            ojt_skill: [...formData.skill_document.ojt_skill],
                          },
                        };
                        data.skill_document.ojt_skill.splice(index, 1);
                        setFormData(data);
                      }}
                    />
                  </Badges>
                </H3>
              );
            })}
            {(!formData?.skill_document?.ojt_skill ||
              formData?.skill_document?.ojt_skill?.length === 0) && (
              <MultiFileUpload
                multiple={false}
                note={FileSizeNote}
                onFileChange={(file, status) => {
                  if (status === "removed") {
                    setOjtDocument(prevDocuments =>
                      prevDocuments.filter(item => item.name !== file.name)
                    );
                  } else if (status === "done" && file) {
                    setOjtDocument(prevDocuments => [...prevDocuments, file]);
                  }
                }}
              />
            )}
          </>
        )}

        {formData?.type !== "ojt" && (
          <div>
            <div className="mb-3">
              <LabelTooltip
                label={`${Upload} ${LearnerDocument}`}
                tooltipText={UploadLearnerDocToolTipMessage}
                important
              />
              {formData?.skill_document?.field_skill?.learner_url?.map(
                (item, index) => {
                  const uniqueId = `${index}-${index + index}-${item?.file_name}`;
                  return (
                    <H3 key={index} className="mt-2">
                      <Badges color="light text-dark">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span
                          className="m-1"
                          id={sanitizeString(`tooltip-course-name${uniqueId}`)}
                        >
                          {item?.file_name?.length > 36
                            ? `${item?.file_name?.substring(0, 36)}...`
                            : item?.file_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[uniqueId] || false}
                          target={sanitizeString(
                            `tooltip-course-name${uniqueId}`
                          )}
                          toggle={() => toggleTooltip(uniqueId)}
                        >
                          {item?.file_name}
                        </Tooltip>
                        <MUIIcons
                          iconName="DeleteOutlineOutlined"
                          className="primary-icon-color pointer"
                          size={24}
                          onClick={() => {
                            const data = {
                              ...formData,
                              skill_document: {
                                ...formData.skill_document,
                                field_skill: {
                                  ...formData.skill_document.field_skill,
                                  learner_url: [
                                    ...(formData.skill_document.field_skill
                                      ?.learner_url || []),
                                  ],
                                },
                              },
                            };
                            data.skill_document.field_skill.learner_url.splice(
                              index,
                              1
                            );
                            setFormData(data);
                          }}
                        />
                      </Badges>
                    </H3>
                  );
                }
              )}
              {(!formData?.skill_document?.field_skill?.learner_url ||
                formData?.skill_document?.field_skill?.learner_url?.length ===
                  0) && (
                <MultiFileUpload
                  multiple={false}
                  note={FileSizeNote}
                  onFileChange={(file, status) => {
                    if (status === "removed") {
                      setLearnerDocument(prevDocuments =>
                        prevDocuments.filter(item => item.name !== file.name)
                      );
                    } else if (status === "done" && file) {
                      setLearnerDocument(prevDocuments => [
                        ...prevDocuments,
                        file,
                      ]);
                    }
                  }}
                />
              )}
            </div>
            <Divider />
            <div className="mt-4 mb-2">
              <LabelTooltip
                label={`${Upload} ${TrainerDocument}`}
                tooltipText={UploadTrainerDocToolTipMessage}
                important
              />
              {formData?.skill_document?.field_skill?.trainer_url?.map(
                (item, index) => {
                  const uniqueId2 = `${index + index}-${index}-${index + index + index}`;
                  return (
                    <H3 key={index} className="mt-2">
                      <Badges color="light text-dark">
                        <MUIIcons
                          size={24}
                          iconName="InsertDriveFileOutlined"
                        />
                        <span
                          className="m-1"
                          id={sanitizeString(`tooltip-course-name${uniqueId2}`)}
                        >
                          {item?.file_name?.length > 36
                            ? `${item?.file_name?.substring(0, 36)}...`
                            : item?.file_name}
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[uniqueId2] || false}
                          target={sanitizeString(
                            `tooltip-course-name${uniqueId2}`
                          )}
                          toggle={() => toggleTooltip(uniqueId2)}
                        >
                          {item?.file_name}
                        </Tooltip>
                        <MUIIcons
                          iconName="DeleteOutlineOutlined"
                          className="primary-icon-color pointer"
                          size={24}
                          onClick={() => {
                            const data = {
                              ...formData,
                              skill_document: {
                                ...formData.skill_document,
                                field_skill: {
                                  ...formData.skill_document.field_skill,
                                  trainer_url: [
                                    ...(formData.skill_document.field_skill
                                      ?.trainer_url || []),
                                  ],
                                },
                              },
                            };
                            data.skill_document.field_skill.trainer_url.splice(
                              index,
                              1
                            );
                            setFormData(data);
                          }}
                        />
                      </Badges>
                    </H3>
                  );
                }
              )}
              {(!formData?.skill_document?.field_skill?.trainer_url ||
                formData?.skill_document?.field_skill?.trainer_url?.length ===
                  0) && (
                <MultiFileUpload
                  multiple={false}
                  note={FileSizeNote}
                  onFileChange={(file, status) => {
                    if (status === "removed") {
                      setTrainerDocument(prevDocuments =>
                        prevDocuments.filter(item => item.name !== file.name)
                      );
                    } else if (status === "done" && file) {
                      setTrainerDocument(prevDocuments => [
                        ...prevDocuments,
                        file,
                      ]);
                    }
                  }}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const onClickItem = async (value, type) => {
    setSelectedAssessmentType(type);
    if (value === "item3") {
      const docs = [];
      const data = { ...courseAssessments };
      if (
        data?.assessments?.skill[selectedAssessment]?.skill_document?.ojt_skill
      )
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.ojt_skill?.map((item: any) => {
          docs.push(item?.file_url);
        });

      if (
        data?.assessments?.skill[selectedAssessment]?.skill_document
          ?.field_skill
      ) {
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.field_skill?.learner_url?.map((item: any) => {
          docs.push(item?.file_url);
        });
        data?.assessments?.skill[
          selectedAssessment
        ]?.skill_document?.field_skill?.trainer_url?.map((item: any) => {
          docs.push(item?.file_url);
        });
      }
      handleAllFilesDownload(docs);
    }
    if (value === "item2") {
      toggleModal();
    }
    if (value === "item1" && type === "Knowledge") {
      const modules = [];
      courseAssessments?.assessments?.knowledge[
        selectedAssessment
      ]?.modules?.forEach(module => {
        modules.push({
          value: module?.module_id,
          label: module?.module_name,
          weightage: module?.weightage,
        });
      });

      const editData: any = {
        assessment_id:
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            ?.assessment_id,
        course_assessment_id:
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            ?.course_assessment_id,

        assessment_subtype:
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            ?.assessment_subtype,
        question_bank_id:
          courseAssessments?.assessments?.knowledge[
            selectedAssessment
          ]?.question_bank?.question_bank_id?.toString(),
        modules: modules,
        name: courseAssessments?.assessments?.knowledge[selectedAssessment]
          ?.name,
        type: courseAssessments?.assessments?.knowledge[selectedAssessment]
          ?.type,
        overall_question_count:
          courseAssessments?.assessments?.knowledge[
            selectedAssessment
          ]?.overall_question_count?.toString(),
        estimated_time:
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            ?.estimated_time,
        policy_id:
          courseAssessments?.assessments?.knowledge[
            selectedAssessment
          ]?.policy?.policy_id?.toString(),
        skill_document: {},
      };
      const outcomes = [];
      courseContent?.assessment_learning_outcome?.forEach((out: any) => {
        if (
          courseAssessments?.assessments?.knowledge[selectedAssessment]
            .course_assessment_id === out?.assessment_id
        ) {
          outcomes.push({
            name: out.id.toString(),
            value: out.outcome,
            label: out.outcome,
          });
        }
      });
      setSelectedLOs(outcomes);
      setFormData(editData);
      setModalContent("EditAssessment");
      sliderToggle();
    } else if (value === "item1" && type === "Skill") {
      const editData: any = {
        assessment_id:
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.assessment_id,
        course_assessment_id:
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.course_assessment_id,

        assessment_subtype:
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.assessment_subtype,
        question_bank_id: null,
        modules: null,
        name: courseAssessments?.assessments?.skill[selectedAssessment]?.name,
        type: courseAssessments?.assessments?.skill[selectedAssessment]?.type,
        overall_question_count: null,
        estimated_time: null,
        policy_id: null,
        skill_document:
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.skill_document,
      };
      const outcomes = [];
      courseContent?.assessment_learning_outcome?.forEach((out: any) => {
        if (
          courseAssessments?.assessments?.skill[selectedAssessment]
            ?.course_assessment_id === out?.assessment_id
        ) {
          outcomes.push({
            name: out.id.toString(),
            value: out.outcome,
            label: out.outcome,
          });
        }
      });
      setSelectedLOs(outcomes);
      setFormData(editData);
      setModalContent("EditAssessment");
      sliderToggle();
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleTypeModal = () => {
    setIsTypeModalOpen(!isTypeModalOpen);
  };

  const updateAssessmentStatus = async type => {
    if (type === "completed") {
      if (
        (!courseAssessments?.assessments?.knowledge ||
          courseAssessments?.assessments?.knowledge?.length === 0) &&
        (!courseAssessments?.assessments?.skill ||
          courseAssessments?.assessments?.skill?.length === 0)
      ) {
        showToast(AddAnyOfAssessments, "error");
        return;
      }

      const summativeExist = courseAssessments?.assessments?.knowledge?.some(
        item => item?.type === "summative"
      );
      if (!summativeExist) {
        showToast(MinimumSummativeAssessmentError, "error");
        return;
      }

      const assessmentData = {
        course_id: courseId,
        is_completed: true,
      };
      await dispatch(updateAssessment({ assessmentData }));
    }
    return navigate(
      `${process.env.PUBLIC_URL}/course-management/course-details?courseId=${courseId}`
    );
  };

  const getModalHeading = () => {
    switch (modalContent) {
      case "CreateAssessment":
        return CreateAssessments;
      case "EditAssessment":
        return EditAssessment;
      case "comments":
      default:
        return Comment;
    }
  };

  return (
    <div className={!isMobile && "page-body pb-5"}>
      <CommonModal
        sizeTitle={SkillTypeHeader}
        modalBodyClassName=""
        isOpen={isTypeModalOpen}
        toggle={toggleTypeModal}
        backdrop="static"
        size="lg"
        showFooter
        primaryBtnText={Confirm}
        onPrimaryBtnClick={async () => {
          changeSkillType();
          toggleTypeModal();
        }}
      >
        {AssessmentTypeChange}
      </CommonModal>
      <CommonModal
        sizeTitle={DeleteAssessmentHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        primaryBtnText={Confirm}
        onPrimaryBtnClick={async () => {
          const deleteObj = {
            deleteAssessmentData: {
              course_assessment_id:
                selectedAssessmentType === "Knowledge"
                  ? knowledgeAssessments[selectedAssessment]
                      ?.course_assessment_id
                  : skillAssessments[selectedAssessment]?.course_assessment_id,
            },
          };
          await dispatch(deleteAssessment(deleteObj));
          await dispatch(getAssessment({ id: courseId }));
          setSelectedAssessment(null);
          setSelectedAssessmentType(null);
          toggleModal();
        }}
      >
        {DeleteAssessment}
      </CommonModal>
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <H2>{getModalHeading()}</H2>
        <Divider />
        {modalContent === "comments" ? (
          <Comments
            userMap={userMap}
            text={iconComments}
            comments={commentList}
            send
            onSendComments={onSendComments}
            onCommentsChange={onCommentsChange}
          />
        ) : (
          <div
            style={{ height: "80vh", overflowY: "auto", overflowX: "hidden" }}
          >
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={AssessmentType}
                  tooltipText={AssessmentTypeToolTipMessage}
                  important
                />
                <Input
                  disabled={selectedAssessment !== null}
                  name="assessment_subtype"
                  type="select"
                  value={formData?.assessment_subtype}
                  onChange={handleChange}
                >
                  {assessmentTypesOptions?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            {formData?.assessment_subtype === "knowledge" &&
              getKnowledgeAssessments()}
            {formData?.assessment_subtype === "skill" && getSkillAssessments()}
          </div>
        )}
        <div
          onClick={e => {
            handleSubmit(e);
          }}
          className="d-flex justify-content-end mt-3"
        >
          <Btn color="primary">{Done}</Btn>
        </div>
      </SliderModal>
      <Card className={isMobile && "custom-mobile-card"}>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="d-flex gap-4">
              <div className="comment-icon-container">
                <MUIIcons
                  size={24}
                  onClick={() => {
                    setModalContent("comments");
                    sliderToggle();
                  }}
                  className="primary-icon-color mt-2 pointer"
                  iconName="ChatBubbleOutlineOutlined"
                />
                {isComentRead != null && isComentRead && (
                  <span className="red-dot"></span>
                )}
              </div>
              <Btn
                onClick={() => {
                  setSelectedAssessment(null);
                  setSelectedAssessmentType(null);
                  setModalContent("CreateAssessment");
                  setSliderModal(true);
                }}
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
              >
                {AddNew}
              </Btn>
            </div>
          </div>
        </CardBody>
      </Card>
      <div>
        <AssessmentTable
          title={Knowledge}
          data={knowledgeAssessments}
          headers={knowledgeHeader}
          onClickItem={onClickItem}
          setSelectedAssessment={setSelectedAssessment}
          courseId={courseId}
        />
      </div>
      <div>
        <AssessmentTable
          title={Skill}
          data={skillAssessments}
          headers={skillHeader}
          onClickItem={onClickItem}
          setSelectedAssessment={setSelectedAssessment}
          courseId={courseId}
        />
      </div>
      <FixedFooter>
        <SecondaryButton onClick={() => updateAssessmentStatus("draft")}>
          {SaveAsDraft}
        </SecondaryButton>

        <Row>
          <Col xs="auto">
            <DefaultButton
              onClick={() => updateAssessmentStatus("completed")}
              color="primary"
            >
              {Save}
            </DefaultButton>
          </Col>
        </Row>
      </FixedFooter>
    </div>
  );
};
export default CreateAssessment;
