import PropTypes from "prop-types";
import React from "react";
import { Input } from "reactstrap";

import {
  AnswerChoice,
  Enter,
  QBAnswerChoiceToolTipMessage,
} from "../../utils/Constant";
import LabelTooltip from "../LabelTooltip";
import "./style.scss";
import MUIIcons from "../MUIIcon/MUIIcons";

type ChooseOptionsProps = {
  index: number;
  value: any;
  onChange: (index: number, newValue: string) => void;
  onDelete: (index: number) => void;
};

const ChooseOptions: React.FC<ChooseOptionsProps> = ({
  index,
  value,
  onChange,
  onDelete,
}) => {
  return (
    <div className="options-container">
      <div className="option-item d-flex align-items-end mb-3">
        <div className="input-container d-flex flex-column flex-grow-1">
          <LabelTooltip
            label={AnswerChoice}
            tooltipText={QBAnswerChoiceToolTipMessage}
            important
          />
          <Input
            type="text"
            className="option-input"
            placeholder={`${Enter} ${AnswerChoice}`}
            value={value}
            onChange={e => onChange(index, e.target.value)}
            maxLength={100}
          />
        </div>
        <MUIIcons
          className="delete-icon ml-3 mb-2 pointer"
          size={24}
          iconName="HighlightOffOutlined"
          onClick={() => onDelete(index)}
        />
      </div>
    </div>
  );
};

ChooseOptions.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ChooseOptions;
