import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { DefaultButton } from "../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import ParticipantAttendanceTable from "../../../../CommonElements/ParticipantAttendanceTable";
import NoData from "../../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getAttendanceForSchedule,
  markAttendanceForSchedule,
} from "../../../../ReduxToolkit/Reducers/AttendanceSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  MarkAttendanceError,
  NotYetAdded,
  Participant,
  Save,
  Trainer,
} from "../../../../utils/Constant";
import { showToast } from "../../../../utils/helper/helper";
import {
  currentUserId,
  hasPermissionToComponent,
} from "../../../../utils/helper/permission";

const AttendanceList = ({
  scheduledId,
  primaryTrainerId,
  replacedTrainerId,
}) => {
  const dispatch = useAppDispatch();
  const [userMap, setUserMap] = useState(null);
  const { usersList } = useAppSelector(state => state.user);
  const { attendanceList } = useAppSelector(state => state.attendance);
  const [datesMap, setDatesMap] = useState([]);
  const [participantMap, setParticipantMap] = useState({});
  const [trainerMap, setTrainerMap] = useState({});
  const [attendance, setAttendance] = useState({});

  useEffect(() => {
    if (scheduledId) {
      dispatch(getAttendanceForSchedule(scheduledId));
    }
  }, [dispatch, scheduledId]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const datesObj = [];
    const userObj = {};
    const participantObj = {};

    if (attendanceList) {
      attendanceList?.trainer_attendance?.forEach(item => {
        if (!datesObj.includes(item?.date)) {
          datesObj.push(item?.date);
        }

        if (!userObj[item?.attendee_id]) {
          userObj[item?.attendee_id] = { sessions: {} };
        }

        if (!userObj[item?.attendee_id].sessions[item?.date]) {
          userObj[item?.attendee_id].sessions[item?.date] = {};
        }

        userObj[item?.attendee_id].sessions[item?.date][item?.session_number] =
          {
            is_present: item?.is_present,
            id: item?.id,
            attendee_id: item?.attendee_id,
          };
      });

      attendanceList?.participant_attendance?.forEach(item => {
        if (!participantObj[item?.attendee_id]) {
          participantObj[item?.attendee_id] = { sessions: {} };
        }

        if (!participantObj[item?.attendee_id].sessions[item?.date]) {
          participantObj[item?.attendee_id].sessions[item?.date] = {};
        }

        participantObj[item?.attendee_id].sessions[item?.date][
          item?.session_number
        ] = {
          is_present: item?.is_present,
          id: item?.id,
          attendee_id: item?.attendee_id,
        };
      });
    }

    setDatesMap(datesObj);
    setParticipantMap(participantObj);
    setTrainerMap(userObj);
  }, [attendanceList]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  const onHandleChange = (id, attendee_id, payload, isTrainer) => {
    const updatedAttendance = { ...attendance };
    updatedAttendance[id] = payload;
    setAttendance(updatedAttendance);

    const updatedMap = isTrainer ? { ...trainerMap } : { ...participantMap };
    updatedMap[attendee_id]["sessions"][payload.date][payload.session_number][
      "is_present"
    ] = payload.is_present;

    isTrainer ? setTrainerMap(updatedMap) : setParticipantMap(updatedMap);
  };

  const onSave = async () => {
    if (Object.keys(attendance)?.length === 0) {
      showToast(MarkAttendanceError, "error");
      return;
    }
    const payload = {
      attendance: [],
    };

    Object.keys(attendance)?.forEach(item => {
      payload.attendance.push(attendance[item]);
    });
    await dispatch(markAttendanceForSchedule(payload));
    await dispatch(getAttendanceForSchedule(scheduledId));
  };

  return (
    <div className="mt-3">
      {hasPermissionToComponent("MARK_TRAINER_ATTENDANCE") && (
        <ParticipantAttendanceTable
          participants={trainerMap}
          dates={datesMap}
          userMap={userMap}
          title={Trainer}
          onHandleChange={onHandleChange}
          isTrainer={true}
          mark={true}
          replacedTrainerId={replacedTrainerId}
        />
      )}

      {(hasPermissionToComponent("MARK_TRAINER_ATTENDANCE") ||
        currentUserId() === primaryTrainerId ||
        replacedTrainerId?.includes(currentUserId())) && (
        <ParticipantAttendanceTable
          participants={participantMap}
          dates={datesMap}
          userMap={userMap}
          title={Participant}
          onHandleChange={onHandleChange}
          isTrainer={false}
          mark={
            currentUserId() === primaryTrainerId ||
            replacedTrainerId?.includes(currentUserId())
          }
        />
      )}
      {hasPermissionToComponent("MARK_TRAINER_ATTENDANCE") ||
      currentUserId() === primaryTrainerId ||
      replacedTrainerId?.includes(currentUserId()) ? (
        <FixedFooter>
          <DefaultButton color="primary" onClick={onSave}>
            {Save}
          </DefaultButton>
        </FixedFooter>
      ) : (
        <div className="p-5">
          <NoData
            showCard={false}
            showIcon={false}
            svg="empty-folder-icon"
            title={NotYetAdded}
          />
        </div>
      )}
    </div>
  );
};

AttendanceList.propTypes = {
  scheduledId: PropTypes.number.isRequired,
  primaryTrainerId: PropTypes.any,
  replacedTrainerId: PropTypes.any,
};

export default AttendanceList;
