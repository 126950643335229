import React from "react";
import { Badge } from "reactstrap";

import { H5, P } from "../../AbstractElements";
import MUIIcons from "../../CommonElements/MUIIcon/MUIIcons";
import { EmployeeId, Mobile, Replacing, WaitList } from "../../utils/Constant";

export interface UserDetails {
  name: string;
  user_name?: string;
  empId: string;
  mobile: string;
  id: number;
  info?: any;
}

interface UserDetailsCardProps {
  userDetails: UserDetails;
  badge?: boolean;
  waitlist?: boolean;
  info?: boolean;
  report?: boolean;
  onInfoClick?: (userDetails: UserDetails) => void;
  onReportClick?: (id: number) => void;
  className?: string;
  showUserName?: boolean;
}

const UserDetailsCard: React.FC<UserDetailsCardProps> = ({
  userDetails,
  info,
  report,
  badge,
  waitlist,
  onInfoClick,
  onReportClick,
  className,
  showUserName = false,
}) => {
  return (
    <div className={`d-flex gap-2 flex-column ${className}`}>
      <div className="d-flex align-items-center">
        {!showUserName && (
          <P className="fw-bold text-black">
            {userDetails.name}
            {badge && (
              <Badge className="text-black mx-2 replacement-card-badge">
                {Replacing}
              </Badge>
            )}
          </P>
        )}
        {showUserName && (
          <div className="d-flex gap-2">
            <div className="fw-bold text-black">{userDetails.user_name}</div>
            {info && (
              <MUIIcons
                iconName="InfoOutlined"
                size={18}
                onClick={() => onInfoClick(userDetails?.info)}
                className="pointer ms-2"
              />
            )}
            {report && (
              <MUIIcons
                className="pointer"
                iconName="ReportProblemOutlined"
                size={20}
                onClick={() => onReportClick(userDetails.id)}
              />
            )}
            {waitlist && (
              <Badge className="text-black mx-2 replacement-card-badge">
                {WaitList}
              </Badge>
            )}
          </div>
        )}
      </div>
      {userDetails.empId && userDetails.mobile && (
        <H5>
          {EmployeeId}: {userDetails.empId} | {Mobile}: {userDetails.mobile}
        </H5>
      )}
    </div>
  );
};

export default UserDetailsCard;
