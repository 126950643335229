import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Badges, MUIIcons, H4, P, ToolTip } from "../../AbstractElements";
import { ALL_USERS_STATUS } from "../../Api/constants";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import DateRangeDisplay from "../../CommonElements/DateRangeDisplay";
import Divider from "../../CommonElements/Divider";
import CommonModal from "../../CommonElements/Modal";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getModificationRequestDetails } from "../../ReduxToolkit/Reducers/LearningPlanSlice";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Approve,
  CompletedOn,
  Decline,
  ModificationRequestedBy,
  ModifyYear,
  Reason,
  RequestedModification,
  PublishedOn,
  ScheduledOn,
  WBT,
  MarkAsCompleted,
} from "../../utils/Constant";
import { formatCustomDate, truncateText } from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";
import "./style.scss";

export interface LearningPlanCardData {
  courseId: string;
  domainName: string[];
  status: string;
  courseName: string;
  completedOn: string;
  scheduledOn?: string;
  assignedBy: string;
  courseType: string;
  percentage?: string;
  badgeTextColor?: boolean;
  reAttemptCount?: number;
}

interface LearningPlanCardProps {
  data: LearningPlanCardData[];
  onDropdownClick?: (id: string, name: string) => void;
  showDropDown?: boolean;
  handleClick?: (item) => void;
}

const LearningPlanCourseCard: React.FC<LearningPlanCardProps> = ({
  data,
  onDropdownClick,
  showDropDown = false,
  handleClick = () => {
    return null;
  },
}) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { modificationRequestDetail } = useAppSelector(
    state => state.learningPlan
  );
  const [tooltipCourseNameOpen, setTooltipCourseNameOpen] = useState<{
    [key: string]: boolean;
  }>({});
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState(null);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, []);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  const courseNameTooltip = (courseId: string) => {
    setTooltipCourseNameOpen(prevState => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  const toggleActionModal = () => {
    setIsActionModalOpen(!isActionModalOpen);
  };

  const getRequestDetails = async id => {
    await dispatch(getModificationRequestDetails({ id: id }));
    toggleActionModal();
  };

  return (
    <>
      <CommonModal
        sizeTitle={ModificationRequestedBy}
        modalBodyClassName=""
        isOpen={isActionModalOpen}
        toggle={toggleActionModal}
        backdrop="static"
        size="sm"
        showFooter
      >
        <H4 className="mb-2">
          {userMap && userMap[modificationRequestDetail?.created_by]?.user_name}
        </H4>
        <DateRangeDisplay
          startDate={modificationRequestDetail?.from_year}
          endDate={modificationRequestDetail?.to_year}
        />

        <P className="mt-3">
          <div className="text-muted">{Reason}:</div>
          {modificationRequestDetail?.reason}
        </P>
      </CommonModal>

      <Row className="learning-plan-course-card mt-5">
        {data?.map((item: any) => {
          return (
            <Col
              className="learning-plan-course-card__container"
              key={item.courseId}
              sm={12}
              lg={4}
              onClick={() => handleClick(item)}
            >
              <Card
                className={`pointer ${isMobile ? "" : "learningPlan-courseCard"}`}
              >
                <CommonCardHeader
                  courseId={item.courseId}
                  badge={item.status}
                  headClass="card-no-border pb-0"
                  title={item.domainName}
                  status={item.status}
                  badgeTextColor={item.badgeTextColor}
                  reAttemptCount={item.reAttemptCount}
                  showDrop={
                    (item.status === "waiting_rm" &&
                      showDropDown &&
                      hasPermissionToComponent(
                        "USER_MANAGEMENT.APPROVE_MODIFIED_YEAR"
                      )) ||
                    ((!item.status ||
                      item.status === "not_scheduled" ||
                      item.status === "over_due" ||
                      item.status === "overdue") &&
                      showDropDown &&
                      item?.courseType !== WBT &&
                      hasPermissionToComponent(
                        "USER_MANAGEMENT.MODIFY_YEAR_TO_COURSE"
                      )) ||
                    (hasPermissionToComponent(
                      "USER_MANAGEMENT.APPROVE_MODIFIED_YEAR"
                    ) &&
                      item?.courseType === WBT &&
                      item.status !== "completed")
                  }
                  dropVal={
                    item.status === "waiting_rm" &&
                    item?.courseType !== WBT &&
                    hasPermissionToComponent(
                      "USER_MANAGEMENT.APPROVE_MODIFIED_YEAR"
                    )
                      ? [
                          { id: 1, courseId: item.id, name: Approve },
                          { id: 2, courseId: item.id, name: Decline },
                        ]
                      : item?.courseType !== WBT
                        ? [{ id: 1, courseId: item.id, name: ModifyYear }]
                        : [
                            {
                              id: 1,
                              courseId: item.courseId,
                              name: MarkAsCompleted,
                            },
                          ]
                  }
                  onDropClick={(id, name) => {
                    onDropdownClick(id, name);
                  }}
                />
                <CardBody className="card-body d-flex flex-column">
                  <div className="flex-grow-1">
                    <div>
                      <span id={`tooltip-course-name${item.courseId}`}>
                        <H4 className="header-text truncated-header">
                          {truncateText(item.courseName, 65)}
                        </H4>
                      </span>
                      <ToolTip
                        placement="top"
                        target={`tooltip-course-name${item.courseId}`}
                        isOpen={tooltipCourseNameOpen[item.courseId] || false}
                        toggle={() => courseNameTooltip(item.courseId)}
                      >
                        {item.courseName}
                      </ToolTip>
                    </div>
                  </div>
                  {(item.completedOn ||
                    item.scheduledOn ||
                    item.assignedBy ||
                    item.courseType) && (
                    <div className="learningPlan-courseCard-footer">
                      <Divider />
                      <div className="d-flex justify-content-between mt-2">
                        <div className="d-flex gap-2">
                          <MUIIcons
                            className="text-gray mt-1"
                            size={18}
                            iconName="CalendarTodayOutlined"
                          />
                          {item.completedOn && (
                            <P className="text-muted mt-1">
                              {CompletedOn} {formatCustomDate(item.completedOn)}
                            </P>
                          )}
                          {item.scheduledOn && (
                            <P className="text-muted mt-1">
                              {ScheduledOn} {formatCustomDate(item.scheduledOn)}
                            </P>
                          )}
                          {item.published_on && (
                            <P className="text-muted mt-1">
                              {PublishedOn}{" "}
                              {formatCustomDate(item.published_on)}
                            </P>
                          )}
                        </div>
                        <div>
                          {item.status === "waiting_rm" &&
                            showDropDown &&
                            hasPermissionToComponent(
                              "USER_MANAGEMENT.APPROVE_MODIFIED_YEAR"
                            ) && (
                              <Badges
                                className="mt-1 reduced-height-badge"
                                status="modification"
                              >
                                <div className="d-flex align-items-center gap-2">
                                  {RequestedModification}
                                  <MUIIcons
                                    onClick={() => getRequestDetails(item?.id)}
                                    className="text-white mb-1"
                                    size={16}
                                    iconName="InfoOutlined"
                                  />
                                </div>
                              </Badges>
                            )}
                          <Badges className="mt-1">{item.assignedBy}</Badges>
                          <Badges className="mt-1">{item.courseType}</Badges>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default LearningPlanCourseCard;
