import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";

import { H4, Btn, P } from "../../../AbstractElements";
import { convertPolicyDetailsPayload } from "../../../Api/parser/policy";
import LabelTooltip from "../../../CommonElements/LabelTooltip";
import CommonModal from "../../../CommonElements/Modal";
import CheckboxButton from "../../../CommonElements/SwitchButton";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  createReAttemptPolicy,
  setBasicInputFormValue,
  setPolicyInputFormValueToInitials,
  updateReAttemptPolicyList,
} from "../../../ReduxToolkit/Reducers/PolicySilce";
import {
  AddReAttemptPolicy,
  AddValidNumberInRange10,
  AddValidNumberInRange100,
  AddValidNumberInRange99,
  ConfirmPublish,
  CreatePolicy,
  CreateReAttemptPolicyPublishConfirmation,
  Enter,
  EnterScoreReAttempt,
  IsReAttemptPolicy,
  IsReAttemptPolicyToolTipMessage,
  MaxScore,
  MaxScoreLessThanPassingPercentage,
  MaxScoreToolTipMessage,
  MinDaysBetweenReAttemptsToolTipMessage,
  MinScore,
  MinScoreLessThanMax,
  MinScoreToolTipMessage,
  MinimumDaysBetweenReAttempts,
  ModifyPolicy,
  ModifyReAttemptPolicyPublishConfirmation,
  NoOfAttempt,
  NoOfAttemptsToolTipMessage,
  PassingPercentage,
  PassingPercentageGreaterThanMaxScore,
  PassingPercentageToolTipMessage,
  PolicyName,
  PolicyNameIsRequired,
  ReAttemptPolicyNameToolTipMessage,
  Submit,
} from "../../../utils/Constant";
import { handleKeyDownOnNumericWithoutDecimal } from "../../../utils/helper/helper";

const AddPolicy: React.FC = () => {
  const dispatch = useAppDispatch();
  const { basicInputFormValue } = useAppSelector(state => state.policy);
  const location = useLocation();
  const navigate = useNavigate();
  const { policyDetail, edit, source } = location.state || {};
  const [errors, setErrors] = useState({
    name: "",
    no_of_attempt: "",
    cooling_period: "",
    pass_percentage: "",
    max_score: "",
    min_score: "",
  });
  const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    if (edit && policyDetail) {
      const editObj = {
        id: policyDetail.id,
        name: policyDetail.name,
        no_of_attempt: policyDetail.no_of_attempt,
        cooling_period: policyDetail.cooling_period,
        pass_percentage: policyDetail.pass_percentage,
        is_reattempt: policyDetail.is_reattempt,
        max_score: policyDetail.max_score,
        min_score: policyDetail.min_score,
      };
      dispatch(setBasicInputFormValue(editObj));
    }
    return () => {
      dispatch(setPolicyInputFormValueToInitials());
    };
  }, [edit, location, dispatch, policyDetail]);

  const handleCheckboxChange = (e: { target: { checked: boolean } }) => {
    dispatch(
      setBasicInputFormValue({
        ...basicInputFormValue,
        is_reattempt: e.target.checked,
      })
    );
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    dispatch(setBasicInputFormValue({ ...basicInputFormValue, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
  };
  const validateForm = () => {
    const newErrors = initializeErrors();
    let isError = false;

    const addError = (field, message) => {
      newErrors[field] = message;
      isError = true;
    };

    const isValidRange = (value, max, message, field, lengthLimit = null) => {
      if (
        !value ||
        value <= 0 ||
        value > max ||
        (lengthLimit && String(value).length > lengthLimit)
      ) {
        addError(field, message);
      }
    };

    const validateMinMaxScores = (minScore, maxScore) => {
      if (minScore >= maxScore) {
        addError("min_score", MinScoreLessThanMax);
      } else if (maxScore > Number(basicInputFormValue.pass_percentage)) {
        addError("max_score", MaxScoreLessThanPassingPercentage);
      }
    };

    function initializeErrors() {
      return {
        name: "",
        no_of_attempt: "",
        cooling_period: "",
        pass_percentage: "",
        max_score: "",
        min_score: "",
      };
    }

    if (!basicInputFormValue.name) {
      addError("name", PolicyNameIsRequired);
    }

    const passPercentageValue = Number(basicInputFormValue.pass_percentage);
    isValidRange(
      passPercentageValue,
      100,
      AddValidNumberInRange100,
      "pass_percentage",
      3
    );

    if (basicInputFormValue.is_reattempt) {
      const minScoreValue = Number(basicInputFormValue.min_score);
      const maxScoreValue = Number(basicInputFormValue.max_score);
      const noOfAttemptsValue = Number(basicInputFormValue.no_of_attempt);
      const coolingPeriodValue = Number(basicInputFormValue.cooling_period);

      isValidRange(
        minScoreValue,
        100,
        AddValidNumberInRange100,
        "min_score",
        3
      );
      isValidRange(
        maxScoreValue,
        100,
        AddValidNumberInRange100,
        "max_score",
        3
      );
      isValidRange(
        noOfAttemptsValue,
        10,
        AddValidNumberInRange10,
        "no_of_attempt",
        2
      );
      isValidRange(
        coolingPeriodValue,
        99,
        AddValidNumberInRange99,
        "cooling_period",
        2
      );
      validateMinMaxScores(minScoreValue, maxScoreValue);

      if (passPercentageValue <= Number(basicInputFormValue.max_score)) {
        addError("pass_percentage", PassingPercentageGreaterThanMaxScore);
      }
    }

    setErrors(newErrors);
    return isError;
  };

  const handleSubmit = async () => {
    const parser = convertPolicyDetailsPayload(basicInputFormValue);
    if (edit) {
      parser.id = policyDetail.id;
      await dispatch(updateReAttemptPolicyList({ policyContent: parser }));
    } else {
      await dispatch(createReAttemptPolicy({ policyContent: parser }));
    }
    navigate(`${process.env.PUBLIC_URL}/master-data/policy-list`, {
      state: { mFrom: source },
    });
  };

  const togglePublishConfirmModal = () => {
    setIsPublishConfirmModalOpen(!isPublishConfirmModalOpen);
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="mt-4">
        <CardHeader>
          <H4>{AddReAttemptPolicy}</H4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={6}>
              <LabelTooltip
                important
                label={PolicyName}
                tooltipText={ReAttemptPolicyNameToolTipMessage}
              />
              <Input
                type="text"
                name="name"
                value={basicInputFormValue.name}
                onChange={handleChange}
                placeholder={`${Enter} ${PolicyName}`}
                invalid={!!errors.name}
                maxLength={100}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </Col>
            <Col lg={6}>
              <LabelTooltip
                important
                label={PassingPercentage}
                tooltipText={PassingPercentageToolTipMessage}
              />
              <Input
                type="number"
                name="pass_percentage"
                inputMode="numeric"
                value={basicInputFormValue.pass_percentage}
                onChange={handleChange}
                placeholder={`${Enter} ${PassingPercentage}`}
                invalid={!!errors.pass_percentage}
                min={1}
                max={100}
                onKeyDown={handleKeyDownOnNumericWithoutDecimal}
              />
              {errors.pass_percentage && (
                <div className="invalid-feedback">{errors.pass_percentage}</div>
              )}
            </Col>
          </Row>
          <div className="mt-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <LabelTooltip
                  label={IsReAttemptPolicy}
                  tooltipText={IsReAttemptPolicyToolTipMessage}
                  important={false}
                  placement={"bottom"}
                />
              </Col>
              <Col xs="auto">
                <CheckboxButton
                  onChange={handleCheckboxChange}
                  checked={basicInputFormValue.is_reattempt}
                />
              </Col>
            </Row>
          </div>
          {basicInputFormValue.is_reattempt && (
            <Row>
              <h3 className="text-black">
                <LabelTooltip
                  important={false}
                  label={EnterScoreReAttempt}
                  tooltipText={EnterScoreReAttempt}
                />
              </h3>
              <Col lg={4}>
                <Row>
                  <Col>
                    <LabelTooltip
                      important
                      label={MinScore}
                      tooltipText={MinScoreToolTipMessage}
                    />
                    <Input
                      type="number"
                      name="min_score"
                      inputMode="numeric"
                      value={basicInputFormValue.min_score}
                      onChange={handleChange}
                      placeholder={`${Enter} ${MinScore}`}
                      invalid={!!errors.min_score}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      min={0}
                      max={100}
                    />
                    {errors.min_score && (
                      <div className="invalid-feedback">{errors.min_score}</div>
                    )}
                  </Col>
                  <Col>
                    <LabelTooltip
                      important
                      label={MaxScore}
                      tooltipText={MaxScoreToolTipMessage}
                    />
                    <Input
                      type="number"
                      name="max_score"
                      inputMode="numeric"
                      value={basicInputFormValue.max_score}
                      onChange={handleChange}
                      placeholder={`${Enter} ${MaxScore}`}
                      invalid={!!errors.max_score}
                      onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                      min={0}
                      max={100}
                    />
                    {errors.max_score && (
                      <div className="invalid-feedback">{errors.max_score}</div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <LabelTooltip
                  important
                  label={NoOfAttempt}
                  tooltipText={NoOfAttemptsToolTipMessage}
                />
                <Input
                  type="number"
                  name="no_of_attempt"
                  inputMode="numeric"
                  value={basicInputFormValue.no_of_attempt}
                  onChange={handleChange}
                  placeholder={`${Enter} ${NoOfAttempt}`}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  invalid={!!errors.no_of_attempt}
                  min={0}
                />
                {errors.no_of_attempt && (
                  <div className="invalid-feedback">{errors.no_of_attempt}</div>
                )}
              </Col>
              <Col lg={4}>
                <LabelTooltip
                  important
                  label={MinimumDaysBetweenReAttempts}
                  tooltipText={MinDaysBetweenReAttemptsToolTipMessage}
                />
                <Input
                  type="number"
                  name="cooling_period"
                  inputMode="numeric"
                  value={basicInputFormValue.cooling_period}
                  onChange={handleChange}
                  placeholder={`${Enter} ${MinimumDaysBetweenReAttempts}`}
                  invalid={!!errors.cooling_period}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  min={0}
                />
                {errors.cooling_period && (
                  <div className="invalid-feedback">
                    {errors.cooling_period}
                  </div>
                )}
              </Col>
            </Row>
          )}
        </CardBody>
        <CardFooter className="d-flex justify-content-end">
          <Row>
            <Col xs="auto">
              <CommonModal
                backdrop="static"
                size="lg"
                isOpen={isPublishConfirmModalOpen}
                toggle={togglePublishConfirmModal}
                sizeTitle={ConfirmPublish}
                showFooter={true}
                primaryBtnText={Submit}
                onPrimaryBtnClick={() => {
                  togglePublishConfirmModal();
                  handleSubmit();
                }}
              >
                <P>
                  {edit
                    ? ModifyReAttemptPolicyPublishConfirmation
                    : CreateReAttemptPolicyPublishConfirmation}
                </P>
              </CommonModal>
              <Btn
                onClick={() => {
                  if (!validateForm()) togglePublishConfirmModal();
                }}
                color="primary"
              >
                {edit ? ModifyPolicy : CreatePolicy}
              </Btn>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default AddPolicy;
