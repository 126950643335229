import React from "react";

import { H5, P } from "../../AbstractElements";

interface TodayTasksBodyProps {
  assign: string;
  name: string;
}

const DetailHeader: React.FC<TodayTasksBodyProps> = ({ assign, name }) => {
  return (
    <div className="flex-grow-1">
      <P className="mb-0 text-muted">{assign}</P>
      <H5>{name}</H5>
    </div>
  );
};

export default DetailHeader;
