import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import {
  REPORTING_MANAGER_STATUS,
  rmTabsStatusMap,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import NoDataFound from "../../../container/NoDataFound";
import RequestCard from "../../../container/RequestCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getCourseRequestListByFilter } from "../../../ReduxToolkit/Reducers/CourseRequestSlice";
import {
  setBasicInputFormValueToInitials,
  setNumberLevel,
  setShowFinish,
} from "../../../ReduxToolkit/Reducers/RequestCourseWizardSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  RequestType,
  NewRequest,
  RequestedOn,
  Select,
} from "../../../utils/Constant";
import {
  formatCustomDate,
  rmTabs,
  tmRequestTabs,
  courseRequestFilterDropdownList,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import useIsMobile from "../../../utils/helper/responsive";

const RequestList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [userMapData, setUserMapData] = useState({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { courseRequestList } = useAppSelector(state => state.courseRequest);
  const { usersList } = useAppSelector(state => state.user);
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || "new_request"
  );
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    dispatch(getUsersList({ role: REPORTING_MANAGER_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    fetchRequestList();
  }, [activeTab, selectedItems]);

  const fetchRequestList = () => {
    const combinedFilters = {
      status: [rmTabsStatusMap[activeTab]],
      filters: selectedItems,
    };
    dispatch(getCourseRequestListByFilter(combinedFilters)).then(response => {
      if (response?.payload?.course_request_list?.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMapData(userMapObj);
  }, [usersList]);

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/course-management/course-request?status=${tabId}`);
  };

  const handleClick = () => {
    dispatch(setBasicInputFormValueToInitials());
    dispatch(setShowFinish(false));
    dispatch(setNumberLevel(1));
  };

  const handleSearch = (query: string) => {
    const filteredCourses = courseRequestList?.course_request_list?.filter(
      course => course.topic.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCourses || []);
    setNoDataFound(!filteredCourses || filteredCourses?.length === 0);
  };

  const renderRequestCard = (course, index) => {
    return (
      <Col key={index} sm={12} lg={3}>
        <Link to={`/course-management/request-details?id=${course.id}`}>
          <RequestCard
            title={""}
            courseRequestId={course.id}
            header={course.topic}
            rm={userMapData[course.created_by]?.user_name}
            status={course.status}
            requestedOn={RequestedOn + formatCustomDate(course?.created_at)}
          />
        </Link>
      </Col>
    );
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["request_type"]) {
      setSelectedItems(values["request_type"]);
    }
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setSelectedItems([]);
  };

  const renderCourseRequestsContent = () => {
    if (noDataFound) {
      return <NoDataFound />;
    }

    if (searchResults?.length > 0) {
      return searchResults.map((course, index) =>
        renderRequestCard(course, index)
      );
    }

    return courseRequestList?.course_request_list?.map(
      (course: any, index: number) => renderRequestCard(course, index)
    );
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={
                  hasPermissionToComponent("REQUEST_TABS")
                    ? rmTabs
                    : tmRequestTabs
                }
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="ml-2 mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: RequestType,
                      key: "request_type",
                      tooltipText: `${Select} ${RequestType}`,
                      options: courseRequestFilterDropdownList,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              {hasPermissionToComponent("RAISE_REQUEST") && (
                <Link to={"/course-management/new-request"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                    onClick={handleClick}
                  >
                    {NewRequest}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderCourseRequestsContent()}</Row>
    </div>
  );
};

export default RequestList;
