import { useEffect } from "react";

const ProfileDetails: React.FC = () => {
  useEffect(() => {
    console.info("ProfileDetails");
  }, []);

  return <div className="page-body page-body-margin"></div>;
};

export default ProfileDetails;
