import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  FREEZE_LOAD_FACTOR,
  FREEZE_LOAD_FACTOR_URL,
  GET,
  GET_COURSE_PEOPLE,
  GET_COURSE_PEOPLE_URL,
  GET_SCHEDULED_COURSE_BY_ID,
  GET_SCHEDULED_COURSE_BY_ID_URL,
  LOAD_FACTOR,
  LOAD_FACTOR_API_URL,
  MODIFY_LOAD_FACTOR,
  MODIFY_LOAD_FACTOR_URL,
  POST,
  PUT,
  REFRESH_LOAD_FACTOR,
  REFRESH_LOAD_FACTOR_URL,
  SCEDULE_COURSE_PUBLISH,
  SCHEDULED_FOR_PUBLISH_URL,
  UPDATE_SCHEDULED_COURSE,
  UPDATE_SCHEDULED_COURSE_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  CourseData,
  CourseSchedule,
  LoadFactorResponse,
  LoadFactorToUpdate,
} from "../../Api/entities/LoadFactorEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface LoadFactorData {
  load_factor: LoadFactorResponse[];
  last_refresh_time?: "";
}

interface LoadFactorState {
  coursePeopleData: CourseData;
  loadFactorList: LoadFactorData;
  scheduleCourseById: CourseSchedule | any;
  loading: boolean;
  error: string | null;
}

const initialState: LoadFactorState = {
  coursePeopleData: {
    online_reattempt: [],
    reappear: [],
    first_timers: [],
    last_course_to_certificate: [],
    overdue: [],
  },
  loadFactorList: { load_factor: [] },
  scheduleCourseById: {},
  loading: false,
  error: null,
};

interface LoadFactorFilters {
  industry_id?: number[];
  job_role_id?: number[];
  curriculum_id?: number[];
  proficiency?: string[];
  year?: string;
}

export const fetchLoadFactorData = createAsyncThunk(
  LOAD_FACTOR,
  async (filters: LoadFactorFilters) => {
    const apiUrl = LOAD_FACTOR_API_URL(filters);
    const response = await apiRequest(GET, apiUrl);

    return response.data;
  }
);

export const updateLoadFactorData = createAsyncThunk(
  FREEZE_LOAD_FACTOR,
  async (year: { year: string }) => {
    const apiUrl = FREEZE_LOAD_FACTOR_URL();
    const response = await apiRequest(PUT, apiUrl, year);

    return response;
  }
);

export const refreshLoadFactorData = createAsyncThunk(
  REFRESH_LOAD_FACTOR,
  async () => {
    const apiUrl = REFRESH_LOAD_FACTOR_URL();
    const response = await apiRequest(POST, apiUrl, {});

    return response;
  }
);

export const modifyLoadFactorData = createAsyncThunk(
  MODIFY_LOAD_FACTOR,
  async (loadFactorUpdates: LoadFactorToUpdate[]) => {
    const apiUrl = MODIFY_LOAD_FACTOR_URL();
    const response = await apiRequest(PUT, apiUrl, {
      load_factor: loadFactorUpdates,
    });

    return response;
  }
);

export const fetchCoursePeopleData = createAsyncThunk(
  GET_COURSE_PEOPLE,
  async (id: any) => {
    const apiUrl = GET_COURSE_PEOPLE_URL(id);
    const response = await apiRequest(GET, apiUrl);

    return response.data;
  }
);

export const scheduleCourse = createAsyncThunk(
  SCEDULE_COURSE_PUBLISH,
  async (data: any) => {
    const apiUrl = SCHEDULED_FOR_PUBLISH_URL();
    const response = await apiRequest(POST, apiUrl, data);

    return response;
  }
);

export const updateScheduledCourse = createAsyncThunk(
  UPDATE_SCHEDULED_COURSE,
  async (data: any) => {
    const apiUrl = UPDATE_SCHEDULED_COURSE_URL();
    const response = await apiRequest(PUT, apiUrl, data);

    return response;
  }
);

export const getScheduledCourseById = createAsyncThunk(
  GET_SCHEDULED_COURSE_BY_ID,
  async (id: string) => {
    const apiUrl = GET_SCHEDULED_COURSE_BY_ID_URL(id);
    const response = await apiRequest(GET, apiUrl);

    return response?.data;
  }
);

const LoadFactorSlice = createSlice({
  name: "loadFactor",
  initialState,
  reducers: {
    resetLoadFactorState: state => {
      state.loadFactorList = { load_factor: [] };
      state.loading = false;
      state.error = null;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(fetchLoadFactorData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLoadFactorData.fulfilled, (state, action) => {
        state.loading = false;
        state.loadFactorList = action.payload;
      })
      .addCase(fetchLoadFactorData.rejected, (state, action) => {
        state.loading = false;
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateLoadFactorData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLoadFactorData.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateLoadFactorData.rejected, (state, action) => {
        state.loading = false;
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(refreshLoadFactorData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refreshLoadFactorData.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(refreshLoadFactorData.rejected, (state, action) => {
        state.loading = false;
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(modifyLoadFactorData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(modifyLoadFactorData.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(modifyLoadFactorData.rejected, (state, action) => {
        state.loading = false;
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(fetchCoursePeopleData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoursePeopleData.fulfilled, (state, action) => {
        state.loading = false;
        state.coursePeopleData = action.payload;
      })
      .addCase(fetchCoursePeopleData.rejected, (state, action) => {
        state.loading = false;
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(scheduleCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(scheduleCourse.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(scheduleCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getScheduledCourseById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScheduledCourseById.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduleCourseById = action.payload;
      })
      .addCase(getScheduledCourseById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateScheduledCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateScheduledCourse.fulfilled, (state, action) => {
        state.loading = false;
        const status_code = action.payload?.status_code;
        const message = action.payload?.message;
        if (status_code === STATUS_200) {
          showToast(message, "success");
        }
      })
      .addCase(updateScheduledCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { resetLoadFactorState } = LoadFactorSlice.actions;

export default LoadFactorSlice.reducer;
