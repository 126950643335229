interface ParagraphPropsTypes {
  children?: React.ReactNode;
  className?: string;
  innerHtml?: string;
  id?: string;
  style?: {
    fontSize?: number;
    fontWeight?: number;
    color?: string;
    opacity?: string;
    margin?: number;
    lineHeight?: number;
  };
}

const P = (props: ParagraphPropsTypes) => {
  return <p {...props}>{props.children}</p>;
};

export default P;
