import { ReactNode } from "react";

export interface H1ElementPropsTypes {
  children?: ReactNode;
  className?: string;
}
const H1 = (props: H1ElementPropsTypes) => {
  return <h1 {...props}>{props.children}</h1>;
};

export default H1;
