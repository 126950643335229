import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Form, FormGroup, Input, Col } from "reactstrap";

import { Btn, H3 } from "../../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../../Api/constants";
import Divider from "../../../../CommonElements/Divider";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import MultiSelectDropdown from "../../../../CommonElements/MultiSelectDropdown";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { createMapping } from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import { getDomainList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import {
  Domain,
  MapButton,
  MapMentorAndMentee,
  Mentee,
  Mentor,
  PleaseSelect,
  Select,
} from "../../../../utils/Constant";
import { mapListToOptions } from "../../../../utils/helper/helper";

import "./style.scss";

const MapMentorAndMenteeForm: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mentorId, domain } = state || {};
  const { usersList } = useAppSelector(state => state.user);
  const { domainList } = useAppSelector(state => state.master);

  const [domainListData, setDomainListData] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState(mentorId || null);
  const [selectedMentee, setSelectedMentee] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);

  const [errors, setErrors] = useState<{
    mentor?: string;
    mentee?: string;
    domain?: string;
  }>({});

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getDomainList());
      await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const domains = mapListToOptions(domainList?.domain, "domain_name", "id");
    setDomainListData(domains);
    if (domain) {
      const preFillDomain = domainList?.domain?.find(
        mDomain => mDomain.domain_name === domain
      );
      if (preFillDomain) setSelectedDomain(preFillDomain.id);
    }
  }, [domainList]);

  const handleMentorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMentor(Number(e.target.value));
    setErrors(prevErrors => ({ ...prevErrors, mentor: "" }));
  };

  const handleMenteesChange = (selectedIds: string[]) => {
    setSelectedMentee(selectedIds);
    setErrors(prevErrors => ({ ...prevErrors, mentee: "" }));
  };

  const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDomain(Number(e.target.value));
    setErrors(prevErrors => ({ ...prevErrors, domain: "" }));
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!selectedMentor) newErrors.mentor = `${PleaseSelect} ${Mentor}`;
    if (selectedMentee.length === 0)
      newErrors.mentee = `${PleaseSelect} ${Mentee}`;
    if (!selectedDomain) newErrors.domain = `${PleaseSelect} ${Domain}`;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleMap = () => {
    if (!validateForm()) return;

    dispatch(
      createMapping({
        mapPayload: {
          mentor_id: selectedMentor,
          mentee_id: selectedMentee,
          domain_id: selectedDomain,
        },
      })
    );

    navigate(`${process.env.PUBLIC_URL}/mentoring/manage-mentoring`);
  };

  const menteeOptions =
    usersList?.users
      ?.filter(user => user.id !== selectedMentor)
      .map(user => ({
        name: user.id.toString(),
        value: user.id.toString(),
        label: user.user_name,
      })) || [];

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody className="custom-map-form">
          <H3 className="fw-bold mt-3">{MapMentorAndMentee}</H3>
          <Divider />

          <Form className="mt-4">
            <Col className="align-items-center">
              <Col md={4} className="position-relative">
                <FormGroup>
                  <LabelTooltip
                    label={Mentor}
                    important
                    tooltipText={`${Select} ${Mentor}`}
                  />
                  <Input
                    type="select"
                    name="mentor"
                    className="text-gray"
                    onChange={handleMentorChange}
                    value={selectedMentor || ""}
                  >
                    <option>
                      {Select} {Mentor}
                    </option>
                    {usersList?.users
                      ?.filter(
                        user => !selectedMentee.includes(user.id.toString())
                      )
                      .map(user => (
                        <option key={user.id} value={user.id}>
                          {user.user_name}
                        </option>
                      ))}
                  </Input>
                  {errors.mentor && (
                    <div className="text-danger">{errors.mentor}</div>
                  )}
                </FormGroup>
              </Col>

              <Col md={4} className="position-relative">
                <FormGroup>
                  <LabelTooltip
                    label={Mentee}
                    important
                    tooltipText={`${Select} ${Mentee}`}
                  />
                  <MultiSelectDropdown
                    options={menteeOptions}
                    placeholder={`${Select} ${Mentee}`}
                    defaultSelected={menteeOptions.filter(option =>
                      selectedMentee.includes(option.value)
                    )}
                    onChange={handleMenteesChange}
                  />
                  {errors.mentee && (
                    <div className="text-danger">{errors.mentee}</div>
                  )}
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <LabelTooltip
                    label={Domain}
                    important
                    tooltipText={`${Select} ${Domain}`}
                  />
                  <Input
                    type="select"
                    name="domain"
                    className="text-gray"
                    onChange={handleDomainChange}
                    value={selectedDomain || ""}
                  >
                    <option className="text-lightgray">
                      {Select} {Domain}
                    </option>
                    {domainListData.map(domain => (
                      <option key={domain.id} value={domain.value}>
                        {domain.label}
                      </option>
                    ))}
                  </Input>
                  {errors.domain && (
                    <div className="text-danger">{errors.domain}</div>
                  )}
                </FormGroup>
              </Col>
              <Divider />
            </Col>

            <div className="d-flex justify-content-end mt-2 mb-2">
              <Btn color="primary" onClick={handleMap}>
                {MapButton}
              </Btn>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default MapMentorAndMenteeForm;
