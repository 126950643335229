import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { DefaultButton, H3 } from "../../../../AbstractElements";
import DateRangeDisplay from "../../../../CommonElements/DateRangeDisplay";
import CommonModal from "../../../../CommonElements/Modal";
import StatusDisplay from "../../../../CommonElements/StatusDisplay";
import FlatContainer from "../../../../container/FlatContainer";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  deleteModuleFeedback,
  getCourseModuleFeedabck,
} from "../../../../ReduxToolkit/Reducers/FeedbackRatingSlice";
import {
  AddNew,
  AreYouSureDelete,
  Confirm,
  Confirmation,
  ContentType,
  Instructor,
  Location,
  ModuleFeedbackTitle,
  PageNo,
  Room,
} from "../../../../utils/Constant";

import "./style.scss";

const ModuleFeedback = () => {
  const [modalInfo, setModalInfo] = useState(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    course_name,
    location_name,
    classroom_name,
    tranierName,
    course_start_date,
    course_end_date,
    course_schedule_id,
  } = location.state;

  const { questionsList } = useAppSelector(state => state.feedback);

  useEffect(() => {
    getFeedbackList();
  }, []);

  const getFeedbackList = () => {
    dispatch(
      getCourseModuleFeedabck({
        type: "module",
        courseScheduleId: course_schedule_id,
      })
    );
  };

  const handleDeleteModule = item => {
    const payload = {
      module_feedback_id: [item.id],
    };
    dispatch(
      deleteModuleFeedback({
        moduleContent: payload,
      })
    ).then(res => {
      if (res.payload) {
        getFeedbackList();
      }
    });
    closeModal();
  };

  const handleEdit = item => {
    return navigate("/schedule-list/course-details/rate-now", {
      state: {
        ...location.state,
        ...item,
      },
    });
  };

  const handleNew = () => {
    return navigate("/schedule-list/course-details/rate-now", {
      state: {
        ...location.state,
      },
    });
  };

  const closeModal = () => {
    setModalInfo(null);
  };

  const handleDelete = item => {
    setModalInfo({
      isOpen: true,
      title: AreYouSureDelete,
      callback: () => handleDeleteModule(item),
    });
  };

  return (
    <div className="page-body module-feedback">
      <CommonModal
        sizeTitle={Confirmation}
        modalBodyClassName=""
        isOpen={modalInfo?.isOpen}
        toggle={closeModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={modalInfo?.callback}
        primaryBtnText={Confirm}
      >
        {modalInfo?.title}
      </CommonModal>

      <Card className="p-2">
        <CardBody className="mb-5">
          <H3 className="fw-bold">{course_name}</H3>
          <Row className="mt-3">
            <Col lg="4" className="mt-1">
              <DateRangeDisplay
                startDate={course_start_date}
                endDate={course_end_date}
              />
            </Col>
            <Col className="d-flex gap-5 mt-3">
              <StatusDisplay label={Location} number={location_name} />
              <StatusDisplay label={Room} number={classroom_name} />
              <StatusDisplay label={Instructor} number={tranierName} />
            </Col>
          </Row>
          <Divider />
          <div className="module-feedback__wrapper my-3">
            <H3 className="fw-bold">{ModuleFeedbackTitle}</H3>
            <DefaultButton color="primary" onClick={handleNew}>
              +{AddNew}
            </DefaultButton>
          </div>
          {questionsList?.module_feedback?.map(item => (
            <div key={item.id} className="py-2">
              <FlatContainer
                id={item.id}
                description={[
                  {
                    label: `${ContentType}:`,
                    value: ` ${item.content_type}`,
                    columnSize: 3,
                  },
                  {
                    label: `${PageNo}:`,
                    value: ` ${item.page_no.toString()}`,
                    columnSize: 2,
                  },
                  {
                    label: "",
                    value: item.feedback,
                    columnSize: 4,
                  },
                ]}
                title={item.module_name}
                actions={[
                  {
                    iconName: "DeleteOutlineOutlined",
                    onClick: () => handleDelete(item),
                  },
                  {
                    iconName: "EditOutlined",
                    onClick: () => handleEdit(item),
                  },
                ]}
              />
              <Divider className="my-2" />
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default ModuleFeedback;
