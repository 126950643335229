import PropTypes from "prop-types";
import React from "react";

import "./style.scss";
import { RatioInput } from "./RatioInput";

interface RatioPickerProps {
  limit: number;
  hour: number;
  minute: number;
  onChange: (time: string) => void;
}

const NumberPicker: React.FC<RatioPickerProps> = ({
  limit,
  hour,
  minute,
  onChange,
}) => {
  const handleChange = (value: number, type: "hour" | "minute") => {
    if (type === "hour") {
      onChange(
        `${String(value).padStart(2, "0")}:${String(minute).padStart(2, "0")}`
      );
    } else if (type === "minute") {
      onChange(
        `${String(hour).padStart(2, "0")}:${String(value).padStart(2, "0")}`
      );
    }
  };

  return (
    <div className="time-picker">
      <div className="time-inputs">
        <RatioInput
          label="Hour"
          maxValue={limit - 1}
          value={hour}
          onChange={val => handleChange(val, "hour")}
        />
        <span className="separator">:</span>
        <RatioInput
          label="Minute"
          maxValue={limit - 1}
          value={minute}
          onChange={val => handleChange(val, "minute")}
        />
      </div>
    </div>
  );
};

NumberPicker.propTypes = {
  limit: PropTypes.number.isRequired,
  hour: PropTypes.number.isRequired,
  minute: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberPicker;
