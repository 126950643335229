import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { ALL_COURSE_FILTER_STATUS, tmTabsStatusMap } from "../../Api/constants";
import FilterSliderModal from "../../CommonElements/FilterSliderModal";
import SearchBar from "../../CommonElements/SearchBar";
import LearningPlanCourseCard from "../../container/LearningPlanCourseCard";
import NoDataFound from "../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getCourseList } from "../../ReduxToolkit/Reducers/CourseSlice";
import { getDomainList } from "../../ReduxToolkit/Reducers/MasterSlice";
import { CourseType, Domain, Select } from "../../utils/Constant";
import { courseFilterDropdownList } from "../../utils/helper/helper";

import "./style.scss";

const CourseCatalog = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [noDataFound, setNoDataFound] = useState(false);
  const [courseFilterValues, setCourseFilterValues] = useState({
    courseType: [],
    domain: [],
  });
  const [domainListData, setDomainListData] = useState([]);

  const { courseList } = useAppSelector(state => state.course);
  const { domainList } = useAppSelector(state => state.master);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const transformCouseData = courseData => {
    const transformedData = courseData.map(item => ({
      course_id: item.id,
      courseId: item.id,
      courseName: item.course_name,
      domainName: [],
      completedOn: null,
      scheduledOn: null,
      assignedBy: null,
      courseType: item.course_type,
      id: item.id,
      published_on: item.published_on,
    }));
    return transformedData;
  };

  useEffect(() => {
    const transformedData = transformCouseData(courseList?.course_list);
    setSearchResults(transformedData);
  }, [courseList?.course_list]);

  useEffect(() => {
    dispatch(getDomainList());
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [courseFilterValues]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  const fetchCourses = () => {
    const { courseType = ALL_COURSE_FILTER_STATUS, domain = [] } =
      courseFilterValues;
    const filteredCourseType =
      courseType.length > 0 ? courseType : ALL_COURSE_FILTER_STATUS;
    const filteredDomain = domain.length > 0 ? domain : null;

    dispatch(
      getCourseList({
        status: tmTabsStatusMap["published"],
        course_type: filteredCourseType,
        domain: filteredDomain,
      })
    ).then(response => {
      setNoDataFound(!response?.payload?.course_list?.length);
    });
  };

  const handleSearch = query => {
    const filteredCourse = courseList?.course_list?.filter(course =>
      course.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(
      !query
        ? transformCouseData(courseList?.course_list)
        : transformCouseData(filteredCourse)
    );
  };

  const handleDone = async values => {
    const selectedCourseTypes = values["request_type"];
    const selectedDomains = values["domain"];
    setCourseFilterValues(prevState => ({
      ...prevState,
      courseType: selectedCourseTypes ?? [],
      domain: selectedDomains ?? [],
    }));
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setCourseFilterValues({
      courseType: [],
      domain: [],
    });
  };

  const handleClick = item => {
    return navigate("/course-catalog/course-details", {
      state: {
        ...item,
      },
    });
  };

  const renderCourses = data => {
    return data?.length > 0 ? (
      <LearningPlanCourseCard data={data} handleClick={handleClick} />
    ) : (
      <NoDataFound />
    );
  };

  return (
    <div className="page-body page-body-margin course-catelog">
      <Card className="p-1">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: CourseType,
                      key: "request_type",
                      tooltipText: `${Select} ${CourseType}`,
                      options: courseFilterDropdownList,
                      isMultiSelect: true,
                    },
                    {
                      label: Domain,
                      key: "domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {noDataFound ? <NoDataFound /> : renderCourses(searchResults)}
    </div>
  );
};

export default CourseCatalog;
