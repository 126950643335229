import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";

const CheckboxButton = ({ checked, onChange, disabled = false }) => {
  return (
    <Label className="switch mb-0">
      <Input
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        checked={checked}
      />
      <span
        className={`switch-state ${checked ? "bg-success" : "bg-primary"}`}
      ></span>
    </Label>
  );
};

CheckboxButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CheckboxButton.defaultProps = {
  onChange: () => {
    return;
  },
};

export default CheckboxButton;
