import PropTypes from "prop-types";

import "./style.scss";
import { PassDescription, YouPassed } from "../../utils/Constant";
import useIsMobile from "../../utils/helper/responsive";

const PassScoreCard = ({ score }) => {
  const isMobile = useIsMobile();
  return (
    <div className={`score-card ${isMobile ? "score-card-m" : ""}`}>
      <div className="score-card__content">
        <div className="text-center">
          <div className="text-white">Your Score</div>
          <div className="score-card__score">{score}</div>
        </div>
        <div>
          <div className="score-card__message">{YouPassed}</div>
          <div className="score-card__details">{PassDescription}</div>
        </div>
      </div>
    </div>
  );
};

PassScoreCard.propTypes = {
  score: PropTypes.string,
};

export default PassScoreCard;
