import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { ALL_USERS_STATUS, courseScheduleStatus } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getLocationList } from "../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Available,
  Cancelled,
  Completed,
  CourseName,
  CoursePeriod,
  Instructor,
  Location,
  NominationClosed,
  NominationOngoing,
  NominationPeriod,
  Ongoing,
  Rescheduled,
  Room,
  Status,
  TrainerApproved,
  WaitingTrainerApproval,
} from "../../utils/Constant";

interface ScheduleListContainerProps {
  data: CourseSchedule[];
  onRowClick?: (row: CourseSchedule) => void;
}

const ScheduleListContainer: React.FC<ScheduleListContainerProps> = ({
  data,
  onRowClick,
}) => {
  const [userNameMap, setUserNameMap] = useState<{
    [key: string]: { user_name: string };
  }>({});
  const [locationNameMap, setLocationNameMap] = useState<{
    [key: string]: { name: string };
  }>({});
  const [classRoomNameMap, setClassRoomNameMap] = useState({});

  const dispatch = useAppDispatch();
  const { usersList } = useAppSelector(state => state.user);
  const { locationList, classRoomList } = useAppSelector(state => state.master);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getLocationList());
  }, [dispatch]);

  useEffect(() => {
    const classRoomMapObj: { [key: string]: { name: string } } = {};
    classRoomList?.class_room?.forEach(location => {
      classRoomMapObj[location.id] = location;
    });
    setClassRoomNameMap(classRoomMapObj);
  }, [classRoomList]);

  useEffect(() => {
    const userMapObj: { [key: string]: { user_name: string } } = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    const locationMapObj: { [key: string]: { name: string } } = {};
    locationList?.location?.forEach(location => {
      locationMapObj[location.id] = location;
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  const getStatusText = (statusKey: string): string => {
    switch (statusKey) {
      case courseScheduleStatus.waiting_for_trainer_approval:
        return WaitingTrainerApproval;
      case courseScheduleStatus.nomination_pending:
      case courseScheduleStatus.nomination_open:
      case courseScheduleStatus.nomination_ongoing:
        return NominationOngoing;
      case courseScheduleStatus.nomination_closed:
        return NominationClosed;
      case courseScheduleStatus.rescheduled:
        return Rescheduled;
      case courseScheduleStatus.cancelled:
        return Cancelled;
      case courseScheduleStatus.trainer_approved:
        return TrainerApproved;
      case courseScheduleStatus.completed:
        return Completed;
      case courseScheduleStatus.ongoing:
        return Ongoing;
      default:
        return statusKey;
    }
  };

  const columns = [
    {
      name: CourseName,
      selector: (row: CourseSchedule) => row.course_name,
      minWidth: "19rem",
      sortable: true,
    },
    {
      name: Instructor,
      selector: (row: CourseSchedule) =>
        userNameMap[row.primary_trainer_id]?.user_name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Location,
      selector: (row: CourseSchedule) =>
        locationNameMap[row.location_id]?.name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Room,
      selector: (row: CourseSchedule) =>
        classRoomNameMap[row.classroom_id]?.name || "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: NominationPeriod,
      minWidth: "15rem",
      sortable: true,
      cell: row => (
        <div>
          {row.status === courseScheduleStatus.ongoing ||
          row.status === courseScheduleStatus.nomination_closed
            ? row.nomination_end_date
            : `${row.nomination_start_date} - ${row.nomination_end_date}`}
        </div>
      ),
    },
    {
      name: Available,
      selector: (row: CourseSchedule) => row.available_count || 0,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: CoursePeriod,
      selector: (row: CourseSchedule) =>
        `${row.course_start_date} - ${row.course_end_date}`,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: Status,
      minWidth: "16rem",
      sortable: true,
      cell: (row: CourseSchedule) =>
        onRowClick ? (
          <button
            onClick={() => onRowClick(row)}
            className={`border-0 bg-transparent schedule-list__${row.status}`}
          >
            {getStatusText(row.status)}
          </button>
        ) : (
          <span className={`schedule-list__${row.status}`}>
            {getStatusText(row.status)}
          </span>
        ),
    },
  ];

  return (
    <DataTable
      onRowClicked={onRowClick}
      columns={columns}
      data={data}
      pagination
      fixedHeader
      striped
    />
  );
};

ScheduleListContainer.propTypes = {
  data: PropTypes.array,
  onRowClick: PropTypes.func,
};

export default ScheduleListContainer;
