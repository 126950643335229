import PropTypes from "prop-types";
import React from "react";

import DefaultButton from "../DefaultButton";

import "./style.scss";

interface FABProps {
  onClick?: () => void;
  icon?: string;
  title?: string;
  buttonType?: string;
}

const FAB: React.FC<FABProps> = ({ onClick, icon, title, buttonType }) => {
  return (
    <DefaultButton
      title={title}
      className={`fab-button btn-${buttonType}`}
      onClick={onClick}
    >
      {icon && <i className={icon}></i>}
      {title}
    </DefaultButton>
  );
};

FAB.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  buttonType: PropTypes.string,
};

export default FAB;
