import React, { useState } from "react";
import { Card, CardBody, Tooltip } from "reactstrap";

import { Badges, H4, MUIIcons, P } from "../../AbstractElements";
import { courseTypeMap } from "../../Api/constants";
import CommonCardHeader from "../../CommonElements/CommonCardHeader/CommonCardHeader";
import Divider from "../Divider";

interface CurriculumCourseCardProps {
  title?: string;
  header: string;
  status?: string;
  id: string;
  badge?: string;
  showDelete?: boolean;
  handleDelete?: () => void;
  requestedOn?: string;
  type?: string;
}

const CurriculumCourseCard: React.FC<CurriculumCourseCardProps> = ({
  title,
  header,
  status,
  id,
  badge,
  showDelete,
  handleDelete,
  requestedOn,
  type,
}) => {
  const truncatedHeader =
    header?.length > 20 ? `${header?.substring(0, 20)}...` : header;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTrainerTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <Card>
      <CommonCardHeader
        courseId={id}
        status={status}
        headClass="card-no-border pb-0"
        title={[title]}
        badge={badge}
        showDelete={showDelete}
        handleDelete={handleDelete}
      />
      <CardBody className="card-body d-flex flex-column">
        <div className="flex-grow-1 w-100">
          <div>
            <span id={`tooltip-course-name${id}`}>
              <H4
                className="header-text truncated-header"
                data-fulltext={header}
              >
                {truncatedHeader}
              </H4>
            </span>
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target={`tooltip-course-name${id}`}
              toggle={toggleTrainerTooltip}
            >
              {header}
            </Tooltip>
          </div>
          <div className="card-bottom-section mt-auto">
            <Divider />
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex gap-2">
                {requestedOn && (
                  <>
                    <MUIIcons
                      className="text-gray mt-1"
                      size={18}
                      iconName="CalendarTodayOutlined"
                    />
                    <P className="text-muted mt-1">{requestedOn}</P>
                  </>
                )}
              </div>
              <Badges className="mt-1">{courseTypeMap[type]}</Badges>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default CurriculumCourseCard;
