import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { H4, Image, UL } from "../../../AbstractElements";
import UserProfile from "../../../Layout/Header/RightHeader/UserProfile/UserProfile";
import { dynamicImage } from "../../../Service";
import "./style.scss";

const Header = ({ name }) => {
  return (
    <Row className="assessment-header bg-black p-3 align-items-center">
      <Col xs="6" xl="6" className="d-flex align-items-center gap-3">
        <div className="logo-wrapper">
          <Image
            className="img-fluid for-light"
            src={dynamicImage("logo/logo.png")}
            alt="logo"
          />
          <Image
            className="img-fluid for-dark"
            src={dynamicImage("logo/logo.png")}
            alt="logo"
          />
        </div>
        <H4 className="f-w-700 text-capitalize text-white m-0">{name}</H4>
      </Col>
      <Col
        xxl="6"
        xl="6"
        md="6"
        xs="6"
        className="d-flex justify-content-end align-items-center"
      >
        <UL className="nav-menus flex-row simple-list">
          <UserProfile isHideLogoutBtn={true} />
        </UL>
      </Col>
    </Row>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Header;
