import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, Input } from "reactstrap";

import Divider from "../../../../CommonElements/Divider";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import NoData from "../../../../container/NoData";
import UserActionCard from "../../../../container/UserActionCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  getNomineesOfSchedule,
  reviewTrainerOrNominee,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import {
  AddComment,
  AddParticipant,
  ApproveNomineeReason,
  Cancel,
  Confirm,
  Description,
  EmptyCommentsAlert,
  Enter,
  NotYetAdded,
  RejectNomineeReason,
  Remove,
  RemoveParticipants,
  Replacement,
} from "../../../../utils/Constant";
import { formatDate, showToast } from "../../../../utils/helper/helper";
import {
  currentUserId,
  hasPermissionToComponent,
} from "../../../../utils/helper/permission";

const NomineesList = React.forwardRef((props: any, ref: any) => {
  const {
    userMap,
    scheduledId,
    courseId,
    courseName,
    setShowNominationButton,
    sbuMap,
    startDate,
    endDate,
    primaryTrainerId,
    replacedTrainerId,
    status,
    getScheduledDetails,
    available,
    booked,
  } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedModalType, setSelectedModalType] = useState("");
  const [comments, setComments] = useState("");
  const { nominees } = useAppSelector(state => state.course);

  useEffect(() => {
    dispatch(
      getNomineesOfSchedule({ schedule_id: scheduledId, type: "nomination" })
    );
  }, [dispatch, scheduledId]);

  useEffect(() => {
    setShowNominationButton(nominees?.nominees?.length > 0);
  }, [nominees]);

  const onHandleClick = () => {
    return navigate("/add-participant", {
      state: {
        scheduledId: scheduledId,
        courseId: courseId,
        courseName: courseName,
        available: available,
        booked: booked,
      },
    });
  };

  const onHandleReplaceClick = (
    _value,
    unique_id,
    participant_id,
    name,
    status
  ) => {
    if (status === "waitlist") {
      setSelectedId(unique_id);
      setSelectedModalType("remove");
      toggleModal();
    } else {
      return navigate("/add-participant", {
        state: {
          replace_id: unique_id,
          participant_id: participant_id,
          replace_name: name,
          scheduledId: scheduledId,
          courseId: courseId,
          courseName: courseName,
        },
      });
    }
  };

  let sizeTitle = "";
  if (selectedModalType === "approve") {
    sizeTitle = ApproveNomineeReason;
  } else if (selectedModalType === "reject") {
    sizeTitle = RejectNomineeReason;
  } else if (selectedModalType === "remove") {
    sizeTitle = Remove;
  }

  const isNominationPeriod = () => {
    if (
      new Date(new Date().setHours(0, 0, 0, 0)) >=
        new Date(new Date(startDate).setHours(0, 0, 0, 0)) &&
      new Date(new Date().setHours(0, 0, 0, 0)) <=
        new Date(new Date(endDate).setHours(0, 0, 0, 0))
    ) {
      return true;
    }
    return false;
  };

  const renderModalBody = type => {
    return (
      <>
        {type !== "remove" && (
          <FormGroup>
            <LabelTooltip
              label={AddComment}
              tooltipText={`${Enter} ${Description}`}
              important
            />
            <Input
              onChange={e => {
                if (
                  (e.target.value && e.target.value.length <= 255) ||
                  e.target.value === ""
                ) {
                  setComments(e.target.value);
                }
              }}
              rows={4}
              name="objective"
              type="textarea"
              placeholder={`${Enter} ${Description}`}
            />
          </FormGroup>
        )}
        {type === "remove" && RemoveParticipants}
      </>
    );
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "approve" || selectedModalType === "reject") {
      if (!comments || comments.trim() === "") {
        showToast(EmptyCommentsAlert, "error");
        return;
      }
    }

    let payload;
    if (selectedModalType === "approve") {
      payload = {
        action: "approved",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "reject") {
      payload = {
        action: "rejected",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    } else if (selectedModalType === "remove") {
      payload = {
        action: "removed",
        type: "nominee",
        id: selectedId,
        reason: comments,
      };
      await dispatch(reviewTrainerOrNominee({ review: payload }));
    }

    await dispatch(
      getNomineesOfSchedule({ schedule_id: scheduledId, type: "nomination" })
    );
    await getScheduledDetails(scheduledId);
    toggleModal();
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId("");
      setSelectedModalType("");
      setComments("");
    }
    setIsModalOpen(!isModalOpen);
  };

  React.useImperativeHandle(ref, () => ({
    onHandleClick,
  }));

  const isEligibleToUpdate = status => {
    return (
      (currentUserId() === primaryTrainerId ||
        replacedTrainerId?.includes(currentUserId()) ||
        hasPermissionToComponent("ADD_NOMINEES")) &&
      status !== "waitlist"
    );
  };

  return (
    <>
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      {nominees?.nominees?.length === 0 &&
      isNominationPeriod() &&
      status !== "waiting_for_trainer_approval" &&
      status !== "cancelled" &&
      hasPermissionToComponent("ADD_NOMINEES") ? (
        <div className="p-5">
          <NoData
            showCard={false}
            showIcon={false}
            svg="empty-folder-icon"
            title={NotYetAdded}
            buttonText={AddParticipant}
            onclick={onHandleClick}
          />
        </div>
      ) : (
        nominees?.nominees?.length === 0 && (
          <div className="p-5">
            <NoData
              showCard={false}
              showIcon={false}
              svg="empty-folder-icon"
              title={NotYetAdded}
            />
          </div>
        )
      )}
      {nominees?.nominees?.length > 0 &&
        nominees?.nominees?.map((item, index) => {
          if (userMap) {
            const details = {
              unique_id: item?.id,
              id: userMap[item?.participant_id]?.id,
              name: userMap[item?.participant_id]?.user_name,
              user_name: userMap[item?.participant_id]?.user_name,
              empId: userMap[item?.participant_id]?.employee_code,
              userId: userMap[item?.participant_id]?.id,
              mobile: userMap[item?.participant_id]?.phone,
              sbu: sbuMap[userMap[item?.participant_id]?.sbu],
              rmName: userMap[item?.participant_id]?.mapped_rm[0]?.name,
              rmMobileNo: userMap[item?.participant_id]?.mapped_rm[0]?.rm_phone,
              status: item?.nomination_status,
              participant_id: item?.id?.toString(),
              nominatedOn: item?.updated_at ? formatDate(item?.updated_at) : "",
            };

            return (
              <div key={item.id} className="mt-3">
                <UserActionCard
                  userDetails={details}
                  nominatedOn
                  actions={
                    isEligibleToUpdate(item?.nomination_status) ||
                    item?.nomination_status === "waitlist"
                  }
                  firstItem={
                    item?.nomination_status === "waitlist"
                      ? Cancel
                      : Replacement
                  }
                  approveOrReject={isEligibleToUpdate(item?.nomination_status)}
                  onClickDropdownItem={onHandleReplaceClick}
                  onApprove={id => {
                    setSelectedId(id?.toString());
                    setSelectedModalType("approve");
                    toggleModal();
                  }}
                  onReject={id => {
                    setSelectedId(id?.toString());
                    setSelectedModalType("reject");
                    toggleModal();
                  }}
                />

                {index < nominees?.nominees.length - 1 && <Divider />}
              </div>
            );
          }
        })}
    </>
  );
});

NomineesList.propTypes = {
  scheduledId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  sbuMap: PropTypes.object,
};

NomineesList.displayName = "NomineesList";

export default NomineesList;
