import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  APPROVE_COURSE_REQUEST,
  APPROVE_COURSE_REQUEST_URL,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_REQUEST_URL,
  GET,
  GET_COURSE_REQUEST_BY_ID,
  GET_COURSE_REQUEST_BY_ID_URL,
  GET_COURSE_REQUEST_FILTERS_URL,
  GET_COURSE_REQUEST_LIST,
  GET_COURSE_REQUEST_LIST_BY_FILTER,
  GET_COURSE_REQUEST_LIST_URL,
  POST,
  PUT,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_REQUEST_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface CourseManagementData {
  createdCourseResponse: CourseRequestListByIdResponse;
  approvedCourse: ApproveCourseRequest;
  courseRequestList: CourseRequestListResponse;
  reviewCourseRequestList: ReviewCourseRequestList;
  courseRequestById: CourseRequestListById;
  loading: boolean;
  error: string | null;
  selectedRmRequestId: string;
}

const initialState: CourseManagementData = {
  createdCourseResponse: null,
  loading: false,
  reviewCourseRequestList: null,
  approvedCourse: null,
  courseRequestById: null,
  courseRequestList: { course_request_list: [] },
  error: null,
  selectedRmRequestId: null,
};

export const getCourseRequestList = createAsyncThunk(
  GET_COURSE_REQUEST_LIST,
  async ({ status }: { status: string[] }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_REQUEST_LIST_URL(status.toString())
    );
    return response.data;
  }
);

export const getCourseRequestListByFilter = createAsyncThunk(
  GET_COURSE_REQUEST_LIST_BY_FILTER,
  async ({ status, filters }: { status: string[]; filters: string[] }) => {
    const response = await apiRequest(
      GET,
      GET_COURSE_REQUEST_FILTERS_URL(status.toString(), filters.toString())
    );
    return response.data;
  }
);

export const getCourseRequestById = createAsyncThunk(
  GET_COURSE_REQUEST_BY_ID,
  async ({ id }: { id: string }) => {
    const response = await apiRequest(GET, GET_COURSE_REQUEST_BY_ID_URL(id));
    return response.data;
  }
);

export const createCourseRequest = createAsyncThunk(
  CREATE_COURSE_REQUEST,
  async ({
    courseRequestFormData,
  }: {
    courseRequestFormData: CourseRequestPayload;
  }) => {
    const response = await apiRequest(
      POST,
      CREATE_COURSE_REQUEST_URL(),
      courseRequestFormData
    );
    return response;
  }
);

export const updateCourseRequest = createAsyncThunk(
  UPDATE_COURSE_REQUEST,
  async ({ courseRequestFormData }: { courseRequestFormData }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_COURSE_REQUEST_URL(),
      courseRequestFormData
    );
    return response.data;
  }
);

export const approveCourseRequest = createAsyncThunk(
  APPROVE_COURSE_REQUEST,
  async ({
    approveCourseData,
  }: {
    approveCourseData: ApproveCourseRequest;
  }) => {
    return await apiRequest(
      PUT,
      APPROVE_COURSE_REQUEST_URL(),
      approveCourseData
    );
  }
);

const CourseSlice = createSlice({
  name: "courseRequest",
  initialState,
  reducers: {
    setRmRequestId: (state, action) => {
      state.selectedRmRequestId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createCourseRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCourseRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.createdCourseResponse = action.payload;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createCourseRequest.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(approveCourseRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveCourseRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.approvedCourse = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(approveCourseRequest.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseRequestList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseRequestList.fulfilled,
        (state, action: PayloadAction<CourseRequestListResponse>) => {
          state.loading = false;
          state.courseRequestList = action.payload;
        }
      )
      .addCase(getCourseRequestList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseRequestListByFilter.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseRequestListByFilter.fulfilled,
        (state, action: PayloadAction<CourseRequestListResponse>) => {
          state.loading = false;
          state.courseRequestList = action.payload;
        }
      )
      .addCase(getCourseRequestListByFilter.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCourseRequestById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCourseRequestById.fulfilled,
        (state, action: PayloadAction<CourseRequestListById>) => {
          state.loading = false;
          state.courseRequestById = action.payload;
        }
      )
      .addCase(getCourseRequestById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCourseRequest.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourseRequest.fulfilled, (state, action) => {
        state.loading = false;

        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateCourseRequest.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;

          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const { setRmRequestId } = CourseSlice.actions;
export default CourseSlice.reducer;
