import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { MUIIcons, Image, H5, H3 } from "../../../AbstractElements";
import { ALL_USERS_STATUS } from "../../../Api/constants";
import Btn from "../../../CommonElements/Button";
import Divider from "../../../CommonElements/Divider";
import SearchBar from "../../../CommonElements/SearchBar";
import NoData from "../../../container/NoData";
import TotalSeatCard from "../../../container/TotalSeatCard";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getMappedMentorList } from "../../../ReduxToolkit/Reducers/LearnerSlice";
import { getDomainList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../Service";
import {
  AddNew,
  MapMentorAndMentee,
  MappedMentee,
  Replace,
  MappedMentor,
  TotalSession,
  TotalDuration,
  UpcomingSession,
  Mentor,
  Domain,
  Mentees,
  Duration,
  Action,
  NoDataText,
} from "../../../utils/Constant";
import { convertMinutesToHours } from "../../../utils/helper/helper";

export interface MappedMentorData {
  mentor_domain_id: number;
  mentorId: number;
  mentorName: string;
  domain: string;
  menteesCount: number;
  totalSession: number;
  duration: number;
  upcomingSession: number;
}

const ManageMentoring = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const { mappedMentorList } = useAppSelector(state => state.learner);
  const { usersList } = useAppSelector(state => state.user);
  const { domainList } = useAppSelector(state => state.master);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getMappedMentorList());
      await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
      dispatch(getDomainList());
    };
    fetchData();
  }, [dispatch]);

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const handleReplace = (
    mentorId: number,
    mentorName: string,
    mentor_domain_id: number
  ) => {
    navigate(
      `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/replace-mentor`,
      { state: { mentorId, mentorName, mentor_domain_id } }
    );
  };

  const MappedMentoringColumns = [
    {
      name: Mentor,
      minWidth: "18rem",
      cell: (row: MappedMentorData) => (
        <div className="d-flex flex-row gap-2 align-items-center">
          <Image
            className="img-40 rounded-circle p-0"
            src={dynamicImage("dashboard-4/user.png")}
            alt="Mentor"
          />
          <H5>{row.mentorName}</H5>
        </div>
      ),
    },
    {
      name: Domain,
      minWidth: "18rem",
      cell: (row: MappedMentorData) => row.domain,
    },
    {
      name: Mentees,
      minWidth: "6rem",
      cell: (row: MappedMentorData) => row.menteesCount,
    },
    {
      name: TotalSession,
      minWidth: "6rem",
      cell: (row: MappedMentorData) => row.totalSession,
    },
    {
      name: Duration,
      minWidth: "6rem",
      cell: (row: MappedMentorData) =>
        convertMinutesToHours(row.duration) + "h",
    },
    {
      name: UpcomingSession,
      minWidth: "6rem",
      cell: (row: MappedMentorData) => row.upcomingSession,
    },
    {
      name: Action,
      selector: (row: MappedMentorData) => row.mentorId,
      minWidth: "6rem",
      cell: (row: MappedMentorData) => (
        <div>
          <Dropdown
            isOpen={row.mentor_domain_id === dropdownOpen}
            toggle={e => toggleDropdown(e, row.mentor_domain_id)}
          >
            <DropdownToggle color="" className="three-dots">
              <div className="mb-1">&#x22EE;</div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() =>
                  handleReplace(
                    row.mentorId,
                    row.mentorName,
                    row.mentor_domain_id
                  )
                }
              >
                {Replace}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const filteredData: MappedMentorData[] = mappedMentorList?.data
    ?.map(mentor => {
      const mentorName =
        usersList?.users.find(user => user.id === mentor.mentor_id)
          ?.user_name || "";
      const domainName =
        domainList.domain.find(domain => domain.id === mentor.domain_id)
          ?.domain_name || "";

      return {
        mentor_domain_id: mentor.mentor_domain_id,
        mentorId: mentor.mentor_id,
        mentorName: mentorName,
        domain: domainName,
        menteesCount: mentor.mentees_count,
        totalSession: mentor.total_sessions,
        duration: mentor.total_duration,
        upcomingSession: mentor.upcoming_sessions,
      };
    })
    .filter(
      mentor =>
        mentor.mentorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        mentor.domain.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const customStyles = {
    rows: {
      style: {
        padding: "1rem 0",
      },
    },
  };

  const TotalSeatData = [
    {
      id: 1,
      count: mappedMentorList?.mapped_mentor_count,
      title: MappedMentor,
    },
    {
      id: 2,
      count: mappedMentorList?.mapped_mentee_count,
      title: MappedMentee,
    },
    { id: 3, count: mappedMentorList?.total_sessions, title: TotalSession },
    {
      id: 4,
      count: convertMinutesToHours(mappedMentorList?.total_duration) + "h",
      title: TotalDuration,
    },
    {
      id: 4,
      count: mappedMentorList?.upcoming_sessions,
      title: UpcomingSession,
    },
  ];

  const handleRowClick = (row: MappedMentorData) => {
    navigate(
      `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/mentor-details`,
      {
        state: {
          mentorId: row.mentorId,
          mentorDomainId: row.mentor_domain_id,
          domain: row.domain,
        },
      }
    );
  };

  const handleAddNewClick = () => {
    return navigate("/mentoring/map-mentor-mentee");
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{MapMentorAndMentee}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              {filteredData?.length > 0 && (
                <Link to={"/mentoring/map-mentor-mentee"}>
                  <Btn
                    icon={
                      <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                    }
                    color="primary"
                  >
                    {AddNew}
                  </Btn>
                </Link>
              )}
            </div>
          </div>

          <Divider />
          {filteredData?.length > 0 ? (
            <>
              <div className="mt-3">
                <TotalSeatCard data={TotalSeatData} />
              </div>

              <DataTable
                className="mt-3"
                highlightOnHover
                pointerOnHover
                columns={MappedMentoringColumns}
                data={filteredData}
                customStyles={customStyles}
                onRowClicked={handleRowClick}
              />
            </>
          ) : (
            <NoData
              svg={"empty-folder-icon"}
              title={NoDataText}
              buttonText={AddNew}
              showIcon
              onclick={handleAddNewClick}
            />
          )}
          <Divider />
        </CardBody>
      </Card>
    </div>
  );
};

export default ManageMentoring;
