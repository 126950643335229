import PropTypes from "prop-types";

const MediaPlayer = ({ src }) => (
  <audio controls>
    <source src={src} type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
);

MediaPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default MediaPlayer;
