import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Input, Row } from "reactstrap";

import { MUIIcons } from "../../AbstractElements";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import { NominatedOn, RM, SBU, Status } from "../../utils/Constant";
import "./style.scss";
import { scheduledDate } from "../../utils/helper/helper";
import CheckListModal from "../CheckListModal";
import UserDetailsCard from "../UserDetailsCard";

interface UserActionCardProps {
  userDetails: UserActionCardData;
  checkBox?: boolean;
  info?: boolean;
  nominatedOn?: boolean;
  actions?: boolean;
  status?: boolean;
  onClickDropdownItem?: (value, id, participant_id, name, status) => void;
  onInfoClick?: (employee: UserActionCardData) => void;
  onSelect?: (id: number) => void;
  onReportClick?: (employee: UserActionCardData) => void;
  firstItem?: string;
  secondItem?: string;
  thirdItem?: string;
  approveOrReject?: boolean;
  report?: boolean;
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
  selectedUserId?: number | null;
  replace_id?: any;
}

interface UserActionCardData {
  id: number;
  info?: any;
  name: string;
  user_name?: string;
  empId: string;
  userId: string;
  unique_id?: any;
  mobile: string;
  sbu: string;
  rmName: string;
  rmMobileNo: string;
  selected?: boolean;
  nominatedOn?: string;
  participant_id?: string;
  status?: string;
  rules?: any;
  checkList?: {
    id: number;
    isChecked: boolean;
    description: string;
  }[];
}

const UserActionCard: React.FC<UserActionCardProps> = ({
  userDetails,
  info,
  nominatedOn,
  checkBox,
  onInfoClick,
  onReportClick,
  onSelect,
  actions,
  firstItem,
  secondItem,
  thirdItem,
  onClickDropdownItem,
  status,
  report,
  approveOrReject,
  onReject = () => {
    return;
  },
  onApprove = () => {
    return;
  },
  selectedUserId,
  replace_id,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col lg="3">
          <UserDetailsCard
            showUserName
            userDetails={userDetails}
            info={info}
            report={report}
            waitlist={userDetails?.status === "waitlist"}
            onInfoClick={() => {
              setSelectedType("info");
              toggleModal();
              onInfoClick && onInfoClick(userDetails);
            }}
            onReportClick={() => {
              setSelectedType("report");
              toggleModal();
              onReportClick && onReportClick(userDetails);
            }}
          />
        </Col>

        <Col lg="8" className={"d-flex align-items-center text-gray gap-3"}>
          {userDetails.sbu && (
            <div className="d-flex align-items-center me-3">
              <MUIIcons iconName="BadgeOutlined" size={20} />
              <span className="ms-2">
                {SBU}: {userDetails.sbu}
              </span>
            </div>
          )}
          {userDetails.rmName && userDetails.rmMobileNo && (
            <div className="d-flex align-items-center">
              <MUIIcons iconName="PersonOutline" size={20} />
              <span className="ms-2">
                {RM} {userDetails.rmName} | {userDetails.rmMobileNo}
              </span>
            </div>
          )}

          {nominatedOn && (
            <div className="d-flex align-items-center">
              <span className="ms-2">
                {NominatedOn}: {scheduledDate(userDetails.nominatedOn)}
              </span>
            </div>
          )}

          {status && (
            <div className="d-flex align-items-center">
              <span className="ms-2">
                {Status}: {userDetails.status ? userDetails.status : "-"}
              </span>
            </div>
          )}
        </Col>

        {(approveOrReject || checkBox || actions) && (
          <Col
            lg="1"
            className="d-flex align-items-center justify-content-between"
          >
            {approveOrReject && (
              <>
                <MUIIcons
                  className="primary-icon-color pointer me-2"
                  iconName="CancelOutlined"
                  size={24}
                  onClick={() => onReject(userDetails?.participant_id)}
                />
                <MUIIcons
                  className="text-success pointer me-2"
                  iconName="CheckCircleOutlined"
                  size={24}
                  onClick={() => onApprove(userDetails?.participant_id)}
                />
              </>
            )}

            {checkBox && (
              <Col
                lg="1"
                className="d-flex align-items-center justify-content-between"
              >
                <Input
                  checked={
                    replace_id
                      ? userDetails.id === selectedUserId
                      : userDetails.selected
                  }
                  className="border-primary custom-user-action-checkbox me-2"
                  type="checkbox"
                  onChange={() => onSelect && onSelect(userDetails.id)}
                />
              </Col>
            )}

            {actions && (
              <CardHeaderDropDown
                mainTitle
                firstItem={firstItem}
                secondItem={secondItem}
                thirdItem={thirdItem}
                onClickItem={value =>
                  onClickDropdownItem(
                    value,
                    userDetails.unique_id,
                    userDetails.id,
                    userDetails.user_name,
                    userDetails?.status
                  )
                }
              />
            )}
          </Col>
        )}
      </Row>

      {isModalOpen && (
        <CheckListModal
          isOpen={isModalOpen}
          toggle={toggleModal}
          selectedType={selectedType}
          data={{
            name: userDetails?.user_name,
            userId: userDetails?.empId,
            checkList: userDetails?.info,
            rules: userDetails?.rules,
          }}
        />
      )}
    </>
  );
};

UserActionCard.propTypes = {
  userDetails: PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    user_name: PropTypes.string,
    info: PropTypes.any,
    empId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    unique_id: PropTypes.any,
    mobile: PropTypes.string.isRequired,
    sbu: PropTypes.string.isRequired,
    rmName: PropTypes.string.isRequired,
    rmMobileNo: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    nominatedOn: PropTypes.string,
    participant_id: PropTypes.string,
    rules: PropTypes.array,
    status: PropTypes.string,
    checkList: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number.isRequired,
        isChecked: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  checkBox: PropTypes.bool,
  nominatedOn: PropTypes.bool,
  info: PropTypes.bool,
  onClickDropdownItem: PropTypes.func,
  onInfoClick: PropTypes.func,
  onSelect: PropTypes.func,
  actions: PropTypes.bool,
  firstItem: PropTypes.string,
  secondItem: PropTypes.string,
  thirdItem: PropTypes.string,
  status: PropTypes.bool,
  approveOrReject: PropTypes.bool,
  report: PropTypes.bool,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  selectedUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  replace_id: PropTypes.any,
};

export default UserActionCard;
