import { ChangeEvent, useEffect, useState } from "react";
import { Form, Input } from "reactstrap";

import { MUIIcons } from "../../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setResponsiveSearch } from "../../../ReduxToolkit/Reducers/LayoutSlice";
import {
  MenuItem,
  SearchSuggestionItem,
} from "../../../Types/Layout/SidebarType";
import { Search } from "../../../utils/Constant";

import ResponsiveSearchList from "./ResponsiveSearchList";

const SearchInput = () => {
  const [arr, setArr] = useState<SearchSuggestionItem[]>([]);
  const [searchedWord, setSearchedWord] = useState<string>("");
  const [searchedArray, setSearchedArray] = useState<SearchSuggestionItem[]>(
    []
  );
  const { responsiveSearch, sideBarMenuItems } = useAppSelector(
    state => state.layout
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setResponsiveSearch());
    setSearchedWord("");
    document.body.classList.remove("offcanvas");
  };

  useEffect(() => {
    const suggestionArray: SearchSuggestionItem[] = [];
    const getAllLink = (item: MenuItem, icon: string | undefined) => {
      if (item.children) {
        item.children?.forEach(ele => {
          getAllLink(ele, icon);
        });
      } else {
        suggestionArray.push({
          icon: icon,
          title: item.title,
          path: item.path || "",
        });
      }
    };
    sideBarMenuItems?.forEach(item => {
      item.Items?.forEach(child => {
        getAllLink(child, child.icon);
      });
    });
    setArr(suggestionArray);
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!searchedWord) setSearchedWord("");
    setSearchedWord(e.target.value);
    const data = [...arr];
    const result = data.filter(item =>
      item.title?.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchedArray(result);
  };
  return (
    <Form
      className={`form-inline search-full ${responsiveSearch ? "open" : ""}`}
      method="get"
    >
      <div className="w-100 form-group">
        <div className="Typeahead Typeahead--twitterUsers">
          <div className="u-posRelative">
            <Input
              className="demo-input Typeahead-input form-control-plaintext w-100"
              type="text"
              placeholder={Search}
              name="q"
              value={searchedWord}
              onChange={e => handleSearch(e)}
            />
            <div className="spinner-border Typeahead-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <MUIIcons
              size={24}
              className="close-search"
              iconName="CloseOutlined"
              onClick={handleClose}
            />
          </div>
          <div
            className={`Typeahead-menu ${searchedWord.length ? "is-open" : ""}`}
          >
            <ResponsiveSearchList
              searchedArray={searchedArray}
              setSearchedWord={setSearchedWord}
            />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SearchInput;
