import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import {
  Badges,
  Btn,
  DefaultButton,
  H3,
  H5,
} from "../../../../AbstractElements";
import {
  ALL_USERS_STATUS,
  CONTENT_APPROVED_SME,
  CONTENT_APPROVED_TM,
  CONTENT_REJECTED_SME,
  CONTENT_REJECTED_TM,
  CONTENT_SUBMITTED,
  DRAFT_STATUS,
  WBT,
  LO_APPROVED_SME,
  LO_APPROVED_TM,
  LO_REJECTED_SME,
  LO_REJECTED_TM,
  LO_SUBMITTED,
  NEW_STATUS,
  PUBLISHED,
  REJECTED_STATUS,
  SCHEDULED_FOR_PUBLISH,
  WEBINAR,
  LEARNING_OUTCOME,
  LESSON_PLAN,
  COURSE_STRUCTURE,
  ASSESSMENTS,
  COURSE_MATERIALS,
  COMPETENCY_STATEMENT,
} from "../../../../Api/constants";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import JustifyTabs from "../../../../CommonElements/JustifyTabs";
import CommonModal from "../../../../CommonElements/Modal";
import SearchBar from "../../../../CommonElements/SearchBar";
import BasicCourseDetails from "../../../../container/BasicCourseDetails";
import CourseInfoTypeCard from "../../../../container/CourseInfoTypeCard";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAssessment } from "../../../../ReduxToolkit/Reducers/AssessmentSlice";
import {
  getCourseContent,
  getCourseScheduledList,
  getPublishedCourseList,
  reviewTrainerOrNominee,
  submitCourseDetailsByTrainer,
} from "../../../../ReduxToolkit/Reducers/CourseSlice";
import { getUsersList } from "../../../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../../../Service";
import {
  CourseStructure,
  Assessment,
  CompetencyStatements,
  Completed,
  LearningOutcomes,
  LessonPlans,
  Locked,
  MaterialsHeader,
  TrainerBondDetails,
  YetToStart,
  InDraft,
  SubmitContentHeader,
  Confirm,
  SubmitContentConfirmation,
  Submit,
  ScheduleList,
  ScheduleNow,
  Status,
  Select,
  StartDate,
  EndDate,
  Locations,
  ClassRoom,
  ApproveCourseSchedule,
  RejectCourseSchedule,
  Approve,
  Reject,
} from "../../../../utils/Constant";
import {
  courseDetailsTabs,
  courseScheduledColumns,
  customTableStyles,
  getCurrentDate,
  scheduleStatusDropValues,
} from "../../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../../utils/helper/permission";
import "./style.scss";

const CourseDetailedView = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState("");
  const [selectedModalType, setSelectedModalType] = useState("");
  const { usersList } = useAppSelector(state => state.user);
  const [userMap, setUserMap] = useState(null);
  const { scheduled } = location.state || "";
  const { courseContent, courseScheduleList } = useAppSelector(
    state => state.course
  );
  const [imageListData, setImageListData] = useState({});
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const { courseAssessments } = useAppSelector(state => state.assessment);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("details");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [locationFilterValues, setLocationFilterValues] = useState([]);
  const [classroomFilterValues, setClassroomFilterValues] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const courseId = queryParams.get("courseId");

  const toggleTooltip = id => {
    setTooltipOpen(tooltipOpen === id ? null : id);
  };

  const handleSearch = (value, courses) => {
    if (!value) return courses;

    return courses.filter(course =>
      [
        course.course_name,
        course.primary_trainer_name,
        course?.location_name,
        course?.classroom_name,
        course.course_start_date,
        course.course_end_date,
        course.nomination_start_date,
        course.nomination_end_date,
      ].some(field => field?.toLowerCase()?.includes(value.toLowerCase()))
    );
  };

  const filteredCourses = handleSearch(
    searchValue,
    courseScheduleList?.scheduled_courses || []
  );

  useEffect(() => {
    const locationMap = [];
    const classRoomMap = [];
    const existingLocation = [];
    const existingClassRoom = [];
    courseScheduleList?.scheduled_courses?.forEach(item => {
      if (!existingLocation.includes(item?.location_id)) {
        existingLocation.push(item?.location_id);
        locationMap.push({
          name: item?.location_id,
          label: item?.location_name,
          value: item?.location_name,
        });
      }
      if (!existingClassRoom.includes(item?.classroom_id)) {
        existingClassRoom.push(item?.classroom_id);
        classRoomMap.push({
          name: item?.classroom_id,
          label: item?.classroom_name,
          value: item?.classroom_name,
        });
      }
    });
    setLocationFilterValues(locationMap);
    setClassroomFilterValues(classRoomMap);
  }, [courseScheduleList]);

  useEffect(() => {
    dispatch(getPublishedCourseList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    if (scheduled) {
      setActiveTab("schedules");
    }
  }, [scheduled]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    if (courseId) {
      dispatch(getCourseContent({ id: courseId }));
      dispatch(getAssessment({ id: Number(courseId) }));
      dispatch(
        getCourseScheduledList({
          course_id: courseId,
          from_date: "",
          to_date: "",
          location_id: "",
          classroom_id: "",
          status: "",
        })
      );
    }
  }, [dispatch, courseId]);

  useEffect(() => {
    if (
      courseAssessments?.assessments?.skill?.length > 0 &&
      courseAssessments?.assessments?.skill[0]?.status
    ) {
      setAssessmentStatus(courseAssessments?.assessments?.skill[0]?.status);
    }
    if (
      courseAssessments?.assessments?.knowledge?.length > 0 &&
      courseAssessments?.assessments?.knowledge[0]?.status
    ) {
      setAssessmentStatus(courseAssessments?.assessments?.knowledge[0]?.status);
    }
  }, [courseAssessments]);

  useEffect(() => {
    const trainers = [];
    const smes = [];
    if (courseContent) {
      courseContent?.course_assignees?.forEach(item => {
        const newItem = Object.assign({}, item, { img: "2.png" });
        if (item.assignment_type === "trainer") {
          trainers.push(newItem);
        }
        if (item.assignment_type === "sme") {
          smes.push(newItem);
        }
      });
    }
    setImageListData({ trainer: trainers, sme: smes });
  }, [courseContent]);

  const getComponentMessage = (type: string, message: string) => {
    if (type === "LO") {
      return message;
    }
    if (type === "COMP") {
      if (!courseContent?.component_status?.competency_statement_status) {
        if (message === Locked) return Locked;
        return YetToStart;
      } else if (
        courseContent?.component_status?.competency_statement_status === "draft"
      ) {
        return InDraft;
      } else {
        return Completed;
      }
    }
    if (type === "LESSON") {
      if (!courseContent?.component_status?.lesson_plan_status) {
        if (message === Locked) return Locked;
        return YetToStart;
      } else if (
        courseContent?.component_status?.lesson_plan_status === "draft"
      ) {
        return InDraft;
      } else {
        return Completed;
      }
    }
    if (type === "INFO") {
      if (!courseContent?.component_status?.additional_information_status) {
        if (message === Locked) return Locked;
        return YetToStart;
      } else if (
        courseContent?.component_status?.additional_information_status ===
        "draft"
      ) {
        return InDraft;
      } else {
        return Completed;
      }
    }
    if (type === "ASSESSMENT") {
      if (!assessmentStatus) {
        if (message === Locked) return Locked;
        return YetToStart;
      } else if (assessmentStatus === "draft") {
        return InDraft;
      } else {
        return Completed;
      }
    }
    if (type === "MATERIAL") {
      if (!courseContent?.component_status?.materials_status) {
        if (message === Locked) return Locked;
        return YetToStart;
      } else if (
        courseContent?.component_status?.materials_status === "draft"
      ) {
        return InDraft;
      } else {
        return Completed;
      }
    }
    if (type === "TRAINER") {
      return message;
    }
  };

  const getComponentColors = (type: string, color: string) => {
    if (type === "LO") {
      return color;
    }
    if (type === "COMP") {
      if (!courseContent?.component_status?.competency_statement_status) {
        if (getStatusFromContent(type) === Locked) return "";
        return "danger";
      } else if (
        courseContent?.component_status?.competency_statement_status === "draft"
      ) {
        return "warning";
      } else {
        return "success";
      }
    }
    if (type === "LESSON") {
      if (!courseContent?.component_status?.lesson_plan_status) {
        if (getStatusFromContent(type) === Locked) return "";
        return "danger";
      } else if (
        courseContent?.component_status?.lesson_plan_status === "draft"
      ) {
        return "warning";
      } else {
        return "success";
      }
    }
    if (type === "INFO") {
      if (!courseContent?.component_status?.additional_information_status) {
        if (getStatusFromContent(type) === Locked) return "";
        return "danger";
      } else if (
        courseContent?.component_status?.additional_information_status ===
        "draft"
      ) {
        return "warning";
      } else {
        return "success";
      }
    }
    if (type === "ASSESSMENT") {
      if (!assessmentStatus) {
        if (getStatusFromContent(type) === Locked) return "";
        return "danger";
      } else if (assessmentStatus === "draft") {
        return "warning";
      } else {
        return "success";
      }
    }
    if (type === "MATERIAL") {
      if (!courseContent?.component_status?.materials_status) {
        if (getStatusFromContent(type) === Locked) return "";
        return "danger";
      } else if (
        courseContent?.component_status?.materials_status === "draft"
      ) {
        return "warning";
      } else {
        return "success";
      }
    }
    if (type === "TRAINER") {
      return color;
    }
  };

  const getStatusFromContent = (type: string) => {
    const status = courseContent?.status;

    if (
      status === NEW_STATUS &&
      courseContent?.course_type === WBT &&
      type === "MATERIAL"
    ) {
      return getComponentMessage(type, InDraft);
    }

    if (
      status === NEW_STATUS ||
      status === LO_SUBMITTED ||
      status === LO_REJECTED_TM ||
      status === LO_REJECTED_SME
    ) {
      if (type === "LO") {
        if (status !== NEW_STATUS) return Completed;
        return getComponentMessage(type, YetToStart);
      }
      if (type !== "LO") {
        return getComponentMessage(type, Locked);
      }
    }

    if (
      status === DRAFT_STATUS ||
      status === LO_APPROVED_TM ||
      status === LO_APPROVED_SME ||
      status === CONTENT_SUBMITTED
    ) {
      if (type === "LO") {
        return getComponentMessage(type, Completed);
      }
      if (type === "TRAINER") {
        return getComponentMessage(type, Locked);
      }
      if (type !== "LO") {
        return getComponentMessage(type, InDraft);
      }
    }

    if (
      status === REJECTED_STATUS ||
      status === CONTENT_REJECTED_TM ||
      status === CONTENT_REJECTED_SME
    ) {
      if (type === "LO") {
        return getComponentMessage(type, Completed);
      }
      if (type === "TRAINER") {
        return getComponentMessage(type, Locked);
      }
      if (type !== "LO") {
        return getComponentMessage(type, InDraft);
      }
    }

    if (status === CONTENT_APPROVED_SME) {
      if (
        courseContent?.course_type === WEBINAR ||
        courseContent?.course_type === WBT
      ) {
        if (type === "LO") {
          return getComponentMessage(type, Completed);
        }
        if (type !== "LO") {
          return getComponentMessage(type, InDraft);
        }
      } else {
        if (type === "LO") {
          return getComponentMessage(type, Completed);
        }
        if (type === "TRAINER") {
          return getComponentMessage(type, Locked);
        }
        if (type !== "LO") {
          return getComponentMessage(type, InDraft);
        }
      }
    }

    if (status === CONTENT_APPROVED_TM) {
      if (type === "LO") {
        return getComponentMessage(type, Completed);
      }
      if (type === "TRAINER") {
        return getComponentMessage(type, YetToStart);
      }
      if (type !== "LO") {
        return getComponentMessage(type, Completed);
      }
    }

    if (status === PUBLISHED || status === SCHEDULED_FOR_PUBLISH)
      return getComponentMessage(type, Completed);
  };

  const getStageFromContent = (type: string) => {
    const status = courseContent?.status;

    if (
      status === NEW_STATUS &&
      courseContent?.course_type === WBT &&
      type === "MATERIAL"
    ) {
      return "1";
    }

    if (
      status === NEW_STATUS ||
      status === LO_SUBMITTED ||
      status === LO_REJECTED_TM ||
      status === LO_REJECTED_SME
    ) {
      if (type === "LO") {
        return "1";
      }
      if (type !== "LO") {
        return "0";
      }
    }

    if (
      status === DRAFT_STATUS ||
      status === LO_APPROVED_TM ||
      status === LO_APPROVED_SME ||
      status === CONTENT_SUBMITTED
    ) {
      if (type === "LO") {
        return "1";
      }
      if (type === "TRAINER") {
        return "0";
      }
      if (type !== "LO") {
        return "1";
      }
    }

    if (
      status === REJECTED_STATUS ||
      status === CONTENT_REJECTED_TM ||
      status === CONTENT_REJECTED_SME
    ) {
      if (type === "LO") {
        return "1";
      }
      if (type === "TRAINER") {
        return "0";
      }
      if (type !== "LO") {
        return "1";
      }
    }

    if (status === CONTENT_APPROVED_SME) {
      if (
        courseContent?.course_type === WEBINAR ||
        courseContent?.course_type === WBT
      ) {
        return "1";
      } else {
        if (type === "LO") {
          return "1";
        }
        if (type === "TRAINER") {
          return "0";
        }
        if (type !== "LO") {
          return "1";
        }
      }
    }

    if (
      status === CONTENT_APPROVED_TM ||
      status === PUBLISHED ||
      status === SCHEDULED_FOR_PUBLISH
    ) {
      return "1";
    }
  };

  const getColorFromContent = (type: string) => {
    const status = courseContent?.status;

    if (
      status === NEW_STATUS &&
      courseContent?.course_type === WBT &&
      type === "MATERIAL"
    ) {
      return getComponentColors(type, "warning");
    }

    if (
      status === NEW_STATUS ||
      status === LO_SUBMITTED ||
      status === LO_REJECTED_TM ||
      status === LO_REJECTED_SME
    ) {
      if (type === "LO") {
        if (status !== NEW_STATUS) return "success";
        return getComponentColors(type, "danger");
      }
      if (type !== "LO") {
        return getComponentColors(type, "");
      }
    }

    if (
      status === DRAFT_STATUS ||
      status === LO_APPROVED_TM ||
      status === LO_APPROVED_SME ||
      status === CONTENT_SUBMITTED
    ) {
      if (type === "LO") {
        return getComponentColors(type, "success");
      }
      if (type === "TRAINER") {
        return getComponentColors(type, "");
      }
      if (type !== "LO") {
        return getComponentColors(type, "warning");
      }
    }

    if (
      status === REJECTED_STATUS ||
      status === CONTENT_REJECTED_TM ||
      status === CONTENT_REJECTED_SME
    ) {
      if (type === "LO") {
        return getComponentColors(type, "success");
      }
      if (type === "TRAINER") {
        return getComponentColors(type, "");
      }
      if (type !== "LO") {
        return getComponentColors(type, "warning");
      }
    }

    if (status === CONTENT_APPROVED_SME) {
      if (
        courseContent?.course_type === WEBINAR ||
        courseContent?.course_type === WBT
      ) {
        if (type === "LO") {
          return getComponentColors(type, "success");
        }
        if (type !== "LO") {
          return getComponentColors(type, "warning");
        }
      } else {
        if (type === "LO") {
          return getComponentColors(type, "success");
        }
        if (type === "TRAINER") {
          return getComponentColors(type, "");
        }
        if (type !== "LO") {
          return getComponentColors(type, "warning");
        }
      }
    }

    if (status === CONTENT_APPROVED_TM) {
      if (type === "LO") {
        return getComponentColors(type, "success");
      }
      if (type === "TRAINER") {
        return getComponentColors(type, "danger");
      }
      if (type !== "LO") {
        return getComponentColors(type, "success");
      }
    }

    if (status === PUBLISHED || status === SCHEDULED_FOR_PUBLISH)
      return getComponentColors(type, "success");
  };

  const getPath = (basePath: string, addPath: string, stage: string) => {
    const isApprovedOrPublished = [
      CONTENT_SUBMITTED,
      CONTENT_APPROVED_TM,
      CONTENT_APPROVED_SME,
      PUBLISHED,
      SCHEDULED_FOR_PUBLISH,
    ].includes(courseContent?.status);

    const isLearningOutcomeStage = stage === LEARNING_OUTCOME;
    const hasPermission = hasPermissionToComponent("CREATE_COURSE_STAGES");

    if (hasPermission && !isApprovedOrPublished) {
      if (isLearningOutcomeStage) {
        const isLOApproved = [LO_APPROVED_SME, LO_APPROVED_TM].includes(
          courseContent?.status
        );

        return isLOApproved ? basePath : addPath;
      }
      return addPath;
    }

    return basePath;
  };

  const submitContent = async () => {
    const combinedArray = [
      ...(courseContent?.competency_statement?.tool_aid?.tool || []),
      ...(courseContent?.competency_statement?.tool_aid?.training_aid || []),
    ];

    const content = {
      ...courseContent,
      status: CONTENT_SUBMITTED,
      min_attendance: courseContent.additional_information.min_attendance,
      max_attendance: courseContent.additional_information.max_attendance,
      suggested_reading: courseContent.additional_information.suggested_reading,
      qualification_certification:
        courseContent.additional_information.qualification_certification,
      assessment_criteria:
        courseContent.additional_information.assessment_criteria,
      theory_time_ratio:
        courseContent.additional_information.theory_time_ratio || 0,
      practical_time_ratio:
        courseContent.additional_information.practical_time_ratio || 0,
      tooling_aids_required:
        courseContent.additional_information.tooling_aids_required,
      date:
        !courseContent.competency_statement.date ||
        courseContent.competency_statement.date === "" ||
        courseContent.competency_statement.date === "None"
          ? getCurrentDate()
          : courseContent.competency_statement.date,
      prerequisite: courseContent.competency_statement.prerequisite,
      version: courseContent.competency_statement.version,
      conditions: courseContent.competency_statement.conditions,
      min_requirements: courseContent.competency_statement.min_requirements,
      media_demonstration:
        courseContent.competency_statement.media_demonstration,
      estimated_time: courseContent.competency_statement.estimated_time,
      classroom_lab: courseContent.competency_statement.classroom_lab,
      resources: combinedArray || [],
      reference: courseContent.competency_statement.reference,
      resources_activity: courseContent.competency_statement.resources_activity,
      review: courseContent.competency_statement.review,
      assessment: courseContent.competency_statement.assessment,
      intended_audience: courseContent.additional_information.intended_audience,
      other_intended_audiences:
        courseContent.additional_information.other_intended_audiences,
      pre_course_work:
        courseContent.additional_information.pre_course_work || [],
      other_pre_course_works:
        courseContent.additional_information.other_pre_course_works || "",
      class_room_ratio: courseContent.competency_statement.class_room_ratio,
      practical_class_ratio:
        courseContent.competency_statement.practical_class_ratio,
      other_references: courseContent?.competency_statement?.other_references,
      other_tools: courseContent?.competency_statement?.other_tools,
      other_training_aids:
        courseContent?.competency_statement?.other_training_aids,
      other_prerequisites:
        courseContent?.competency_statement?.other_prerequisites,
      practical_activity_details:
        courseContent?.additional_information?.practical_activity_details,
      registration_requirement:
        courseContent?.additional_information?.registration_requirement,
    };
    await dispatch(submitCourseDetailsByTrainer({ courseContent: content }));
    await dispatch(getCourseContent({ id: courseId }));
  };

  const isAllStagesDone = () => {
    if (
      courseContent?.component_status?.competency_statement_status ===
        "completed" &&
      courseContent?.component_status?.lesson_plan_status === "completed" &&
      courseContent?.component_status?.additional_information_status ===
        "completed" &&
      courseContent?.component_status?.materials_status === "completed" &&
      assessmentStatus === "completed"
    ) {
      return true;
    }
    return false;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const isToShowMessageIndicator = (requiredStage: string) => {
    try {
      const infoStage = courseContent?.comment?.comment_status?.find(
        (stage: { stages: string }) => stage.stages === requiredStage
      );
      return infoStage ? !infoStage.is_read : false;
    } catch (error) {
      return false;
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const fromDate = values["start_date"] ? values["start_date"][0] : "";
    const toDate = values["end_date"] ? values["end_date"][0] : "";

    const formattedFromDate = fromDate
      ? new Date(fromDate).toLocaleDateString("en-CA").split("T")[0]
      : "";
    const formattedToDate = toDate
      ? new Date(toDate).toLocaleDateString("en-CA").split("T")[0]
      : "";

    const locationIds = values["location"] ? values["location"].join(",") : "";
    const classroomIds = values["classroom"]
      ? values["classroom"].join(",")
      : "";
    const status = values["status"] ? values["status"].join(",") : "";

    await dispatch(
      getCourseScheduledList({
        course_id: courseId,
        from_date: formattedFromDate,
        to_date: formattedToDate,
        location_id: locationIds,
        classroom_id: classroomIds,
        status: status,
      })
    );
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    await dispatch(
      getCourseScheduledList({
        course_id: courseId,
        from_date: "",
        to_date: "",
        location_id: "",
        classroom_id: "",
        status: "",
      })
    );
  };

  const handleRowClick = row => {
    if (row?.status !== "tm_cancelled")
      return navigate("/schedule-details", {
        state: {
          courseId: courseId,
          scheduledId: row?.course_schedule_id,
        },
      });
  };

  const onReview = (id, type) => {
    setSelectedId(id);
    setSelectedModalType(type);
    toggleModal();
  };

  let sizeTitle = "";
  if (selectedModalType === "approve") {
    sizeTitle = Approve;
  } else if (selectedModalType === "reject") {
    sizeTitle = Reject;
  } else {
    sizeTitle = SubmitContentHeader;
  }

  const renderModalBody = type => {
    if (type === "submit") {
      return <p>{SubmitContentConfirmation}</p>;
    }

    if (selectedModalType === "approve") {
      return ApproveCourseSchedule;
    }

    if (selectedModalType === "reject") {
      return RejectCourseSchedule;
    }
  };

  const handlePrimaryBtnClick = async () => {
    if (selectedModalType === "approve" || selectedModalType === "reject") {
      const payload = {
        action: selectedModalType === "approve" ? "approved" : "rejected",
        type: "trainer",
        id: null,
        reason: "",
        course_schedule_id: selectedId,
      };

      await dispatch(reviewTrainerOrNominee({ review: payload }));

      await dispatch(
        getCourseScheduledList({
          course_id: "",
          from_date: "",
          to_date: "",
          location_id: "",
          classroom_id: "",
          status: "",
        })
      );
    } else if (selectedModalType === "submit") {
      submitContent();
    }

    toggleModal();
  };

  return (
    <div className="page-body pb-5">
      <CommonModal
        sizeTitle={sizeTitle}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryBtnClick}
        primaryBtnText={Confirm}
      >
        {renderModalBody(selectedModalType)}
      </CommonModal>
      <BasicCourseDetails
        userMap={userMap}
        courseContent={courseContent}
        imageData={imageListData}
        statusBadge={
          <H5>
            <Badges status={courseContent?.status} />
          </H5>
        }
        header={courseContent?.course_name}
        rm={courseContent?.objective}
      />

      {hasPermissionToComponent("SCHEDULE_COURSE_TABS") &&
        courseContent?.course_type !== WBT &&
        courseContent?.course_type !== WEBINAR && (
          <Card>
            <CardBody className="details-tab-width">
              <JustifyTabs
                tabs={courseDetailsTabs}
                activeTab={activeTab}
                onTabClick={setActiveTab}
              />
            </CardBody>
          </Card>
        )}

      {((hasPermissionToComponent("SCHEDULE_COURSE_TABS") &&
        activeTab === "details") ||
        !hasPermissionToComponent("SCHEDULE_COURSE_TABS")) && (
        <div>
          {courseContent?.course_type !== WBT && (
            <Row>
              <Col>
                <CourseInfoTypeCard
                  courseId={courseId}
                  name={LearningOutcomes}
                  status={getStatusFromContent("LO")}
                  color={getColorFromContent("LO")}
                  imagePath={dynamicImage("course/stage_1.png")}
                  stage={getStageFromContent("LO")}
                  path={getPath(
                    "/course-management/course-details/learning-outcome",
                    "/course-management/course-details/add-learning-outcome",
                    LEARNING_OUTCOME
                  )}
                  showMessage={
                    courseContent?.comment?.learning_outcome?.length > 0
                  }
                  showMessageIndicator={isToShowMessageIndicator(
                    "learning_outcome"
                  )}
                />
              </Col>
              <Col>
                <CourseInfoTypeCard
                  courseId={courseId}
                  name={CompetencyStatements}
                  status={getStatusFromContent("COMP")}
                  color={getColorFromContent("COMP")}
                  imagePath={dynamicImage("course/stage_2.png")}
                  stage={getStageFromContent("COMP")}
                  path={
                    courseContent?.course_type !== WEBINAR
                      ? getPath(
                          "/course-management/course-details/competancy-statement",
                          "/course-management/course-details/add-competency-statement",
                          COMPETENCY_STATEMENT
                        )
                      : getPath(
                          "/course-management/course-details/webinar-competency-statement",
                          "/course-management/course-details/add-webinar-competency-statement",
                          COMPETENCY_STATEMENT
                        )
                  }
                  showMessage={
                    courseContent?.comment?.competency_statement?.length > 0
                  }
                  showMessageIndicator={isToShowMessageIndicator(
                    "competency_statement"
                  )}
                />
              </Col>
            </Row>
          )}
          {courseContent?.course_type !== WBT &&
            courseContent?.course_type !== WEBINAR && (
              <Row>
                <Col>
                  <CourseInfoTypeCard
                    courseId={courseId}
                    name={LessonPlans}
                    status={getStatusFromContent("LESSON")}
                    color={getColorFromContent("LESSON")}
                    imagePath={dynamicImage("course/stage_3.png")}
                    stage={getStageFromContent("LESSON")}
                    path={getPath(
                      "/course-management/course-details/lesson-plan",
                      "/course-management/course-details/add-lesson-plan",
                      LESSON_PLAN
                    )}
                    showMessage={
                      courseContent?.comment?.lesson_plan?.length > 0
                    }
                    showMessageIndicator={isToShowMessageIndicator(
                      "lesson_plan"
                    )}
                  />
                </Col>
                <Col>
                  <CourseInfoTypeCard
                    courseId={courseId}
                    name={CourseStructure}
                    status={getStatusFromContent("INFO")}
                    color={getColorFromContent("INFO")}
                    imagePath={dynamicImage("course/stage_4.png")}
                    stage={getStageFromContent("INFO")}
                    path={getPath(
                      "/course-management/course-details/course-structure",
                      "/course-management/course-details/add-course-structure",
                      COURSE_STRUCTURE
                    )}
                    showMessage={
                      courseContent?.comment?.additional_information?.length > 0
                    }
                    showMessageIndicator={isToShowMessageIndicator(
                      "additional_information"
                    )}
                  />
                </Col>
              </Row>
            )}
          {courseContent?.course_type !== WEBINAR && (
            <Row
              className={
                hasPermissionToComponent("CREATE_COURSE_STAGES") ? "pb-5" : ""
              }
            >
              {courseContent?.course_type !== WBT && (
                <Col>
                  <CourseInfoTypeCard
                    courseId={courseId}
                    name={Assessment}
                    status={getStatusFromContent("ASSESSMENT")}
                    color={getColorFromContent("ASSESSMENT")}
                    imagePath={dynamicImage("course/stage_5.png")}
                    stage={getStageFromContent("ASSESSMENT")}
                    path={getPath(
                      "/course-management/course-details/assessment",
                      "/course-management/course-details/add-assessment",
                      ASSESSMENTS
                    )}
                    showMessage={courseContent?.comment?.assessment?.length > 0}
                    showMessageIndicator={isToShowMessageIndicator(
                      "assessment"
                    )}
                  />
                </Col>
              )}
              <Col>
                <CourseInfoTypeCard
                  courseId={courseId}
                  name={MaterialsHeader}
                  status={getStatusFromContent("MATERIAL")}
                  color={getColorFromContent("MATERIAL")}
                  imagePath={dynamicImage("course/stage_6.png")}
                  stage={getStageFromContent("MATERIAL")}
                  path={getPath(
                    "/course-management/course-details/course-material",
                    "/course-management/course-details/add-material",
                    COURSE_MATERIALS
                  )}
                  showMessage={
                    courseContent?.comment?.course_material?.length > 0
                  }
                  showMessageIndicator={isToShowMessageIndicator(
                    "course_material"
                  )}
                />
              </Col>
            </Row>
          )}
          {hasPermissionToComponent("TRAINER_BOND_DETAILS") &&
            courseContent?.course_type !== WBT &&
            courseContent?.course_type !== WEBINAR && (
              <Row className="pb-5">
                <Col lg="6">
                  <CourseInfoTypeCard
                    courseId={courseId}
                    name={TrainerBondDetails}
                    status={getStatusFromContent("TRAINER")}
                    color={getColorFromContent("TRAINER")}
                    imagePath={dynamicImage("course/stage_7.png")}
                    stage={getStageFromContent("TRAINER")}
                    path="/course-management/course-details/bond-details"
                    showMessage={false}
                    showMessageIndicator={false}
                  />
                </Col>
              </Row>
            )}

          {hasPermissionToComponent("CREATE_COURSE_STAGES") &&
            (isAllStagesDone() ||
              (courseContent?.course_type === WBT &&
                courseContent?.component_status?.materials_status ===
                  "completed") ||
              (courseContent?.course_type === WEBINAR &&
                courseContent?.component_status?.competency_statement_status ===
                  "completed")) &&
            (courseContent?.status === DRAFT_STATUS ||
              courseContent?.status === LO_APPROVED_SME ||
              courseContent?.status === LO_APPROVED_TM ||
              courseContent?.status === CONTENT_REJECTED_SME ||
              (courseContent?.course_type === WBT &&
                courseContent?.status === NEW_STATUS) ||
              courseContent?.status === CONTENT_REJECTED_TM) && (
              <FixedFooter>
                <DefaultButton
                  onClick={() => {
                    setSelectedModalType("submit");
                    toggleModal();
                  }}
                  color="primary float-end"
                >
                  {Submit}
                </DefaultButton>
              </FixedFooter>
            )}
        </div>
      )}
      {hasPermissionToComponent("SCHEDULE_COURSE_TABS") &&
        activeTab === "schedules" && (
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <H3 className="mt-3">{ScheduleList}</H3>
                <div className="d-flex gap-2 align-items-center">
                  <SearchBar onSearch={value => setSearchValue(value)} />
                  <div className="mt-2">
                    <FilterSliderModal
                      dropdowns={[
                        {
                          label: StartDate,
                          key: "start_date",
                          tooltipText: StartDate,
                          isDate: true,
                          isMultiSelect: false,
                        },
                        {
                          label: EndDate,
                          key: "end_date",
                          tooltipText: EndDate,
                          isDate: true,
                          isMultiSelect: false,
                        },
                        {
                          label: Locations,
                          key: "location",
                          tooltipText: `${Select} ${Locations}`,
                          options: locationFilterValues,
                          isMultiSelect: true,
                        },
                        {
                          label: ClassRoom,
                          key: "classroom",
                          tooltipText: `${Select} ${ClassRoom}`,
                          options: classroomFilterValues,
                          isMultiSelect: true,
                        },
                        {
                          label: Status,
                          key: "status",
                          tooltipText: `${Select} ${Status}`,
                          options: scheduleStatusDropValues,
                          isMultiSelect: true,
                        },
                      ]}
                      selectedFilterValues={selectedFilterValues}
                      defaultFilterValues={defaultFilterValues}
                      setSelectedFilterValues={setSelectedFilterValues}
                      setDefaultFilterValues={setDefaultFilterValues}
                      onDone={handleDone}
                      onClear={handleClear}
                    />
                  </div>
                  {hasPermissionToComponent("SCHEDULE_COURSE") && (
                    <Link
                      to={"/schedule-course"}
                      state={{
                        course_id: courseId,
                        course_name: courseContent?.course_name,
                      }}
                      className="text-white"
                    >
                      <Btn color="primary">{ScheduleNow}</Btn>
                    </Link>
                  )}
                </div>
              </div>
              <div className="mt-3">
                <DataTable
                  className="load-factor-table"
                  columns={courseScheduledColumns(
                    navigate,
                    toggleTooltip,
                    tooltipOpen,
                    onReview,
                    userMap
                  )}
                  data={filteredCourses}
                  pagination
                  fixedHeader
                  striped
                  onRowClicked={handleRowClick}
                  customStyles={customTableStyles}
                />
              </div>
            </CardBody>
          </Card>
        )}
    </div>
  );
};

export default CourseDetailedView;
