import React from "react";

import { P } from "../../AbstractElements";

interface TodayTasksBodyProps {
  assign: string;
  name: React.ReactNode;
}

const IconHeader: React.FC<TodayTasksBodyProps> = ({ assign, name }) => {
  return (
    <div className="flex-grow-1">
      <P className="mb-3 text-gray">{assign}</P>
      {name}
    </div>
  );
};

export default IconHeader;
