import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Col,
  Row,
} from "reactstrap";

import { H4, MUIIcons, P } from "../../AbstractElements";
import "./style.scss";
import { CreatedBy, CreatedOn, ModifiedOn } from "../../utils/Constant";
import { formatCustomDate } from "../../utils/helper/helper";

interface LOAccordionProps {
  accordionItems: LOAccordionItemData;
  onAccordionClick?: (id: string) => void;
  onEditClick?: (id: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  onArchiveClick?: (id: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  unArchive?: boolean;
}

interface LOAccordionItemData {
  id: string;
  title: string;
  description: string;
  depedentBadges: DepedentBadgesData[];
  createdOn: string;
  modifiedOn: string;
  createdBy: string;
}

interface DepedentBadgesData {
  id: number;
  badge: string;
}

const LOAccordion: React.FC<LOAccordionProps> = ({
  accordionItems,
  onAccordionClick = () => {
    return;
  },
  onEditClick,
  onArchiveClick,
  unArchive,
}) => {
  const [open, setOpen] = useState<string | undefined>("0");

  const toggle = (id: string) =>
    open === id ? setOpen(undefined) : setOpen(id);

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem className="custom-lo-accordion mb-3">
        <AccordionHeader targetId={accordionItems.id}>
          <Col>
            <Row className="mb-3">
              <Col lg={11}>
                <H4>{accordionItems.title}</H4>
              </Col>
            </Row>

            <Row>
              <Col lg={10} className="d-flex gap-3">
                {accordionItems?.depedentBadges.map(item => (
                  <Badge
                    key={item.id}
                    className="text-gray custom-lo-accordion-badge"
                  >
                    {item.badge}
                  </Badge>
                ))}
                <div className="d-flex gap-1 text-gray align-items-center">
                  <MUIIcons
                    className="text-gray"
                    iconName="PersonOutlined"
                    size={17}
                  />
                  <P className="mb-0">
                    {CreatedBy}: {accordionItems.createdBy}
                  </P>
                </div>
                <div className="d-flex gap-1 text-gray align-items-center">
                  <MUIIcons
                    className="text-gray"
                    iconName="CalendarTodayOutlined"
                    size={17}
                  />
                  <P className="mb-0">
                    {CreatedOn}: {formatCustomDate(accordionItems.createdOn)}
                  </P>
                </div>
                <div className="d-flex gap-1 text-gray align-items-center">
                  <MUIIcons
                    className="text-gray"
                    iconName="CalendarTodayOutlined"
                    size={17}
                  />
                  <P className="mb-0">
                    {ModifiedOn}: {formatCustomDate(accordionItems.modifiedOn)}
                  </P>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={1} className="d-flex justify-content-end gap-3">
            {unArchive ? (
              <button
                className="border-0 bg-transparent pointer"
                onClick={e => onArchiveClick(accordionItems.id, e)}
              >
                <MUIIcons
                  className="primary-icon-color"
                  iconName="UnarchiveOutlined"
                  size={20}
                />
              </button>
            ) : (
              <button
                className="border-0 bg-transparent pointer"
                onClick={e => onEditClick(accordionItems.id, e)}
              >
                <MUIIcons
                  className="primary-icon-color"
                  iconName="EditOutlined"
                  size={20}
                />
              </button>
            )}

            <MUIIcons
              onClick={() => onAccordionClick(accordionItems.id)}
              size={20}
              iconName="ExpandMoreOutlined"
            />
          </Col>
        </AccordionHeader>
        <AccordionBody accordionId={accordionItems.id}>
          <div
            dangerouslySetInnerHTML={{
              __html: accordionItems.description,
            }}
          />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default LOAccordion;
