import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { Btn, MUIIcons } from "../../../AbstractElements";
import {
  ALL_COURSE_FILTER_STATUS,
  ALL_USERS_STATUS,
  tmTabsStatusMap,
} from "../../../Api/constants";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import JustifyTabs from "../../../CommonElements/JustifyTabs";
import SearchBar from "../../../CommonElements/SearchBar";
import CourseCard from "../../../container/CourseCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getCourseList,
  setCourseContentToNull,
  setCourseListToNull,
} from "../../../ReduxToolkit/Reducers/CourseSlice";
import { setCourseBasicInputFormValueToInitials } from "../../../ReduxToolkit/Reducers/CourseWizardSlice";
import { getDomainList } from "../../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../../ReduxToolkit/Reducers/UserSlice";
import { AddCourse, CourseType, Domain, Select } from "../../../utils/Constant";
import {
  formatCustomDate,
  courseListTabs,
  courseFilterDropdownList,
} from "../../../utils/helper/helper";
import { hasPermissionToComponent } from "../../../utils/helper/permission";
import { CourseFilterState } from "../../../utils/helper/propTypes";
import useIsMobile from "../../../utils/helper/responsive";

const CourseList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusParam = params.get("status");
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [domainListData, setDomainListData] = useState([]);
  const [courseFilterValues, setCourseFilterValues] =
    useState<CourseFilterState>({
      courseType: [],
      domain: [],
    });
  const [activeTab, setActiveTab] = useState<string>(
    statusParam || "new_request"
  );
  const { courseList } = useAppSelector(state => state.course);
  const { usersList } = useAppSelector(state => state.user);
  const { domainList } = useAppSelector(state => state.master);
  const [userNameMap, setUserNameMap] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (statusParam && statusParam !== activeTab) {
      setActiveTab(statusParam);
    }
  }, [statusParam, activeTab]);

  useEffect(() => {
    const domains = [];
    domainList?.domain?.forEach(element => {
      domains.push({
        name: element.id.toString(),
        value: element.domain_name,
        label: element.domain_name,
      });
    });
    setDomainListData(domains);
  }, [domainList]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    await dispatch(getDomainList());
    await dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(setCourseContentToNull());
  };

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserNameMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(setCourseListToNull());
    fetchCourses();
  }, [activeTab, courseFilterValues]);

  const fetchCourses = () => {
    const { courseType = ALL_COURSE_FILTER_STATUS, domain = [] } =
      courseFilterValues;
    const filteredCourseType =
      courseType.length > 0 ? courseType : ALL_COURSE_FILTER_STATUS;
    const filteredDomain = domain.length > 0 ? domain : null;

    dispatch(
      getCourseList({
        status: tmTabsStatusMap[activeTab],
        course_type: filteredCourseType,
        domain: filteredDomain,
      })
    ).then(response => {
      setNoDataFound(!response?.payload?.course_list?.length);
    });
  };

  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId);
    setSearchResults([]);
    navigate(`/course-management/course-list?status=${tabId}`);
  };

  const handleClick = () => {
    dispatch(setCourseContentToNull());
    dispatch(setCourseBasicInputFormValueToInitials());
  };

  const handleSearch = (query: string) => {
    const filteredCourses = courseList?.course_list?.filter(course =>
      course.course_name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredCourses || []);
    setNoDataFound(!filteredCourses || filteredCourses?.length === 0);
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    const selectedCourseTypes = values["request_type"];
    const selectedDomains = values["domain"];
    setCourseFilterValues(prevState => ({
      ...prevState,
      courseType: selectedCourseTypes ?? [],
      domain: selectedDomains ?? [],
    }));
  };

  const handleClear = async () => {
    setSelectedFilterValues({});
    setDefaultFilterValues({});
    setCourseFilterValues({
      courseType: [],
      domain: [],
    });
  };

  const renderCourseCard = (course: CourseList, index) => {
    return (
      <Col key={index} sm={12} lg={3}>
        <Link to={`/course-management/course-details?courseId=${course.id}`}>
          <CourseCard
            userNameMap={userNameMap}
            course_id={course?.id?.toString()}
            title={course?.domain_names}
            header={course?.course_name}
            rm={course?.description}
            requestedOn={formatCustomDate(course?.created_at)}
            status={course?.status}
            levels={
              course?.expertise_levels
                ? course?.expertise_levels.filter(item => item !== null)
                : []
            }
            type={course?.course_type}
            range={`${course?.min_attendance}-${course?.max_attendance}`}
            assignments={course?.assignments}
          />
        </Link>
      </Col>
    );
  };

  const renderCoursesContent = () => {
    if (noDataFound) {
      return <NoDataFound />;
    }

    if (searchResults?.length > 0) {
      return searchResults.map((course, index) =>
        renderCourseCard(course, index)
      );
    }

    return courseList?.course_list?.map((course, index) =>
      renderCourseCard(course, index)
    );
  };

  return (
    <div className="page-body page-body-margin">
      <Card className="p-1">
        <CardBody>
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${!isMobile ? "align-items-center" : ""}`}
          >
            <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
              <JustifyTabs
                tabs={courseListTabs}
                activeTab={activeTab}
                onTabClick={handleTabClick}
              />
            </div>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
              <SearchBar onSearch={handleSearch} />
              <div className="mt-2">
                <FilterSliderModal
                  dropdowns={[
                    {
                      label: CourseType,
                      key: "request_type",
                      tooltipText: `${Select} ${CourseType}`,
                      options: courseFilterDropdownList,
                      isMultiSelect: true,
                    },
                    {
                      label: Domain,
                      key: "domain",
                      tooltipText: `${Select} ${Domain}`,
                      options: domainListData,
                      isMultiSelect: true,
                    },
                  ]}
                  selectedFilterValues={selectedFilterValues}
                  defaultFilterValues={defaultFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setDefaultFilterValues={setDefaultFilterValues}
                  onDone={handleDone}
                  onClear={handleClear}
                />
              </div>
              {hasPermissionToComponent("ADD_COURSE") && (
                <Link
                  to={"/course-management/add-course"}
                  state={{ courseRequestId: "" }}
                >
                  <Btn
                    icon={
                      <MUIIcons size={16} iconName="AddCircleOutlineOutlined" />
                    }
                    color="primary"
                    onClick={handleClick}
                  >
                    {AddCourse}
                  </Btn>
                </Link>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Row className="mt-5">{renderCoursesContent()}</Row>
    </div>
  );
};

export default CourseList;
