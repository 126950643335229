import PropTypes from "prop-types";
import "./style.scss";

const FixedFooter = ({ children, alignFirstButtonStart = false }) => {
  return (
    <footer className="footer-gmmco bg-white text-white mt-3 px-2">
      {alignFirstButtonStart ? (
        <div className="footer-container d-flex justify-content-between">
          <div className="d-flex justify-content-start footer-container-start">
            {children[0]}
          </div>
          <div className="d-flex justify-content-end gap-3">
            {children.slice(1)}
          </div>
        </div>
      ) : (
        <div className="footer-container">
          <div className="py-3 d-flex gap-3">{children}</div>
        </div>
      )}
    </footer>
  );
};

FixedFooter.propTypes = {
  children: PropTypes.node.isRequired,
  alignFirstButtonStart: PropTypes.bool,
};

export default FixedFooter;
