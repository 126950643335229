import AssessmentCatlogList from "../Pages/Assessment/AssessmentCatlog";
import AddAssessmentCatlog from "../Pages/Assessment/AssessmentCatlog/AddAssessmentCatlog";
import AssessmentCatlogDetails from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails";
import ScheduleAssessmentsCatlog from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails/ScheduleAssessment";
import ScheduledUserDetails from "../Pages/Assessment/AssessmentCatlog/AssessmentsCatlogDetails/ScheduledUserDetails";
import LearnerAssessmets from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments";
import CompetencyAssessmentDetails from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessmentDetail";
import CompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/CompetencyAssessments";
import ReviewCompetencyAssessment from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/ReviewCompetencyAssessment";
import ReviewStandaloneAssessments from "../Pages/Assessment/AssessmentCatlog/LearnerAssessments/ReviewStandaloneAssessments";
import ScheduledAssessments from "../Pages/Assessment/ScheduledAssessments";
import AssessmentList from "../Pages/AssessmentList";
import AssessmentListDetails from "../Pages/AssessmentList/AssessmentListDetails";
import Attempts from "../Pages/AssessmentList/AssessmentListDetails/Attempts";
import ComingSoon from "../Pages/ComingSoon";
import CourseCatalog from "../Pages/CourseCatalog";
import CourseDetails from "../Pages/CourseCatalog/CourseDetails";
import CourseList from "../Pages/CourseMangement/CourseList";
import CourseDetailedView from "../Pages/CourseMangement/CourseList/CourseStageDetails";
import AdditionalInformation from "../Pages/CourseMangement/CourseList/CourseStageDetails/AdditionalInformation";
import CreateAdditionalInformation from "../Pages/CourseMangement/CourseList/CourseStageDetails/AdditionalInformation/CreateAdditionalInformation";
import Assessment from "../Pages/CourseMangement/CourseList/CourseStageDetails/Assessment";
import CreateAssessment from "../Pages/CourseMangement/CourseList/CourseStageDetails/Assessment/CreateAssessment";
import CompetancyStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/CompetancyStatement";
import CreateCompetencyStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/CompetancyStatement/CreateCompetencyStatement";
import CourseMaterials from "../Pages/CourseMangement/CourseList/CourseStageDetails/CourseMaterials";
import CreateMaterials from "../Pages/CourseMangement/CourseList/CourseStageDetails/CourseMaterials/CreateMaterials";
import LessonPlan from "../Pages/CourseMangement/CourseList/CourseStageDetails/LessonPlan";
import CreateLessonPlan from "../Pages/CourseMangement/CourseList/CourseStageDetails/LessonPlan/CreateLessonPlan";
import LODetails from "../Pages/CourseMangement/CourseList/CourseStageDetails/LO";
import CreateLO from "../Pages/CourseMangement/CourseList/CourseStageDetails/LO/CreateLo";
import TrainerBondDetails from "../Pages/CourseMangement/CourseList/CourseStageDetails/TrainerBondDetails";
import WebinarCompetativeStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/WebinarCompetencyStatement";
import CreateWebinarCompetativeStatement from "../Pages/CourseMangement/CourseList/CourseStageDetails/WebinarCompetencyStatement/createWebinarCompetencyStatement";
import CreateBasicCourseDetails from "../Pages/CourseMangement/CourseList/CreateBasicDetails";
import RequestList from "../Pages/CourseMangement/RequestList";
import CourseCreation from "../Pages/CourseMangement/RequestList/RaiseNewRequest";
import RequestDetails from "../Pages/CourseMangement/RequestList/RequestDetails";
import AddCurriculum from "../Pages/Curriculum/AddCurriculum";
import CurriculumList from "../Pages/Curriculum/CurriculumList";
import SamplePage from "../Pages/Dashboard/SamplePage";
import GlobalSettings from "../Pages/GlobalSettings";
import LearnerScheduleDetails from "../Pages/LearnerSchedule/LearnerScheduleDetails";
import CourseFeedback from "../Pages/LearnerSchedule/LearnerScheduleDetails/CourseFeedback";
import ModuleFeedback from "../Pages/LearnerSchedule/LearnerScheduleDetails/ModuleFeedback";
import RateNow from "../Pages/LearnerSchedule/LearnerScheduleDetails/ModuleFeedback/RateNow";
import LearnerScheduleList from "../Pages/LearnerSchedule/LearnerScheduleList";
import ParticipantDetails from "../Pages/LearningAssessment/ParticipantDetails";
import LoadFactor from "../Pages/LoadFactor";
import ModifyPlan from "../Pages/LoadFactor/ModifyPlan";
import ScheduleCourse from "../Pages/LoadFactor/ScheduleCourse";
import ViewPeople from "../Pages/LoadFactor/ViewPeople";
import ClassRoomPage from "../Pages/MasterData/ClassRoom";
import CompetencyPage from "../Pages/MasterData/Competency";
import DomainPage from "../Pages/MasterData/Domain";
import IndustryPage from "../Pages/MasterData/Industry";
import JobRolePage from "../Pages/MasterData/JobRole";
import LearningOutcomePage from "../Pages/MasterData/LearningOutcome";
import LOForm from "../Pages/MasterData/LearningOutcome/LOForm";
import QuestionBankList from "../Pages/MasterData/QuestionBank";
import AddQuestionBank from "../Pages/MasterData/QuestionBank/AddQuestionBank";
import QuestionBankPreview from "../Pages/MasterData/QuestionBank/QuestionBankPreview";
import InstructionPage from "../Pages/Mentoring&Coaching/CommonPagesOfMentoring&Coaching/InstructionPage";
import SessionDetailPage from "../Pages/Mentoring&Coaching/CommonPagesOfMentoring&Coaching/SessionDetailsPage";
import ManageMentoring from "../Pages/Mentoring&Coaching/ManageMentoring";
import MapMentorAndMenteeForm from "../Pages/Mentoring&Coaching/ManageMentoring/MapMentorAndMenteeForm";
import MentorDetails from "../Pages/Mentoring&Coaching/ManageMentoring/MentorDetails";
import ReplaceMentor from "../Pages/Mentoring&Coaching/ManageMentoring/ReplaceMentor";
import MenteePage from "../Pages/Mentoring&Coaching/Mentee";
import MenteeDetailsPage from "../Pages/Mentoring&Coaching/Mentee/MenteeDetailsPage";
import MenteeSessionFeedbackForm from "../Pages/Mentoring&Coaching/Mentee/MenteeSessionFeedbackForm";
import MentorDetailsPage from "../Pages/Mentoring&Coaching/Mentee/MentorDetailsPage";
import MentorPage from "../Pages/Mentoring&Coaching/Mentor";
import AddSessionForm from "../Pages/Mentoring&Coaching/Mentor/AddSessionForm";
import MyLearningPlan from "../Pages/MyLearningPlan";
import NominationRequest from "../Pages/NominationRequest";
import NominationCourseDetails from "../Pages/NominationRequest/CourseDetails";
import PolicyList from "../Pages/Policy";
import AddPolicy from "../Pages/Policy/AddPolicy";
import ProfileDetails from "../Pages/ProfileDetails";
import ScheduledCourseList from "../Pages/ScheduledCourse";
import ScheduleDetails from "../Pages/ScheduledCourse/ScheduleDetails";
import AddNominees from "../Pages/ScheduledCourse/ScheduleDetails/AddNominees";
import RatingSummary from "../Pages/ScheduledCourse/ScheduleDetails/Rating/RatingSummary";
import MapSga from "../Pages/SGA/MapSga";
import SgaList from "../Pages/SGA/SgaList";
import UserManagement from "../Pages/UserManagement";
import AddExternalUser from "../Pages/UserManagement/AddUser";
import UserDetails from "../Pages/UserManagement/UserDetails";

const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <ComingSoon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/samplepage`,
    Component: <SamplePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/user-management`,
    Component: <UserManagement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/user-management/user-details`,
    Component: <UserDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/new-request`,
    Component: <CourseCreation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/add-course`,
    Component: <CreateBasicCourseDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-request`,
    Component: <RequestList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-list`,
    Component: <CourseList />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/request-details`,
    Component: <RequestDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details`,
    Component: <CourseDetailedView />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/learning-outcome`,
    Component: <LODetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/competancy-statement`,
    Component: <CompetancyStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/lesson-plan`,
    Component: <LessonPlan />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/course-structure`,
    Component: <AdditionalInformation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/assessment`,
    Component: <Assessment />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/question-bank`,
    Component: <QuestionBankPreview />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/course-material`,
    Component: <CourseMaterials />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/bond-details`,
    Component: <TrainerBondDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-learning-outcome`,
    Component: <CreateLO />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-competency-statement`,
    Component: <CreateCompetencyStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-webinar-competency-statement`,
    Component: <CreateWebinarCompetativeStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/webinar-competency-statement`,
    Component: <WebinarCompetativeStatement />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-lesson-plan`,
    Component: <CreateLessonPlan />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-course-structure`,
    Component: <CreateAdditionalInformation />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-assessment`,
    Component: <CreateAssessment />,
  },

  {
    path: `${process.env.PUBLIC_URL}/course-management/course-details/add-material`,
    Component: <CreateMaterials />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/curriculum-list`,
    Component: <CurriculumList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/policy-list`,
    Component: <PolicyList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-new-curriculum`,
    Component: <AddCurriculum />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/question-bank-list`,
    Component: <QuestionBankList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-question-bank`,
    Component: <AddQuestionBank />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/add-policy`,
    Component: <AddPolicy />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/skill-gap-analysis`,
    Component: <SgaList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/map-sga`,
    Component: <MapSga />,
  },
  {
    path: `${process.env.PUBLIC_URL}/my-learning-plan`,
    Component: <MyLearningPlan />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/add-assessment`,
    Component: <AddAssessmentCatlog />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/assessment-details`,
    Component: <AssessmentCatlogDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/schedule-assessment`,
    Component: <ScheduleAssessmentsCatlog />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/schedule-details`,
    Component: <ScheduledUserDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/learner-assessments`,
    Component: <LearnerAssessmets />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/self-assessment`,
    Component: <CompetencyAssessment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/review-self-assessments`,
    Component: <ReviewCompetencyAssessment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/self-assessment-details`,
    Component: <CompetencyAssessmentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/review-standalone-assessment`,
    Component: <ReviewStandaloneAssessments />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list`,
    Component: <AssessmentList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list/assessment-details`,
    Component: <AssessmentListDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessment-list/assessment-details/attempts`,
    Component: <Attempts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/assessments-list`,
    Component: <AssessmentCatlogList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/assessments/scheduled-assessments`,
    Component: <ScheduledAssessments />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/domain`,
    Component: <DomainPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/competency`,
    Component: <CompetencyPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/learning-outcome`,
    Component: <LearningOutcomePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/learning-outcome/upsert-learning-outcome`,
    Component: <LOForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/industry`,
    Component: <IndustryPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/job-role`,
    Component: <JobRolePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master-data/classroom`,
    Component: <ClassRoomPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring`,
    Component: <ManageMentoring />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor`,
    Component: <MentorPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentee`,
    Component: <MenteePage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/map-mentor-mentee`,
    Component: <MapMentorAndMenteeForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/replace-mentor`,
    Component: <ReplaceMentor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring/mentor-details`,
    Component: <MentorDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/mentoring-details/session-feedback`,
    Component: <MenteeSessionFeedbackForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/session-details`,
    Component: <SessionDetailPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/mentee-details`,
    Component: <MenteeDetailsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor/session-instruction`,
    Component: <InstructionPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor/add-session`,
    Component: <AddSessionForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentee/mentor-details-page`,
    Component: <MentorDetailsPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/load-factor`,
    Component: <LoadFactor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/modify-plan`,
    Component: <ModifyPlan />,
  },
  {
    path: `${process.env.PUBLIC_URL}/people`,
    Component: <ViewPeople />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-course`,
    Component: <ScheduleCourse />,
  },
  {
    path: `${process.env.PUBLIC_URL}/course-schedule-list`,
    Component: <ScheduledCourseList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-details`,
    Component: <ScheduleDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/add-participant`,
    Component: <AddNominees />,
  },
  {
    path: `${process.env.PUBLIC_URL}/rating-summary`,
    Component: <RatingSummary />,
  },
  {
    path: `${process.env.PUBLIC_URL}/global-setting`,
    Component: <GlobalSettings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details`,
    Component: <LearnerScheduleDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list`,
    Component: <LearnerScheduleList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/course-catalog`,
    Component: <CourseCatalog />,
  },
  {
    path: `${process.env.PUBLIC_URL}/course-catalog/course-details`,
    Component: <CourseDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details/course-feedback`,
    Component: <CourseFeedback />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details/module-feedback`,
    Component: <ModuleFeedback />,
  },
  {
    path: `${process.env.PUBLIC_URL}/schedule-list/course-details/rate-now`,
    Component: <RateNow />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nomination-request`,
    Component: <NominationRequest />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nomination-request/course-details`,
    Component: <NominationCourseDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/participant-details`,
    Component: <ParticipantDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/add-employee`,
    Component: <AddExternalUser />,
  },
  {
    path: `${process.env.PUBLIC_URL}/profile`,
    Component: <ProfileDetails />,
  },
];

export default routes;
