import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";

import { H4, P, H6 } from "../../../AbstractElements";
import "./style.scss";
import { Question, QuestionsWronglyAnswered } from "../../../utils/Constant";

const WrongQuestions = ({ wrongQuestions }) => {
  return (
    <Card className="wrong-questions mt-2">
      <CardHeader>
        <H4>{QuestionsWronglyAnswered}</H4>
      </CardHeader>
      <CardBody className="mt-2">
        {wrongQuestions?.map((item, index) => (
          <div className="wrong-questions__question-wrapper" key={index}>
            <P className="wrong-questions__question">
              {Question} {index + 1}
            </P>
            <H6 className="wrong-questions__question-title">
              {item.question_text}
            </H6>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

WrongQuestions.propTypes = {
  wrongQuestions: PropTypes.array.isRequired,
};

export default WrongQuestions;
