import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import {
  Btn,
  DefaultButton,
  H4,
  H5,
  P,
  Progressbar,
  SecondaryButton,
} from "../../../../../AbstractElements";
import AnswerStatusIndicator from "../../../../../CommonElements/AnswerStatusIndicator";
import FileHandler from "../../../../../CommonElements/FileHandler";
import "./style.scss";
import FixedFooter from "../../../../../CommonElements/FixedFooter";
import MCQScoreContainer from "../../../../../container/MCQScoreContainer";
import QuestionNavigator from "../../../../../container/QuestionNavigator";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../ReduxToolkit/Hooks";
import {
  getAssessmentResultForReview,
  reviewLearnerAssessment,
} from "../../../../../ReduxToolkit/Reducers/AssessmentCatlogSlice";
import {
  AnswerGiven,
  Correct,
  Module,
  Next,
  PleaseReviewAll,
  Previous,
  Question,
  SaveAsDraft,
  Submit,
  Wrong,
} from "../../../../../utils/Constant";
import { showToast } from "../../../../../utils/helper/helper";

const ReviewStandaloneAssessments = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { participantId, courseId, scheduledId } = location.state || "";
  const { assessmentResultForReview } = useAppSelector(
    state => state.assessmentCatlog
  );

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalReviewed, setTotalReviewed] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const modulesRef = useRef(modules);

  useEffect(() => {
    setSelectedQuestion(null);
    setSelectedModule(null);
    if (participantId) {
      dispatch(getAssessmentResultForReview({ participant_id: participantId }));
    }
  }, [participantId]);

  useEffect(() => {
    const moduleMap = [];
    let questions = 0;
    let reviewed = 0;
    if (assessmentResultForReview) {
      assessmentResultForReview?.modules?.map((item, index) => {
        const obj = {};
        obj["module_id"] = item?.module_id;
        obj["module_name"] = item?.module_name;
        obj["questions"] = [];
        assessmentResultForReview?.modules[index]?.questions?.map(
          (question, ind) => {
            if (
              question?.reviewer_response &&
              question?.reviewer_response !== null
            ) {
              reviewed = reviewed + 1;
            }

            const clonedQuestion = { ...question };
            clonedQuestion.number = ind + 1;

            if (question?.status) {
              if (question?.status === "under_review") {
                clonedQuestion.type = 1;
              } else {
                clonedQuestion.type = 3;
              }
            } else {
              clonedQuestion.type = 1;
            }

            if (question?.status && question?.status !== "under_review") {
              if (question?.status === "passed") {
                clonedQuestion.reviewer_response = "correct";
              } else {
                clonedQuestion.reviewer_response = "wrong";
              }
            } else {
              clonedQuestion.reviewer_response = null;
            }

            questions = questions + 1;
            obj["questions"].push(clonedQuestion);
          }
        );

        moduleMap.push(obj);
      });
    }
    setTotalReviewed(reviewed);
    setSelectedModule(0);
    setSelectedQuestion(0);
    setModules(moduleMap);
    setTotalQuestions(questions);
  }, [assessmentResultForReview]);

  useEffect(() => {
    modulesRef.current = modules;

    let reviewed = 0;
    if (modules) {
      modules?.forEach(item => {
        item?.questions.forEach(question => {
          if (question?.reviewer_response) {
            reviewed = reviewed + 1;
          }
        });
      });
    }
    setTotalReviewed(reviewed);
  }, [modules]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      reviewAssessment("draft", false);
      setSeconds(seconds + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, [seconds]);

  const reviewAssessment = async (type, nav = true) => {
    const reviewedModules = [];
    const currentModules = modulesRef.current;

    currentModules?.forEach(item => {
      let count = 0;
      const obj = {
        module_id: item?.module_id,
        questions: [],
      };
      item?.questions?.forEach(question => {
        if (
          question?.reviewer_response &&
          question?.reviewer_response !== null
        ) {
          count = count + 1;
          obj.questions.push({
            id: question?.id,
            is_correct: question?.reviewer_response === "correct",
          });
        }
      });
      if (count > 0) {
        reviewedModules.push(obj);
      }
    });

    const data = {
      participant_id: participantId,
      assessment_result_id: assessmentResultForReview?.assessment_result_id,
      is_competency: false,
      status: type,
      modules: reviewedModules,
    };

    if (assessmentResultForReview?.assessment_result_id)
      await dispatch(reviewLearnerAssessment({ data: data }));

    if (nav) {
      if (courseId && scheduledId) {
        return navigate("/schedule-details", {
          state: {
            courseId: courseId,
            scheduledId: scheduledId,
          },
        });
      }

      return navigate(
        `${process.env.PUBLIC_URL}/assessments/assessment-details?assessmentId=${assessmentResultForReview?.assessment_id}`
      );
    }
  };

  return (
    <div className="page-body">
      <Row>
        <Col lg="8">
          <Card>
            <CardBody className="m-2 mt-3">
              <div className="d-flex gap-3">
                <P className="fw-bold">
                  {Module} {selectedModule + 1}
                </P>
                <P>
                  {Question} {selectedQuestion + 1}
                </P>
              </div>
              <H4 className="mt-2">
                {
                  modules[selectedModule]?.questions[selectedQuestion]
                    ?.question_text
                }
              </H4>
              {modules[selectedModule]?.questions[selectedQuestion]
                ?.media_url && (
                <div className="mt-2">
                  <FileHandler
                    mediaUrl={
                      modules[selectedModule]?.questions[selectedQuestion]
                        ?.media_url
                    }
                  />
                </div>
              )}
              <Form className="mt-3">
                <FormGroup>
                  <Label>
                    {AnswerGiven} :
                    {
                      modules[selectedModule]?.questions[selectedQuestion]
                        ?.participant_response
                    }
                  </Label>
                </FormGroup>

                <div className="d-flex gap-5">
                  <FormGroup check>
                    <Label check>
                      <Input
                        className="custom-checkbox"
                        type="radio"
                        value="correct"
                        checked={
                          modules[selectedModule]?.questions[selectedQuestion]
                            ?.reviewer_response === "correct"
                        }
                        onChange={() => {
                          const data = [...modules];
                          data[selectedModule].questions[
                            selectedQuestion
                          ].reviewer_response = "correct";
                          data[selectedModule].questions[
                            selectedQuestion
                          ].type = 3;
                          setModules(data);
                        }}
                      />
                      <span
                        className={
                          modules[selectedModule]?.questions[selectedQuestion]
                            ?.reviewer_response === "correct"
                            ? "correct-label"
                            : ""
                        }
                      >
                        {Correct}
                      </span>
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <Input
                        className="custom-checkbox"
                        type="radio"
                        value="wrong"
                        checked={
                          modules[selectedModule]?.questions[selectedQuestion]
                            ?.reviewer_response === "wrong"
                        }
                        onChange={() => {
                          const data = [...modules];
                          data[selectedModule].questions[
                            selectedQuestion
                          ].reviewer_response = "wrong";
                          data[selectedModule].questions[
                            selectedQuestion
                          ].type = 3;
                          setModules(data);
                        }}
                      />
                      <span
                        className={
                          modules[selectedModule]?.questions[selectedQuestion]
                            ?.reviewer_response === "wrong"
                            ? "wrong-label"
                            : ""
                        }
                      >
                        {Wrong}
                      </span>
                    </Label>
                  </FormGroup>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody className="m-2 mt-3">
              <Card>
                <CardBody className="mt-2">
                  <MCQScoreContainer
                    score={assessmentResultForReview?.score + "%"}
                    mcqStatus={
                      assessmentResultForReview?.mcq?.correct_mcq +
                      "/" +
                      assessmentResultForReview?.mcq?.total_mcq
                    }
                    manualStatus={0 + "/" + totalQuestions?.toString()}
                  />
                </CardBody>
              </Card>
              <div className="mt-3 d-flex gap-3 align-items-center">
                <H5>
                  {totalReviewed}/{totalQuestions}
                </H5>
                <Progressbar
                  color="dark"
                  value={(totalReviewed / totalQuestions) * 100}
                  style={{ height: "8px" }}
                  className="w-100"
                />
              </div>
              <div className="d-flex gap-4 mt-4">
                <AnswerStatusIndicator color="dark" text={"Reviewed"} />
                <AnswerStatusIndicator color="white" text={"Not reviewed"} />
              </div>
              <div className="mt-4">
                {modules.map((item, index) => {
                  return (
                    <div key={index}>
                      <QuestionNavigator
                        handleClick={(_, ind) => {
                          setSelectedModule(index);
                          setSelectedQuestion(ind);
                        }}
                        moduleName={item.module_name}
                        questions={item.questions}
                      />
                    </div>
                  );
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        <FixedFooter>
          <SecondaryButton
            onClick={() => {
              reviewAssessment("draft");
            }}
          >
            {SaveAsDraft}
          </SecondaryButton>

          {selectedQuestion > 0 || selectedModule > 0 ? (
            <DefaultButton
              onClick={() => {
                if (selectedQuestion > 0) {
                  setSelectedQuestion(selectedQuestion - 1);
                } else if (selectedModule > 0) {
                  const prevModuleQuestionsLength =
                    modules[selectedModule - 1]?.questions?.length || 0;
                  setSelectedModule(selectedModule - 1);
                  setSelectedQuestion(prevModuleQuestionsLength - 1);
                }
              }}
              color="primary"
            >
              {Previous}
            </DefaultButton>
          ) : null}

          {selectedModule < modules.length &&
            (selectedQuestion <
              modules[selectedModule]?.questions?.length - 1 ||
              selectedModule < modules?.length - 1) && (
              <Btn
                color="primary"
                onClick={() => {
                  if (
                    selectedQuestion <
                    modules[selectedModule]?.questions?.length - 1
                  ) {
                    setSelectedQuestion(selectedQuestion + 1);
                  } else if (
                    selectedModule < modules.length - 1 &&
                    selectedQuestion ===
                      modules[selectedModule]?.questions?.length - 1
                  ) {
                    setSelectedModule(selectedModule + 1);
                    setSelectedQuestion(0);
                  }
                }}
              >
                {Next}
              </Btn>
            )}
          {selectedModule === modules.length - 1 &&
            selectedQuestion ===
              modules[selectedModule]?.questions?.length - 1 && (
              <Btn
                color="primary"
                onClick={async () => {
                  if (totalReviewed !== totalQuestions) {
                    showToast(PleaseReviewAll, "error");
                    return;
                  }
                  reviewAssessment("review_completed");
                }}
              >
                {Submit}
              </Btn>
            )}
        </FixedFooter>
      </div>
    </div>
  );
};

export default ReviewStandaloneAssessments;
