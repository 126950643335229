import { UpsertPolicyPayload } from "../entities/PolicyEntity";

export const convertPolicyDetailsPayload = (
  basicInputFormValue: any
): UpsertPolicyPayload => {
  return {
    name: basicInputFormValue.name,
    no_of_attempt: basicInputFormValue.is_reattempt
      ? basicInputFormValue.no_of_attempt
      : null,
    cooling_period: basicInputFormValue.is_reattempt
      ? basicInputFormValue.cooling_period
      : null,
    pass_percentage: basicInputFormValue.pass_percentage,
    is_reattempt: basicInputFormValue.is_reattempt,
    max_score: basicInputFormValue.is_reattempt
      ? basicInputFormValue.max_score
      : null,
    min_score: basicInputFormValue.is_reattempt
      ? basicInputFormValue.min_score
      : null,
  };
};
