import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { MUIIcons } from "../../AbstractElements";
import { RM, SBU } from "../../utils/Constant";
import UserDetailsCard from "../UserDetailsCard";

interface TmReplacementCardProps {
  data: TmReplacementData;
  showCard?: boolean;
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
  replaceData?: any;
  showActions?: boolean;
}

interface TmReplacementData {
  name: string;
  unique_id?: string;
  empId: string;
  mobile: string;
  id: number;
  sbu: string;
  rmName: string;
  rmMobileNo: string;
}

const TmReplacementCard: React.FC<TmReplacementCardProps> = ({
  data,
  showCard = true,
  onReject = () => {
    return;
  },
  onApprove = () => {
    return;
  },
  replaceData,
  showActions = false,
}) => {
  const content = (
    <Row className="d-flex justify-content-between align-items-center">
      <Col xs={12} md={3}>
        <UserDetailsCard userDetails={data} badge />
      </Col>

      <Col xs={12} md={5} className="d-flex align-items-center text-gray gap-3">
        {data.sbu && (
          <div className="d-flex align-items-center me-3">
            <MUIIcons iconName="BadgeOutlined" size={20} />
            <span className="ms-2">
              {SBU}: {data.sbu}
            </span>
          </div>
        )}
        {data.rmName && data.rmMobileNo && (
          <div className="d-flex align-items-center">
            <MUIIcons iconName="PersonOutline" size={20} />
            <span className="ms-2">
              {RM} {data.rmName} | {data.rmMobileNo}
            </span>
          </div>
        )}
      </Col>

      <Col xs={12} md={3}>
        <UserDetailsCard
          userDetails={replaceData}
          className="align-items-end"
        />
      </Col>

      <Col xs={12} md={1}>
        {showActions && (
          <div className="d-flex gap-3">
            <MUIIcons
              className="primary-icon-color pointer"
              iconName="CancelOutlined"
              size={24}
              onClick={() => onReject(data?.unique_id?.toString())}
            />
            <MUIIcons
              className="text-success pointer"
              iconName="CheckCircleOutlined"
              size={24}
              onClick={() => onApprove(data?.unique_id?.toString())}
            />
          </div>
        )}
      </Col>
    </Row>
  );

  return showCard ? (
    <Card>
      <CardBody>{content}</CardBody>
    </Card>
  ) : (
    content
  );
};

TmReplacementCard.propTypes = {
  data: PropTypes.exact({
    name: PropTypes.string.isRequired,
    unique_id: PropTypes.string,
    empId: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    sbu: PropTypes.string.isRequired,
    rmName: PropTypes.string.isRequired,
    rmMobileNo: PropTypes.string.isRequired,
  }).isRequired,
  showCard: PropTypes.bool,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  replaceData: PropTypes.any,
};

export default TmReplacementCard;
