export const saveToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key: string): any => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return null;
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const removeAllLocalStorage = () => {
  localStorage.clear();
};

export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const SAVE_LOCATION_STATE = "LOCATION_STATE";
export const LEARNER_COMPETENCY_ASSESSMENT = "LEARNER_COMPETENCY_ASSESSMENT";
export const OPENED_SCORM_DETAILS = "OPENED_SCORM_DETAILS";
export const COURSE_ASSESSMENT = "COURSE_ASSESSMENT";
