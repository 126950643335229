import { createSlice } from "@reduxjs/toolkit";

import {
  SideBarTitleAssessment,
  SideBarTitleAssessmentCatalog,
  SideBarTitleClassRoom,
  SideBarTitleCompetency,
  SideBarTitleCourseCatalog,
  SideBarTitleCourseList,
  SideBarTitleCourseManagement,
  SideBarTitleCourseRequest,
  SideBarTitleCurriculumList,
  SideBarTitleDashboard,
  SideBarTitleDomain,
  SideBarTitleGeneral,
  SideBarTitleGlobalSetting,
  SideBarTitleIAmAMentee,
  SideBarTitleIAmAMentor,
  SideBarTitleIndustry,
  SideBarTitleLearningOutcome,
  SideBarTitleJobRole,
  SideBarTitleLoadFactor,
  SideBarTitleManageMentoring,
  SideBarTitleMasterData,
  SideBarTitleMentoring,
  SideBarTitleMyAssessment,
  SideBarTitleMyDevelopmentPlan,
  SideBarTitleMyLearningPlan,
  SideBarTitleNominationRequest,
  SideBarTitlePolicy,
  SideBarTitleQuestionBankList,
  SideBarTitleScheduledAssessments,
  SideBarTitleScheduledCourse,
  SideBarTitleScheduledCourses,
  SideBarTitleSGA,
  SideBarTitleUserManagement,
} from "../../utils/Constant";
import {
  getPermissionObject,
  hasPermissionToComponent,
} from "../../utils/helper/permission";

const initialState = {
  flip: false,
  darkMode: true,
  toggleSidebar: false,
  pinedMenu: [""],
  layoutName: "",
  responsiveSearch: false,
  scroll: false,
  margin: 0,
  sideBarMenuItems: [],
};

const LayoutSlice = createSlice({
  name: "LayoutSlice",
  initialState,
  reducers: {
    setFlip: state => {
      state.flip = !state.flip;
    },
    setDarkMode: state => {
      state.darkMode = !state.darkMode;
    },
    setToggleSidebar: (state, action) => {
      state.toggleSidebar = action.payload;
    },
    setPinedMenu: (state, action) => {
      state.pinedMenu = action.payload;
    },
    handlePined: (state, action) => {
      if (action.payload) {
        if (state.pinedMenu.includes(action.payload)) {
          const filterMenu = state.pinedMenu.filter(
            item => item !== action.payload
          );
          state.pinedMenu = filterMenu;
        } else {
          state.pinedMenu = [...state.pinedMenu, action.payload];
        }
      }
    },
    handleResponsiveToggle: state => {
      state.toggleSidebar = true;
    },
    setResponsiveSearch: state => {
      state.responsiveSearch = !state.responsiveSearch;
    },
    setScrollMenu: (state, action) => {
      state.scroll = action.payload;
    },
    scrollToLeft: state => {
      state.margin += 500;
    },
    scrollToRight: state => {
      state.margin -= 500;
    },

    setSideBarMenuItems: state => {
      const constructedSideMenu = [
        {
          id: 101,
          title: SideBarTitleDashboard,
          path: "/dashboard",
          icon: "home",
          type: "sub",
        },
      ];

      if (hasPermissionToComponent("USER_MANAGEMENT.ROOT")) {
        const userManagementDataItem = {
          id: 102,
          title: SideBarTitleUserManagement,
          path: "/user-management",
          icon: "support-tickets",
          type: "sub",
        };
        constructedSideMenu.push(userManagementDataItem);
      }

      const courseManagementPermissions =
        getPermissionObject("COURSE_MANAGEMENT");

      if (hasPermissionToComponent("COURSE_MANAGEMENT.ROOT")) {
        const courseManagementDataItem = {
          id: 103,
          title: SideBarTitleCourseManagement,
          path: "/course-management",
          icon: "calendar",
          type: "sub",
          children: [
            courseManagementPermissions?.COURSE_REQUEST && {
              id: 201,
              path: `${process.env.PUBLIC_URL}/course-management/course-request`,
              title: SideBarTitleCourseRequest,
              type: "link",
            },
            courseManagementPermissions?.COURSE_LIST && {
              id: 202,
              path: `${process.env.PUBLIC_URL}/course-management/course-list`,
              title: SideBarTitleCourseList,
              type: "link",
            },
          ].filter(Boolean),
        };
        constructedSideMenu.push(courseManagementDataItem);
      }

      if (hasPermissionToComponent("LOAD_FACTOR")) {
        const loadFactorDataItem = {
          id: 104,
          title: SideBarTitleLoadFactor,
          path: "/load-factor",
          icon: "support-tickets",
          type: "sub",
        };
        constructedSideMenu.push(loadFactorDataItem);
      }

      if (hasPermissionToComponent("SCHEDULED_COURSE_LIST")) {
        const sheduledCourseDataItem = {
          id: 105,
          title: SideBarTitleScheduledCourse,
          path: "/course-schedule-list",
          icon: "support-tickets",
          type: "sub",
        };
        constructedSideMenu.push(sheduledCourseDataItem);
      }

      if (hasPermissionToComponent("GLOBAL_SETTINGS")) {
        const globalSettingsDataItem = {
          id: 106,
          title: SideBarTitleGlobalSetting,
          path: "/global-setting",
          icon: "support-tickets",
          type: "sub",
        };
        constructedSideMenu.push(globalSettingsDataItem);
      }

      if (hasPermissionToComponent("NOMINATION_REQUEST")) {
        const nominationRequestDataItem = {
          id: 107,
          title: SideBarTitleNominationRequest,
          path: "/nomination-request",
          icon: "support-tickets",
          type: "sub",
        };
        constructedSideMenu.push(nominationRequestDataItem);
      }

      if (hasPermissionToComponent("COURSE_CATALOG")) {
        const courseCatalogDataItem = {
          id: 108,
          title: SideBarTitleCourseCatalog,
          path: "/course-catalog",
          icon: "support-tickets",
          type: "sub",
        };
        constructedSideMenu.push(courseCatalogDataItem);
      }

      const assessmentManagementPermission = getPermissionObject(
        "ASSESSMENT_MANAGEMENT"
      );

      if (hasPermissionToComponent("ASSESSMENT_MANAGEMENT.ROOT")) {
        const assessmentMasterItems = {
          id: 109,
          title: SideBarTitleAssessment,
          path: "/assessments",
          icon: "file",
          type: "sub",
          children: [
            assessmentManagementPermission?.ASSESSMENT_CATALOG && {
              id: 203,
              path: `${process.env.PUBLIC_URL}/assessments/assessments-list`,
              title: SideBarTitleAssessmentCatalog,
              type: "link",
            },
            assessmentManagementPermission?.ASSESSMENT_REVIEW && {
              id: 204,
              path: `${process.env.PUBLIC_URL}/assessments/scheduled-assessments`,
              title: SideBarTitleScheduledAssessments,
              type: "link",
            },
          ].filter(Boolean),
        };
        constructedSideMenu.push(assessmentMasterItems);
      }

      if (hasPermissionToComponent("MY_ASSESSMENT")) {
        const assessmentDataItem = {
          id: 110,
          title: SideBarTitleMyAssessment,
          path: "/assessment-list",
          icon: "file",
          type: "sub",
        };
        constructedSideMenu.push(assessmentDataItem);
      }

      const masterDataPermission = getPermissionObject("MASTER_DATA");

      if (hasPermissionToComponent("MASTER_DATA.ROOT")) {
        const masterDataItem = {
          id: 111,
          title: SideBarTitleMasterData,
          path: `${process.env.PUBLIC_URL}/master-data/question-bank-list`,
          icon: "support-tickets",
          type: "sub",
          children: [
            masterDataPermission?.QUESTION_BANK && {
              id: 205,
              path: `${process.env.PUBLIC_URL}/master-data/question-bank-list`,
              title: SideBarTitleQuestionBankList,
              type: "link",
            },
            masterDataPermission?.POLICY && {
              id: 206,
              path: `${process.env.PUBLIC_URL}/master-data/policy-list`,
              title: SideBarTitlePolicy,
              type: "link",
            },
            masterDataPermission?.CURRICULUM && {
              id: 207,
              path: `${process.env.PUBLIC_URL}/master-data/curriculum-list`,
              title: SideBarTitleCurriculumList,
              type: "link",
            },
            masterDataPermission?.SGA && {
              id: 208,
              path: `${process.env.PUBLIC_URL}/master-data/skill-gap-analysis`,
              title: SideBarTitleSGA,
              type: "link",
            },
            masterDataPermission?.DOMAIN && {
              id: 214,
              path: `${process.env.PUBLIC_URL}/master-data/domain`,
              title: SideBarTitleDomain,
              type: "link",
            },
            masterDataPermission?.COMPETENCY && {
              id: 215,
              path: `${process.env.PUBLIC_URL}/master-data/competency`,
              title: SideBarTitleCompetency,
              type: "link",
            },
            masterDataPermission?.LEARNING_OUTCOME && {
              id: 216,
              path: `${process.env.PUBLIC_URL}/master-data/learning-outcome`,
              title: SideBarTitleLearningOutcome,
              type: "link",
            },
            masterDataPermission?.INDUSTRY && {
              id: 217,
              path: `${process.env.PUBLIC_URL}/master-data/industry`,
              title: SideBarTitleIndustry,
              type: "link",
            },
            masterDataPermission?.JOB_ROLE && {
              id: 218,
              path: `${process.env.PUBLIC_URL}/master-data/job-role`,
              title: SideBarTitleJobRole,
              type: "link",
            },
            masterDataPermission?.CLASSROOM && {
              id: 219,
              path: `${process.env.PUBLIC_URL}/master-data/classroom`,
              title: SideBarTitleClassRoom,
              type: "link",
            },
          ].filter(Boolean),
        };
        constructedSideMenu.push(masterDataItem);
      }

      const myDevelopmentPermission = getPermissionObject(
        "MY_DEVELOPMENT_PLAN"
      );

      if (hasPermissionToComponent("MY_DEVELOPMENT_PLAN.ROOT")) {
        const myDevelopment = {
          id: 112,
          title: SideBarTitleMyDevelopmentPlan,
          path: "/my-learning-plan",
          icon: "calendar",
          type: "sub",
          children: [
            myDevelopmentPermission?.MY_LEARNING_PLAN && {
              id: 209,
              path: `${process.env.PUBLIC_URL}/my-learning-plan`,
              title: SideBarTitleMyLearningPlan,
              type: "link",
            },
            myDevelopmentPermission?.SCHEDULED_COURSES && {
              id: 210,
              path: `${process.env.PUBLIC_URL}/schedule-list`,
              title: SideBarTitleScheduledCourses,
              type: "link",
            },
          ].filter(Boolean),
        };
        constructedSideMenu.push(myDevelopment);
      }

      const mentoringPermissions = getPermissionObject("MENTORING");

      if (hasPermissionToComponent("MENTORING.ROOT")) {
        const mentoringDataItem = {
          id: 113,
          title: SideBarTitleMentoring,
          path: "/mentoring",
          icon: "calendar",
          type: "sub",
          children: [
            mentoringPermissions?.MANAGE_MENTORING && {
              id: 211,
              path: `${process.env.PUBLIC_URL}/mentoring/manage-mentoring`,
              title: SideBarTitleManageMentoring,
              type: "link",
            },
            mentoringPermissions?.MENTOR && {
              id: 212,
              path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentor`,
              title: SideBarTitleIAmAMentor,
              type: "link",
            },
            mentoringPermissions?.MENTEE && {
              id: 213,
              path: `${process.env.PUBLIC_URL}/mentoring/i-am-mentee`,
              title: SideBarTitleIAmAMentee,
              type: "link",
            },
          ].filter(Boolean),
        };
        constructedSideMenu.push(mentoringDataItem);
      }

      state.sideBarMenuItems = [
        {
          title: SideBarTitleGeneral,
          lanClass: "lan-1",
          Items: constructedSideMenu,
          id: 501,
        },
      ];
    },
  },
});

export const {
  setFlip,
  setDarkMode,
  setToggleSidebar,
  setPinedMenu,
  handlePined,
  handleResponsiveToggle,
  setResponsiveSearch,
  setScrollMenu,
  scrollToLeft,
  scrollToRight,
  setSideBarMenuItems,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
