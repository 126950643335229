import PropTypes from "prop-types";
import React from "react";
import { Rating } from "react-simple-star-rating";

interface StarRatingProps {
  size: number;
  onClick: (rate: number) => void;
  initialValue?: number;
  disabled?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({
  size = 17,
  onClick,
  initialValue = 0,
  disabled = false,
}) => {
  return (
    <Rating
      readonly={disabled}
      iconsCount={6}
      size={size}
      fillColor="red"
      onClick={onClick}
      initialValue={initialValue}
    />
  );
};

StarRating.propTypes = {
  size: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StarRating;
