import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Row, Button } from "reactstrap";

import { Btn, MUIIcons, H5, H2 } from "../../AbstractElements";
import {
  CONTENT_REJECTED_SME,
  CONTENT_REJECTED_TM,
  DRAFT_STATUS,
  LO_APPROVED_SME,
  LO_APPROVED_TM,
  MINUTES_PER_DAY,
} from "../../Api/constants";
import BadgeWithIcon from "../../CommonElements/BadgeWithIcon";
import DetailHeaderContainer from "../../CommonElements/DetailHeaderContainer";
import Divider from "../../CommonElements/Divider";
import IconHeader from "../../CommonElements/IconHeader";
import LabelTooltip from "../../CommonElements/LabelTooltip";
import CommonModal from "../../CommonElements/Modal";
import SliderModal from "../../CommonElements/SliderModal";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getCourseContent } from "../../ReduxToolkit/Reducers/CourseSlice";
import {
  createLessonPlan,
  updateLessonPlan,
} from "../../ReduxToolkit/Reducers/CourseStagesSlice";
import {
  AddNew,
  DayWiseTopic,
  DayWiseLearning,
  DayWiseDelivery,
  DayWiseTime,
  LessonPlans,
  Enter,
  Done,
  DeleteLessonPlan,
  Confirm,
  DeleteLessonPlanHeader,
  AlreadyReachedLimitForTheDay,
  Min,
  LessonPlanTopicToolTipMessage,
  LearningActivitiesToolTipMessages,
  DeliveryToolTipMessage,
  EstimatedTimeToolTipMessage,
  TopicRequired,
  LearningActivityRequired,
  DeliveryRequired,
  EstimatedTimeRequired,
  TopicValidationMessage,
  LearningActivityValidation,
  DeliveryValidation,
  LearningActivity,
} from "../../utils/Constant";
import { getRandomInteger, showToast } from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import useIsMobile from "../../utils/helper/responsive";

interface LessonPlan {
  id: number;
  day_number?: number;
  topic: string;
  learning_activity: string;
  delivery: string;
  estimated: string;
}

interface LessonPlanContainerProps {
  showCreate: boolean;
  id?: number;
  courseId: string;
  dayTotalTime?: number;
}

const LessonPlanContainer: React.FC<LessonPlanContainerProps> = ({
  showCreate,
  id,
  courseId,
  dayTotalTime,
}) => {
  const processedDays = new Set();
  const dispatch = useAppDispatch();
  const { courseContent } = useAppSelector(state => state.course);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newLessonPlan, setNewLessonPlan] = useState<LessonPlan>({
    id: null,
    topic: "",
    learning_activity: "",
    delivery: "",
    estimated: "",
  });
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [lessonPlans, setLessonPlans] = useState<LessonPlan[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const isMobile = useIsMobile();
  const [sliderModal, setSliderModal] = useState(false);
  const [errors, setErrors] = useState<Partial<LessonPlan>>({});

  useEffect(() => {
    const lessons = [];
    if (courseContent) {
      courseContent?.lesson_plans?.forEach(item => {
        const lessonObj = {
          id: item.id,
          day_number: item.day_number,
          topic: item.topic,
          learning_activity: item.learning_activities,
          delivery: item.delivery,
          estimated: item.estimated_time,
        };
        lessons.push(lessonObj);
      });
    }
    setLessonPlans(lessons);
  }, [courseContent]);

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedIndex(null);
    }
    setIsModalOpen(!isModalOpen);
  };

  const sliderToggle = () => {
    setSliderModal(!sliderModal);
    if (!sliderModal) {
      setNewLessonPlan({
        id: null,
        topic: "",
        learning_activity: "",
        delivery: "",
        estimated: "",
      });
      setEditIndex(null);
      setErrors({});
    }
  };

  const handleModalSave = async (type?: string, deleteIndexes?: number) => {
    const currentErrors: Partial<LessonPlan> = {};

    if (type !== "delete") {
      if (!newLessonPlan.topic) currentErrors.topic = TopicRequired;
      if (!newLessonPlan.learning_activity)
        currentErrors.learning_activity = LearningActivityRequired;
      if (!newLessonPlan.delivery) currentErrors.delivery = DeliveryRequired;
      if (!newLessonPlan.estimated)
        currentErrors.estimated = EstimatedTimeRequired;

      if (
        Object.keys(currentErrors).length > 0 ||
        newLessonPlan.topic.length > 100 ||
        newLessonPlan.learning_activity.length > 255 ||
        newLessonPlan.delivery.length > 255
      ) {
        if (newLessonPlan.topic.length > 100) {
          currentErrors.topic = TopicValidationMessage;
        }
        if (newLessonPlan.learning_activity.length > 255) {
          currentErrors.learning_activity = LearningActivityValidation;
        }
        if (newLessonPlan.delivery.length > 255) {
          currentErrors.delivery = DeliveryValidation;
        }
        setErrors(currentErrors);
        return;
      }
    }

    setErrors({});
    const actionType = editIndex !== null ? "edit" : "create";

    if (editIndex !== null) {
      const updatedLessonPlans = [...lessonPlans];
      updatedLessonPlans[editIndex] = { ...newLessonPlan, day_number: id };
      setLessonPlans(updatedLessonPlans);
    } else {
      setLessonPlans([...lessonPlans, { ...newLessonPlan, day_number: id }]);
    }
    setNewLessonPlan({
      id: null,
      topic: "",
      learning_activity: "",
      delivery: "",
      estimated: "",
    });

    const allPlans = [];
    const deletePlans = [];
    lessonPlans?.forEach((lesson, index) => {
      allPlans.push({
        day_number: lesson.day_number,
        topic: lesson.topic,
        learning_activities: lesson.learning_activity,
        delivery: lesson.delivery,
        estimated_time: lesson.estimated,
      });
      if (editIndex === index) {
        allPlans[index].day_number = newLessonPlan.day_number;
        allPlans[index].topic = newLessonPlan.topic;
        allPlans[index].learning_activities = newLessonPlan.learning_activity;
        allPlans[index].delivery = newLessonPlan.delivery;
        allPlans[index].estimated_time = newLessonPlan.estimated;
      }
      if (type === "delete" && deleteIndexes !== index) {
        deletePlans.push({
          day_number: lesson.day_number,
          topic: lesson.topic,
          learning_activities: lesson.learning_activity,
          delivery: lesson.delivery,
          estimated_time: lesson.estimated,
        });
      }
    });

    const dayPlan: CourseLessonPlan = {
      course_id: courseId,
      status: "draft",
      lesson_plans: [
        {
          day_number: id,
          topic: newLessonPlan.topic,
          learning_activities: newLessonPlan.learning_activity,
          delivery: newLessonPlan.delivery,
          estimated_time: newLessonPlan.estimated,
        },
      ],
    };

    const editDayPlan: CourseLessonPlan = {
      course_id: courseId,
      status: "draft",
      lesson_plans: allPlans || null,
    };

    const deleteDayPlan: CourseLessonPlan = {
      course_id: courseId,
      status: "draft",
      lesson_plans: deletePlans || null,
    };

    if (type === "delete")
      await dispatch(updateLessonPlan({ lessonPlan: deleteDayPlan }));
    else if (actionType === "create")
      await dispatch(createLessonPlan({ lessonPlan: dayPlan }));
    else await dispatch(updateLessonPlan({ lessonPlan: editDayPlan }));

    setNewLessonPlan({
      id: null,
      topic: "",
      learning_activity: "",
      delivery: "",
      estimated: "",
    });
    if (type !== "delete") sliderToggle();
    await dispatch(getCourseContent({ id: courseId }));
  };

  const editLessonPlan = (index: number) => {
    setNewLessonPlan(lessonPlans[index]);
    setEditIndex(index);
    setSliderModal(true);
  };

  const deleteLessonPlan = (index: number) => {
    const updatedPlans = [...lessonPlans];
    updatedPlans.splice(index, 1);
    setLessonPlans(updatedPlans);
    handleModalSave("delete", index);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof LessonPlan
  ) => {
    const value = e.target.value;

    const currentErrors = { ...errors };
    if (field === "topic" && value.length > 100) {
      currentErrors.topic = TopicValidationMessage;
    } else if (field === "learning_activity" && value.length > 255) {
      currentErrors.learning_activity = LearningActivityValidation;
    } else if (field === "delivery" && value.length > 255) {
      currentErrors.delivery = DeliveryValidation;
    } else {
      delete currentErrors[field];
    }

    setNewLessonPlan({ ...newLessonPlan, [field]: value });
    setErrors(currentErrors);
  };

  const handleEstimatedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const alreradyEnteredTime = lessonPlans[editIndex]?.estimated
      ? parseInt(lessonPlans[editIndex].estimated)
      : 0;
    const remainingTimeLeftForTheDay =
      MINUTES_PER_DAY - dayTotalTime + alreradyEnteredTime;
    if (parseInt(value) > remainingTimeLeftForTheDay) {
      setNewLessonPlan({ ...newLessonPlan, estimated: "" });
      setErrors({
        ...errors,
        estimated: `Already added ${dayTotalTime} minutes for the day. So please add less than ${remainingTimeLeftForTheDay + 1}`,
      });
      return;
    }
    if (
      /^\d+$/.test(value) &&
      value.length <= 4 &&
      parseInt(value) > 0 &&
      parseInt(value) <= MINUTES_PER_DAY
    ) {
      setNewLessonPlan({ ...newLessonPlan, estimated: value });
      setErrors({ ...errors, estimated: "" });
    } else if (value === "" || value === "0") {
      setNewLessonPlan({ ...newLessonPlan, estimated: "" });
      setErrors({ ...errors, estimated: "" });
    }
  };

  return (
    <div>
      <CommonModal
        sizeTitle={DeleteLessonPlanHeader}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={() => {
          deleteLessonPlan(selectedIndex);
          toggleModal();
        }}
        primaryBtnText={Confirm}
      >
        {DeleteLessonPlan}
      </CommonModal>
      <SliderModal isOpen={sliderModal} toggle={sliderToggle}>
        <div>
          <H2>{LessonPlans}</H2>
          <Divider />
          <FormGroup>
            <LabelTooltip
              label={DayWiseTopic}
              tooltipText={LessonPlanTopicToolTipMessage}
              important
            />
            <Input
              value={newLessonPlan.topic}
              onChange={e => handleChange(e, "topic")}
              type="textarea"
              rows={3}
              maxLength={100}
              placeholder={`${Enter} ${DayWiseTopic}`}
              invalid={!!errors.topic}
            />
            <div className="invalid-feedback">{errors.topic}</div>
          </FormGroup>
          <FormGroup>
            <LabelTooltip
              label={DayWiseLearning}
              tooltipText={LearningActivitiesToolTipMessages}
              important
            />
            <Input
              value={newLessonPlan.learning_activity}
              onChange={e => handleChange(e, "learning_activity")}
              type="textarea"
              rows={3}
              maxLength={255}
              placeholder={`${Enter} ${DayWiseLearning}`}
              invalid={!!errors.learning_activity}
            />
            <div className="invalid-feedback">{errors.learning_activity}</div>
          </FormGroup>
          <FormGroup>
            <LabelTooltip
              label={DayWiseDelivery}
              tooltipText={DeliveryToolTipMessage}
              important
            />
            <Input
              value={newLessonPlan.delivery}
              onChange={e => handleChange(e, "delivery")}
              type="textarea"
              rows={3}
              maxLength={255}
              placeholder={`${Enter} ${DayWiseDelivery}`}
              invalid={!!errors.delivery}
            />
            <div className="invalid-feedback">{errors.delivery}</div>
          </FormGroup>
          <Row className="mt-4">
            <Col>
              <FormGroup>
                <LabelTooltip
                  label={DayWiseTime}
                  tooltipText={EstimatedTimeToolTipMessage}
                  important
                />
                <Input
                  value={newLessonPlan.estimated}
                  onChange={handleEstimatedChange}
                  type="text"
                  placeholder={`${Enter} ${DayWiseTime}`}
                  invalid={!!errors.estimated}
                />
                <div className="invalid-feedback">{errors.estimated}</div>
              </FormGroup>
            </Col>
          </Row>
          <Button color="primary" onClick={() => handleModalSave("add")}>
            {Done}
          </Button>
        </div>
      </SliderModal>

      <div>
        <Divider />
        {lessonPlans?.map((item, index) => {
          if (item.day_number !== id) return null;

          const isFirstEntryOfDay = !processedDays.has(item.day_number);
          if (isFirstEntryOfDay) {
            processedDays.add(item.day_number);
          }

          return (
            <div className="p-3" key={item.id}>
              <Row>
                <Col xs="6" sm="6" lg="3">
                  {isMobile || isFirstEntryOfDay ? (
                    <DetailHeaderContainer
                      assign={DayWiseTopic}
                      name={item.topic}
                      key={getRandomInteger(1, 100)}
                    />
                  ) : (
                    <H5>{item.topic}</H5>
                  )}
                </Col>
                <Col xs="6" sm="6" lg="3">
                  {isMobile || isFirstEntryOfDay ? (
                    <DetailHeaderContainer
                      assign={LearningActivity}
                      name={item.learning_activity}
                      key={getRandomInteger(1, 100)}
                    />
                  ) : (
                    <H5>{item.learning_activity}</H5>
                  )}
                </Col>
                <Col className={isMobile && "mt-4"} xs="6" sm="6" lg="3">
                  {isMobile || isFirstEntryOfDay ? (
                    <DetailHeaderContainer
                      assign="Delivery"
                      name={item.delivery}
                      key={getRandomInteger(1, 100)}
                    />
                  ) : (
                    <H5>{item.delivery}</H5>
                  )}
                </Col>
                <Col className={isMobile && "mt-4"} xs="6" sm="6" lg="2">
                  {isMobile || isFirstEntryOfDay ? (
                    <IconHeader
                      assign={DayWiseTime}
                      name={
                        <BadgeWithIcon
                          text={item.estimated + Min}
                          iconSize={24}
                          iconName="QueryBuilderOutlined"
                        />
                      }
                    />
                  ) : (
                    <BadgeWithIcon
                      text={item.estimated + Min}
                      iconSize={24}
                      iconName="QueryBuilderOutlined"
                    />
                  )}
                </Col>
                {showCreate &&
                  (courseContent?.status === LO_APPROVED_SME ||
                    courseContent?.status === LO_APPROVED_TM ||
                    courseContent?.status === DRAFT_STATUS ||
                    courseContent?.status === CONTENT_REJECTED_TM ||
                    courseContent?.status === CONTENT_REJECTED_SME) &&
                  hasPermissionToComponent("CREATE_ASSESSMENT") && (
                    <Col lg="1" xs="3" sm="3" className="d-flex">
                      <IconHeader
                        assign=""
                        name={
                          <div className="d-flex gap-5">
                            <MUIIcons
                              size={24}
                              className="primary-icon-color pointer"
                              onClick={() => {
                                setSelectedIndex(index);
                                toggleModal();
                              }}
                              iconName="DeleteOutlineOutlined"
                            />
                            <MUIIcons
                              size={24}
                              className="primary-icon-color pointer"
                              onClick={() => editLessonPlan(index)}
                              iconName="EditOutlined"
                            />
                          </div>
                        }
                      />
                    </Col>
                  )}
              </Row>
              <Divider />
            </div>
          );
        })}
        {showCreate &&
          (courseContent?.status === LO_APPROVED_SME ||
            courseContent?.status === LO_APPROVED_TM ||
            courseContent?.status === DRAFT_STATUS ||
            courseContent?.status === CONTENT_REJECTED_TM ||
            courseContent?.status === CONTENT_REJECTED_SME) &&
          hasPermissionToComponent("CREATE_ASSESSMENT") && (
            <Btn
              color="primary"
              onClick={() => {
                if (dayTotalTime < MINUTES_PER_DAY) {
                  sliderToggle();
                } else {
                  showToast(AlreadyReachedLimitForTheDay, "error");
                }
              }}
            >
              {AddNew}
            </Btn>
          )}
      </div>
    </div>
  );
};

export default LessonPlanContainer;
