import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import {
  CREATE_PASSWORD_EXTERNAL_USER,
  CREATE_PASSWORD_EXTERNAL_USER_API,
  GET,
  GET_REFRESH_TOKEN,
  GET_REFRESH_TOKEN_API,
  GET_SSO_LOGIN_URL,
  GET_SSO_LOGIN_URL_API,
  LOGIN_EXTERNAL_USER,
  LOGIN_EXTERNAL_USER_API,
  LOGOUT,
  LOGOUT_API,
  POST,
  PUT,
  TRIGGER_RESET_PASSWORD_EXTERNAL_USER,
  TRIGGER_RESET_PASSWORD_EXTERNAL_USER_API,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  AuthenticationPayload,
  ExternalUserResponse,
} from "../../Api/entities/AuthenticationEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface AuthenticationInterface {
  externalUserResponse: ExternalUserResponse;
  loading: boolean;
  error: string | null;
}

const initialState: AuthenticationInterface = {
  externalUserResponse: null,
  loading: false,
  error: null,
};

export const getSSOLoginUrl = createAsyncThunk(GET_SSO_LOGIN_URL, async () => {
  const response = await apiRequest(GET, GET_SSO_LOGIN_URL_API());
  return response.data;
});

export const generateRefreshTokenViaSwitchRole = createAsyncThunk(
  GET_REFRESH_TOKEN,
  async ({ role }: { role: string }) => {
    const response = await apiRequest(
      GET,
      GET_REFRESH_TOKEN_API("switch-role", role)
    );
    return response.data;
  }
);

export const loginExternalUser = createAsyncThunk(
  LOGIN_EXTERNAL_USER,
  async ({ data }: { data: AuthenticationPayload }) => {
    const response = await apiRequest(POST, LOGIN_EXTERNAL_USER_API(), data);
    return response.data;
  }
);

export const createPasswordExternalUser = createAsyncThunk(
  CREATE_PASSWORD_EXTERNAL_USER,
  async ({
    data,
    emailToken,
  }: {
    data: AuthenticationPayload;
    emailToken: string;
  }) => {
    const response = await apiRequest(
      PUT,
      CREATE_PASSWORD_EXTERNAL_USER_API(emailToken),
      data
    );
    return response;
  }
);

export const triggerResetPasswordExternalUser = createAsyncThunk(
  TRIGGER_RESET_PASSWORD_EXTERNAL_USER,
  async ({ data }: { data: AuthenticationPayload }) => {
    const response = await apiRequest(
      POST,
      TRIGGER_RESET_PASSWORD_EXTERNAL_USER_API(),
      data
    );
    return response;
  }
);

export const logout = createAsyncThunk(LOGOUT, async () => {
  const response = await apiRequest(POST, LOGOUT_API(), {});
  return response.data;
});

const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(generateRefreshTokenViaSwitchRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateRefreshTokenViaSwitchRole.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(generateRefreshTokenViaSwitchRole.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(loginExternalUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginExternalUser.fulfilled,
        (state, action: PayloadAction<ExternalUserResponse>) => {
          state.loading = false;
          state.externalUserResponse = action.payload;
        }
      )
      .addCase(loginExternalUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(createPasswordExternalUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPasswordExternalUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createPasswordExternalUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(triggerResetPasswordExternalUser.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(triggerResetPasswordExternalUser.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(triggerResetPasswordExternalUser.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getSSOLoginUrl.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSSOLoginUrl.fulfilled, (state, action) => {
        state.loading = false;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getSSOLoginUrl.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(logout.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, state => {
        state.loading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export default AuthenticationSlice.reducer;
