import PropTypes from "prop-types";
import React from "react";
import "./style.scss";

interface QuestionNumberChooseButtonProps {
  number: number;
  colorName: string;
  onCorrectResponse: () => void;
  selectedQuestionType?: string;
}

const QuestionNumberChooseButton: React.FC<QuestionNumberChooseButtonProps> = ({
  number,
  colorName,
  onCorrectResponse,
  selectedQuestionType,
}) => {
  const buttonClass = `${
    selectedQuestionType === "single_select" ||
    selectedQuestionType === "match_the_following"
      ? "question-selection-button2 mt-2"
      : "question-selection-button mt-2"
  } ${colorName}`;

  return (
    <button
      className={buttonClass}
      onClick={e => {
        if (onCorrectResponse) {
          e.preventDefault();
          e.stopPropagation();
          onCorrectResponse();
        }
      }}
    >
      {number}
    </button>
  );
};

QuestionNumberChooseButton.propTypes = {
  number: PropTypes.number.isRequired,
  colorName: PropTypes.string.isRequired,
};

export default QuestionNumberChooseButton;
