import React from "react";
import { Progress } from "reactstrap";
import "./style.scss";

interface ProgressBarData {
  title: string;
  className: string;
  percentage: number;
}

interface MultiProgressBarProps {
  progressBars: ProgressBarData[];
}

const MultiProgressBar: React.FC<MultiProgressBarProps> = ({
  progressBars,
}) => {
  return (
    <Progress multi className="custom-multi-progressBar">
      {progressBars.map(
        (bar, index) =>
          bar.percentage > 0 && (
            <Progress
              bar
              className={`custom-bar ${bar.className}`}
              value={bar.percentage}
              key={index}
            >
              <p className="percentage">{bar.percentage}%</p>
            </Progress>
          )
      )}
    </Progress>
  );
};

export default MultiProgressBar;
