import { Link } from "react-router-dom";

import Image from "../../../CommonElements/Media";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { setToggleSidebar } from "../../../ReduxToolkit/Reducers/LayoutSlice";
import { dynamicImage } from "../../../Service";

const HeaderLogoWrapper = () => {
  const dispatch = useAppDispatch();
  const { toggleSidebar } = useAppSelector(state => state.layout);
  return (
    <div className="header-logo-wrapper col-auto p-0">
      <div className="logo-wrapper">
        <Link to={"/"}>
          <Image
            className="img-fluid"
            src={dynamicImage("logo/logo.png")}
            alt="MofiLogo"
          />
        </Link>
      </div>
      <div
        className="toggle-sidebar bg-black"
        onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}
      >
        <Image
          width={70}
          className="img-fluid"
          src={dynamicImage("logo/logo.png")}
          alt="MofiLogo"
        />
      </div>
    </div>
  );
};

export default HeaderLogoWrapper;
