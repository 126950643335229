import React from "react";
import { Card, CardBody } from "reactstrap";

import { H2, H4 } from "../../AbstractElements";
import { TimeRemaining } from "../../utils/Constant";
import "./style.scss";

interface TimeObject {
  hours: number;
  minutes: number;
  seconds: number;
}
interface AssessmentTimerCardProps {
  time: TimeObject;
}

const AssessmentTimerCard: React.FC<AssessmentTimerCardProps> = ({ time }) => {
  return (
    <Card className="time-remaining-card">
      <CardBody className="text-center m-3">
        <H4 className="text-gray">{TimeRemaining}</H4>
        <H2 className="time-remaining mt-3 fw-bold">
          {time?.hours}h : {time?.minutes.toString().padStart(2, "0")} min :{" "}
          {time?.seconds.toString().padStart(2, "0")} Sec
        </H2>
      </CardBody>
    </Card>
  );
};

export default AssessmentTimerCard;
