import React from "react";
import { Label, Input, Row, Col } from "reactstrap";

import { H5 } from "../../AbstractElements";
import { Questions, Weightage } from "../../utils/Constant";
import { handleKeyDownOnNumericWithoutDecimal } from "../../utils/helper/helper";

interface ModuleProps {
  moduleId: string;
  moduleTitle: string;
  questionsCount: number;
  weightage: number | "";
  onWeightageChange: (moduleId: string, weightage: number | "") => void;
  disabled?: boolean;
}

const Module: React.FC<ModuleProps> = ({
  moduleTitle,
  questionsCount,
  weightage,
  onWeightageChange,
  moduleId,
  disabled = false,
}) => {
  const handleWeightageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "") {
      onWeightageChange(moduleId, "");
      return;
    }

    const numericValue = Number(value);

    if (numericValue >= 0 && numericValue <= 100)
      onWeightageChange(moduleId, numericValue);
  };

  return (
    <Row>
      <Col className="mt-3" lg="3">
        <H5>{moduleTitle} :</H5>
        <div>
          <Label>
            {questionsCount} {Questions}
          </Label>
        </div>
      </Col>
      <Col>
        <div>
          <Label>{Weightage}</Label>
          <Input
            onKeyDown={handleKeyDownOnNumericWithoutDecimal}
            type="number"
            inputMode="numeric"
            value={weightage === "" ? "" : weightage}
            onChange={handleWeightageChange}
            min={1}
            max={100}
            disabled={disabled}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Module;
