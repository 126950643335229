import React from "react";

import { Badges, H4, MUIIcons } from "../../AbstractElements";
import "./style.scss";

interface BadgeWithIconProps {
  className?: string;
  text?: string;
  iconName: any;
  iconSize?: number;
  badgeClassName?: string;
  direction?: string;
}

const BadgeWithIcon: React.FC<BadgeWithIconProps> = ({
  text,
  iconName,
  iconSize = 24,
  className = "d-flex gap-2 flex-wrap",
  badgeClassName = "time-badge",
  direction = "start",
}) => {
  return (
    <H4 className={className}>
      <Badges className={badgeClassName} pill>
        <div className="d-flex align-items-center text-align-center gap-1 text-dark">
          {direction === "start" && (
            <div>
              <MUIIcons size={iconSize} iconName={iconName} />
            </div>
          )}
          {text}
          {direction === "end" && (
            <div>
              <MUIIcons size={iconSize} iconName={iconName} />
            </div>
          )}
        </div>
      </Badges>
    </H4>
  );
};

export default BadgeWithIcon;
