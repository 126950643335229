import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody } from "reactstrap";

import "./style.scss";
import Progressbar from "../../CommonElements/Progressbar";

interface SgaCardProps {
  role: string;
  expertiseLevel: string;
  percentageCompleted: number;
}

const SgaCard: React.FC<SgaCardProps> = ({
  role,
  expertiseLevel,
  percentageCompleted,
}) => {
  return (
    <Card>
      <CardBody className="p-4 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
        <h3 className="text-black mb-3 mb-md-0">
          {` ${role} - ${expertiseLevel}`}
        </h3>
        <div className="d-flex flex-row align-items-center gap-3">
          <Progressbar
            barClassName="custom-sga-progressBar"
            value={percentageCompleted}
            className="custom-sgaProgressBar"
          />
          {`${percentageCompleted}%`}
        </div>
      </CardBody>
    </Card>
  );
};

SgaCard.propTypes = {
  role: PropTypes.string.isRequired,
  expertiseLevel: PropTypes.string.isRequired,
};

export default SgaCard;
