export const parseCourseRequestData = (
  courseRequestData: CourseRequestForm,
  isRecurringRef: React.RefObject<boolean>
): CourseRequestPayload => {
  return {
    id: courseRequestData?.id,
    request_type: courseRequestData.request_type,
    region: courseRequestData.region,
    topic: courseRequestData.content_topic,
    topic_summary: courseRequestData.summary,
    training_accomplishment: courseRequestData.accomplishment,
    target_audience: courseRequestData.target_audiance,
    audience_count:
      parseInt(courseRequestData.expected_target_audience, 10) || 0,
    objective: courseRequestData.objective,
    course_duration: courseRequestData.course_duration || 0,
    recurring: false,
    contacts: {
      primary: {
        name: courseRequestData.primary_contact || "",
        email: courseRequestData.email_id || "",
        phone: courseRequestData.phone_number?.toString() || "",
        phone_ext: courseRequestData.extension || "",
      },
      secondary: {
        name: courseRequestData.secondary_contact || "",
        email: courseRequestData.secondary_email_id || "",
        phone: courseRequestData.secondary_phone_number?.toString() || "",
        phone_ext: courseRequestData.secondary_extension || "",
      },
    },
    notes: courseRequestData.notes,
    external_request_json: {
      customer_name: courseRequestData.customer_name,
      nature_of_business: courseRequestData.nature_of_business,
      gmmco_point_of_contact: courseRequestData.point_of_contact,
    },
    submitted_to: 0,
    frequency_count: isRecurringRef.current
      ? courseRequestData.frequency_count
      : null,
    frequency_type: isRecurringRef.current
      ? courseRequestData.frequency_type
      : null,
    course_duration_type: courseRequestData?.course_duration_type,
    status: courseRequestData.status,
    designation_id: courseRequestData?.designation_id || "",
    business_group_id: courseRequestData?.business_group_id || null,
  };
};

export const convertCoursePayloadToForm = payload => {
  return {
    id: payload?.id,
    request_type: payload?.request_type,
    region: payload?.region,
    content_topic: payload?.topic,
    summary: payload?.topic_summary,
    accomplishment: payload?.training_accomplishment,
    target_audiance: payload?.target_audience,
    expected_target_audience: payload?.audience_count?.toString(),
    objective: payload?.objective,
    course_duration: payload?.course_duration,
    primary_contact: payload?.contacts?.primary?.name,
    phone_number: payload?.contacts?.primary?.phone,
    email_id: payload?.contacts?.primary?.email,
    extension: payload?.contacts?.primary?.phone_ext || "",
    secondary_contact: payload?.contacts?.secondary?.name,
    secondary_phone_number: payload?.contacts?.secondary?.phone,
    secondary_email_id: payload?.contacts?.secondary?.email,
    secondary_extension: payload?.contacts?.secondary?.phone_ext || "",
    customer_name: payload?.external_request_json?.customer_name,
    nature_of_business: payload?.external_request_json?.nature_of_business,
    point_of_contact: payload?.external_request_json?.gmmco_point_of_contact,
    notes: payload?.notes,
    created_by: payload?.created_by,
    submitted_to: 0,
    designation: "",
    created_at: payload?.created_at,
    frequency_count: payload?.frequency_count,
    frequency_type: payload?.frequency_type,
    course_duration_type: payload?.course_duration_type,
    status: payload?.status,
    designation_id: payload?.designation_id || "",
    business_group_id: payload?.business_group_id || "",
    submitted_at: payload?.submitted_at || "",
  };
};
