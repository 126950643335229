import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col } from "reactstrap";

import { SideBarTitleDashboard } from "../../utils/Constant";
import { hasPermissionToComponent } from "../../utils/helper/permission";
import H4 from "../Headings/H4Element";
import MUIIcons from "../MUIIcon/MUIIcons";

const CommonBreadcrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const baseBreadcrumb = [
    {
      name: "Course Management",
      path: "/course-management/course-request",
    },
    {
      name: "Course Details",
      path: "/course-management/request-details",
      callBack: handleGoBack,
    },
  ];

  const breadcrumbData = {
    "learning-outcome": [
      ...baseBreadcrumb,
      {
        name: "Learning Outcome",
        path: "/course-management/course-details/learning-outcome",
      },
    ],
    "competancy-statement": [
      ...baseBreadcrumb,
      {
        name: "Competency Statement",
        path: "/course-management/course-details/competancy-statement",
      },
    ],
    "lesson-plan": [
      ...baseBreadcrumb,
      {
        name: "Lesson Plan",
        path: "/course-management/course-details/lesson-plan",
      },
    ],
    "course-structure": [
      ...baseBreadcrumb,
      {
        name: "Course Structure",
        path: "/course-management/course-details/course-structure",
      },
    ],
    assessment: [
      ...baseBreadcrumb,
      {
        name: "Assessment",
        path: "/course-management/course-details/assessment",
      },
    ],
    "course-material": [
      ...baseBreadcrumb,
      {
        name: "Course Material",
        path: "/course-management/course-details/course-material",
      },
    ],
    "bond-details": [
      ...baseBreadcrumb,
      {
        name: "Bond Details",
        path: "/course-management/course-details/bond-details",
      },
    ],
    "assessment-details": [
      {
        name: "Assessment List",
        path: "/assessment-list",
      },
      {
        name: "Assessment Details",
        path: "/assessment-list/assessment-details",
        callBack: handleGoBack,
      },
    ],
    attempts: [
      {
        name: "Assessment List",
        path: "/assessment-list",
      },
      {
        name: "Assessment Details",
        path: "/assessment-list/assessment-details",
        callBack: handleGoBack,
      },
      {
        name: "Attempts",
        path: "/assessment-list/assessment-details/attempts",
      },
    ],
  };

  const currentRoute = pathname.split("/").pop();
  const breadcrumbs = breadcrumbData[currentRoute] || [];

  const getPath = (firstPart, secondPart, pathName) => {
    if (firstPart === "dashboard" || firstPart === "user-management") {
      return `${process.env.PUBLIC_URL}/${firstPart}`;
    }
    if (
      hasPermissionToComponent("COURSE_MANAGEMENT.COURSE_REQUEST") &&
      firstPart === "course-management"
    ) {
      return `${process.env.PUBLIC_URL}/${firstPart}/course-request`;
    }
    if (
      !hasPermissionToComponent("COURSE_MANAGEMENT.COURSE_REQUEST") &&
      firstPart === "course-management"
    ) {
      return `${process.env.PUBLIC_URL}/${firstPart}/course-list`;
    }
    if (firstPart === "assessments") {
      return `${process.env.PUBLIC_URL}/${firstPart}/assessments-list`;
    }
    if (firstPart === "master-data") {
      return `${process.env.PUBLIC_URL}/${firstPart}/question-bank-list`;
    }
    if (secondPart === "course-list" || secondPart === "course-request") {
      return `${process.env.PUBLIC_URL}/${firstPart}/${secondPart}`;
    }
    return pathName;
  };

  const pathParts = pathname.split("/").filter(Boolean);
  const firstPart = pathParts[0];
  const secondPart = pathParts[1];

  const dynamicBreadcrumbs = pathParts.reduce((breadcrumbs, segment, index) => {
    const pathName = `/${pathParts.slice(0, index + 1).join("/")}`;
    const path = getPath(firstPart, secondPart, pathName);
    const breadcrumbName = segment
      .replace("-", " ")
      .replace(/^\w/, c => c.toUpperCase());
    breadcrumbs.push({ name: breadcrumbName, path });

    return breadcrumbs;
  }, []);

  const finalBreadcrumbs = breadcrumbs.length
    ? breadcrumbs
    : dynamicBreadcrumbs;

  const renderBreadcrumbContent = (item, isLast) => {
    if (isLast) {
      return (
        <span className="f-w-400 text-capitalize primary-text-color active">
          {item.name}
        </span>
      );
    }
    if (item.callBack) {
      return (
        <button
          className="border-0 bg-transparent pointer text-white"
          onClick={item.callBack}
        >
          {item.name}
        </button>
      );
    }
    return (
      <Link
        to={`${process.env.PUBLIC_URL}${item.path}`}
        state={{ ...location.state }}
        className="text-white"
      >
        {item.name}
      </Link>
    );
  };

  const lastBreadcrumbName =
    finalBreadcrumbs.length > 0
      ? finalBreadcrumbs[finalBreadcrumbs.length - 1].name
      : SideBarTitleDashboard;

  return (
    <Col
      xs="6"
      xl="6"
      className="page-title d-flex flex-column align-items-start gap-1"
    >
      <H4 className="f-w-700 text-capitalize text-white">
        {lastBreadcrumbName}
      </H4>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="text-white"
            state={{ ...location.state }}
          >
            <MUIIcons size={24} iconName="HomeOutlined" />
          </Link>
        </BreadcrumbItem>
        {finalBreadcrumbs.map((item, index) => (
          <BreadcrumbItem
            key={item.path + index}
            className="f-w-400 text-capitalize primary-text-color"
          >
            {renderBreadcrumbContent(
              item,
              index === finalBreadcrumbs.length - 1
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Col>
  );
};

export default CommonBreadcrumb;
