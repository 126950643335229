import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";
import { Col } from "reactstrap";

import "react-dropzone-uploader/dist/styles.css";
import {
  FileSizeError,
  UploadOrDragFiles,
  FileTypeError,
  DuplicateFileError,
} from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";
import P from "../Paragraph";

const MultiFileUpload = ({
  onFileChange,
  note,
  accept = ".pdf,.docx,.txt,.pptx",
  multiple = true,
  materials = false,
  flushData = false,
}: {
  onFileChange: (files: File, status?: string) => void;
  note?: string;
  accept?: string;
  multiple?: boolean;
  materials?: boolean;
  flushData?: boolean;
}) => {
  const MAX_SIZE_BYTES = 10485760;
  const dropzoneRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState<
    { name: string; size: number }[]
  >([]);

  useEffect(() => {
    if (flushData && dropzoneRef.current) {
      const filesCopy = [...dropzoneRef.current.files];
      filesCopy.forEach(file => {
        file.remove();
      });
      setSelectedFiles([]);
    }
  }, [flushData]);

  const isFileTypeValid = (file: File) => {
    const acceptedTypes = accept.split(",").map(type => type.trim());
    return acceptedTypes.some(type => file.name.endsWith(type));
  };

  const isDuplicateFile = (file: File) => {
    return selectedFiles.some(
      f => f.name === file.name && f.size === file.size
    );
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { file },
    status
  ) => {
    if (status === "done") {
      if (file.size > MAX_SIZE_BYTES) {
        return;
      }
      if (onFileChange) {
        onFileChange(file, status);
        setSelectedFiles(prev => [
          ...prev,
          { name: file.name, size: file.size },
        ]);
      }
    } else if (status === "removed") {
      if (onFileChange) {
        onFileChange(file, status);
        setSelectedFiles(prev =>
          prev.filter(f => f.name !== file.name || f.size !== file.size)
        );
      }
    }
  };

  return (
    <Col className="mt-2" lg={materials ? 6 : 12}>
      <P className="primary-text-color">{note}</P>
      <Dropzone
        ref={dropzoneRef}
        onChangeStatus={handleChangeStatus}
        maxFiles={multiple ? 10 : 1}
        maxSizeBytes={MAX_SIZE_BYTES}
        multiple={multiple}
        inputContent={
          <>
            <MUIIcons size={24} iconName="UploadFileOutlined" />{" "}
            {UploadOrDragFiles}
          </>
        }
        accept={accept}
        getFilesFromEvent={event => {
          const target = event.target as HTMLInputElement;
          const files = target.files ? Array.from(target.files) : [];
          const largeFiles = files.filter(file => file.size > MAX_SIZE_BYTES);
          const invalidFiles = files.filter(file => !isFileTypeValid(file));
          const duplicateFiles = files.filter(file => isDuplicateFile(file));

          if (largeFiles.length > 0) {
            showToast(FileSizeError, "error");
          }
          if (invalidFiles.length > 0) {
            showToast(FileTypeError(accept), "error");
          }
          if (duplicateFiles.length > 0) {
            showToast(DuplicateFileError, "error");
          }

          return files.filter(
            file =>
              file.size <= MAX_SIZE_BYTES &&
              isFileTypeValid(file) &&
              !isDuplicateFile(file)
          );
        }}
        styles={{
          dropzone: { minHeight: 200, maxHeight: 250 },
          dropzoneActive: { borderColor: "green" },
        }}
      />
    </Col>
  );
};

MultiFileUpload.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  materials: PropTypes.bool,
  flushData: PropTypes.bool,
};

export default MultiFileUpload;
