import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { courseTypeMap } from "../../../Api/constants";
import {
  DomainInfo,
  UnmappedCourse,
} from "../../../Api/entities/LearningPlanEntity";
import SelectYearModal from "../../../CommonElements/SelectYearModal";
import FloatingCourseCard, {
  FloatingCourserCardData,
} from "../../../container/FloatingCourseCard";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import { getUnMappedCourse } from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import { ILT, Select, VILT, WBT, Webinar, Year } from "../../../utils/Constant";
import { hasPermissionToComponent } from "../../../utils/helper/permission";

const ILPUnMapped = ({ userId }) => {
  const dispatch = useAppDispatch();
  const [unMappedData, setUnMappedData] = useState<FloatingCourserCardData[]>(
    []
  );
  const [isSelectYearModalOpen, setIsSelectYearModalOpen] = useState(false);
  const { unMappedCourse } = useAppSelector(state => state.learningPlan);
  const [selectedCourse, setSelectedCourse] = useState<UnmappedCourse>();
  const [noDataFound, setNoDataFound] = useState(false);

  useEffect(() => {
    fetchUnMappedCourse();
  }, [userId]);

  const fetchUnMappedCourse = async () => {
    await dispatch(getUnMappedCourse({ userId }));
  };

  useEffect(() => {
    const getDomainNames = (domains: DomainInfo[]) => {
      return domains.map(domain => domain.name);
    };

    const mapCoursesToFloatingCardDetails = (
      course: UnmappedCourse
    ): FloatingCourserCardData => ({
      courseId: course.course_id.toString(),
      domainName: unMappedCourse?.domain
        ? getDomainNames(unMappedCourse?.domain[course.course_id])
        : [],
      courseName: course.course_name,
      courseType: courseTypeMap[course.course_type],
    });
    const constructedData = unMappedCourse?.unmapped_courses?.map(
      mapCoursesToFloatingCardDetails
    );
    setNoDataFound(constructedData?.length === 0);
    setUnMappedData(constructedData);
  }, [unMappedCourse]);

  const selectYearModalToggle = (doRefresh: boolean) => {
    if (isSelectYearModalOpen) setSelectedCourse(null);
    setIsSelectYearModalOpen(!isSelectYearModalOpen);
    if (doRefresh) {
      fetchUnMappedCourse();
    }
  };

  return (
    <div>
      {userId && (
        <Card className="p-3 mb-3">
          <CardBody>
            <Row className="align-items-center gap-lg-0 gap-3">
              <Col
                xs="12"
                lg="7"
                className="d-flex justify-content-start gap-3"
              >
                <span className="text-gray d-flex gap-1">
                  {ILT} :
                  <strong className="text-black">
                    {unMappedCourse?.course_type_count?.ilt}
                  </strong>
                </span>
                <span className="text-gray d-flex gap-1">
                  {VILT} :
                  <strong className="text-black">
                    {unMappedCourse?.course_type_count?.vilt}
                  </strong>
                </span>
                <span className="text-gray d-flex gap-1">
                  {Webinar}:
                  <strong className="text-black">
                    {unMappedCourse?.course_type_count?.webinar}
                  </strong>
                </span>
                <span className="text-gray d-flex gap-1">
                  {WBT}:
                  <strong className="text-black">
                    {unMappedCourse?.course_type_count?.wbt}
                  </strong>
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {noDataFound ? (
        <NoDataFound />
      ) : (
        <FloatingCourseCard
          data={unMappedData || []}
          mainTitle={
            userId &&
            hasPermissionToComponent("USER_MANAGEMENT.ASSIGN_YEAR_TO_COURSE")
          }
          firstItem={`${Select} ${Year}`}
          onFirstItemClick={courseId => {
            setSelectedCourse(
              unMappedCourse?.unmapped_courses?.find(
                course => course.course_id == courseId
              )
            );
            selectYearModalToggle(false);
          }}
        />
      )}

      <SelectYearModal
        isOpen={isSelectYearModalOpen}
        userId={userId}
        toggle={doRefresh => {
          selectYearModalToggle(doRefresh);
        }}
        selectedCourse={selectedCourse}
      />
    </div>
  );
};
ILPUnMapped.propTypes = {
  userId: PropTypes.number,
};
export default ILPUnMapped;
