import React from "react";
import { Container, Row, Col } from "reactstrap";

import { dynamicImage } from "../../Service";
import "./style.scss";
import { scheduledDate } from "../../utils/helper/helper";
import MUIIcons from "../MUIIcon/MUIIcons";

interface DateRangeDisplayProps {
  startDate: string;
  endDate: string;
  type?: string;
}

const DateRangeDisplay: React.FC<DateRangeDisplayProps> = ({
  startDate,
  endDate,
  type = "date",
}) => {
  return (
    <Container className="date-range-display p-2">
      <Row className="align-items-center">
        <Col lg="1">
          {type === "date" && (
            <MUIIcons
              className="svg-color mt-1"
              size={17}
              iconName="CalendarTodayOutlined"
            />
          )}
        </Col>
        <Col className="date-text" xs="auto">
          {startDate &&
            (type === "date" ? scheduledDate(startDate) : startDate)}
        </Col>
        <Col lg="1" xs="auto" className="line">
          <img src={dynamicImage("line.svg")} alt="Line" />
        </Col>
        <Col className="date-text" xs="auto">
          {endDate && (type === "date" ? scheduledDate(endDate) : endDate)}
        </Col>
      </Row>
    </Container>
  );
};

export default DateRangeDisplay;
