import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import { Btn, H4 } from "../../../../AbstractElements";
import { UpsertSessionForMenteePayload } from "../../../../Api/entities/MentoringCoachingEntity";
import Divider from "../../../../CommonElements/Divider";
import FilterSliderModal from "../../../../CommonElements/FilterSliderModal";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CheckboxButton from "../../../../CommonElements/SwitchButton";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import {
  createSession,
  getAllMenteesUnderMentorList,
  updateSession,
} from "../../../../ReduxToolkit/Reducers/LearnerSlice";
import {
  getFilterRolesList,
  getProficiencyList,
  getSbuList,
  getUserMasterList,
} from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  Add,
  Dates,
  Descriptions,
  Done,
  DoYouWantToAddNextSession,
  Duration,
  DurationInMinutes,
  DurationLimitValidationMessage,
  DurationValidationMessage,
  Enter,
  EnterTopicValidationMessage,
  KeyHighlights,
  KeyThoughtsOrSteps,
  KeyThoughtsOrStepsLimitationMessage,
  KeyThoughtsOrStepsValidationMessage,
  Level,
  Mentee,
  MenteeValidationMessage,
  NextSessionValidationMessage,
  ResourceRequiredLimitationMessage,
  ResourceRequiredValidationMessage,
  ResourcesRequired,
  Role,
  SBU,
  Select,
  Session,
  SessionDetails,
  StartDateValidationMessage,
  Topic,
  TopicValidationMessage,
  WhatsNotWorkingWell,
  WhatsNotWorkingWellLimitationMessage,
  WhatsNotWorkingWellValidationMessage,
  WhatsWorkingWell,
  WhatsWorkingWellLimitationMessage,
  WhatsWorkingWellValidationMessage,
} from "../../../../utils/Constant";
import {
  formatDate,
  handleKeyDownOnNumericWithoutDecimal,
} from "../../../../utils/helper/helper";
import useIsMobile from "../../../../utils/helper/responsive";

interface FormErrors {
  [key: string]: string;
}

const AddSessionForm = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const { isUpdate, sessionId, sessionDate, mentorMenteeId } = state || {};
  const { roleOnFilter, sbuList, proficiencyList, userMasterList } =
    useAppSelector(state => state.master);
  const { getAllMenteesUnderMentor } = useAppSelector(state => state.learner);

  const [formState, setFormState] = useState({
    selectedMentee: 0,
    selectedDuration: null,
    startDate: isUpdate ? sessionDate : new Date(),
    topic: "",
    keyThoughts: "",
    workingWell: "",
    notWorkingWell: "",
    resourcesRequired: "",
    nextSession: false,
    nextSessionDate: new Date(),
  });

  const [roleFilterList, setRoleFilterList] = useState([]);
  const [sbuFilterList, setSbuFilterList] = useState([]);
  const [levelFilterList, setLevelFilterList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [defaultFilterValues, setDefaultFilterValues] = useState({});
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    dispatch(getAllMenteesUnderMentorList({ status: null }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getUserMasterList({
        roleId: null,
        levelId: null,
        sbuId: null,
      })
    );
    dispatch(getFilterRolesList());
    dispatch(getSbuList());
    dispatch(getProficiencyList());
  }, [dispatch]);

  useEffect(() => {
    if (userMasterList?.users && getAllMenteesUnderMentor?.mentees) {
      const filteredUsers = userMasterList.users.filter(user =>
        getAllMenteesUnderMentor.mentees.some(
          mentee => mentee.mentee_id === user.id
        )
      );

      const formattedUsers = filteredUsers.map((user: any) => {
        const menteeData = getAllMenteesUnderMentor.mentees.find(
          mentee => mentee.mentee_id === user.id
        );
        return {
          name: user.id,
          label: user.user_name,
          value: user.user_name,
          mentor_mentee_id: menteeData?.mentor_mentee_id,
        };
      });

      setUsersList(formattedUsers);
    }
  }, [userMasterList, getAllMenteesUnderMentor]);

  useEffect(() => {
    const value = [];
    if (roleOnFilter) {
      roleOnFilter?.job_role?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setRoleFilterList(value);
  }, [roleOnFilter]);

  useEffect(() => {
    const value = [];
    if (sbuList) {
      sbuList?.sbu?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.name,
          value: item?.name,
        });
      });
    }
    setSbuFilterList(value);
  }, [sbuList]);

  useEffect(() => {
    const value = [];
    if (proficiencyList) {
      proficiencyList?.proficiency?.forEach(item => {
        value.push({
          name: item?.id,
          label: item?.level_name,
          value: item?.level_name,
        });
      });
    }
    setLevelFilterList(value);
  }, [proficiencyList]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: undefined,
    }));

    const checked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;

    setFormState(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors(prevErrors => ({
      ...prevErrors,
      selectedMentee: undefined,
    }));

    setFormState(prevState => ({
      ...prevState,
      selectedMentee: e.target.value ? Number(e.target.value) : 0,
    }));
  };

  const handleCheckboxChange = () => {
    setFormState(prevState => ({
      ...prevState,
      nextSession: !prevState.nextSession,
    }));
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!isUpdate) {
      if (!formState.selectedMentee) {
        newErrors.selectedMentee = MenteeValidationMessage;
      }
    }

    if (!formState.selectedDuration) {
      newErrors.selectedDuration = DurationValidationMessage;
    } else {
      const duration = Number(formState.selectedDuration);
      if (isNaN(duration) || duration < 1 || duration > 1440) {
        newErrors.selectedDuration = DurationLimitValidationMessage;
      }
    }

    if (!formState.startDate) {
      newErrors.startDate = StartDateValidationMessage;
    }

    if (!formState.topic) {
      newErrors.topic = EnterTopicValidationMessage;
    } else if (formState.topic.length > 100) {
      newErrors.topic = TopicValidationMessage;
    }

    if (!formState.keyThoughts) {
      newErrors.keyThoughts = KeyThoughtsOrStepsValidationMessage;
    } else if (formState.keyThoughts.length > 255) {
      newErrors.keyThoughts = KeyThoughtsOrStepsLimitationMessage;
    }

    if (!formState.workingWell) {
      newErrors.workingWell = WhatsWorkingWellValidationMessage;
    } else if (formState.workingWell.length > 255) {
      newErrors.workingWell = WhatsWorkingWellLimitationMessage;
    }

    if (!formState.notWorkingWell) {
      newErrors.notWorkingWell = WhatsNotWorkingWellValidationMessage;
    } else if (formState.notWorkingWell.length > 255) {
      newErrors.notWorkingWell = WhatsNotWorkingWellLimitationMessage;
    }

    if (!formState.resourcesRequired) {
      newErrors.resourcesRequired = ResourceRequiredValidationMessage;
    } else if (formState.resourcesRequired.length > 255) {
      newErrors.resourcesRequired = ResourceRequiredLimitationMessage;
    }

    if (formState.nextSession) {
      if (!formState.nextSessionDate) {
        newErrors.nextSessionDate = NextSessionValidationMessage;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDoneClick = () => {
    if (validateForm()) {
      const selectedMenteeData = getAllMenteesUnderMentor?.mentees?.find(
        mentee => {
          return mentee?.mentee_id === formState.selectedMentee;
        }
      );

      const payload: UpsertSessionForMenteePayload = {
        mentor_mentee_id: isUpdate
          ? mentorMenteeId
          : selectedMenteeData?.mentor_mentee_id,
        duration: formState.selectedDuration,
        start_date: formatDate(formState.startDate),
        topic: formState.topic,
        key_thoughts: formState.keyThoughts,
        whats_working_well: formState.workingWell,
        whats_not_working: formState.notWorkingWell,
        resources_required: formState.resourcesRequired,
        is_upcoming: formState.nextSession,
        status: formState.nextSession ? "upcoming" : "completed",
      };

      if (formState.nextSession) {
        payload.next_session_date = formatDate(formState.nextSessionDate);
      }

      if (isUpdate) {
        payload.session_id = sessionId;
      }

      if (isUpdate) {
        dispatch(updateSession({ upsertSessionPayload: payload }));
      } else {
        dispatch(createSession({ upsertSessionPayload: payload }));
      }
      navigate(`${process.env.PUBLIC_URL}/mentoring/i-am-mentor`);
    }
  };

  const handleDone = async (values: { [key: string]: string[] }) => {
    if (values["role"] || values["sbu"] || values["level"]) {
      await dispatch(
        getUserMasterList({
          roleId: values["role"]?.toString(),
          levelId: values["level"]?.toString(),
          sbuId: values["sbu"]?.toString(),
        })
      );
    }
  };

  const handleClear = async () => {
    setUsersList([]);
    await dispatch(
      getUserMasterList({
        roleId: "",
        levelId: "",
        sbuId: "",
      })
    );
    setSelectedFilterValues({});
    setDefaultFilterValues({});
  };

  const handleDateChange = (name: string, date: Date) => {
    setFormState(prevState => ({
      ...prevState,
      [name]: date || null,
    }));
  };

  return (
    <div className="page-body page-body-margin">
      <Card>
        <CardBody className="p-4">
          <H4 className="p-2 fw-bold">
            {Add} {SessionDetails}
          </H4>
          <Divider />

          {!isUpdate && (
            <Row className="align-items-center">
              <Col md={5}>
                <FormGroup>
                  <LabelTooltip
                    label={Mentee}
                    important
                    tooltipText={`${Select} ${Mentee}`}
                  />
                  <Input
                    type="select"
                    name="selectedMentee"
                    className="text-gray"
                    onChange={handleSelectChange}
                    value={formState.selectedMentee || ""}
                    invalid={!!errors.selectedMentee}
                  >
                    <option value="" disabled>
                      {Select} {Mentee}
                    </option>
                    {usersList?.map(user => (
                      <option key={user.value} value={user.name}>
                        {user.label}
                      </option>
                    ))}
                  </Input>
                  {errors.selectedMentee && (
                    <div className="text-danger">{errors.selectedMentee}</div>
                  )}
                </FormGroup>
              </Col>

              <Col md={7}>
                <div className="mt-4 d-none">
                  <FilterSliderModal
                    dropdowns={[
                      {
                        label: Role,
                        key: "role",
                        tooltipText: `${Select} ${Role}`,
                        options: roleFilterList,
                        isMultiSelect: true,
                      },
                      {
                        label: SBU,
                        key: "sbu",
                        tooltipText: `${Select} ${SBU}`,
                        options: sbuFilterList,
                        isMultiSelect: true,
                      },
                      {
                        label: Level,
                        key: "level",
                        tooltipText: `${Select} ${Level}`,
                        options: levelFilterList,
                        isMultiSelect: true,
                      },
                    ]}
                    selectedFilterValues={selectedFilterValues}
                    defaultFilterValues={defaultFilterValues}
                    setSelectedFilterValues={setSelectedFilterValues}
                    setDefaultFilterValues={setDefaultFilterValues}
                    onDone={handleDone}
                    onClear={handleClear}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={Dates}
                  important
                  tooltipText={`${Select} ${Dates}`}
                />

                <DatePicker
                  selected={formState.startDate}
                  onChange={(date: Date | null) =>
                    handleDateChange("startDate", date)
                  }
                  disabled={isUpdate}
                  maxDate={new Date()}
                  placeholderText={`${Select} ${Dates}`}
                  className={`form-control ${errors?.startDate ? "is-invalid" : ""}`}
                  dateFormat="dd-MM-yyyy"
                  disabledKeyboardNavigation={true}
                  onKeyDown={e => e.preventDefault()}
                />

                {errors.startDate && (
                  <div className="text-danger">{errors.startDate}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <LabelTooltip
                  label={Duration}
                  important
                  tooltipText={`${Enter} ${DurationInMinutes}`}
                />
                <Input
                  type="number"
                  name="selectedDuration"
                  onChange={handleInputChange}
                  value={formState.selectedDuration}
                  onKeyDown={handleKeyDownOnNumericWithoutDecimal}
                  placeholder={`${Enter} ${DurationInMinutes}`}
                  invalid={!!errors.selectedDuration}
                  maxLength={3}
                  min={1}
                />
                {errors.selectedDuration && (
                  <div className="text-danger">{errors.selectedDuration}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={Topic}
                  important
                  tooltipText={`${Enter} ${Topic}`}
                />
                <Input
                  type="textarea"
                  name="topic"
                  placeholder={`${Enter} ${Topic}`}
                  onChange={handleInputChange}
                  value={formState.topic}
                  invalid={!!errors.topic}
                />
                {errors.topic && (
                  <div className="text-danger">{errors.topic}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={KeyThoughtsOrSteps}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="keyThoughts"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.keyThoughts}
                  invalid={!!errors.keyThoughts}
                />
                {errors.keyThoughts && (
                  <div className="text-danger">{errors.keyThoughts}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <H4 className="mt-3 mb-3 fw-bold">{KeyHighlights}</H4>
          <Row>
            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={WhatsWorkingWell}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="workingWell"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.workingWell}
                  invalid={!!errors.workingWell}
                />
                {errors.workingWell && (
                  <div className="text-danger">{errors.workingWell}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={WhatsNotWorkingWell}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="notWorkingWell"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.notWorkingWell}
                  invalid={!!errors.notWorkingWell}
                />
                {errors.notWorkingWell && (
                  <div className="text-danger">{errors.notWorkingWell}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <LabelTooltip
                  label={ResourcesRequired}
                  important
                  tooltipText={`${Enter} ${Descriptions}`}
                />
                <Input
                  type="textarea"
                  name="resourcesRequired"
                  placeholder={`${Enter} ${Descriptions}`}
                  onChange={handleInputChange}
                  value={formState.resourcesRequired}
                  invalid={!!errors.resourcesRequired}
                />
                {errors.resourcesRequired && (
                  <div className="text-danger">{errors.resourcesRequired}</div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={12} className="d-flex align-items-center">
              <LabelTooltip
                label={DoYouWantToAddNextSession}
                tooltipText=""
                important={false}
              />
              <div className="mt-1 ms-3">
                <CheckboxButton
                  checked={formState.nextSession}
                  onChange={handleCheckboxChange}
                />
              </div>
            </Col>
          </Row>

          {formState.nextSession && (
            <Row className="mt-3">
              <Col md={6}>
                <FormGroup>
                  <LabelTooltip
                    label={`${Session} ${Dates}`}
                    important
                    tooltipText={`${Select} ${Session} ${Dates}`}
                  />
                  <DatePicker
                    selected={formState.nextSessionDate}
                    onChange={(date: Date | null) =>
                      handleDateChange("nextSessionDate", date)
                    }
                    minDate={new Date()}
                    placeholderText={`${Select} ${Dates}`}
                    className={`form-control ${errors?.nextSessionDate ? "is-invalid" : ""}`}
                    dateFormat="dd-MM-yyyy"
                    disabledKeyboardNavigation={true}
                    onKeyDown={e => e.preventDefault()}
                  />

                  {errors.nextSessionDate && (
                    <div className="text-danger">{errors.nextSessionDate}</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}

          <Divider />
          <Row className="d-flex justify-content-end mt-3">
            <Col xs="auto" className={isMobile && "mb-5"}>
              <Btn color="primary" onClick={handleDoneClick}>
                {Done}
              </Btn>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddSessionForm;
