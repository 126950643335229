import { createSlice } from "@reduxjs/toolkit";

import { FillAllFields } from "../../utils/Constant";
import { getCurrentDate, showToast } from "../../utils/helper/helper";

interface CourseRequestSlice {
  numberLevel: number;
  basicInputFormValue: CourseRequestForm;
  showFinish: boolean;
}

const initialState: CourseRequestSlice = {
  numberLevel: 1,
  basicInputFormValue: {
    request_type: "external",
    frequency_type: "yearly",
    course_duration_type: "daily",
    customer_name: "",
    created_by: "",
    designation: "",
    region: "11",
    created_at: getCurrentDate(),
    point_of_contact: "",
    nature_of_business: "",
    content_topic: "",
    summary: "",
    accomplishment: "",
    target_audiance: "",
    expected_target_audience: "",
    course_duration: "",
    objective: "",
    primary_contact: "",
    phone_number: null,
    email_id: "",
    extension: "",
    secondary_contact: "",
    secondary_phone_number: null,
    secondary_email_id: "",
    secondary_extension: "",
    notes: "",
    submitted_to: 0,
    status: "",
    frequency_count: null,
    designation_id: "1",
    business_group_id: "1",
  },
  showFinish: false,
};

const CreateCourseWizardSlice = createSlice({
  name: "CreateCourseWizardSlice",
  initialState,
  reducers: {
    setNumberLevel: (state, action) => {
      state.numberLevel = action.payload;
    },
    setBasicInputFormValue: (state, action) => {
      state.basicInputFormValue = action.payload;
    },
    setBasicInputFormValueToInitials: state => {
      state.basicInputFormValue.created_at = getCurrentDate();
      state.basicInputFormValue = initialState.basicInputFormValue;
    },
    setShowFinish: (state, action) => {
      state.showFinish = action.payload;
    },
    handleBackButton: state => {
      state.showFinish = false;
      if (state.numberLevel === 2) {
        state.numberLevel = state.numberLevel - 1;
      }
      if (state.numberLevel === 3) {
        state.numberLevel = state.numberLevel - 1;
      }
      if (state.numberLevel === 4) {
        state.numberLevel = state.numberLevel - 1;
      }
    },
    handleNextButton: state => {
      if (state.numberLevel === 1) {
        state.numberLevel = 2;
      } else if (state.numberLevel === 2) {
        state.numberLevel = 3;
      } else if (state.numberLevel === 3) {
        state.numberLevel = 4;
        state.showFinish = true;
      } else {
        showToast(FillAllFields, "error");
      }
    },
  },
});

export const {
  setNumberLevel,
  setBasicInputFormValue,
  setShowFinish,
  handleBackButton,
  handleNextButton,
  setBasicInputFormValueToInitials,
} = CreateCourseWizardSlice.actions;
export default CreateCourseWizardSlice.reducer;
