import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_COURSE_MATERIALS,
  CREATE_COURSE_MATERIALS_URL,
  CREATE_LEARNING_OUTCOME,
  CREATE_LEARNING_OUTCOME_URL,
  CREATE_LESSON_PLAN,
  CREATE_LESSON_PLAN_URL,
  POST,
  PUT,
  UPDATE_LEARNING_OUTCOME,
  UPDATE_LEARNING_OUTCOME_URL,
  UPDATE_LESSON_PLAN,
  UPDATE_LESSON_PLAN_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface CourseInterface {
  learning_outcome: CourseLearningOutcomes;
  materials: CourseMaterials;
  lessonPlan: CourseLessonPlan;
  loading: boolean;
  error: string;
}

const initialState: CourseInterface = {
  learning_outcome: null,
  loading: false,
  error: null,
  materials: null,
  lessonPlan: null,
};

export const createLearningOutcome = createAsyncThunk(
  CREATE_LEARNING_OUTCOME,
  async ({ learningOutcome }: { learningOutcome }) => {
    const response = await apiRequest(
      POST,
      CREATE_LEARNING_OUTCOME_URL(),
      learningOutcome
    );
    return response;
  }
);

export const createLessonPlan = createAsyncThunk(
  CREATE_LESSON_PLAN,
  async ({ lessonPlan }: { lessonPlan: CourseLessonPlan }) => {
    const response = await apiRequest(
      POST,
      CREATE_LESSON_PLAN_URL(),
      lessonPlan
    );
    return response;
  }
);

export const updateLessonPlan = createAsyncThunk(
  UPDATE_LESSON_PLAN,
  async ({ lessonPlan }: { lessonPlan: CourseLessonPlan }) => {
    const response = await apiRequest(
      PUT,
      UPDATE_LESSON_PLAN_URL(),
      lessonPlan
    );
    return response;
  }
);

export const updateLearningOutcome = createAsyncThunk(
  UPDATE_LEARNING_OUTCOME,
  async ({ learningOutcome }: { learningOutcome }) => {
    return await apiRequest(
      PUT,
      UPDATE_LEARNING_OUTCOME_URL(),
      learningOutcome
    );
  }
);

export const createCourseMaterials = createAsyncThunk(
  CREATE_COURSE_MATERIALS,
  async ({ materialsData }: { materialsData: CourseMaterials }) => {
    return await apiRequest(PUT, CREATE_COURSE_MATERIALS_URL(), materialsData);
  }
);

const CourseStagesSlice = createSlice({
  name: "courseStages",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createLearningOutcome.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLearningOutcome.fulfilled, (state, action) => {
        state.loading = false;
        state.learning_outcome = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createLearningOutcome.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateLearningOutcome.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLearningOutcome.fulfilled, (state, action) => {
        state.loading = false;
        state.learning_outcome = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateLearningOutcome.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createCourseMaterials.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCourseMaterials.fulfilled, (state, action) => {
        state.loading = false;
        state.materials = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createCourseMaterials.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createLessonPlan.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLessonPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.lessonPlan = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createLessonPlan.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
    builder
      .addCase(updateLessonPlan.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLessonPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.lessonPlan = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateLessonPlan.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const actions = CourseStagesSlice.actions;
export default CourseStagesSlice.reducer;
