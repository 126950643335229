import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { H4, P, Progressbar } from "../../../AbstractElements";
import SimpleAccordion from "../../../CommonElements/Accordion";
import CommonModal from "../../../CommonElements/Modal";
import MultiFileUpload from "../../../CommonElements/MultiFileUpload/MultiFileUpload";
import ILPPendingSkillTable from "../../../container/ILPPendingSkillTable";
import "./style.scss";
import NoDataFound from "../../../container/NoDataFound";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  approveSkillDocs,
  getLOForAssessment,
  getPendingSkillUpdateList,
  uploadSkillDocs,
} from "../../../ReduxToolkit/Reducers/LearningPlanSlice";
import { uploadFileApi } from "../../../ReduxToolkit/Reducers/UserSlice";
import {
  Approved,
  ApproveFile,
  ApproveSkillFile,
  Confirm,
  DeclineFile,
  PendingSkillFileSizeNote,
  PleaseUploadFile,
  RejectSkillFile,
  Upload,
  UploadFile,
} from "../../../utils/Constant";
import {
  assessmentTypeOptionsMap,
  PendingSkillTableHeader,
  showToast,
} from "../../../utils/helper/helper";
import useIsMobile from "../../../utils/helper/responsive";

const PendingSkillUpdate = ({ userId }) => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { pendingSkillUpdateList } = useAppSelector(
    state => state.learningPlan
  );
  const [updateData, setUpdateData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [document, setDocument] = useState([]);

  useEffect(() => {
    getData();
  }, [dispatch, userId]);

  const getData = async () => {
    if (userId) {
      await dispatch(getPendingSkillUpdateList({ userId: userId }));
    } else {
      await dispatch(getPendingSkillUpdateList({ userId: null }));
    }
  };

  useEffect(() => {
    if (pendingSkillUpdateList?.data?.skill_document) {
      getAccordionData();
    }
  }, [pendingSkillUpdateList]);

  const getAccordionData = async () => {
    const assessmentIds = [];
    const accordionData = {};
    const processedData = [];
    pendingSkillUpdateList?.data?.skill_document?.forEach(item => {
      assessmentIds.push(item?.course_assessment_id);
    });

    const LOs = await dispatch(
      getLOForAssessment({ ids: assessmentIds?.toString() })
    );

    const percentage = {};

    if (LOs?.payload?.data) {
      pendingSkillUpdateList?.data?.skill_document?.forEach(item => {
        let name = "";
        if (!percentage[item?.course_id]) {
          percentage[item?.course_id] = {
            total: 0,
            completed: 0,
          };
        }

        percentage[item?.course_id].total += 1;
        if (item?.status === Approved) {
          percentage[item?.course_id].completed += 1;
        }

        const outcomes = [];
        if (LOs?.payload?.data[item?.course_assessment_id]) {
          name = LOs?.payload?.data[item?.course_assessment_id]?.course_name;
          LOs?.payload?.data[item?.course_assessment_id]?.outcomes?.forEach(
            lo => {
              outcomes.push(lo?.outcome);
            }
          );
        }

        const skills = [];
        if (item?.skill_document?.field_skill?.learner_url)
          item?.skill_document?.field_skill?.learner_url?.forEach(docs => {
            if (docs?.file_url) skills.push(docs?.file_url);
          });

        if (item?.skill_document?.ojt_skill)
          item?.skill_document?.ojt_skill?.forEach(docs => {
            if (docs?.file_url) skills.push(docs?.file_url);
          });

        if (!accordionData[item?.course_id]) {
          accordionData[item?.course_id] = {
            courseName: name,
            percentageCompleted:
              Math.floor(
                (percentage[item?.course_id].completed /
                  percentage[item?.course_id].total) *
                  100
              ) || 0,
            SkillDetails: [],
          };
        }

        accordionData[item?.course_id].courseName = name;
        accordionData[item?.course_id].percentageCompleted =
          Math.floor(
            (percentage[item?.course_id].completed /
              percentage[item?.course_id].total) *
              100
          ) || 0;
        accordionData[item?.course_id].SkillDetails.push({
          id: item?.id,
          lo: outcomes,
          type: assessmentTypeOptionsMap[item?.type],
          learnerDocs: skills,
          trainerDocs: item?.uploaded_learner_document,
          status: item?.status,
        });
      });
    }

    Object.keys(accordionData)?.forEach(item => {
      const modifyObj = {
        id: item?.toString(),
        icon: true,
        accordionHeading: (
          <div
            className={`${isMobile ? "custom-accordion-header-responsive" : "custom-accordion-header"}`}
          >
            <H4>{accordionData[item]?.courseName}</H4>
            <div
              className={`${isMobile ? "custom-skillUpdate-progressWrapper-responsive" : "custom-skillUpdate-progressWrapper"}`}
            >
              <Progressbar
                barClassName="custom-skillUpdate-Bar"
                value={Math.floor(
                  (percentage[item]?.completed / percentage[item]?.total) * 100
                )}
                className="custom-skillUpdate-ProgressBar"
              />
              <span>
                {Math.floor(
                  (percentage[item]?.completed / percentage[item]?.total) * 100
                )}{" "}
                %
              </span>
            </div>
          </div>
        ),
        spanClass: "custom-skillUpdate-heading",
        bodyText: (
          <ILPPendingSkillTable
            data={accordionData[item]?.SkillDetails}
            TableHeaders={PendingSkillTableHeader}
            onDropdownClick={onDropdownClick}
            onUpdateStatus={onUpdateStatus}
          />
        ),
      };
      processedData.push(modifyObj);
    });

    setUpdateData(processedData);
  };

  const onDropdownClick = id => {
    setSelectedId(id);
    toggleModal();
  };

  const onUpdateStatus = (id, status) => {
    setSelectedId(id);
    setSelectedType(status);
    toggleModal();
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setSelectedId(null);
      setSelectedType("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const uploadFile = async documents => {
    const postData = new FormData();
    documents?.forEach(file => {
      postData.append("files", file);
    });
    const response = await dispatch(uploadFileApi({ content: postData }));
    return response?.payload?.data?.file_urls;
  };

  const handlePrimaryClick = async () => {
    if (selectedType === "approved") {
      const payload = {
        action: "approved",
        skill_document: [
          {
            id: selectedId,
          },
        ],
      };
      await dispatch(approveSkillDocs({ docs: payload }));
    } else if (selectedType === "declined") {
      const payload = {
        action: "rejected",
        skill_document: [
          {
            id: selectedId,
          },
        ],
      };
      await dispatch(approveSkillDocs({ docs: payload }));
    } else {
      if (document?.length === 0) {
        showToast(PleaseUploadFile, "error");
        return;
      }

      await uploadFile(document).then(async uploaded => {
        const payload = {
          action: "upload_document",
          skill_document: [
            {
              id: selectedId,
              uploaded_learner_document: uploaded,
            },
          ],
        };
        await dispatch(uploadSkillDocs({ docs: payload }));
      });
    }
    await getData();
    toggleModal();
  };

  const getModalTitle = () => {
    if (selectedType === "approved") return ApproveFile;
    if (selectedType === "declined") return DeclineFile;
    return UploadFile;
  };

  const getPrimaryButtonText = () => {
    if (selectedType === "approved" || selectedType === "declined") {
      return Confirm;
    }
    return Upload;
  };

  const renderModalBody = () => {
    if (selectedType === "approved") {
      return <P>{ApproveSkillFile}</P>;
    }
    if (selectedType === "declined") {
      return <P>{RejectSkillFile}</P>;
    }
    return (
      <MultiFileUpload
        accept={".pdf,.docx,.txt"}
        note={PendingSkillFileSizeNote}
        onFileChange={(file, status) => {
          if (status === "removed") {
            setDocument(prevDocuments =>
              prevDocuments.filter(item => item.name !== file.name)
            );
          } else if (status === "done" && file) {
            setDocument(prevDocuments => [...prevDocuments, file]);
          }
        }}
      />
    );
  };

  return (
    <div className="pb-5">
      <CommonModal
        sizeTitle={getModalTitle()}
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={handlePrimaryClick}
        primaryBtnText={getPrimaryButtonText()}
      >
        {renderModalBody()}
      </CommonModal>

      {updateData?.length > 0 && <SimpleAccordion accordionList={updateData} />}
      {updateData?.length === 0 && <NoDataFound />}
    </div>
  );
};

PendingSkillUpdate.propTypes = {
  userId: PropTypes.number,
};

export default PendingSkillUpdate;
