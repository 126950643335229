import { useCallback, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import { MUIIcons } from "../../../AbstractElements";
import { UpsertIndustryPayload } from "../../../Api/entities/ManageMasterDataEntity";
import Btn from "../../../CommonElements/Button";
import Divider from "../../../CommonElements/Divider";
import H3 from "../../../CommonElements/Headings/H3Element";
import MasterDataAddNewModal from "../../../CommonElements/MasterDataAddNewModal";
import CommonModal from "../../../CommonElements/Modal";
import SearchBar from "../../../CommonElements/SearchBar";
import FlatContainer from "../../../container/FlatContainer";
import NoData from "../../../container/NoData";
import { useAppDispatch, useAppSelector } from "../../../ReduxToolkit/Hooks";
import {
  getIndustryList,
  createIndustry,
  updateIndustry,
  getIndustryListById,
  deleteIndustry,
  setIndustryListByIdToIntialValue,
} from "../../../ReduxToolkit/Reducers/ManageMasterDataSlice";
import {
  AddNew,
  Confirm,
  DeleteIndustryHeader,
  DeleteIndustryMessage,
  Enter,
  Industries,
  Industry,
  NoDataText,
} from "../../../utils/Constant";

const IndustryPage = () => {
  const dispatch = useAppDispatch();
  const { industryList, industryListById } = useAppSelector(
    state => state.manageMasterData
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false);
  const [industryToDelete, setIndustryToDelete] = useState(null);
  const [modalFormValues, setModalFormValues] = useState({
    name: "",
    description: "",
    selectedOption: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const toggleDeleteConfirmModal = () =>
    setIsDeleteConfirmModalOpen(!isDeleteConfirmModalOpen);

  useEffect(() => {
    dispatch(getIndustryList());
  }, [dispatch]);

  useEffect(() => {
    if (industryListById) {
      setModalFormValues(prevState => ({
        ...prevState,
        name: industryListById.name,
        description: industryListById.description,
      }));
    }
  }, [industryListById]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const filteredContainerData = industryList?.industry?.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSave = useCallback(
    (name: string, description: string) => {
      const payload: UpsertIndustryPayload = { name, description };
      if (industryListById) {
        payload.id = industryListById.id;
        dispatch(updateIndustry({ updateIndustryPayload: payload })).then(
          () => {
            dispatch(getIndustryList());
            toggleModal();
          }
        );
      } else {
        dispatch(createIndustry({ createIndustryPayload: payload })).then(
          () => {
            dispatch(getIndustryList());
            toggleModal();
          }
        );
      }
    },
    [dispatch, toggleModal]
  );

  const handleEditClick = (id: number) => {
    dispatch(getIndustryListById({ id }));
    toggleModal();
  };

  const handleDeleteClick = (id: number) => {
    setIndustryToDelete(id);
    toggleDeleteConfirmModal();
  };

  const confirmDelete = () => {
    if (industryToDelete) {
      dispatch(deleteIndustry({ id: industryToDelete })).then(() => {
        dispatch(getIndustryList());
        toggleDeleteConfirmModal();
      });
    }
  };

  return (
    <div className="page-body page-body-margin">
      <CommonModal
        sizeTitle={DeleteIndustryHeader}
        isOpen={isDeleteConfirmModalOpen}
        toggle={toggleDeleteConfirmModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={confirmDelete}
        primaryBtnText={Confirm}
      >
        {DeleteIndustryMessage}
      </CommonModal>
      <Card>
        <CardBody>
          <div className="d-flex flex-row align-items-center">
            <H3 className="fw-bold">{Industries}</H3>
            <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-3 ms-auto">
              <SearchBar onSearch={query => setSearchQuery(query)} />
              <Btn
                icon={
                  <MUIIcons iconName="AddCircleOutlineOutlined" size={16} />
                }
                color="primary"
                onClick={() => {
                  dispatch(setIndustryListByIdToIntialValue());
                  setModalFormValues(null);
                  toggleModal();
                }}
              >
                {AddNew}
              </Btn>
            </div>
          </div>
          <Divider />

          {filteredContainerData?.length > 0 ? (
            filteredContainerData.map(industry => (
              <div className="mt-3" key={industry.id}>
                <FlatContainer
                  id={industry.id}
                  description={[
                    {
                      value: industry.description,
                      columnSize: 8,
                      label: "",
                    },
                  ]}
                  title={industry.name}
                  actions={[
                    {
                      iconName: "DeleteOutlineOutlined",
                      onClick: () => handleDeleteClick(industry.id),
                    },
                    {
                      iconName: "EditOutlined",
                      onClick: () => handleEditClick(industry.id),
                    },
                  ]}
                />
                <Divider />
              </div>
            ))
          ) : (
            <NoData
              svg={"empty-folder-icon"}
              title={NoDataText}
              showCard={false}
            />
          )}
        </CardBody>
      </Card>
      <MasterDataAddNewModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSave}
        labelText={Industry}
        placeholderText={`${Enter} ${Industry}`}
        toolTipText={`${Enter} ${Industry}`}
        initialValues={modalFormValues}
      />
    </div>
  );
};

export default IndustryPage;
