import { Col } from "reactstrap";

import { UL } from "../../../AbstractElements";

import UserProfile from "./UserProfile/UserProfile";

const RightHeader = () => {
  return (
    <Col
      xxl="10"
      xl="8"
      md="9"
      xs="6"
      className="nav-right pull-right right-header p-0 ms-auto"
    >
      <UL className="nav-menus flex-row simple-list">
        <UserProfile />
      </UL>
    </Col>
  );
};

export default RightHeader;
