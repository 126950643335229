import PropTypes from "prop-types";
import { Input, Label } from "reactstrap";

import { H1, H2 } from "../../../../AbstractElements";
import "./style.scss";
import { WILL_BE_UPLOADED } from "../../../../Api/constants";
import FileHandler from "../../../../CommonElements/FileHandler";

const SingleChoise = ({
  questionId,
  questionNumber,
  title,
  options,
  handleChange,
  isChecked,
  mediaUrl,
}) => {
  return (
    <div className="single-choise">
      <H1 className="single-choise__question">{questionNumber}</H1>
      <H2 className="single-choise__title">{title}</H2>
      {mediaUrl && mediaUrl !== WILL_BE_UPLOADED && (
        <FileHandler showPreview={true} mediaUrl={mediaUrl} />
      )}
      {options?.map(option => (
        <div key={option.option_id} className="single-choise__checkbox">
          <Input
            onChange={() => handleChange(option.option_id, questionId)}
            name="is_reattempt"
            id={option.option_id}
            type="radio"
            checked={isChecked(option.option_id, questionId)}
          />
          <Label
            className="single-choise__checkbox__label"
            htmlFor={option.option_id}
            check
          >
            {option.option_text}
          </Label>
        </div>
      ))}
    </div>
  );
};

SingleChoise.propTypes = {
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  questionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      option_text: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
  mediaUrl: PropTypes.string,
};

export default SingleChoise;
