import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../ReduxToolkit/Hooks";
import { setToggleSidebar } from "../ReduxToolkit/Reducers/LayoutSlice";
import { setLayout } from "../ReduxToolkit/Reducers/ThemeCustomizerSlice";

import Header from "./Header/Header";
import Loader from "./Loader/Loader";
import Sidebar from "./Sidebar/Sidebar";
import TapTop from "./TapTop/TapTop";
import ThemeCustomizer from "./ThemeCustomizer/ThemeCustomizer";

const Layout = () => {
  const { layout, loading, activeRequests } = useAppSelector(state => ({
    layout: state.themeCustomizer.layout,
    loading: state.loader.loading,
    activeRequests: state.loader.activeRequests,
  }));
  const dispatch = useAppDispatch();
  const compactSidebar = () => {
    const windowWidth = window.innerWidth;
    if (layout === "compact-wrapper") {
      if (windowWidth < 1200) {
        dispatch(setToggleSidebar(true));
      } else {
        dispatch(setToggleSidebar(false));
      }
    } else if (layout === "horizontal-wrapper") {
      if (windowWidth < 992) {
        dispatch(setToggleSidebar(true));
        dispatch(setLayout("compact-wrapper"));
      } else {
        dispatch(setToggleSidebar(false));
        dispatch(setLayout(localStorage.getItem("layout")));
      }
    }
  };

  useEffect(() => {
    compactSidebar();
    window.addEventListener("resize", () => {
      compactSidebar();
    });
  }, [layout]);

  return (
    <>
      {(loading || activeRequests !== 0) && <Loader />}
      <TapTop />
      <div className={`page-wrapper ${layout}`}>
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <Outlet />
        </div>
      </div>
      <ThemeCustomizer />
    </>
  );
};

export default Layout;
