import React, { useEffect } from "react";
import { Row, Col, Badge } from "reactstrap";

import { Btn } from "../../AbstractElements";
import Divider from "../../CommonElements/Divider";
import {
  Available,
  Booked,
  DeSelectAll,
  EligibleList,
  SelectAll,
  selected,
} from "../../utils/Constant";
import "./style.scss";
import UserActionCard from "../UserActionCard";

interface EligibleListingProps {
  eligibleData: any[];
  participantTotalCount?: ParticipantTotalCountData;
  employees?: any;
  setEmployees?: any;
  selectAll?: any;
  setSelectAll?: any;
  replace_id?: any;
  setSelectedUserId?: any;
  selectedUserId?: any;
  available?: any;
  booked?: any;
}

interface ParticipantTotalCountData {
  available: number;
  booked: number;
}

const EligibleListing: React.FC<EligibleListingProps> = ({
  eligibleData,
  participantTotalCount,
  employees,
  setEmployees,
  selectAll,
  setSelectAll,
  replace_id,
  setSelectedUserId,
  selectedUserId,
  available,
  booked,
}) => {
  useEffect(() => {
    if (eligibleData?.length === employees.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [employees, eligibleData, setSelectAll]);

  const toggleSelectAll = () => {
    if (selectAll) {
      setEmployees([]);
    } else {
      const allEmployeeIds = eligibleData.map(emp => emp.id);
      setEmployees(allEmployeeIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSelect = (id: number) => {
    setSelectAll(false);
    if (replace_id) {
      setSelectedUserId(id === selectedUserId ? null : id);
      setEmployees([id]);
    } else {
      if (employees.includes(id)) {
        const updatedEmployees = employees.filter(
          employeeId => employeeId !== id
        );
        setEmployees(updatedEmployees);
      } else {
        setEmployees([...employees, id]);
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Row className="gap-1">
          <Col className="d-flex flex-row gap-2 mb-2">
            <h3>{EligibleList}</h3>
            <Badge className="text-black mx-2 mt-1 custom-eligible-badge">
              {employees.length} {selected}
            </Badge>
          </Col>
          {participantTotalCount && !replace_id && (
            <div className="custom-participant-count d-flex gap-2">
              <span>
                {Available} {available || 0}
              </span>
              <span> | </span>
              <span>
                {Booked} {booked || 0}
              </span>
            </div>
          )}
        </Row>

        {eligibleData?.length > 0 && !replace_id && (
          <Btn
            color="link"
            onClick={toggleSelectAll}
            className="text-danger justify-content-end"
          >
            {selectAll ? DeSelectAll : SelectAll}
          </Btn>
        )}
      </div>

      {eligibleData.map((employee, index) => (
        <div key={employee.name} className="mt-3">
          <UserActionCard
            userDetails={{
              ...employee,
              selected: employees.includes(employee.id),
            }}
            info
            checkBox
            onSelect={handleSelect}
            report={employee?.is_info}
            selectedUserId={selectedUserId}
            replace_id={replace_id}
          />

          {index < eligibleData.length - 1 && <Divider />}
        </div>
      ))}
    </>
  );
};

export default EligibleListing;
