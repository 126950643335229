import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Col,
} from "reactstrap";

import { H4, MUIIcons, P } from "../../AbstractElements";
import "./style.scss";
import { CreatedOn, ModifiedOn } from "../../utils/Constant";
import { formatCustomDate } from "../../utils/helper/helper";

interface MasterDataAccordionProps {
  accordionItems: AccordionItemData;
  onAccordionClick?: (id: string) => void;
  onEditClick?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
}

interface AccordionItemData {
  id: string;
  name: string;
  description: string;
  createdOn: string;
  modifiedOn: string;
  depedentBadges?: DepedentBadgesData[];
}

interface DepedentBadgesData {
  id: number;
  badge: string;
}

const MasterDataAccordion: React.FC<MasterDataAccordionProps> = ({
  accordionItems,
  onAccordionClick = () => {
    return;
  },
  onEditClick,
  onDeleteClick,
}) => {
  const [open, setOpen] = useState<string | undefined>("0");

  const toggle = (id: string) =>
    open === id ? setOpen(undefined) : setOpen(id);

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem className="custom-master-accordion mb-3">
        <AccordionHeader
          targetId={accordionItems.id}
          className="d-flex flex-row align-items-center p-1"
        >
          <Col lg={4} className="d-flex justify-content-start">
            <H4>{accordionItems.name}</H4>
          </Col>

          <Col
            lg={6}
            className="d-flex justify-content-center align-items-center gap-3"
          >
            {accordionItems?.depedentBadges.map(item => (
              <Badge key={item.id} className="text-gray custom-accordion-badge">
                {item.badge}
              </Badge>
            ))}

            <div className="d-flex gap-1 text-gray">
              <MUIIcons
                className="text-gray"
                iconName="CalendarTodayOutlined"
                size={17}
              />
              <P className="mb-0">
                {CreatedOn}: {formatCustomDate(accordionItems.createdOn)}
              </P>
            </div>
            <div className="d-flex gap-1 text-gray">
              <MUIIcons
                className="text-gray"
                iconName="CalendarTodayOutlined"
                size={17}
              />
              <P className="mb-0">
                {ModifiedOn}: {formatCustomDate(accordionItems.modifiedOn)}
              </P>
            </div>
          </Col>
          <Col lg={2} className="d-flex justify-content-end gap-3">
            <MUIIcons
              className="primary-icon-color"
              iconName={"EditOutlined"}
              size={20}
              onClick={() => onEditClick(accordionItems.id)}
            />
            <MUIIcons
              className="primary-icon-color"
              iconName={"DeleteOutlineOutlined"}
              size={20}
              onClick={() => onDeleteClick(accordionItems.id)}
            />
            <MUIIcons
              onClick={() => onAccordionClick(accordionItems.id)}
              size={20}
              iconName="ExpandMoreOutlined"
            />
          </Col>
        </AccordionHeader>
        <AccordionBody accordionId={accordionItems.id}>
          <div
            dangerouslySetInnerHTML={{
              __html: accordionItems.description,
            }}
          />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default MasterDataAccordion;
