import "./style.scss";

const VerticalDivider = () => {
  return (
    <div className="container-line">
      <div className="divider-line algn-item-center mt-2 bg-gray"></div>
    </div>
  );
};

export default VerticalDivider;
