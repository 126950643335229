import { NoDataText } from "../../utils/Constant";
import "./style.scss";

const NoDataFound = () => {
  return (
    <div className="no-data-container">
      <h1 className="primary-text-color">{NoDataText}</h1>
    </div>
  );
};

export default NoDataFound;
