import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
} from "reactstrap";

import { MUIIcons } from "../../AbstractElements";
import { ALL_USERS_STATUS } from "../../Api/constants";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getLocationList } from "../../ReduxToolkit/Reducers/MasterSlice";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import {
  Action,
  Available,
  Booked,
  CourseName,
  CoursePeriod,
  Employee,
  Instructor,
  Location,
  NominationClosesOn,
  WaitList,
} from "../../utils/Constant";
import {
  calculateDayDifference,
  isDateGreaterThanTodayDate,
} from "../../utils/helper/helper";
import "./style.scss";

const NominationListTable = ({ data, handleCourseClick, actions }) => {
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [userMap, setUserMap] = useState(null);
  const [locationNameMap, setLocationNameMap] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const dispatch = useAppDispatch();

  const { usersList } = useAppSelector(state => state.user);
  const { locationList } = useAppSelector(state => state.master);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    setUserMap(userMapObj);
  }, [usersList]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
    dispatch(getLocationList());
  }, []);

  useEffect(() => {
    const locationMapObj = {};
    locationList?.location?.forEach(location => {
      locationMapObj[location.id] = location;
    });
    setLocationNameMap(locationMapObj);
  }, [locationList]);

  const toggleTooltip = index => {
    setTooltipOpen(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const renderActions = row => {
    return actions
      ?.filter(action => !action.isHide)
      .map((action, index) => {
        if (action?.type === "icon") {
          return (
            <>
              {row?.nomination_status === "pending" && row.rm_approval && (
                <span
                  key={action.label + index}
                  id={`tooltip-${action.label}-${index}`}
                >
                  <MUIIcons
                    iconName={action.icon}
                    onClick={() => action.onClick(row)}
                    className={action.className || "pointer"}
                    size={27}
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[index] || false}
                    target={`tooltip-${action.label}-${index}`}
                    toggle={() => toggleTooltip(index)}
                  >
                    {action.label}
                  </Tooltip>
                </span>
              )}
            </>
          );
        } else if (action?.type === "dropdown") {
          return (
            <Dropdown
              key={index + action.label}
              isOpen={row?.nomination_id === dropdownOpen}
              toggle={e => {
                e.preventDefault();
                setDropdownOpen(row.nomination_id);
              }}
            >
              <DropdownToggle color="" className="three-dots">
                <div className="mb-1">&#x22EE;</div>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {action.dropdownOptions
                  .filter(dropdownOption => !dropdownOption.isHide)
                  .map((dropdownOption, idx) => (
                    <DropdownItem
                      key={dropdownOption.label + idx}
                      onClick={() => dropdownOption.onClick(row)}
                    >
                      {dropdownOption.label}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </Dropdown>
          );
        }
        return "-";
      });
  };

  const scheduledCourseColumns = () => [
    {
      name: Employee,
      selector: row =>
        userMap ? userMap[row?.participant_id]?.user_name : "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: CourseName,
      selector: row => (
        <button
          onClick={() => handleCourseClick(row)}
          className="border-0 bg-transparent pointer primary-text-color"
        >
          {row.course_name}
        </button>
      ),
      minWidth: "16rem",
      sortable: true,
    },
    {
      name: Instructor,
      selector: row =>
        userMap ? userMap[row?.primary_trainer_id]?.user_name : "-",
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: Location,
      selector: row =>
        locationNameMap ? locationNameMap[row.location_id]?.name : "-",
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: CoursePeriod,
      selector: row => `${row.course_start_date} - ${row.course_end_date}`,
      minWidth: "12rem",
      sortable: true,
    },
    {
      name: Available,
      selector: row => row.available_count,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: Booked,
      selector: row => row.booked_count,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: WaitList,
      selector: row => row.waitlist_count,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: NominationClosesOn,
      selector: row => `${calculateDayDifference(row.course_end_date)} days`,
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: Action,
      cell: row =>
        isDateGreaterThanTodayDate(row.course_start_date) ? (
          <div className="d-flex gap-2">{renderActions(row)}</div>
        ) : (
          "-"
        ),
      minWidth: "6rem",
      sortable: true,
    },
  ];

  return (
    <DataTable
      className="nomination-table"
      columns={scheduledCourseColumns()}
      data={data}
      pagination
      fixedHeader
      striped
    />
  );
};

NominationListTable.propTypes = {
  data: PropTypes.array,
  handleCourseClick: PropTypes.func,
  actions: PropTypes.array,
};

export default NominationListTable;
