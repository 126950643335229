import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppProvider } from "./AppContext";
import Store from "./ReduxToolkit/Store";
import RouterData from "./Routes";

const App = () => {
  return (
    <Provider store={Store}>
      <BrowserRouter basename={"/"}>
        <AppProvider>
          <RouterData />
          <ToastContainer />
        </AppProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
