import React from "react";

import H5 from "../Headings/H5Element";

interface StatusDisplayProps {
  label: string;
  number: string;
  color?: string;
}

const StatusDisplay: React.FC<StatusDisplayProps> = ({
  label,
  number,
  color,
}) => {
  return (
    <div className="d-flex gap-2">
      <H5 className={`${color || "text-secondary"}`}>{label}:</H5>
      <H5 className={`fw-bold ${color}`}>{number}</H5>
    </div>
  );
};

export default StatusDisplay;
