import React from "react";

import { H5, P } from "../../AbstractElements";
import { DetailHeaderProps } from "../../utils/helper/propTypes";

const DetailHeaderContainer: React.FC<DetailHeaderProps> = ({
  assign,
  name,
}) => {
  return (
    <div className="flex-grow-1">
      <P className="mb-3 text-gray">{assign}</P>
      <H5>{name}</H5>
    </div>
  );
};

export default DetailHeaderContainer;
