import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H4, H5 } from "../../../../AbstractElements";
import SimpleAccordion from "../../../../CommonElements/Accordion";
import ModuleQuestion from "../../../../CommonElements/ModuleQuestion";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getAllQuestionsAndModules } from "../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import { CorrectAnswer } from "../../../../utils/Constant";

const QuestionBankPreview = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { questionAndModuleList } = useAppSelector(state => state.questionBank);
  const [moduleList, setModuleList] = useState([]);
  const { questionBankId, courseId, selectedModules } = location.state || "";

  useEffect(() => {
    if (questionBankId) {
      dispatch(getAllQuestionsAndModules({ question_bank_id: questionBankId }));
    }
  }, [dispatch, questionBankId]);

  useEffect(() => {
    const modules = [];
    if (questionAndModuleList) {
      questionAndModuleList?.modules?.forEach(item => {
        const moduleObj = {
          id: item?.id?.toString(),
          icon: courseId,
          accordionHeading: <H4>{item?.module_name}</H4>,
          spanClass: "custom-skillUpdate-heading",
          bodyText: item?.questions?.map((item, index) => {
            return (
              <div key={item?.id}>
                <ModuleQuestion
                  id={item?.id}
                  type={item?.question_type}
                  status=""
                >
                  <H5>{`${index + 1}. ${item?.question_text}`}</H5>
                  <H5 className="mt-1">
                    <>
                      <b>{CorrectAnswer} : </b>
                      {item?.options
                        ?.filter(option => option?.is_correct)
                        .map(option => option.option_text)
                        .join(", ") || "-"}
                    </>
                  </H5>
                </ModuleQuestion>
              </div>
            );
          }),
        };
        if (selectedModules?.includes(item?.id)) modules.push(moduleObj);
      });
    }
    setModuleList(modules);
  }, [questionAndModuleList]);

  return (
    <div className="page-body pb-5">
      <Card className="p-2">
        <CardBody>
          <H4>{questionAndModuleList?.name}</H4>
        </CardBody>
      </Card>

      {moduleList && (
        <div className="mt-3">
          <SimpleAccordion
            showNoOfQuestions={false}
            accordionList={moduleList}
            showDropdown={false}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionBankPreview;
