import PropTypes from "prop-types";
import React from "react";
import { Container } from "reactstrap";

import { H4, H5 } from "../../AbstractElements";
import StatusDisplay from "../../CommonElements/StatusDisplay";
import { MCQScore } from "../../utils/Constant";

interface MCQScoreContainerProps {
  score: string;
  mcqStatus: string;
  manualStatus: string;
}

const MCQScoreContainer: React.FC<MCQScoreContainerProps> = ({
  score,
  mcqStatus,
  manualStatus,
}) => {
  return (
    <Container className="mcq-card p-2 generic-module-card">
      <div className="d-flex flex-column align-items-center">
        <div>
          <H5>{MCQScore}</H5>
        </div>
        <div className="mt-3">
          <H4 className="text-success">{score}</H4>
        </div>
      </div>
      <div className="d-flex gap-5 mt-2 justify-content-between p-3">
        <StatusDisplay label="MCQ" number={mcqStatus} />
        <StatusDisplay label="Manual" number={manualStatus} />
      </div>
    </Container>
  );
};

MCQScoreContainer.propTypes = {
  score: PropTypes.string.isRequired,
  mcqStatus: PropTypes.string.isRequired,
  manualStatus: PropTypes.string.isRequired,
};

export default MCQScoreContainer;
