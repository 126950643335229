import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import MediaPlayer from "../MediaPlayer";

interface PreviewModalProps {
  isOpen: boolean;
  toggle: () => void;
  mediaUrl: string;
}

const getFileExtension = (url: string) =>
  url.split(".").pop()?.toLowerCase() || "";

const renderMedia = (fileExtension: string, mediaUrl: string) => {
  if (["png", "jpg", "jpeg"].includes(fileExtension)) {
    return <img src={mediaUrl} alt="Preview" style={{ width: "100%" }} />;
  } else if (fileExtension === "mp4") {
    return (
      <video controls style={{ width: "100%" }}>
        <source src={mediaUrl} type="video/mp4" />
      </video>
    );
  } else if (fileExtension === "mp3") {
    return <MediaPlayer src={mediaUrl} />;
  } else {
    return null;
  }
};

const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  toggle,
  mediaUrl,
}) => {
  const fileExtension = getFileExtension(mediaUrl);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
      <ModalHeader toggle={toggle}>File Preview</ModalHeader>
      <ModalBody>{renderMedia(fileExtension, mediaUrl)}</ModalBody>
    </Modal>
  );
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  mediaUrl: PropTypes.string.isRequired,
};

export default PreviewModal;
