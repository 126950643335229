import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { H3 } from "../../AbstractElements";

const ComingSoon = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get("switch");
  useEffect(() => {
    if (paramValue) {
      const handlePopState = () => {
        navigate("/dashboard?switch=true");
        window.location.reload();
      };

      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("popstate", handlePopState);
      };
    }
  }, [paramValue]);

  return (
    <div className="page-body">
      <Card>
        <CardBody>
          <H3 className="text-center mt-2">Coming Soon</H3>
        </CardBody>
      </Card>
    </div>
  );
};

export default ComingSoon;
