import { configureStore } from "@reduxjs/toolkit";

import AdditionalInfoWizardSlice from "./Reducers/AdditionaInfoWizrd";
import AssessmentsCatlogSlice from "./Reducers/AssessmentCatlogSlice";
import AssessmentSlice from "./Reducers/AssessmentSlice";
import AttendanceSlice from "./Reducers/AttendanceSlice";
import CompetencyWizardSlice from "./Reducers/CompetencyWizardSlice";
import CourseRequestSlice from "./Reducers/CourseRequestSlice";
import CourseSlice from "./Reducers/CourseSlice";
import CourseWizardSlice from "./Reducers/CourseWizardSlice";
import CurriculumSlice from "./Reducers/CurriculumSlice";
import FeedbackRatingSlice from "./Reducers/FeedbackRatingSlice";
import LayoutSlice from "./Reducers/LayoutSlice";
import LearnerSlice from "./Reducers/LearnerSlice";
import LearningPlanSlice from "./Reducers/LearningPlanSlice";
import LoaderSlice from "./Reducers/LoaderSlice";
import LoadFactorSlice from "./Reducers/LoadFactorSlice";
import ManageMasterDataSlice from "./Reducers/ManageMasterDataSlice";
import MasterSlice from "./Reducers/MasterSlice";
import PolicySilce from "./Reducers/PolicySilce";
import QuestionBankSlice from "./Reducers/QuestionBankSlice";
import CreateCourseWizardSlice from "./Reducers/RequestCourseWizardSlice";
import SgaSlice from "./Reducers/SgaSlice";
import ThemeCustomizerSlice from "./Reducers/ThemeCustomizerSlice";
import UserManagementSlice from "./Reducers/UserManagement";
import UserSlice from "./Reducers/UserSlice";

const Store = configureStore({
  reducer: {
    layout: LayoutSlice,
    themeCustomizer: ThemeCustomizerSlice,
    createCourse: CreateCourseWizardSlice,
    user: UserSlice,
    courseWizard: CourseWizardSlice,
    competencyStatement: CompetencyWizardSlice,
    courseRequest: CourseRequestSlice,
    course: CourseSlice,
    master: MasterSlice,
    assessment: AssessmentSlice,
    additionalInfoWizard: AdditionalInfoWizardSlice,
    loader: LoaderSlice,
    curriculum: CurriculumSlice,
    questionBank: QuestionBankSlice,
    policy: PolicySilce,
    sga: SgaSlice,
    assessmentCatlog: AssessmentsCatlogSlice,
    learner: LearnerSlice,
    learningPlan: LearningPlanSlice,
    loadFactor: LoadFactorSlice,
    attendance: AttendanceSlice,
    feedback: FeedbackRatingSlice,
    manageMasterData: ManageMasterDataSlice,
    userManagement: UserManagementSlice,
  },
});

export default Store;

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;
