import { Link } from "react-router-dom";

import { Image } from "../../AbstractElements";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import {
  handleResponsiveToggle,
  setToggleSidebar,
} from "../../ReduxToolkit/Reducers/LayoutSlice";
import { dynamicImage } from "../../Service";

const LogoWrapper = () => {
  const dispatch = useAppDispatch();
  const { toggleSidebar } = useAppSelector(state => state.layout);

  return (
    <>
      <div className="logo-wrapper">
        <Link to={"/dashboard"}>
          <Image
            width={84}
            className="img-fluid"
            src={dynamicImage("logo/logo.png")}
            alt="logo"
          />
        </Link>
        <div
          className="back-btn"
          onClick={() => dispatch(handleResponsiveToggle())}
        >
          <i className="fa fa-angle-left"></i>
        </div>
        <div
          className="toggle-sidebar"
          onClick={() => dispatch(setToggleSidebar(!toggleSidebar))}
        >
          <Image
            width={20}
            height={20}
            className="img-fluid"
            src={dynamicImage("logo/logo-icon.png")}
            alt="MofiLogo"
          />
        </div>
      </div>
      <div className="logo-icon-wrapper">
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          <Image
            width={35}
            height={35}
            className="img-fluid"
            src={dynamicImage("logo/logo-icon.png")}
            alt="logo"
          />
        </Link>
      </div>
    </>
  );
};

export default LogoWrapper;
