import React from "react";

import { P, SVG } from "../../AbstractElements";
import {
  ComingSoon,
  Course,
  Courses,
  DownloadCertificates,
} from "../../utils/Constant";
import { formatCustomDate, showToast } from "../../utils/helper/helper";
import Progressbar from "../Progressbar";

import "./style.scss";

export interface TargetLevelModalData {
  id: number;
  expertiseLevel: string;
  totalCount: number;
  completedCount: number;
  percentageCompleted: number;
  completedDate: string;
  status: string;
}

interface TargetLevelModalProps {
  targetLevelData: TargetLevelModalData[];
}

const TargetLevelModal: React.FC<TargetLevelModalProps> = ({
  targetLevelData,
}) => {
  const handleCertificate = () => {
    showToast(ComingSoon, "warning");
  };
  return (
    <div className="target-level-modal">
      {targetLevelData?.map(item => (
        <div key={item.id} className="custom-targetlevel-accordion">
          <div className="custom-targetlevel-accordion-header">
            {item.status === "certified" ? (
              <div className="d-flex gap-2 align-items-center">
                <P className="target-level-modal__header">
                  {item.expertiseLevel}
                </P>
                <SVG
                  iconId="completed-icon-green"
                  className="completed-icon-circle"
                />
              </div>
            ) : (
              <P className="target-level-modal__header">
                {item.expertiseLevel} ({item.percentageCompleted}%)
              </P>
            )}
            <Progressbar
              barClassName="custom-targetBar"
              value={item.percentageCompleted}
              className="custom-targetProgressBar"
            />
            {item.status === "certified" ? (
              <div className="d-flex flex-row justify-content-between">
                <p className="mt-2 text-gray">
                  {formatCustomDate(item.completedDate)}
                </p>
                <div className="pointer mt-2" onClick={handleCertificate}>
                  <p className="primary-text-color">{DownloadCertificates}</p>
                </div>
              </div>
            ) : (
              <p className="mt-2 text-gray">{`Total Courses ${item.completedCount} / ${item.totalCount}  ${item.totalCount === 0 || item.totalCount === 1 ? Course : Courses}`}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TargetLevelModal;
