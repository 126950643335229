import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import { Btn, H1, H4, H5, SecondaryButton } from "../../AbstractElements";
import "./style.scss";

interface CommonModalType {
  children?: React.ReactNode;
  size?: string;
  isOpen?: boolean;
  toggle?: () => void;
  title?: string;
  modalBodyClassName?: string;
  sizeTitle?: string;
  fullTitle?: string;
  onClosed?: () => void;
  backdrop?: boolean | "static";
  showFooter?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  footer?: React.ReactNode;
  onPrimaryBtnClick?: () => void;
  onSecondaryBtnClick?: () => void;
}

const CommonModal: React.FC<CommonModalType> = ({
  backdrop,
  size,
  isOpen,
  toggle,
  title,
  onClosed,
  sizeTitle,
  fullTitle,
  modalBodyClassName,
  children,
  showFooter,
  footer,
  primaryBtnText,
  secondaryBtnText,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
}) => {
  return (
    <>
      {isOpen && <div className="common-modal-overlay"></div>}
      <Modal
        backdrop={backdrop}
        centered
        size={size}
        isOpen={isOpen}
        toggle={toggle}
        onClosed={onClosed}
      >
        {(title || sizeTitle || fullTitle) && (
          <div className="modal-header" onClick={toggle}>
            {title && <H5 className="f-w-600">{title}</H5>}
            {sizeTitle && <H4>{sizeTitle}</H4>}
            {fullTitle && <H1 className="fs-5">{fullTitle}</H1>}
            <Btn close></Btn>
          </div>
        )}
        <ModalBody className={modalBodyClassName || ""}>{children}</ModalBody>
        {showFooter && !footer && (
          <ModalFooter>
            {secondaryBtnText && (
              <SecondaryButton onClick={onSecondaryBtnClick}>
                {secondaryBtnText}
              </SecondaryButton>
            )}
            {primaryBtnText && (
              <Btn color="primary" onClick={onPrimaryBtnClick}>
                {primaryBtnText}
              </Btn>
            )}
          </ModalFooter>
        )}
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </Modal>
    </>
  );
};

export default CommonModal;
