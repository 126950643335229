import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { MUIIcons, H5, Image } from "../../AbstractElements";
import Miscellaneous from "../../CommonElements/Miscellaneous";
import "./style.scss";
import { useAppDispatch } from "../../ReduxToolkit/Hooks";
import {
  setCompetencyBasicInputFormValueToInitials,
  setCompetencyNumberLevel,
  setShowFinish,
} from "../../ReduxToolkit/Reducers/CompetencyWizardSlice";
import { setLearningOutcomeToDefault } from "../../ReduxToolkit/Reducers/MasterSlice";
import { ViewDetails } from "../../utils/Constant";

interface CourseInfoTypeCardProps {
  name: string;
  status: string;
  color: string;
  imagePath: string;
  stage: string;
  path: string;
  courseId: string;
  showMessage: boolean;
  showMessageIndicator: boolean;
}

const CourseInfoTypeCard: React.FC<CourseInfoTypeCardProps> = ({
  name,
  status,
  color,
  imagePath,
  stage,
  path,
  courseId,
  showMessage,
  showMessageIndicator,
}) => {
  const dispatch = useAppDispatch();
  const getBackgroundColor = (color: string) => {
    switch (color) {
      case "danger":
        return "#fffee8";
      case "warning":
        return "#fffee8";
      case "success":
        return "#fffee8";
      case "lightbrown":
        return "#fff1f0";
      case "lightwarning":
        return "#fff9ec";
      default:
        return "#c5c5c3";
    }
  };

  const imageContainerStyle = {
    width: "4.5rem",
    height: "4.5rem",
    backgroundColor: color ? getBackgroundColor(color) : "#c5c5c3",
    borderRadius: "1.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  return (
    <Card>
      <CardBody className="mb-2">
        <Row>
          <Col lg="8" sm="8" xs="8">
            <div className="d-flex gap-4 align-items-end">
              <div
                className="flex-shrink-0 mt-1 imageContainer"
                style={imageContainerStyle}
              >
                <Image src={imagePath} alt="student" />
              </div>
              <Miscellaneous name={name} status={status} color={color} />
            </div>
          </Col>
          <Col className="mt-4 d-flex gap-5 sm-2 xs-2">
            {stage !== "0" && showMessage && (
              <Link to={path} state={{ courseId: courseId }}>
                <div className="comment-icon-container">
                  <MUIIcons
                    size={24}
                    className="primary-icon-color mt-2"
                    iconName="ChatBubbleOutlineOutlined"
                  />
                  {showMessageIndicator && <span className="red-dot"></span>}
                </div>
              </Link>
            )}
            {stage === "0" && (
              <div className="d-flex gap-5 mt-2">
                <div></div>
                <MUIIcons
                  size={24}
                  className="primary-icon-color"
                  iconName="LockOutlined"
                />
              </div>
            )}
            {stage === "1" && (
              <Link to={path} state={{ courseId: courseId }}>
                <div
                  onClick={() => {
                    dispatch(setShowFinish(false));
                    dispatch(setCompetencyBasicInputFormValueToInitials());
                    dispatch(setCompetencyNumberLevel(1));
                    dispatch(setLearningOutcomeToDefault());
                  }}
                  className="d-flex mt-2 gap-2 pointer"
                >
                  <H5 className="primary-text-color">{ViewDetails}</H5>
                  <MUIIcons
                    size={24}
                    className="primary-icon-color"
                    iconName="ArrowForwardOutlined"
                  />
                </div>
              </Link>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CourseInfoTypeCard;
