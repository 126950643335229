import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Tooltip,
} from "reactstrap";

import {
  DefaultButton,
  H2,
  H4,
  P,
  SecondaryButton,
} from "../../../../AbstractElements";
import { STATUS_200 } from "../../../../Api/constants";
import SimpleAccordion from "../../../../CommonElements/Accordion";
import BorderTabs from "../../../../CommonElements/BorderTabs";
import ButtonDropdown from "../../../../CommonElements/ButtonDropdown";
import Divider from "../../../../CommonElements/Divider";
import FixedFooter from "../../../../CommonElements/FixedFooter";
import JustifyTabs from "../../../../CommonElements/JustifyTabs";
import LabelTooltip from "../../../../CommonElements/LabelTooltip";
import CommonModal from "../../../../CommonElements/Modal";
import ModuleQuestion from "../../../../CommonElements/ModuleQuestion";
import SliderModal from "../../../../CommonElements/SliderModal";
import StatusDisplay from "../../../../CommonElements/StatusDisplay";
import CheckboxButton from "../../../../CommonElements/SwitchButton";
import TagInput from "../../../../CommonElements/TagInput";
import { useAppDispatch, useAppSelector } from "../../../../ReduxToolkit/Hooks";
import { getDomainList } from "../../../../ReduxToolkit/Reducers/MasterSlice";
import {
  bulkUploadQb,
  createQuestionBank,
  deleteModuleOrQb,
  getAllQuestionsAndModules,
  getQuestionAndOptionByModule,
  setQuestionBankPayload,
  setQuestionBankPayloadToInitials,
  updateQuestionBank,
} from "../../../../ReduxToolkit/Reducers/QuestionBankSlice";
import {
  AddModule,
  AddQuestions,
  Auto,
  Confirm,
  DeleteModuleFromQB,
  DeleteModuleHeader,
  Draft,
  Enter,
  ErrorInTheFile,
  IsSelfAssessment,
  LoadMore,
  Manual,
  ModuleAddedSuccessfully,
  ModuleDetails,
  ModuleName,
  MultiSelection,
  OpenField,
  PleaseAddModule,
  PleaseAddQuestion,
  PleaseUploadFile,
  Publish,
  QuestionBank,
  QuestionBankCreatedSuccessful,
  QBModuleNameTootTipMessage,
  QuestionBankName,
  QBNameToolTipMessage,
  QuestionBankUpdatedSuccessful,
  QuestionsTab,
  RenameModuleName,
  Save,
  SaveAsDraft,
  SingleSelection,
  SomethingWentWrong,
  Tag,
  UploadFile,
  UploadedSuccessfully,
  ViewAll,
} from "../../../../utils/Constant";
import {
  addModulesTabs,
  addQuestionBankTabs,
  downloadCsv,
  getQuestionsType,
  getRandomInteger,
  sanitizeString,
  scrollToTop,
  selectionTypeMap,
  showToast,
  validateValue,
} from "../../../../utils/helper/helper";
import useIsMobile from "../../../../utils/helper/responsive";

import BulkUpload from "./BulkUpload";
import OpenChoiceAndCompetencyQuestions from "./OpenChoiceAndCompetencyQuestions";
import RemedialAction from "./RemedialAction";
import SingleAndMultiSelectQuestions from "./SingleAndMultiSelect";

import "./style.scss";

const AddQuestionBank = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobileView = useIsMobile();
  const { questionBankId } = location.state || "";
  const [flushData, setFlushData] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(Manual);
  const { questionBankPayloadData, questionAndModuleList } = useAppSelector(
    state => state.questionBank
  );
  const { domainList } = useAppSelector(state => state.master);
  const [eachModuleQuestionMap, setEachModuleQuestionMap] = useState({});
  const [totalQuestionCountMap, setTotalQuestionCountMap] = useState({});
  const [eachModuleQuestionNumberMap, setEachModuleQuestionNumberMap] =
    useState({});
  const [isSelfAssessment, setIsSelfAssessment] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [page, setPage] = useState(1);
  const [bulkUploadFile, setBulkUploadFile] = useState(null);
  const [questionBankName, setQuestionBankName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [activeModuleTab, setActiveModuleTab] = useState<string>(QuestionsTab);
  const [sliderModal, setSliderModal] = useState(false);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [accordionList, setAccordionList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tags, setTags] = useState<string[]>(null);
  const [tooltipOpen, setTooltipOpen] = useState({});

  useEffect(() => {
    setFlushData(true);
    setFlushData(false);
    setAccordionList([]);
    dispatch(getDomainList());
    dispatch(setQuestionBankPayloadToInitials());
    dispatch(
      setQuestionBankPayload({
        name: "",
        status: "draft",
        is_competency: false,
        modules: [],
        tag: [],
      })
    );
    setActiveTab(Manual);
    setQuestionBankName("");
    setModuleName("");
    setBulkUploadFile(null);
    setTags(null);
    setIsSelfAssessment(false);
    setActiveModuleTab(QuestionsTab);
    setSelectedQuestionType(null);
  }, [dispatch]);

  useEffect(() => {
    getQuestionBankData(questionBankId);
  }, [dispatch, questionBankId]);

  const getQuestionBankData = questionBankId => {
    if (questionBankId) {
      dispatch(getAllQuestionsAndModules({ question_bank_id: questionBankId }));
    }
  };

  useEffect(() => {
    if (questionAndModuleList) {
      const updatedModules = questionAndModuleList.modules.map(module => ({
        ...module,
        questions: module.questions.map(question => ({
          ...question,
          saved: true,
        })),
      }));

      dispatch(
        setQuestionBankPayload({
          ...questionAndModuleList,
          modules: updatedModules,
        })
      );
    }
  }, [dispatch, questionAndModuleList]);

  const toggleTooltip = module_id => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [module_id]: !prevState[module_id],
    }));
  };

  const onQuestionClick = (id, module_id, module) => {
    scrollToTop();
    setSelectedQuestion(id);
    setSelectedModule(module_id);
    setSelectedQuestionType(module?.question_type);
  };

  const getEachModuleQuestion = async (
    module_id,
    toggle = false,
    isEdit = false
  ) => {
    const data = await dispatch(
      getQuestionAndOptionByModule({
        module_id: module_id?.toString(),
        page: toggle ? page : 1,
      })
    );
    if (toggle) {
      setPage(page + 1);
    }

    if (module_id) {
      setEachModuleQuestionMap(prev => {
        const obj = { ...prev };
        if (!obj[module_id]) obj[module_id] = {};

        if (data?.payload?.questions?.length === 0) {
          obj[module_id] = {};
        } else {
          data?.payload?.questions?.forEach(item => {
            if (!obj[module_id][item.id] && isEdit) {
              delete obj[module_id][selectedQuestion];
              setSelectedQuestion(item.id);
            }
            obj[module_id][item.id] = item;
          });
        }

        return obj;
      });

      setTotalQuestionCountMap(prev => {
        const countObj = { ...prev };
        countObj[module_id] = data?.payload?.question_type_count || {};
        return countObj;
      });
    }
  };

  useEffect(() => {
    setAccordionList([]);
    const modules = [];
    if (
      eachModuleQuestionMap &&
      Object.keys(eachModuleQuestionMap).length > 0
    ) {
      questionBankPayloadData?.modules.map((item, index) => {
        modules.push({
          id: item?.id,
          module_id: item?.id,
          onAccordionClick: id => {
            setSelectedQuestion(null);
            setSelectedModule(id);
          },
          accordionItemClass: "item-class",
          accordionHeaderClass: "header-class",
          spanClass: "span-class",
          onHeaderDropdownClick: item => {
            if (item === "item1") {
              setModuleName(
                questionBankPayloadData?.modules[index]?.module_name
              );
            }
            setSelectedOption(item);
            toggleModal();
          },
          accordionHeading: getModuleHeading(
            questionBankPayloadData?.modules[index]?.module_name,
            item?.id + "-" + getRandomInteger(1, 100) + "-" + index
          ),
          icon: true,
          questions: questionBankPayloadData?.modules[index]?.question_count,
          bodyText: (
            <div onClick={() => setSelectedModule(item?.id)}>
              {eachModuleQuestionMap[item?.id] &&
                Object.keys(eachModuleQuestionMap[item?.id])
                  ?.filter(
                    question =>
                      eachModuleQuestionMap[item?.id][question].saved ||
                      eachModuleQuestionMap[item?.id][question].id
                  )
                  ?.map((question, ind) => {
                    if (ind < 3)
                      return (
                        <div key={ind}>
                          <ModuleQuestion
                            id={parseInt(question)}
                            module={eachModuleQuestionMap[item?.id][question]}
                            module_id={index}
                            onQuestionClick={onQuestionClick}
                            status={
                              eachModuleQuestionMap[item?.id][question]?.status
                            }
                          >
                            {
                              eachModuleQuestionMap[item?.id][question]
                                ?.question_text
                            }
                          </ModuleQuestion>
                        </div>
                      );
                  })}
              <div className="d-flex justify-content-between">
                {eachModuleQuestionMap[item?.id] &&
                questionBankPayloadData?.modules[index]?.question_count > 3 ? (
                  <div
                    onClick={() => {
                      sliderToggle(index);
                      setSliderModal(true);
                      setPage(2);
                    }}
                  >
                    <P className="primary-text-color mt-2 pointer">{ViewAll}</P>
                  </div>
                ) : (
                  <div></div>
                )}
                {questionBankPayloadData?.is_competency ? (
                  <DefaultButton
                    color="primary"
                    onClick={() => getQuestionTypes("open_text")}
                  >
                    {AddQuestions}
                  </DefaultButton>
                ) : (
                  <ButtonDropdown
                    items={getQuestionsType(getQuestionTypes, index)}
                    btnText={AddQuestions}
                    direction="down"
                  />
                )}
              </div>
            </div>
          ),
        });
      });
    }
    setAccordionList(modules);
  }, [eachModuleQuestionMap]);

  useEffect(() => {
    if (questionBankPayloadData) {
      setQuestionBankName(questionBankPayloadData?.name);
      setTags(questionBankPayloadData?.tag || null);
      setIsSelfAssessment(questionBankPayloadData?.is_competency);

      const map = {};
      const processModules = async () => {
        const promises = questionBankPayloadData?.modules?.map(async item => {
          if (item?.id) {
            map[item.id] = item.question_count;
            return getEachModuleQuestion(item?.id);
          }
          return Promise.resolve();
        });
        await Promise.all(promises);
        setEachModuleQuestionNumberMap(map);
      };
      processModules();

      if (
        (questionBankId || questionBankPayloadData?.id) &&
        selectedModule === null
      ) {
        setSelectedModule(questionBankPayloadData?.modules[0]?.id || null);
      }
      if (selectedQuestionType === null) {
        if (isSelfAssessment) setSelectedQuestionType("single_select");
        else setSelectedQuestionType("open_text");
      }
      if (questionBankPayloadData?.modules?.length === 0) {
        setSelectedModule(null);
        setSelectedQuestion(null);
      }
    }
  }, [questionBankPayloadData]);

  const getQuestionTypes = (clicked: any) => {
    scrollToTop();
    setSelectedQuestion(null);
    setSelectedQuestionType(clicked);
  };

  const sliderToggle = async (id = null) => {
    if (!sliderModal) {
      const module_id: any = questionBankPayloadData?.modules?.filter(
        item => item?.id === id
      );
      getEachModuleQuestion(module_id?.id, true);
    } else {
      scrollToTop();
      setPage(1);
    }
  };

  const handleTabClick = (tabId: any) => {
    setBulkUploadFile(null);
    setActiveTab(tabId);
  };

  const handleModuleTabClick = (tabId: any) => {
    setActiveModuleTab(tabId);
  };

  const createQb = () => {
    const qbMappedData = { ...questionBankPayloadData };
    const qbName = validateValue(QuestionBankName, questionBankName, true);
    const mName = validateValue(ModuleName, moduleName, true);
    if (!qbName?.isValid) {
      showToast(qbName?.errorMessage, "error");
      return;
    }
    if (!mName?.isValid) {
      showToast(mName?.errorMessage, "error");
      return;
    }
    qbMappedData.name = questionBankName;
    qbMappedData.tag = tags;
    qbMappedData.status = Draft;
    qbMappedData.is_competency = isSelfAssessment;

    const newModule = [...(questionBankPayloadData?.modules || [])];
    setSelectedQuestion(null);
    newModule.push({
      module_name: moduleName,
      questions: [
        {
          saved: false,
          question_text: "",
          question_type: selectedQuestionType,
          media_url: "",
          options:
            selectedQuestionType === "open_text"
              ? []
              : [
                  { option_text: "", is_correct: false },
                  { option_text: "", is_correct: false },
                ],
        },
      ],
      remedial_action: null,
    });
    qbMappedData.modules = newModule;
    dispatch(setQuestionBankPayload(qbMappedData));
    onCreateQuestionBank("draft", qbMappedData);
    setActiveModuleTab(QuestionsTab);
  };

  const getModuleHeading = (header: string, module_id: any) => {
    const truncatedHeader =
      header?.length > 6 ? `${header?.substring(0, 8)}...` : header;

    return (
      <>
        <span
          className="m-1"
          id={sanitizeString(`tooltip-module-name${module_id}`)}
        >
          {truncatedHeader}
        </span>
        <Tooltip
          placement="top"
          isOpen={tooltipOpen[module_id] || false}
          target={sanitizeString(`tooltip-module-name${module_id}`)}
          toggle={() => toggleTooltip(module_id)}
        >
          {header}
        </Tooltip>
      </>
    );
  };

  const onCreateQuestionBank = async (
    type,
    qbData = null,
    returnValue = false
  ) => {
    let qbMappedData = { ...questionBankPayloadData };

    if (qbData !== null) {
      qbMappedData = { ...qbData };
    }
    const qbName = validateValue(QuestionBankName, questionBankName, true);
    if (!qbName?.isValid) {
      showToast(qbName?.errorMessage, "error");
      return;
    }
    qbMappedData.name = questionBankName;
    qbMappedData.tag = tags;
    qbMappedData.status = type;
    qbMappedData.is_competency = isSelfAssessment;
    const modulesWithUpdatedRemedialAction = [];

    const modules = [];
    let questions = 0;
    qbMappedData.modules = await Promise.all(
      qbMappedData.modules.map(async (module, index) => {
        if (module?.question_count)
          questions = questions + module?.question_count;
        modules.push(index);
        modulesWithUpdatedRemedialAction.push({
          ...module,
          remedial_action: {},
          questions: [],
        });

        return modulesWithUpdatedRemedialAction[
          modulesWithUpdatedRemedialAction.length - 1
        ];
      })
    );

    if (type === "published") {
      if (modules?.length === 0) {
        showToast(PleaseAddModule, "error");
        return;
      }

      if (questions === 0) {
        showToast(PleaseAddQuestion, "error");
        return;
      }
    }

    let res = null;
    if (questionBankId || questionBankPayloadData?.id) {
      res = await dispatch(updateQuestionBank({ question_bank: qbMappedData }));
    } else {
      res = await dispatch(createQuestionBank({ question_bank: qbMappedData }));
    }
    setModuleName("");
    if (res?.payload?.data?.question_bank_id) {
      const modules = await dispatch(
        getAllQuestionsAndModules({
          question_bank_id: res?.payload?.data?.question_bank_id,
        })
      );
      setSelectedModule(modules?.payload?.modules[0]?.id);
      if (modules?.payload) {
        qbMappedData.id = res?.payload?.data?.question_bank_id;
        dispatch(setQuestionBankPayload(modules?.payload));
      }
      if (returnValue) {
        return res?.payload?.data?.question_bank_id;
      }
    }
    if (
      res?.payload?.status_code === STATUS_200 ||
      res?.payload?.data?.question_bank_id
    ) {
      if (qbData !== null) {
        showToast(ModuleAddedSuccessfully, "success");
      } else {
        if (questionBankId || questionBankPayloadData?.id) {
          showToast(QuestionBankUpdatedSuccessful, "success");
        } else {
          showToast(QuestionBankCreatedSuccessful, "success");
        }
      }
    } else {
      showToast(SomethingWentWrong, "error");
    }
    if (res?.payload?.status_code === STATUS_200 && qbData === null) {
      return navigate(
        `${process.env.PUBLIC_URL}/master-data/question-bank-list`
      );
    }
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setModuleName("");
      setSelectedOption("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const handleTagsChange = (newTags: string[]) => {
    setTags(newTags);
    return newTags;
  };

  const onBulkUpload = async () => {
    if (!bulkUploadFile || bulkUploadFile === null) {
      showToast(PleaseUploadFile, "error");
      return;
    }

    const postData = new FormData();
    bulkUploadFile.forEach(file => {
      postData.append("file", file);
    });

    let id = questionBankId || questionBankPayloadData?.id;
    if (!id || id === null) {
      id = await onCreateQuestionBank("draft", null, true);
    }
    if (!id) {
      return;
    }

    const res = await dispatch(bulkUploadQb({ id: id, file: postData }));
    if (
      res?.payload &&
      res?.payload?.status_code === 200 &&
      res?.type === "BULK_UPLOAD_QB/fulfilled"
    ) {
      if (id) {
        dispatch(getAllQuestionsAndModules({ question_bank_id: id }));
      }
      showToast(UploadedSuccessfully, "success");
      setActiveTab(Manual);
      return;
    }

    if (res?.payload && res?.type === "BULK_UPLOAD_QB/fulfilled") {
      showToast(ErrorInTheFile, "error");
      downloadCsv(questionBankPayloadData?.name + "_Error.csv", res?.payload);
    }
    setFlushData(true);
    setBulkUploadFile(null);
  };

  return (
    <div className="page-body">
      <CommonModal
        sizeTitle={
          selectedOption === "item1" ? RenameModuleName : DeleteModuleHeader
        }
        modalBodyClassName=""
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop="static"
        size="lg"
        showFooter
        onPrimaryBtnClick={async () => {
          if (selectedOption === "item1") {
            const mName = validateValue(ModuleName, moduleName, true);
            if (!mName?.isValid) {
              showToast(mName?.errorMessage, "error");
              return;
            }
            const updateModule = {
              ...questionBankPayloadData,
              modules: questionBankPayloadData?.modules.map(module =>
                module?.id === selectedModule
                  ? { ...module, module_name: moduleName }
                  : module
              ),
            };
            setModuleName("");
            dispatch(setQuestionBankPayload(updateModule));
          } else {
            let deleted = null;
            const deleteModule = { ...questionBankPayloadData };
            if (selectedModule) {
              const deleteObj = {
                type: "module",
                id: [selectedModule],
              };

              deleted = await dispatch(deleteModuleOrQb({ data: deleteObj }));
            }
            if (deleted?.payload?.status_code === STATUS_200) {
              deleteModule.modules = deleteModule.modules.filter(
                item => item?.id !== selectedModule
              );
            }
            const modules = await dispatch(
              getAllQuestionsAndModules({
                question_bank_id: questionBankPayloadData?.id,
              })
            );
            if (modules?.payload) {
              setSelectedModule(modules?.payload?.modules[0]?.id);
              dispatch(setQuestionBankPayload(modules?.payload));
            }
            if (modules?.payload?.modules?.length === 0) {
              setSelectedModule(null);
              setEachModuleQuestionMap({});
            }
          }
          toggleModal();
        }}
        primaryBtnText={selectedOption === "item1" ? Save : Confirm}
      >
        {selectedOption === "item1" ? (
          <FormGroup>
            <LabelTooltip
              label={ModuleName}
              tooltipText={`${Enter} ${ModuleName}`}
              important
            />
            <Input
              rows={4}
              name="module_name"
              type="text"
              value={moduleName}
              placeholder={`${Enter} ${ModuleName}`}
              maxLength={100}
              onChange={e => {
                setModuleName(e.target.value);
              }}
            />
          </FormGroup>
        ) : (
          DeleteModuleFromQB
        )}
      </CommonModal>
      <SliderModal
        isOpen={sliderModal}
        toggle={() => {
          setPage(1);
          setSliderModal(false);
        }}
      >
        <H2>{ModuleDetails}</H2>
        <Divider />
        <div className="mt-3 comments-wrapper">
          {questionBankPayloadData &&
            selectedModule !== null &&
            selectedModule &&
            totalQuestionCountMap?.[selectedModule] && (
              <div className="mb-4 d-flex gap-3">
                {!isSelfAssessment && (
                  <StatusDisplay
                    label={SingleSelection}
                    number={
                      totalQuestionCountMap[selectedModule]?.single_select || 0
                    }
                  />
                )}
                {!isSelfAssessment && (
                  <StatusDisplay
                    label={MultiSelection}
                    number={
                      totalQuestionCountMap[selectedModule]?.multiple_choice ||
                      0
                    }
                  />
                )}
                <StatusDisplay
                  label={OpenField}
                  number={totalQuestionCountMap[selectedModule]?.open_text || 0}
                />
              </div>
            )}
          <div className="pt-4 mr-5 comments-wrapper">
            <div className="comments-section">
              {eachModuleQuestionMap &&
                selectedModule &&
                Object.keys(eachModuleQuestionMap[selectedModule] || {})?.map(
                  (item, index) => {
                    const selected = eachModuleQuestionMap[selectedModule];
                    return (
                      <ModuleQuestion
                        key={index}
                        onQuestionClick={() => {
                          scrollToTop();
                          setPage(1);
                          setSliderModal(false);
                          setSelectedQuestion(selected?.[item]?.id);
                          setSelectedQuestionType(
                            selected?.[item]?.question_type
                          );
                        }}
                        type={selected?.[item]?.question_type}
                        status={selected?.[item]?.status}
                      >
                        {selected?.[item]?.question_text}
                      </ModuleQuestion>
                    );
                  }
                )}
            </div>
          </div>
          {eachModuleQuestionMap &&
            questionBankPayloadData &&
            selectedModule &&
            eachModuleQuestionMap[selectedModule] &&
            Object.keys(eachModuleQuestionMap[selectedModule])?.length <
              questionBankPayloadData?.modules?.filter(
                item => item?.id === selectedModule
              )[0]?.question_count && (
              <Container className={"mt-3 pr-5 comment-input-container"}>
                <div
                  className={`pb-3 d-flex justify-content-between ${!isMobileView ? "comment-section-margin" : ""}`}
                >
                  <DefaultButton
                    color="primary"
                    onClick={async () => {
                      const module_id = selectedModule;
                      getEachModuleQuestion(module_id, true);
                    }}
                  >
                    {LoadMore}
                  </DefaultButton>
                </div>
              </Container>
            )}
        </div>
      </SliderModal>
      <Row className="mb-5">
        <Col lg="8" className="mb-5 pb-5">
          <Card className="p-1">
            <CardBody>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="mb-1 mb-sm-0 d-flex flex-column gap-2 ">
                  <JustifyTabs
                    tabs={addQuestionBankTabs}
                    activeTab={activeTab}
                    onTabClick={handleTabClick}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
          {activeTab === Manual &&
            questionBankPayloadData?.modules?.filter(
              item => item?.id === selectedModule
            )[0]?.module_name && (
              <Card>
                <CardBody className="m-1">
                  <H4>
                    {
                      questionBankPayloadData?.modules?.filter(
                        item => item?.id === selectedModule
                      )[0]?.module_name
                    }
                  </H4>
                </CardBody>
              </Card>
            )}
          {activeTab === Manual &&
            (selectedModule || selectedModule === 0) &&
            selectedModule !== null && (
              <>
                {!isSelfAssessment && (
                  <BorderTabs
                    tabs={addModulesTabs}
                    activeTab={activeModuleTab}
                    handleTabClick={handleModuleTabClick}
                  />
                )}
                {activeModuleTab === "questions" && (
                  <div>
                    {(selectedQuestionType === "single_select" ||
                      selectedQuestionType === "match_the_following" ||
                      selectedQuestionType === "multiple_choice") && (
                      <SingleAndMultiSelectQuestions
                        questionBankPayloadData={questionBankPayloadData}
                        type={selectionTypeMap[selectedQuestionType]}
                        selectedModule={selectedModule}
                        selectedQuestion={selectedQuestion}
                        selectedQuestionType={selectedQuestionType}
                        getQuestionTypes={getQuestionTypes}
                        questionBankId={
                          questionBankPayloadData?.id || questionBankId
                        }
                        getQuestionBankData={getQuestionBankData}
                        eachModuleQuestionMap={eachModuleQuestionMap}
                        setEachModuleQuestionMap={setEachModuleQuestionMap}
                        eachModuleQuestionNumberMap={
                          eachModuleQuestionNumberMap
                        }
                        getEachModuleQuestion={getEachModuleQuestion}
                      />
                    )}
                    {selectedQuestionType === "open_text" && (
                      <OpenChoiceAndCompetencyQuestions
                        eachModuleQuestionNumberMap={
                          eachModuleQuestionNumberMap
                        }
                        getQuestionBankData={getQuestionBankData}
                        selectedModule={selectedModule}
                        selectedQuestionType={selectedQuestionType}
                        selectedQuestion={selectedQuestion}
                        questionBankPayloadData={questionBankPayloadData}
                        type={OpenField}
                        getQuestionTypes={getQuestionTypes}
                        questionBankId={
                          questionBankPayloadData?.id || questionBankId
                        }
                        setSelectedQuestionType={setSelectedQuestionType}
                        eachModuleQuestionMap={eachModuleQuestionMap}
                        getEachModuleQuestion={getEachModuleQuestion}
                        setEachModuleQuestionMap={setEachModuleQuestionMap}
                      />
                    )}
                  </div>
                )}
                {activeModuleTab === "remidial_actions" && (
                  <RemedialAction
                    questionBankPayloadData={questionBankPayloadData}
                    selectedModule={selectedModule}
                    domainList={domainList}
                  />
                )}
              </>
            )}
          {activeTab === Auto && (
            <>
              <BulkUpload
                flushData={flushData}
                onFileChange={file => {
                  setBulkUploadFile([file]);
                }}
              />
              <DefaultButton color="primary" onClick={onBulkUpload}>
                {UploadFile}
              </DefaultButton>
            </>
          )}
        </Col>
        <Col lg="4">
          <Card className="p-2">
            <CardBody className="mt-2">
              <H4>{QuestionBank}</H4>
              <FormGroup className="mt-2">
                <LabelTooltip
                  label={QuestionBankName}
                  tooltipText={QBNameToolTipMessage}
                  important
                />
                <Input
                  rows={4}
                  name="question_bank_name"
                  type="text"
                  placeholder={`${Enter} ${QuestionBankName}`}
                  maxLength={100}
                  value={questionBankName}
                  onChange={e => setQuestionBankName(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <LabelTooltip
                  label={Tag}
                  tooltipText={`${Enter} ${Tag}`}
                  important={false}
                />
                <TagInput initialTags={tags} onTagsChange={handleTagsChange} />
              </FormGroup>
              <FormGroup className="mt-4 d-flex gap-2">
                <Label
                  className="text-dark fw-bold mt-1"
                  htmlFor="flexCheckAddCurriculum"
                  check
                >
                  {IsSelfAssessment}
                </Label>
                <CheckboxButton
                  onChange={() => {
                    setIsSelfAssessment(!isSelfAssessment);
                  }}
                  checked={isSelfAssessment}
                  disabled={questionBankPayloadData?.modules?.length > 0}
                />
              </FormGroup>
              {activeTab !== Auto && (
                <>
                  {" "}
                  <FormGroup>
                    <LabelTooltip
                      label={ModuleName}
                      tooltipText={QBModuleNameTootTipMessage}
                      important
                    />
                    <Input
                      rows={4}
                      name="module_name"
                      type="text"
                      value={moduleName}
                      placeholder={`${Enter} ${ModuleName}`}
                      maxLength={100}
                      onChange={e => {
                        setModuleName(e.target.value);
                      }}
                    />
                  </FormGroup>
                  <DefaultButton
                    onClick={createQb}
                    className="mt-3 w-100"
                    color="primary"
                  >
                    {AddModule}
                  </DefaultButton>
                  {accordionList && (
                    <div className="mt-5">
                      <SimpleAccordion
                        showNoOfQuestions={true}
                        accordionList={accordionList}
                        showDropdown={true}
                      />
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        <FixedFooter>
          <SecondaryButton onClick={() => onCreateQuestionBank("draft")}>
            {SaveAsDraft}
          </SecondaryButton>
          <DefaultButton
            onClick={() => onCreateQuestionBank("published")}
            color="primary"
          >
            {Publish}
          </DefaultButton>
        </FixedFooter>
      </div>
    </div>
  );
};

export default AddQuestionBank;
