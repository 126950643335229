import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  CREATE_CLASSROOM,
  CREATE_COMPETENCY,
  CREATE_DOMAIN,
  CREATE_INDUSTRY,
  CREATE_JOB_ROLE,
  CREATE_MASTER_LEARNING_OUTCOME,
  DELETE,
  DELETE_CLASSROOM,
  DELETE_COMPETENCY,
  DELETE_DOMAIN,
  DELETE_INDUSTRY,
  DELETE_JOB_ROLE,
  GET,
  GET_ALL_LEARNING_OUTCOME_LIST,
  GET_ALL_LEARNING_OUTCOME_LIST_URL,
  GET_CLASSROOM_LIST,
  GET_CLASSROOM_LIST_BY_ID,
  GET_COMPETENCY_BY_ID,
  GET_COMPETENCY_LIST,
  GET_DOMAIN_BY_ID,
  GET_DOMAIN_LIST,
  GET_DOMAIN_LIST_URL,
  GET_INDUSTRY_LIST,
  GET_INDUSTRY_LIST_BY_ID,
  GET_JOB_ROLE_LIST,
  GET_JOB_ROLE_LIST_BY_ID,
  GET_JOB_ROLE_LIST_URL,
  GET_LEARNING_OUTCOME_BY_ID,
  POST,
  PUT,
  UN_ARCHIVE_LEARNING_OUTCOME,
  UPDATE_CLASSROOM,
  UPDATE_COMPETENCY,
  UPDATE_DOMAIN,
  UPDATE_INDUSTRY,
  UPDATE_JOB_ROLE,
  UPSERT_CLASSROOM_LIST_URL,
  UPSERT_COMPETENCY_LIST_URL,
  UPSERT_DOMAIN_LIST_URL,
  UPSERT_INDUSTRY_LIST_URL,
  UPSERT_JOB_ROLE_LIST_URL,
  UPSERT_LEARNING_OUTCOME_LIST_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { STATUS_200 } from "../../Api/constants";
import {
  ClassRoomListByIdResponse,
  ClassRoomListResponse,
  CompetencyListResponse,
  IndustryListByIdResponse,
  IndustryListResponse,
  JobRoleByIdResponse,
  JobRoleResponse,
  LearningOutcomeListByIdResponse,
  LearningOutcomeListResponse,
  ManageMasterCompetencyistByIdResponse,
  ManageMasterDomainListByIdResponse,
  ManageMasterDomainListResponse,
  UnArchiveLearningOutcomePayload,
  UpsertClassRoomPayload,
  UpsertCompetencyPayload,
  UpsertDomainPayload,
  UpsertIndustryPayload,
  UpsertJobRolePayload,
  UpsertLearningOutcomePayload,
} from "../../Api/entities/ManageMasterDataEntity";
import { SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface MasterData {
  loading: boolean;
  error: string | null;
  classRoomList: ClassRoomListResponse;
  classRoomListById: ClassRoomListByIdResponse;
  createClassRoom: UpsertClassRoomPayload;
  updateClassRoom: UpsertClassRoomPayload;
  deleteClassRoom: {};
  jobRoleList: JobRoleResponse;
  jobRoleListById: JobRoleByIdResponse;
  createJobRole: UpsertJobRolePayload;
  updateJobRole: UpsertJobRolePayload;
  deleteJobRole: {};
  industryList: IndustryListResponse;
  industryListById: IndustryListByIdResponse;
  createIndustry: UpsertIndustryPayload;
  updateIndustry: UpsertIndustryPayload;
  deleteIndustry: {};
  domainList: ManageMasterDomainListResponse;
  domainListById: ManageMasterDomainListByIdResponse;
  createDomain: UpsertDomainPayload;
  updateDomain: UpsertDomainPayload;
  deleteDomain: {};
  competencyList: CompetencyListResponse;
  competencyListById: ManageMasterCompetencyistByIdResponse;
  createCompetency: UpsertCompetencyPayload;
  updateCompetency: UpsertCompetencyPayload;
  deleteCompetency: {};
  learningOutcomeList: LearningOutcomeListResponse;
  learningOutcomeListById: LearningOutcomeListByIdResponse;
  createLearningOutcome: UpsertLearningOutcomePayload;
  updateLearningOutcome: UpsertLearningOutcomePayload;
  unArchiveLearningOutcome: UnArchiveLearningOutcomePayload;
}

const initialState: MasterData = {
  loading: false,
  error: null,
  classRoomList: null,
  classRoomListById: null,
  createClassRoom: null,
  updateClassRoom: null,
  deleteClassRoom: null,
  jobRoleList: null,
  jobRoleListById: null,
  createJobRole: null,
  updateJobRole: null,
  deleteJobRole: null,
  industryList: null,
  industryListById: null,
  createIndustry: null,
  updateIndustry: null,
  deleteIndustry: null,
  domainList: null,
  domainListById: null,
  createDomain: null,
  updateDomain: null,
  deleteDomain: null,
  competencyList: null,
  competencyListById: null,
  createCompetency: null,
  updateCompetency: null,
  deleteCompetency: null,
  learningOutcomeList: null,
  learningOutcomeListById: null,
  createLearningOutcome: null,
  updateLearningOutcome: null,
  unArchiveLearningOutcome: null,
};

export const getClassRoomList = createAsyncThunk(
  GET_CLASSROOM_LIST,
  async () => {
    const response = await apiRequest(GET, UPSERT_CLASSROOM_LIST_URL());
    return response.data;
  }
);

export const getClassRoomListById = createAsyncThunk(
  GET_CLASSROOM_LIST_BY_ID,
  async ({ id }: { id?: number }) => {
    const response = await apiRequest(GET, UPSERT_CLASSROOM_LIST_URL(id));
    return response.data;
  }
);

export const createClassRoom = createAsyncThunk(
  CREATE_CLASSROOM,
  async ({
    createClassRoomPayload,
  }: {
    createClassRoomPayload: UpsertClassRoomPayload;
  }) => {
    const response = await apiRequest(
      POST,
      UPSERT_CLASSROOM_LIST_URL(),
      createClassRoomPayload
    );
    return response.data;
  }
);

export const updateClassRoom = createAsyncThunk(
  UPDATE_CLASSROOM,
  async ({
    updateClassRoomPayload,
  }: {
    updateClassRoomPayload: UpsertClassRoomPayload;
  }) => {
    const response = await apiRequest(
      PUT,
      UPSERT_CLASSROOM_LIST_URL(),
      updateClassRoomPayload
    );
    return response.data;
  }
);

export const deleteClassRoom = createAsyncThunk(
  DELETE_CLASSROOM,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(DELETE, UPSERT_CLASSROOM_LIST_URL(id));
    return response.data;
  }
);

export const getJobRoleList = createAsyncThunk(GET_JOB_ROLE_LIST, async () => {
  const response = await apiRequest(GET, GET_JOB_ROLE_LIST_URL());
  return response.data;
});

export const getJobRoleListById = createAsyncThunk(
  GET_JOB_ROLE_LIST_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_JOB_ROLE_LIST_URL(id));
    return response.data;
  }
);

export const createJobRole = createAsyncThunk(
  CREATE_JOB_ROLE,
  async ({
    createJobRolePayload,
  }: {
    createJobRolePayload: UpsertJobRolePayload;
  }) => {
    const response = await apiRequest(
      POST,
      UPSERT_JOB_ROLE_LIST_URL(),
      createJobRolePayload
    );
    return response.data;
  }
);

export const updateJobRole = createAsyncThunk(
  UPDATE_JOB_ROLE,
  async ({
    updateJobRolePayload,
  }: {
    updateJobRolePayload: UpsertJobRolePayload;
  }) => {
    const response = await apiRequest(
      PUT,
      UPSERT_JOB_ROLE_LIST_URL(),
      updateJobRolePayload
    );
    return response.data;
  }
);

export const deleteJobRole = createAsyncThunk(
  DELETE_JOB_ROLE,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(DELETE, UPSERT_JOB_ROLE_LIST_URL(id));
    return response.data;
  }
);

export const getIndustryList = createAsyncThunk(GET_INDUSTRY_LIST, async () => {
  const response = await apiRequest(GET, UPSERT_INDUSTRY_LIST_URL());
  return response.data;
});

export const getIndustryListById = createAsyncThunk(
  GET_INDUSTRY_LIST_BY_ID,
  async ({ id }: { id?: number }) => {
    const response = await apiRequest(GET, UPSERT_INDUSTRY_LIST_URL(id));
    return response.data;
  }
);

export const createIndustry = createAsyncThunk(
  CREATE_INDUSTRY,
  async ({
    createIndustryPayload,
  }: {
    createIndustryPayload: UpsertIndustryPayload;
  }) => {
    const response = await apiRequest(
      POST,
      UPSERT_INDUSTRY_LIST_URL(),
      createIndustryPayload
    );
    return response.data;
  }
);

export const updateIndustry = createAsyncThunk(
  UPDATE_INDUSTRY,
  async ({
    updateIndustryPayload,
  }: {
    updateIndustryPayload: UpsertIndustryPayload;
  }) => {
    const response = await apiRequest(
      PUT,
      UPSERT_INDUSTRY_LIST_URL(),
      updateIndustryPayload
    );
    return response.data;
  }
);

export const deleteIndustry = createAsyncThunk(
  DELETE_INDUSTRY,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(DELETE, UPSERT_INDUSTRY_LIST_URL(id));
    return response.data;
  }
);

export const getManageMasterDomainList = createAsyncThunk(
  GET_DOMAIN_LIST,
  async () => {
    const response = await apiRequest(GET, GET_DOMAIN_LIST_URL());
    return response.data;
  }
);

export const getManageMasterDomainListById = createAsyncThunk(
  GET_DOMAIN_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, GET_DOMAIN_LIST_URL(id));
    return response.data;
  }
);

export const createDomain = createAsyncThunk(
  CREATE_DOMAIN,
  async ({
    createDomainPayload,
  }: {
    createDomainPayload: UpsertDomainPayload;
  }) => {
    const response = await apiRequest(
      POST,
      UPSERT_DOMAIN_LIST_URL(),
      createDomainPayload
    );
    return response.data;
  }
);

export const updateDomain = createAsyncThunk(
  UPDATE_DOMAIN,
  async ({
    updateDomainPayload,
  }: {
    updateDomainPayload: UpsertDomainPayload;
  }) => {
    const response = await apiRequest(
      PUT,
      UPSERT_DOMAIN_LIST_URL(),
      updateDomainPayload
    );
    return response.data;
  }
);

export const deleteDomain = createAsyncThunk(
  DELETE_DOMAIN,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(DELETE, GET_DOMAIN_LIST_URL(id));
    return response.data;
  }
);

export const getCompetencyList = createAsyncThunk(
  GET_COMPETENCY_LIST,
  async () => {
    const response = await apiRequest(GET, UPSERT_COMPETENCY_LIST_URL());
    return response.data;
  }
);

export const getManageMasterCompetencyListById = createAsyncThunk(
  GET_COMPETENCY_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(GET, UPSERT_COMPETENCY_LIST_URL(id));
    return response.data;
  }
);

export const createCompetency = createAsyncThunk(
  CREATE_COMPETENCY,
  async ({
    createCompetencyPayload,
  }: {
    createCompetencyPayload: UpsertCompetencyPayload;
  }) => {
    const response = await apiRequest(
      POST,
      UPSERT_COMPETENCY_LIST_URL(),
      createCompetencyPayload
    );
    return response.data;
  }
);

export const updateCompetency = createAsyncThunk(
  UPDATE_COMPETENCY,
  async ({
    updateCompetencyPayload,
  }: {
    updateCompetencyPayload: UpsertCompetencyPayload;
  }) => {
    const response = await apiRequest(
      PUT,
      UPSERT_COMPETENCY_LIST_URL(),
      updateCompetencyPayload
    );
    return response.data;
  }
);

export const deleteCompetency = createAsyncThunk(
  DELETE_COMPETENCY,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(DELETE, UPSERT_COMPETENCY_LIST_URL(id));
    return response.data;
  }
);

export const getAllLearningOutcomeList = createAsyncThunk(
  GET_ALL_LEARNING_OUTCOME_LIST,
  async ({
    status,
    domainIds,
    competencyIds,
    proficiencyIds,
  }: {
    status: string;
    domainIds?: string;
    competencyIds?: string;
    proficiencyIds?: string;
  }) => {
    const response = await apiRequest(
      GET,
      GET_ALL_LEARNING_OUTCOME_LIST_URL(
        status,
        domainIds,
        competencyIds,
        proficiencyIds
      )
    );
    return response.data;
  }
);

export const getLearningOutcomeById = createAsyncThunk(
  GET_LEARNING_OUTCOME_BY_ID,
  async ({ id }: { id: number }) => {
    const response = await apiRequest(
      GET,
      UPSERT_LEARNING_OUTCOME_LIST_URL(id)
    );
    return response.data;
  }
);

export const createLearningOutcome = createAsyncThunk(
  CREATE_MASTER_LEARNING_OUTCOME,
  async ({
    createLearningOutcomePayload,
  }: {
    createLearningOutcomePayload: UpsertLearningOutcomePayload;
  }) => {
    const response = await apiRequest(
      createLearningOutcomePayload.id ? PUT : POST,
      UPSERT_LEARNING_OUTCOME_LIST_URL(),
      createLearningOutcomePayload
    );
    return response.data;
  }
);

export const unArchiveLearningOutcome = createAsyncThunk(
  UN_ARCHIVE_LEARNING_OUTCOME,
  async ({
    unArchiveLearningOutcomePayload,
  }: {
    unArchiveLearningOutcomePayload: UnArchiveLearningOutcomePayload;
  }) => {
    const response = await apiRequest(
      PUT,
      UPSERT_LEARNING_OUTCOME_LIST_URL(),
      unArchiveLearningOutcomePayload
    );
    return response.data;
  }
);

const ManageMasterDataSlice = createSlice({
  name: "Manage-master-data ",
  initialState,
  reducers: {
    setJobRoleListByIdToIntialValue(state) {
      state.jobRoleListById = initialState.jobRoleListById;
    },
    setClassRoomListByIdToInitialValue(state) {
      state.classRoomListById = initialState?.classRoomListById;
    },

    setIndustryListByIdToIntialValue(state) {
      state.industryListById = initialState.industryListById;
    },
    setCompetencyListByIdToInitialValue(state) {
      state.competencyListById = initialState?.competencyListById;
    },
    setDomainListByIdToInitialValue(state) {
      state.domainListById = initialState?.domainListById;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getClassRoomList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClassRoomList.fulfilled, (state, action) => {
        state.loading = false;
        state.classRoomList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getClassRoomList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getClassRoomListById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClassRoomListById.fulfilled, (state, action) => {
        state.loading = false;
        state.classRoomListById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getClassRoomListById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createClassRoom.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClassRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.createClassRoom = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createClassRoom.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateClassRoom.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClassRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.updateClassRoom = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateClassRoom.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteClassRoom.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteClassRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteClassRoom = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteClassRoom.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getJobRoleList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.jobRoleList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getJobRoleList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getJobRoleListById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getJobRoleListById.fulfilled, (state, action) => {
        state.loading = false;
        state.jobRoleListById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getJobRoleListById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createJobRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createJobRole.fulfilled, (state, action) => {
        state.loading = false;
        state.createJobRole = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createJobRole.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateJobRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateJobRole.fulfilled, (state, action) => {
        state.loading = false;
        state.updateJobRole = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateJobRole.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteJobRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteJobRole.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteJobRole = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteJobRole.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getIndustryList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIndustryList.fulfilled, (state, action) => {
        state.loading = false;
        state.industryList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getIndustryList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getIndustryListById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIndustryListById.fulfilled, (state, action) => {
        state.loading = false;
        state.industryListById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getIndustryListById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createIndustry.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createIndustry.fulfilled, (state, action) => {
        state.loading = false;
        state.createIndustry = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createIndustry.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateIndustry.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateIndustry.fulfilled, (state, action) => {
        state.loading = false;
        state.updateIndustry = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateIndustry.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteIndustry.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteIndustry.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteIndustry = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteIndustry.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getManageMasterDomainList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getManageMasterDomainList.fulfilled, (state, action) => {
        state.loading = false;
        state.domainList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getManageMasterDomainList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getManageMasterDomainListById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getManageMasterDomainListById.fulfilled, (state, action) => {
        state.loading = false;
        state.domainListById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getManageMasterDomainListById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createDomain.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.createDomain = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createDomain.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateDomain.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.updateDomain = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateDomain.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteDomain.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteDomain = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteDomain.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getCompetencyList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompetencyList.fulfilled, (state, action) => {
        state.loading = false;
        state.competencyList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getCompetencyList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getManageMasterCompetencyListById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getManageMasterCompetencyListById.fulfilled, (state, action) => {
        state.loading = false;
        state.competencyListById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getManageMasterCompetencyListById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createCompetency.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCompetency.fulfilled, (state, action) => {
        state.loading = false;
        state.createCompetency = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createCompetency.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(updateCompetency.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCompetency.fulfilled, (state, action) => {
        state.loading = false;
        state.updateCompetency = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(updateCompetency.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(deleteCompetency.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCompetency.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteCompetency = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(deleteCompetency.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getAllLearningOutcomeList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllLearningOutcomeList.fulfilled, (state, action) => {
        state.loading = false;
        state.learningOutcomeList = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getAllLearningOutcomeList.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(getLearningOutcomeById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLearningOutcomeById.fulfilled, (state, action) => {
        state.loading = false;
        state.learningOutcomeListById = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(getLearningOutcomeById.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(createLearningOutcome.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createLearningOutcome.fulfilled, (state, action) => {
        state.loading = false;
        state.createLearningOutcome = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(createLearningOutcome.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });

    builder
      .addCase(unArchiveLearningOutcome.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(unArchiveLearningOutcome.fulfilled, (state, action) => {
        state.loading = false;
        state.unArchiveLearningOutcome = action.payload;
        const { status_code, message } = action.payload;
        if (status_code === STATUS_200) showToast(message, "success");
      })
      .addCase(unArchiveLearningOutcome.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export const {
  setJobRoleListByIdToIntialValue,
  setClassRoomListByIdToInitialValue,
  setIndustryListByIdToIntialValue,
  setCompetencyListByIdToInitialValue,
  setDomainListByIdToInitialValue,
} = ManageMasterDataSlice.actions;

export default ManageMasterDataSlice.reducer;
