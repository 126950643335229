import React from "react";

import { MUIIcons, H4 } from "../../AbstractElements";
import { Completed } from "../../utils/Constant";

interface MiscellaneousProps {
  name: string;
  status: string;
  color: string;
}

const Miscellaneous: React.FC<MiscellaneousProps> = ({
  name,
  status,
  color,
}) => {
  const textColorClass = color ? `text-${color}` : "text-secondary";
  const italicStyle = { fontStyle: "italic" };

  return (
    <div className="flex-grow-1 mb-2">
      <H4 className={"mb-0"}>{name}</H4>
      <H4 className={textColorClass + " d-flex gap-2 mt-2"} style={italicStyle}>
        {status === Completed && (
          <MUIIcons className="mt-1" iconName="TaskAltOutlined" size={18} />
        )}
        {status}...
      </H4>
    </div>
  );
};

export default Miscellaneous;
