import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

interface GraphProps {
  options: ApexOptions;
  series: ApexAxisChartSeries;
}

const Graph: React.FC<GraphProps> = ({ options, series }) => {
  return (
    <div id="annotationchart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default Graph;
