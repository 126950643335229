import PropTypes from "prop-types";
import { Card, Col, Row } from "reactstrap";

import { Image } from "../../AbstractElements";

import "./style.scss";
import ProfileDetail from "./ProfileDetail";

interface ProfileDetailData {
  id: number;
  icon: string;
  title: string;
  detail: string;
}

interface ProfileData {
  userProfileUrl: string;
  name: string;
  designation: string;
  userDetails: ProfileDetailData[];
}

interface ProfileDataProps {
  userData: ProfileData;
}

const UserProfileCard: React.FC<ProfileDataProps> = ({ userData }) => {
  return (
    <Row>
      <Col md="12" sm="12">
        <Card className="user-profile-hovercard">
          <div className="user-profile-cardheader">
            <></>
          </div>
          <div className="user-profile-avatar text-center">
            <Image
              src={userData?.userProfileUrl}
              className="step1"
              alt="profile"
            />
          </div>
          <ProfileDetail profileDetailData={userData} />
        </Card>
      </Col>
    </Row>
  );
};

UserProfileCard.propTypes = {
  userData: PropTypes.exact({
    userProfileUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    designation: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired,
    expertiseLevel: PropTypes.string.isRequired,
    userDetails: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number.isRequired,
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        detail: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default UserProfileCard;
