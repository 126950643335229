import PropTypes from "prop-types";
import { Label } from "reactstrap";

import { H4, LI, P } from "../../AbstractElements";

export interface TimelineItem {
  date: string;
  heading: string;
  description: string;
}

interface AnnualFunctionHoveringTimelineProps<T> {
  data: T[];
  parseTimeLineList: (data: T[]) => TimelineItem[];
}
const AnnualFunctionHoveringTimeline = <T,>(
  props: AnnualFunctionHoveringTimelineProps<T>
) => {
  const { parseTimeLineList, data } = props;
  const parsedData = parseTimeLineList(data);
  return (
    <>
      {parsedData.map((item, index) => (
        <LI key={index} className="timeline-event">
          <Label className="timeline-event-icon"></Label>
          <div className="timeline-event-wrapper">
            <P className="timeline-thumbnail">{item.date}</P>
            <H4>{item.heading}</H4>
            <P className="pt-3 mb-4">{item.description}</P>
          </div>
        </LI>
      ))}
    </>
  );
};

AnnualFunctionHoveringTimeline.propTypes = {
  data: PropTypes.array.isRequired,
  parseTimeLineList: PropTypes.func.isRequired,
};

export default AnnualFunctionHoveringTimeline;
