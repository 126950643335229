import React, { useEffect } from "react";

import useIsMobile from "../../utils/helper/responsive";
import CommonModal from "../Modal";
import "./style.scss";
import MUIIcons from "../MUIIcon/MUIIcons";

interface SliderModalProps {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
}

const SliderModal: React.FC<SliderModalProps> = ({
  isOpen,
  toggle,
  children,
}) => {
  const isMobileView = useIsMobile();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [isOpen]);

  if (isMobileView) {
    return (
      <CommonModal
        modalBodyClassName=""
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        size="lg"
      >
        <MUIIcons
          size={24}
          iconName="CloseOutlined"
          className="close-button"
          onClick={toggle}
          aria-label="Close"
        />
        {children}
      </CommonModal>
    );
  } else {
    return (
      <>
        <div
          className={`overlay ${isOpen ? "open" : ""}`}
          onClick={toggle}
        ></div>
        <div className={`slider-modal ${isOpen ? "open" : ""}`}>
          <div className="slider-modal-content">
            <MUIIcons
              size={24}
              iconName="CloseOutlined"
              className="close-button"
              onClick={toggle}
              aria-label="Close"
            />
            {children}
          </div>
        </div>
      </>
    );
  }
};

export default SliderModal;
