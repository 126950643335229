import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  POST,
  PUT,
  PUT_ASSIGN_REQUEST,
  PUT_ASSIGN_REQUEST_EXISTING,
  PUT_ASSIGN_URL,
} from "../../Api";
import apiRequest from "../../Api/connector";
import { AssigneesUpdated, SomethingWentWrong } from "../../utils/Constant";
import { showToast } from "../../utils/helper/helper";

interface AssignData {
  AssigneeListData: AssigneeData;
  loading: boolean;
  error: string | null;
}

const initialState: AssignData = {
  AssigneeListData: null,
  loading: false,
  error: null,
};

export const assignUserToCourse = createAsyncThunk(
  PUT_ASSIGN_REQUEST,
  async (postObj: { course_id: number; assignments: Array<any> }) => {
    return await apiRequest(POST, PUT_ASSIGN_URL(), postObj);
  }
);
export const assignUserToExistingCourse = createAsyncThunk(
  PUT_ASSIGN_REQUEST_EXISTING,
  async (postObj: { course_id; assignments }) => {
    return await apiRequest(PUT, PUT_ASSIGN_URL(), postObj);
  }
);

const AssignSlice = createSlice({
  name: "assign",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(assignUserToCourse.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        assignUserToCourse.fulfilled,
        (state, action: PayloadAction<AssigneeData>) => {
          state.loading = false;
          state.AssigneeListData.course_id = action.payload.course_id;
          state.AssigneeListData.assignments = action.payload.assignments;
          showToast(AssigneesUpdated, "success");
        }
      )
      .addCase(assignUserToCourse.rejected, (state, action) => {
        try {
          const error = JSON.parse(action.error.message);
          state.error = error.message;
          showToast(state?.error, "error");
        } catch (e) {
          state.error = SomethingWentWrong;
        }
      });
  },
});

export default AssignSlice.reducer;
