import { useState } from "react";
import DataTable from "react-data-table-component";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

import { H3, H4, MUIIcons, P, ToolTip } from "../../../AbstractElements";
import Divider from "../../../CommonElements/Divider";
import FilterSliderModal from "../../../CommonElements/FilterSliderModal";
import RangeSlider from "../../../CommonElements/RangeSlider";
import SearchBar from "../../../CommonElements/SearchBar";
import StarRating from "../../../CommonElements/StarRating";
import EligibleListing from "../../../container/EligibleList";
import ProjectDescription from "../../../container/ProjectDescription";
import RmReplacementCard from "../../../container/RmReplacementCard";
import TmReplacementCard from "../../../container/TmReplacementCard";
import TotalCountCard from "../../../container/TotalCountCard";
import TotalSeatCard from "../../../container/TotalSeatCard";
import TrainerDropdown from "../../../container/TrainerDropdown";
import UserActionCard from "../../../container/UserActionCard";
import {
  CourseID,
  CourseType,
  ProjectDeliveryMethods,
  ScheduledOn,
} from "../../../utils/Constant";
import { ratingDropdown } from "../../../utils/helper/helper";

export const EligibleListingData = [
  {
    id: 1,
    name: "Salvador Reynoso",
    empId: "1234",
    userId: "@savador",
    mobile: "92881921",
    sbu: "ETB SBU",
    rmName: "Manikandan",
    rmMobileNo: "8765432767",
    selected: false,
    nominatedOn: "2024-10-07T06:46:10Z",
    status: "Pre-Test Completed",
    checkList: [
      {
        id: 1,
        description: "Course is in participant's ILP",
        isChecked: true,
      },
      {
        id: 2,
        description: "Final Assessment score is below 80",
        isChecked: false,
      },
      {
        id: 3,
        description: "Active SGA Enrollment",
        isChecked: true,
      },
      {
        id: 4,
        description: "Course is in participant's ILP",
        isChecked: true,
      },
      {
        id: 5,
        description: "Final Assessment score is below 80",
        isChecked: false,
      },
    ],
  },
  {
    id: 2,
    name: "Albert Einstein",
    empId: "5678",
    userId: "@albert",
    mobile: "9876543221",
    sbu: "ABC DEF",
    rmName: "Murali",
    rmMobileNo: "8765432769",
    selected: false,
    nominatedOn: "2023-10-17T06:46:10Z",
    checkList: [
      {
        id: 1,
        description:
          "Course is in participant's ILP, Course is in participant's ILP,Course is in participant's ILP",
        isChecked: true,
      },
      {
        id: 2,
        description: "Final Assessment score is below 80",
        isChecked: false,
      },
      {
        id: 3,
        description: "Active SGA Enrollment",
        isChecked: true,
      },
      {
        id: 4,
        description: "Course is in participant's ILP",
        isChecked: true,
      },
      {
        id: 5,
        description: "Final Assessment score is below 80",
        isChecked: false,
      },
    ],
  },
];

export const ParticipantTotalCount = {
  available: 30,
  booked: 20,
};

export const TmReplacingCardData = {
  id: 1,
  name: "John Doe",
  empId: "E123",
  mobile: "1234567890",
  sbu: "ETB SBU",
  rmName: "Manikandan",
  rmMobileNo: "8765432767",
};

const SamplePage = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [rating, setRating] = useState<number>(0);

  const RmReplacingCardData = [
    { id: 1, oldName: "John Doe", newName: "Jane Doe" },
    { id: 2, oldName: "Alice Smith", newName: "Bob Johnson" },
    { id: 3, oldName: "Peter Parker", newName: "Clark Kent" },
  ];

  const EligibleListingData = [
    {
      id: 1,
      name: "Salvador Reynoso",
      empId: "1234",
      userId: "@savador",
      mobile: "92881921",
      sbu: "ETB SBU",
      rmName: "Manikandan",
      rmMobileNo: "8765432767",
      selected: false,
      nominatedOn: "2024-10-07T06:46:10Z",
      status: "Pre-Test Completed",
      checkList: [
        {
          id: 1,
          description: "Course is in participant's ILP",
          isChecked: true,
        },
        {
          id: 2,
          description: "Final Assessment score is below 80",
          isChecked: false,
        },
        {
          id: 3,
          description: "Active SGA Enrollment",
          isChecked: true,
        },
        {
          id: 4,
          description: "Course is in participant's ILP",
          isChecked: true,
        },
        {
          id: 5,
          description: "Final Assessment score is below 80",
          isChecked: false,
        },
      ],
    },
    {
      id: 2,
      name: "Albert Einstein",
      empId: "5678",
      userId: "@albert",
      mobile: "9876543221",
      sbu: "ABC DEF",
      rmName: "Murali",
      rmMobileNo: "8765432769",
      selected: false,
      nominatedOn: "2023-10-17T06:46:10Z",
      checkList: [
        {
          id: 1,
          description:
            "Course is in participant's ILP, Course is in participant's ILP,Course is in participant's ILP",
          isChecked: true,
        },
        {
          id: 2,
          description: "Final Assessment score is below 80",
          isChecked: false,
        },
        {
          id: 3,
          description: "Active SGA Enrollment",
          isChecked: true,
        },
        {
          id: 4,
          description: "Course is in participant's ILP",
          isChecked: true,
        },
        {
          id: 5,
          description: "Final Assessment score is below 80",
          isChecked: false,
        },
      ],
    },
  ];

  const TotalCountData = [
    { count: 2, title: "Total Request", icon: "total-request" },
    { count: 20, title: "Approved", icon: "approved" },
    {
      count: 100,
      title: "Declined",
      icon: "declined",
    },
    { count: 100, title: "Expired", icon: "declined" },
  ];

  const TotalSeatData = [
    { id: 1, count: 2, title: "Total Seat" },
    { id: 2, count: 20, title: "Available" },
    { id: 3, count: 100, title: "Booked" },
    { id: 4, count: 0, title: "Waitlist" },
  ];

  const trainersData = {
    primaryTrainers: [{ id: "1", name: "Hemang Sharma", available: false }],
    secondaryTrainers: [
      { id: "2", name: "Hemang Sharma", available: false },
      { id: "3", name: "Prakash", available: true },
    ],
    otherTrainers: [
      { id: "4", name: "Manikandan", available: false },
      { id: "5", name: "ArunKarthik", available: false },
      { id: "6", name: "Hemanth Kunder", available: false },
    ],
  };

  const handleBlur = (selectedTrainers: any[]) => {
    return selectedTrainers;
  };

  const toggleDropdown = (e, rowId) => {
    e.preventDefault();
    setDropdownOpen(prevDropdownOpen =>
      prevDropdownOpen === rowId ? null : rowId
    );
  };

  const handleEditItem = e => {
    e.stopPropagation();
  };

  const toggleTooltip = id => {
    setTooltipOpen(tooltipOpen === id ? null : id);
  };

  const columns = [
    {
      name: "Course name",
      selector: row => row.courseName,
      minWidth: "10rem",
      sortable: true,
      cell: row => (
        <div>
          <Input className="table-input" type="text" value={row.courseName} />
        </div>
      ),
    },
    {
      name: "Instructor",
      selector: row => (
        <div className="d-flex gap-1 align-items-center">
          {row.instructor}
          <span id={`tooltipIcon${row.id}`} className="ml-2 pointer">
            <MUIIcons
              className="pointer"
              iconName="InfoOutlined"
              size={18}
              onClick={() => null}
            />
          </span>
          <ToolTip
            placement="top"
            target={`tooltipIcon${row.id}`}
            isOpen={tooltipOpen === `tooltipIcon${row.id}`}
            toggle={() => toggleTooltip(`tooltipIcon${row.id}`)}
          >
            {row.instructor}
          </ToolTip>
        </div>
      ),
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: "Location",
      selector: row => row.location,
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: "Room",
      selector: row => row.room,
      minWidth: "8rem",
      sortable: true,
    },
    {
      name: "Nomination Period",
      selector: row => row.nominationPeriod,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: "Course Period",
      selector: row => row.coursePeriod,
      minWidth: "15rem",
      sortable: true,
    },
    {
      name: "Available",
      selector: row => row.available,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: "Booked",
      selector: row => row.booked,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: "Waitlist",
      selector: row => row.waitlist,
      minWidth: "6rem",
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Action",
      selector: row => row.id,
      sticky: "right",
      cell: row => (
        <div>
          <Dropdown
            isOpen={row.id === dropdownOpen}
            toggle={e => toggleDropdown(e, row.id)}
          >
            <DropdownToggle color="" className="three-dots">
              <div className="mb-1">&#x22EE;</div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={handleEditItem}>Edit</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 2,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 3,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
    {
      id: 4,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 5,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 6,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
    {
      id: 7,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 8,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 9,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
    {
      id: 10,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Awaiting trainer confirmation..",
    },
    {
      id: 11,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Nomination Closed",
    },
    {
      id: 12,
      courseName: "You will learn how to design beautiful websites..",
      instructor: "Ganesh",
      location: "Chennai",
      room: "Room #4",
      nominationPeriod: "28/08/2024 - 30/09/2025",
      coursePeriod: "28/08/2024 - 30/09/2025",
      available: 0,
      booked: 15,
      waitlist: 0,
      status: "Completed",
    },
  ];

  const getDropdown = (row, key) => {
    return (
      <div className="w-100">
        {
          <Input
            type="select"
            onChange={() => {
              return null;
            }}
            value={row[key]}
          >
            {ratingDropdown.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
        }
      </div>
    );
  };

  const ratingColumns = [
    {
      name: "Participant",
      minWidth: "18rem",
      sortable: true,
      cell: row => (
        <div>
          <P className="mb-1">{row.name}</P>
          <P className="m-0">
            EMP ID: {row.empId} | Mobile : {row.mobile}
          </P>
        </div>
      ),
    },
    {
      name: "Parameter 1",
      cell: row => getDropdown(row, "parameter1"),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Parameter 2",
      cell: row => getDropdown(row, "parameter2"),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Parameter 3",
      cell: row => getDropdown(row, "parameter3"),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Parameter 4",
      cell: row => getDropdown(row, "parameter4"),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Parameter 5",
      cell: row => getDropdown(row, "parameter5"),
      minWidth: "10rem",
      sortable: true,
    },
    {
      name: "Parameter 6",
      cell: row => getDropdown(row, "parameter5"),
      minWidth: "10rem",
      sortable: true,
    },
  ];

  const ratingData = [
    {
      name: "Salvador Reynoso",
      empId: "1235",
      mobile: "8988888888",
      parameter1: "1",
      parameter2: "2",
      parameter3: "3",
      parameter4: "5",
      parameter5: "5",
      parameter6: "6",
      parameter7: "7",
    },
    {
      name: "Salvador Reynoso",
      empId: "1235",
      mobile: "8988888888",
      parameter1: "1",
      parameter2: "1",
      parameter3: "3",
      parameter4: "1",
      parameter5: "1",
      parameter6: "1",
      parameter7: "1",
    },
    {
      name: "Salvador Reynoso",
      empId: "1235",
      mobile: "8988888888",
      parameter1: "1",
      parameter2: "1",
      parameter3: "1",
      parameter4: "1",
      parameter5: "5",
      parameter6: "1",
      parameter7: "1",
    },
  ];

  return (
    <div className="page-body mb-5">
      <Card className="custom-table p-3">
        <div className="d-flex align-items-center">
          <div>
            <H4 className="comment-text">Scheduled list</H4>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
            <SearchBar onSearch={() => null} />
            <div className="mt-1">
              <FilterSliderModal
                dropdowns={[]}
                selectedFilterValues={[]}
                defaultFilterValues={[]}
                setSelectedFilterValues={() => null}
                setDefaultFilterValues={() => null}
                onDone={() => null}
                onClear={() => null}
              />
            </div>
          </div>
        </div>
        <CardBody className="p-2">
          <DataTable
            columns={columns}
            data={data}
            pagination
            fixedHeader
            striped
          />
        </CardBody>
      </Card>
      <div>
        <H3>Range Slider</H3>
        <RangeSlider
          min={100}
          max={500}
          onValueChange={() => {
            ("");
          }}
        />

        <H3 className="mb-3">RM Replacement Card</H3>
        <RmReplacementCard data={RmReplacingCardData} />

        <H3 className="mb-3">TM Replacement Card</H3>
        <TmReplacementCard data={TmReplacingCardData} />

        <Card>
          <CardBody>
            <EligibleListing
              eligibleData={EligibleListingData}
              participantTotalCount={ParticipantTotalCount}
            />
          </CardBody>
        </Card>

        <H3 className="mb-3">User Action Card ( Nomination Tab )</H3>
        <Card>
          <CardBody>
            {EligibleListingData.map((item, index) => (
              <div key={item.id} className="mt-3">
                <UserActionCard
                  userDetails={item}
                  nominatedOn
                  actions
                  firstItem="Replacement"
                  approveOrReject
                />

                {index < EligibleListingData.length - 1 && <Divider />}
              </div>
            ))}
          </CardBody>
        </Card>

        <H3 className="mb-3">User Action Card ( Attendees Tab )</H3>
        <Card>
          <CardBody>
            {EligibleListingData.map((item, index) => (
              <div key={item.id} className="mt-3">
                <UserActionCard
                  userDetails={item}
                  actions
                  firstItem="Grading"
                  secondItem="Cancel"
                  thirdItem="Replacement"
                  status
                />

                {index < EligibleListingData.length - 1 && <Divider />}
              </div>
            ))}
          </CardBody>
        </Card>

        <H3 className="mb-3">TotalCount Card</H3>
        <TotalCountCard data={TotalCountData} />

        <H3 className="mb-3">Total Seat Card</H3>
        <TotalSeatCard data={TotalSeatData} />

        <H3 className="mb-3">Trainer Dropdown</H3>
        <TrainerDropdown
          primaryTrainers={trainersData.primaryTrainers}
          secondaryTrainers={trainersData.secondaryTrainers}
          otherTrainers={trainersData.otherTrainers}
          isMultiSelect={false}
          onBlur={handleBlur}
        />

        <ProjectDescription
          title={ProjectDeliveryMethods}
          description={`Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.`}
          courseValues={[
            {
              label: CourseType,
              value: "ILT",
              id: CourseType,
            },
            {
              label: CourseID,
              value: "EEADVILT",
              id: CourseID,
            },
          ]}
          scheduledOn="10/08/2024"
        />
        {/* <ParticipantAttendanceTable
          userMap={[]}
          participants={participants}
          dates={dates}
        /> */}
      </div>

      <Card>
        <CardBody>
          <div className="course-description__date-wrapper d-flex gap-2">
            <MUIIcons
              className="course-description__calender"
              size={18}
              iconName="CalendarTodayOutlined"
            />
            <P className="course-description__schedule-on m-0">
              {ScheduledOn} 10/08/2024
            </P>
          </div>
        </CardBody>
      </Card>

      <Card className="rating-table p-3">
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-end align-items-center mt-sm-0 mt-2 gap-4 ms-auto">
            <SearchBar onSearch={() => null} />
          </div>
        </div>
        <CardBody className="p-2">
          <DataTable
            columns={ratingColumns}
            data={ratingData}
            pagination
            fixedHeader
            striped
          />
        </CardBody>
      </Card>

      <H4 className="mb-3">Star Rating</H4>
      <StarRating size={30} onClick={rate => setRating(rate)} />
      <p>Your Rating: {rating}</p>
    </div>
  );
};

export default SamplePage;
