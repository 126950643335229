import { createSlice } from "@reduxjs/toolkit";

interface LoaderState {
  loading: boolean;
  activeRequests: number;
}

const initialState: LoaderState = {
  loading: false,
  activeRequests: 0,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoader: state => {
      state.loading = true;
    },
    hideLoader: state => {
      state.loading = false;
    },
    incrementActiveRequests: state => {
      state.activeRequests++;
    },
    decrementActiveRequests: state => {
      if (state.activeRequests > 0) {
        state.activeRequests--;
      }
    },
  },
});

export const {
  showLoader,
  hideLoader,
  incrementActiveRequests,
  decrementActiveRequests,
} = loaderSlice.actions;

export default loaderSlice.reducer;
